import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css"
import "../css/style.css"
import "../css/purpleresponsive.css"
import "../css/font-awesome.min.css"

export default class contactusForm extends Component {
  render() {
    return (
        
        
        <div className="container contactus mt-3">
            <div className="fullcontactus">
            <div className="row">
                <div className="col-lg-6">
                    <div className="contactleftcontent mb-5">
                        <div className="telehealthfeaturehead text-left pt-0">Contact Us</div>
                        <p className="">We want to hear from you! <br></br> Please fill out the form.</p>
                    </div>
                    <div className="contactleftcontent mt-5 mb-5">
                        <div className="telehealthfeaturehead text-left pt-0">Support </div>
                        <p className="mb-0">support@purplehealth.com</p>
                        <hr></hr>
                        <p className="mb-0">Phone: +91 95449 86088</p>
                        <p className="mb-0">Mon-Fri 9:30 AM - 5:30 PM</p>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="contactrightcontent">
                        <input type="text" placeholder="Your Name" className="contactrightfield"></input>
                        <input type="text" placeholder="E-mail" className="contactrightfield"></input>
                        <input type="number" placeholder="Phone Number" className="contactrightfield"></input>
                        <select className="contactrightfield">
                            <option>Feedback</option>
                        </select>
                        <input type="text" placeholder="Leave us a message..." className="contactrightfield"></input>
                        <textarea type="message" placeholder="message" className="contactrightfield"></textarea>
                        <div className="row">
                            <div className="col-md-7">
                                <input type="text" placeholder="Enter Displayed Characters" className="contactrightfield"></input>
                            </div>
                            <div className="col-md-5 captchaBox">
                                <span class="captchaOverTexr noselect" id="lblCaptchaCode">9Hg9x</span>
                                <div class="captchaRefresh">Request New Captcha
                                    <i class="fa fa-refresh"></i>
                                </div>
                            </div>
                        </div>
                        <div className="contactusbtn"><span className="commonButton px-5">Send</span></div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            
        
       
    )
  }
}
