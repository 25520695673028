import React from "react";
import $ from "jquery";
import jwt from "jsonwebtoken";
import { jwtSecret } from "../config";
import { apiCalling } from "../apiService";
import tickImg from "../image/icons/tick.png"

const LoginL = React.lazy(() => import("../layout/login"));
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",  
      loginpassword: "",
      otp: "",
      isDoctorLogin: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.isDoctorLoginFn = this.isDoctorLoginFn.bind(this);
  }

  isDoctorLoginFn = (event) => {
    if(event.target.id === "doctorsLogin") {
      this.setState({isDoctorLogin: true})
      $("#doctorsLogin").addClass("logn-tab-active")
      $("#userLogin").removeClass("logn-tab-active")
    }
    else{
      this.setState({isDoctorLogin: false})
      $("#userLogin").addClass("logn-tab-active")
      $("#doctorsLogin").removeClass("logn-tab-active")
    }
  }
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  chatmessageClickValidate() {
    $(".loader").show();
    const jsonData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "messageChatFree",
        chatDoctorPanelId: window.sessionStorage.getItem("chatDoctorPanelId"),
        memberId: window.sessionStorage.getItem("userId"),
        doctorId: window.sessionStorage.getItem("appDocId"),
        hospitalId: window.sessionStorage.getItem("appHosId"),
      }),
    };
    // console.log(jsonData.body);
    apiCalling(jsonData).then((data) => {
      if (data.success === "1") {
        window.sessionStorage.setItem("API_KEY", data.API_KEY);
        window.sessionStorage.setItem("sessionId", data.sessionId);
        window.sessionStorage.setItem("token", data.token);
        window.sessionStorage.setItem(
          "appDocId",
          window.sessionStorage.getItem("appDocId")
        );
        window.sessionStorage.setItem(
          "appHosId",
          window.sessionStorage.getItem("appHosId")
        );
        window.sessionStorage.setItem("chatDoctorPanelId", data.chatGroupId); //chatDoctorPanelId);
        window.location.href = "/chat/chatmessage";
      } else {
        this.chatmessageClickValidate();
        // console.log("Error: API : messageChatFree");
      }
    });
  }

  componentDidMount() {
    let self = this;
    $("#username").focus();
    //window.sessionStorage.setItem("siteId", "22");
    $("#Loginsubmit").click(function () {
      $("#loginpassword").val("");
      $("#otp").val("");
      let username = self.state.username;
      let loginpassword = self.state.loginpassword;
      var loginErrorMsg = "";
      let formIsValid;
      $("#loginErrorMsg").text("");
      var characters =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var email = $("#username").val();
      var splitemail = email.split(/\r|\r\n|\n/);
      var phoneno = /^[0-9]*$/;
      var emailcount = splitemail.length;
      if (!username) {
        loginErrorMsg = "Please enter registered Email Id or Mobile Number";
        formIsValid = false;
      } else if (
        characters.test(splitemail[emailcount - 1]) === false &&
        $("#username").val() !== "" &&
        !phoneno.test(username)
      ) {
        loginErrorMsg = "Invalid Email Id";
        $("#username").focus();
        formIsValid = false;
      } else if (username.includes("@")) {
        $("#username").hide();
        $("#loginpassword").show();
        $("#loginpassword").focus();
        $("#Loginsubmit").hide();
        $("#OTPLogin").hide();
        $("#PasswordLogin").show();
      } else if (
        !username.includes("@") &&
        username.length !== 10 &&
        phoneno.test(username)
      ) {
        loginErrorMsg = "Invalid Mobile Number";
        formIsValid = false;
      } else if (!username.includes("@") && username.length === 10) {
        let mobileNumber = username;
        const token = jwt.sign(
          {
            username: mobileNumber,
            password: loginpassword,
          },
          jwtSecret()
        );
        const loginOTPRequest = {
          method: "POST",
          body: JSON.stringify({
            functionName: "login",
            token: token,
            siteId: window.sessionStorage.getItem("siteId"),
            otp: "",
          }),
        };
        $(".loader").show();
        // console.log("111111111", loginOTPRequest);
        apiCalling(loginOTPRequest).then((data) => {
          if (data.success === "0") {
            $("#loginErrorMsg").text("Mobile Number is not registered");
            $(".loader").hide();

            formIsValid = false;
            return false;
          } else if (data.success === "1") {
            $("#username").hide();
            $("#loginpassword").hide();
            $("#otp").show();
            $("#otp").focus();
            $("#Loginsubmit").hide();
            $("#PasswordLogin").hide();
            $("#OTPLogin").show();
            $("#loginSuccessMsg").text(
              "OTP has been sent to your Mobile Number"
            );
            setTimeout(function () {
              $("#resendOTP").show();
            }, 30000);
          } else {
            formIsValid = true;
          }
          $(".loader").hide();
        });
      } else {
        formIsValid = true;
      }
      if (!formIsValid) {
        $("#loginErrorMsg").text(loginErrorMsg);
        messageClear();
        return false;
      }
    });
    $("#username").keyup(function (event) {
      if (event.keyCode === 13) {
        $("#Loginsubmit").click();
        $("#loginpassword").focus();
      }
    });
    $("#loginpassword").keyup(function (event) {
      if (event.keyCode === 13) {
        $("#PasswordLogin").click();
      }
    });

    $("#forgotPswd").click(function () {
      $("#SignupWrap").hide();
      $("#signInWrapDiv").hide();
      $("#ForgotPasswordWrap").show();
      $("#emailMobno").focus();
    });
    $("#signUp").click(function () {
      $("#signInWrapDiv").hide();
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").show();
      $("#firstName").focus();
    });

    $("#PasswordLogin").click(function () {
      let username = self.state.username;
      let loginpassword = self.state.loginpassword;
      var loginErrorMsg = "";
      let formIsValid;
      $("#loginErrorMsg").text("");
      if (!loginpassword) {
        loginErrorMsg = "Password required";
        formIsValid = false;
      } else {
        formIsValid = true;
      }
      if (!formIsValid) {
        $("#loginErrorMsg").text(loginErrorMsg);
        return false;
      } else {
        $("#loginErrorMsg").text("");
        const token = jwt.sign(
          {
            username: username,
            password: loginpassword,
          },
          jwtSecret()
        );
        var PasswordLoginRequest = {};
        if (self.state.isDoctorLogin === true) {
          PasswordLoginRequest = {
            method: "POST",
            body: JSON.stringify({
              functionName: "idaLogin",
              token: token,
              siteId: window.sessionStorage.getItem("siteId"),
              otp: "",
            }),
          };
        } else {
          PasswordLoginRequest = {
            method: "POST",
            body: JSON.stringify({
              functionName: "login",
              token: token,
              siteId: window.sessionStorage.getItem("siteId"),
              fromDevice:"Web",
              otp: "",
            }),
          };
        }
        // const PasswordLoginRequest = {
        //   method: "POST",
        //   body: JSON.stringify({
        //     functionName: "login",
        //     token: token,
        //     siteId: window.sessionStorage.getItem("siteId"),
        //     otp: "",
        //   }),
        // };
        // console.log("PasswordLoginRequest...", PasswordLoginRequest.body);
        setTimeout(10000);
        $(".loader").show();
        apiCalling(PasswordLoginRequest).then((data) => {
          if (data.success === "1") {
            $("#loginSuccessMsg").text("Logged in Successfully");
            if (data.userData) {
              // alert(data.userData[0].userId);
              window.sessionStorage.setItem("userId", data.userData[0].userId);
              window.sessionStorage.setItem(
                "emailId",
                data.userData[0].emailId
              );
              window.sessionStorage.setItem(
                "userName",
                data.userData[0].userName
              );
              window.sessionStorage.setItem(
                "logedSiteId",
                window.sessionStorage.getItem("siteId")
              );
              window.sessionStorage.setItem("isLogin", "true");
              $("#showMyName").text(data.userData[0].userName);
              window.sessionStorage.setItem(
                "mobileNumber",
                data.userData[0].mobileNumber
              );
              window.sessionStorage.setItem("role", data.userData[0].role);

              if (data.userData[0].role === "ROLE_DOCTOR") {
                $(".doctorMenuTop").show();
                window.location.href = "/doctor/dashboard";
              }
              if (data.userData[0].role === "ROLE_USER") {
                $(".patientMenuTop").show();
              }
              if (data.userData[0].role === "ROLE_ADMIN") {
                window.location.href = "/admin/dashboard";
              }
              if (data.userData[0].role === "ROLE_GROUPHOSPITALADMIN") {
                window.location.href = "/admin/dashboard";
              }
              //  if(data.userData[0].userId == 23881) {
              //    window.location.href = "/admin/dashboard";
              // }

              setTimeout(() => {
                $(".signInWrap").hide();
                $(".commonButton ").hide();
                $("#signInWrap").hide();
                $("#loginErrorMsg").text("");
              }, 500);
              if (window.sessionStorage.getItem("loginValidate") === "book") {
                window.sessionStorage.setItem("loginValidate", "");
                window.location.href = "/patient/bookappointment";
              }
              if (
                window.sessionStorage.getItem("loginValidate") === "TextChatPay"
              ) {
                window.sessionStorage.setItem("loginValidate", "");
                window.location.href = "/patient/textchatpayment";
              }
              if (
                window.sessionStorage.getItem("loginValidate") ===
                "TextChatFree"
              ) {
                window.sessionStorage.setItem("loginValidate", "");
                self.chatmessageClickValidate();
              }
            }
          } else {
            $("#loginErrorMsg").text(data.errorMessage);
            $("#username").show();
            $("#loginpassword").hide();
            $("#otp").hide();
            $("#username").focus();
            $("#Loginsubmit").show();
            $("#PasswordLogin").hide();
            $("#OTPLogin").hide();
            messageClear();
          }
          $(".loader").hide();
        });
      }
    });
    $("#OTPLogin").click(function () {
      let username = self.state.username;
      let otp = self.state.otp;
      let loginpassword = "";
      var loginErrorMsg = "";
      let formIsValid;
      $("#loginErrorMsg").text("");
      if (!otp) {
        loginErrorMsg = "OTP required";
        formIsValid = false;
      } else {
        formIsValid = true;
      }
      if (!formIsValid) {
        $("#loginErrorMsg").text(loginErrorMsg);
        return false;
      } else {
        $("#loginErrorMsg").text("");
        const token = jwt.sign(
          {
            username: username,
            password: loginpassword,
          },
          jwtSecret()
        );

        const OTPVerifiedLoginRequest = {
          method: "POST",
          body: JSON.stringify({
            functionName: "login",
            token: token,
            siteId: window.sessionStorage.getItem("siteId"),
            otp: otp,
          }),
        };
        $(".loader").show();
        apiCalling(OTPVerifiedLoginRequest).then((data) => {
          if (data.success === "1") {
            $("#loginSuccessMsg").text("Logged in Successfully");
            if (data.userData) {
              window.sessionStorage.setItem("userId", data.userData[0].userId);
              window.sessionStorage.setItem(
                "emailId",
                data.userData[0].emailId
              );
              window.sessionStorage.setItem(
                "userName",
                data.userData[0].userName
              );
              window.sessionStorage.setItem("isLogin", "true");

              window.sessionStorage.setItem(
                "mobileNumber",
                data.userData[0].mobileNumber
              );
              window.sessionStorage.setItem("role", data.userData[0].role);
              if (data.userData[0].role === "ROLE_DOCTOR") {
                $(".doctorMenuTop").show();
                window.location.href = "/doctor/dashboard";
              }
              if (data.userData[0].role === "ROLE_USER") {
                $(".patientMenuTop").show();
              }

              setTimeout(() => {
                $(".signInWrap").hide();
                $(".commonButton ").hide();
                $("#signInWrap").hide();
                $("#loginErrorMsg").text("");
              }, 500);
              if (window.sessionStorage.getItem("loginValidate" === "book")) {
                window.sessionStorage.setItem("loginValidate", "");
                window.location.href = "/patient/bookappointment";
              }
              if (
                window.sessionStorage.getItem("loginValidate") === "TextChatPay"
              ) {
                window.sessionStorage.setItem("loginValidate", "");
                window.location.href = "/patient/textchatpayment";
              }
              if (
                window.sessionStorage.getItem("loginValidate") ===
                "TextChatFree"
              ) {
                window.sessionStorage.setItem("loginValidate", "");
                self.chatmessageClickValidate();
              }
            }
          } else {
            $("#loginErrorMsg").text(data.successMessage);
            $("#loginSuccessMsg").hide();
            setTimeout(function () {
              $("#resendOTP").show();
            }, 30000);
            messageClear();
          }
          $(".loader").hide();
        });
      }
    });

    $("#resendOTP").click(function () {
      let formIsValid;
      $("#loginErrorMsg").text("");
      let mobileNumber = self.state.username;
      let loginpassword = "";
      const token = jwt.sign(
        {
          username: mobileNumber,
          password: loginpassword,
        },
        jwtSecret()
      );
      const ResendOTPRequest = {
        method: "POST",
        body: JSON.stringify({
          functionName: "login",
          token: token,
          siteId: window.sessionStorage.getItem("siteId"),
          otp: "",
        }),
      };
      $(".loader").show();
      apiCalling(ResendOTPRequest).then((data) => {
        if (data.success === "0") {
          $("#loginErrorMsg").text("Mobile Number is not registered");
          formIsValid = false;
          return false;
        } else if (data.success === "1") {
          $("#username").hide();
          $("#loginpassword").hide();
          $("#otp").show();
          $("#otp").focus();
          $("#Loginsubmit").hide();
          $("#PasswordLogin").hide();
          $("#OTPLogin").show();
          $("#resendOTP").hide();
          $("#loginSuccessMsg").show();
        } else {
          formIsValid = true;
        }
        $(".loader").hide();
      });
    });
    function messageClear() {
      setTimeout(() => {
        $("#loginErrorMsg").text("");
      }, 5000);
    }

    $(".popup-login-hide").click(function () {
      $(".signInWrap").hide();
      $("#username").show();
      $("#loginpassword").hide();
      $("#username").val("");
      $("#loginpassword").val("");
      $("#otp").hide();
      $("#otp").val("");
      $("#resendOTP").hide();
    });
    // $(".popup-overlay").click(function () {
    //   $(".signInWrap").hide();
    // });

    $(".popup-content").click(function (event) {
      event.stopPropagation();
    });
  }
  loginRedirectFunction() {
  
  }
  render() {
    return (
      <div class="popup-overlay signInPopup">
        <div className="popup-content">
          <div className="modal-popup">
            <a className="close-popup popup-login-hide" title="close">
              &times;
            </a>
            <div class="signInHead">
              <h2>LOGIN</h2>
              {/* <p>Log in with your Email ID/Mobile number and password</p> */}
              <p
                id="loginErrorMsg"
                style={{ color: "red", fontSize: "12px" }}
              ></p>
              <p
                id="loginSuccessMsg"
                style={{ color: "green", fontSize: "12px" }}
              ></p>
            </div>
            {
              window.sessionStorage.getItem("isIDALogin") === "true" && 
              <div className="logn-tab-main">
                <span className="logn-tab logn-tab-active" id="userLogin" 
                  onMouseDown={ (event) => this.isDoctorLoginFn(event) }>
                    {
                      this.state.isDoctorLogin === false ?
                      // <img src={tickImg} width="15" className="mr-2"></img>
                      <i className="fa fa-check-circle mr-2" aria-hidden="true"></i>
                      :
                      <i className="fa fa-check-circle-o mr-2" aria-hidden="true"></i>
                    }
                  User Login
                </span>
                <span className="logn-tab" id="doctorsLogin" 
                  onMouseDown={ (event) => this.isDoctorLoginFn(event) }>
                  {
                    this.state.isDoctorLogin === true ? 
                    <i className="fa fa-check-circle mr-2" aria-hidden="true"></i>
                    :
                    <i className="fa fa-check-circle-o mr-2" aria-hidden="true"></i>
                  }
                  Doctors Login
                </span>
              </div>
            }
            <div class="signInBody">
              <input
                type="text"
                id="username"
                name="username"
                class="input-design my-2"
                placeholder="Email ID Or Mobile Number"
                onChange={this.handleInputChange}
              />
              <div id="subLiginDiv">
                <input
                  type="password"
                  id="loginpassword"
                  name="loginpassword"
                  class="input-design my-2"
                  placeholder="Password"
                  style={{ display: "none" }}
                  onChange={this.handleInputChange}
                />
                <div style={{ position: "relative" }}>
                  <input
                    type="text"
                    id="otp"
                    name="otp"
                    class="input-design my-2"
                    placeholder="OTP"
                    style={{ display: "none" }}
                    onChange={this.handleInputChange}
                  />

                  <button
                    id="resendOTP"
                    className="otpButton"
                    style={{ display: "none" }}
                  >
                    Resend OTP
                  </button>
                </div>
              </div>
              <div class="signInForgot">
                {/* <span>
                  <div class="form-check form-check-inline radio-item mt-0">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="keepsign"
                    />
                    <label class="form-check-label" for="keepsign"></label>
                  </div>
                  keep me sign in
                </span> */}
                <a id="forgotPswd">Forgot?</a>
              </div>
              <div class="signInButton">
                <button
                  class="formButtonBg"
                  id="Loginsubmit"
                  //on={this.onSubmit}
                >
                  Submit
                </button>
              </div>
              <div className="signInButton">
                <button
                  className="formButtonBg"
                  style={{ display: "none" }}
                  type="submit"
                  id="PasswordLogin"
                >
                  Sign In
                </button>
              </div>
              <div className="signInButton">
                <button
                  className="formButtonBg"
                  style={{ display: "none" }}
                  type="submit"
                  id="OTPLogin"
                >
                  Sign In
                </button>
              </div>
              <div class="signUp">
                <h3>
                  Don't have an account? <a id="signUp">Register here</a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
