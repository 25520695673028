import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import { Link, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../css/style.css";
import "../css/purpleresponsive.css";

import profile from "../image/theme/Screenshot-3.png";
import correct from "../image/theme/correct.png";
import phone from "../image/theme/phoneg.png";
import mail from "../image/theme/mail.png";
import video from "../image/theme/videog.png";
import chat from "../image/theme/chatg.png";
import booking from "../image/theme/inperson.png";
import info from "../image/theme/info.png";
import close from "../image/theme/close.png";
import activedr from "../image/theme/active.png";
import $ from "jquery";
import Bookappointment from "../patient/bookappointment";
import { apiCalling } from "../apiService";
export default class DoctorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callADoctor: "",
      bookApp: "",
      messaging: "",
      emailConsult: "",
      vcChat: "",
      drServiceList: [],
    };
  }
  isLoginCheck() {
    let flag = true;
    if (!window.sessionStorage.getItem("isLogin")) {
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").hide();
      $("#signInWrapDiv").show();
      flag = false;
    }
    return flag;
  }
  addToFavourites(docId) {
    if (this.isLoginCheck() == true) {
      const jsonData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "addToFavourites",
          siteId: window.sessionStorage.getItem("siteId"),
          userId: window.sessionStorage.getItem("userId"),
          favouriteUserId: docId,
        }),
      };
      apiCalling(jsonData).then((data) => {
        if (data.success == "1") {
          alert(data.successMessage);
        }
      });
    }
  }

  componentDidMount() {
    let self = this;

    if (this.props.docData.services) {
      const obj = this.props.docData.services;

      if (obj.includes("Messaging")) {
        $("#chatDr" + this.props.consti).show();
      } else {
        $("#chatDr" + this.props.consti).hide();
      }

      if (obj.includes("Call a Doctor")) {
        $("#callDr" + this.props.consti).show();
      } else {
        $("#callDr" + this.props.consti).hide();
      }
      if (obj.includes("Video Chat")) {
        $("#vcDr" + this.props.consti).show();
      } else {
        $("#vcDr" + this.props.consti).hide();
      }
      if (obj.includes("Email Consult")) {
        $("#emailDr" + this.props.consti).show();
      } else {
        $("#emailDr" + this.props.consti).hide();
      }
      if (obj.includes("Book Appointment")) {
        $("#bookDr" + this.props.consti).show();
      } else {
        $("#bookDr" + this.props.consti).hide();
      }
    }
    if (this.props.docData.isLogged == "1") {
      $("#isLogged" + this.props.docData.doctorId).show();
    } else {
      $("#isLogged" + this.props.docData.doctorId).hide();
    }
  }
  doctorProfile(doctorId) {
    let LazyPage;
    LazyPage = React.lazy(() => import("../home/doctor-detail"));
    ReactDOM.render(
      <div>
        <Suspense fallback={<div></div>}>
          <section>
            <LazyPage doctorId={doctorId} />
          </section>
        </Suspense>
      </div>,
      document.getElementById("lazyHomeBody")
    );
  }
  bookappointmentClick(doctorId, hospitalId, flagOption) {
    let LazyPage;
    LazyPage = React.lazy(() => import("../patient/bookappointment"));
    ReactDOM.render(
      <div>
        <Suspense fallback={<div></div>}>
          <section>
            <LazyPage
              doctorId={doctorId}
              hospitalId={hospitalId}
              flagOption={flagOption}
            />
          </section>
        </Suspense>
      </div>,
      document.getElementById("lazyHomeBody")
    );
  }
  textchatpaymentClick(doctorId, hospitalId, chatDoctorPanelId, textChatPrice) {
    let LazyPage;
    // LazyPage = React.lazy(() => import("../patient/textchatpayment"));

    ReactDOM.render(
      <div>
        <Suspense fallback={<div></div>}>
          <section>
            <LazyPage
              doctorId={doctorId}
              hospitalId={hospitalId}
              chatDoctorPanelId={chatDoctorPanelId}
              textChatPrice={textChatPrice}
            />
          </section>
        </Suspense>
      </div>,
      document.getElementById("lazyHomeBody")
    );
  }
  chatmessageClick(doctorId, hospitalId, chatDoctorPanelId, textChatPrice) {
    let LazyPage;
    LazyPage = React.lazy(() => import("../patient/chatmessage"));
    ReactDOM.render(
      <div>
        <Suspense fallback={<div></div>}>
          <section>
            <LazyPage
              doctorId={doctorId}
              hospitalId={hospitalId}
              chatDoctorPanelId={chatDoctorPanelId}
              textChatPrice={textChatPrice}
            />
          </section>
        </Suspense>
      </div>,
      document.getElementById("lazyHomeBody")
    );
  }
  render() {
    return (
      <div className="doctorDetailProfile doctorListProfile">
        <div className="doctorDetailLeft">
          <label className="drListHeart imgshowhide">
            <input type="checkbox" />
            <span
              class="checkmark"
              onClick={() => this.addToFavourites(this.props.docData.doctorId)}
            ></span>
          </label>
          {/* <div className="drListdrActive">
            <img src={activedr} width="20"></img>
          </div>*/}
          <div className="doctorDetailProfileImg">
            <img src={this.props.docData.image} width="110"></img>
          </div>
          <div class="drName">
            {this.props.docData.displayName}
            <a
              href="#"
              onClick={() => this.doctorProfile(this.props.docData.doctorId)}
            >
              <img src={info}></img>
            </a>
          </div>
          <span class="drspecialist">{this.props.docData.specialization}</span>
          {/*<p class="drspecialist-text mt-2 mb-0">12 Years Experience</p>*/}
          <p class="drspecialist-text mb-0 mt-2">
            {this.props.docData.address}
          </p>
          <p className="drspecialist-text mb-0">
            {this.props.docData.hospitalName}
            {/*<select class="drspecialist-hospital">
              <option value={this.props.docData.hospitalId}>
                {this.props.docData.hospitalName}
              </option>
            </select>*/}
          </p>
          {/* <span
            id={"isLogged" + this.props.consti}
            class="profileAvailable"
            onClick={() => this.profileAvailable(this.props.consti)}
          >
            <img src={correct} width="17"></img>
            Available
          </span> */}
        </div>
        <div className="drServiceMain" id={"drServiceMain" + this.props.consti}>
          <div className="drService-subhead">SERVICES:</div>
          <div
            className="drServiceSp drServiceSp-book"
            id={"bookDr" + this.props.consti}
          >
            <a
              href="#"
              onClick={() =>
                this.bookappointmentClick(
                  this.props.docData.doctorId,
                  this.props.docData.hospitalId,
                  "p"
                )
              }
              className="text-decoration-none"
            >
              <img src={booking}></img> In-Person
              <span className="drService-rate">
                ₹ 0.00{/*this.props.docData.inPersonprice*/}
              </span>
            </a>
          </div>

          <div
            className="drServiceSp drServiceSp-videochat"
            id={"vcDr" + this.props.consti}
          >
            <a
              href="#"
              onClick={() =>
                this.bookappointmentClick(
                  this.props.docData.doctorId,
                  this.props.docData.hospitalId,
                  "v"
                )
              }
              className="text-decoration-none"
            >
              <img src={video}></img> Video Chat
              <span className="drService-rate">
                ₹{this.props.docData.videoChatPrice}
              </span>
            </a>
          </div>

          <div
            className="drServiceSp drServiceSp-call"
            id={"callDr" + this.props.consti}
          >
            <a
              href="#"
              onClick={() =>
                this.bookappointmentClick(
                  this.props.docData.doctorId,
                  this.props.docData.hospitalId,
                  "c"
                )
              }
              className="text-decoration-none"
            >
              <img src={phone}></img> Call
              <span className="drService-rate">
                ₹{this.props.docData.audioPrice}
              </span>
            </a>
          </div>

          <div
            className="drServiceSp drServiceSp-chat"
            id={"chatDr" + this.props.consti}
          >
            {this.props.docData.textChatPrice != "0.00" ? (
              <a
                href="#"
                onClick={() =>
                  this.textchatpaymentClick(
                    this.props.docData.doctorId,
                    this.props.docData.hospitalId,
                    this.props.docData.chatDoctorPanelId,
                    this.props.docData.textChatPrice
                  )
                }
                className="text-decoration-none"
              >
                <img src={chat}></img> Chat(MSG)
                <span className="drService-rate">
                  ₹{this.props.docData.textChatPrice}
                </span>
              </a>
            ) : (
              <a
                href="#"
                onClick={() =>
                  this.chatmessageClick(
                    this.props.docData.doctorId,
                    this.props.docData.hospitalId,
                    this.props.docData.chatDoctorPanelId,
                    this.props.docData.textChatPrice
                  )
                }
                className="text-decoration-none"
              >
                <img src={chat}></img> Chat(MSG)
                <span className="drService-rate">
                  ₹{this.props.docData.textChatPrice}
                </span>
              </a>
            )}
          </div>
          {/* <div className="drServiceSp" id={"emailDr" + this.props.consti}>
            <a href="../patient/email">
              <img src={mail}></img> Email
              <span className="drService-rate">$15,00</span>
            </a>
          </div> */}
          {/* <div className="drServiceSp">
            <Link
              to={{
                pathname: "/home/doctor-detail",
                doctorId: this.props.docData.doctorId,
              }}
              state={{ docId: this.props.docData.doctorId }}
              className="text-decoration-none"
            >
              <img src={info}></img> Doctor info
            </Link>
          </div> */}
        </div>
      </div>
    );
  }
}
