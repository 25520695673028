import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import { Home,Mail,MessageSquare,CheckSquare } from 'react-feather';
export default class DashboardLeftmenu extends Component {

  render() {
    return (
      <div className="dashbrd-menu-leftt">
          <div className="QckLinksNew">
            <div className="LinksList">
              <ul className="dashBoardMenu quicklinks">
                <li>
                  <Link to="/doctor/doctorprofile">
                    <Home/>
                    <span className="analytics_span">Dashboard</span>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <Mail/>
                    <span className="analytics_span">Profile</span>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <MessageSquare/>
                    <span className="analytics_span">Profile</span>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <CheckSquare/>
                    <span className="analytics_span">Profile</span>
                  </Link>
                </li>
              </ul>
            </div>
        </div>
      </div>
    );
  }
}
