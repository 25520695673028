import React, { Component, Suspense } from "react";
import messegeicon from "../image/theme/messege.png";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Messgedetail extends Component {
  componentDidMount() {
    $("#file1,#remfile1,#file2,#remfile2,#file3,#remfile3").hide();
    $("#attach").click(function () {
      if ($("#file1").is(":hidden")) {
        $("#file1").show();
        $("#file1").click();
        $("#remfile1").show();
      } else if ($("#file2").is(":hidden")) {
        $("#file2").show();
        $("#file2").click();
        $("#remfile2").show();
      } else if ($("#file3").is(":hidden")) {
        $("#file3").show();
        $("#file3").click();
        $("#remfile3").show();
      }
    });
    $("#remfile1").click(function () {
      $("#file1").val("");
      $("#file1").hide();
      $("#remfile1").hide();
    });
    $("#remfile2").click(function () {
      $("#file2").val("");
      $("#file2").hide();
      $("#remfile2").hide();
    });
    $("#remfile3").click(function () {
      $("#file3").val("");
      $("#file3").hide();
      $("#remfile3").hide();
    });
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper-smwidth1">
            <div class="col-md-12">
              <div class="form-head memberHead">
                <img src={messegeicon}></img>Message Center
              </div>
              <div class="messageCenterDiv">
                <div class="col-sm-12 mb-3">
                  <a href="/patient/inbox">
                    <input
                      type="button"
                      value="Inbox"
                      class="formButton"
                    ></input>
                  </a>
                  <a href="/patient/send">
                    <input
                      type="button"
                      value="Sent"
                      class="formButton"
                    ></input>
                  </a>
                </div>
                <div class="form-section">
                  <div class="col-sm-12">
                    <textarea
                      class="input-design"
                      placeholder=""
                      rows="6"
                    ></textarea>
                  </div>

                  <div class="col-sm-12 mt-2">
                    <div
                      id="attach"
                      title="Add"
                      class="fileUpload mr-3 float-left"
                    >
                      Add Attachment
                    </div>
                    <p class="note">
                      (Max. 3 files. format: JPEG, PDF, GIF, JPG, PNG, File
                      Size: 10MB maximum.)
                    </p>
                  </div>

                  <div class="fileAttachment col-sm-12">
                    <div class="fileAttachmentList">
                      <input type="file" id="file1" class="attchedFile" />
                      <input
                        type="button"
                        id="remfile1"
                        class="attchedDelete"
                      />
                    </div>
                    <div class="fileAttachmentList">
                      <input type="file" id="file2" class="attchedFile" />
                      <input
                        type="button"
                        id="remfile2"
                        class="attchedDelete"
                      />
                    </div>
                    <div class="fileAttachmentList">
                      <input type="file" id="file3" class="attchedFile" />
                      <input
                        type="button"
                        id="remfile3"
                        class="attchedDelete"
                      />
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <input
                      type="submit"
                      value="Send Message"
                      className="formButton mt-4 mx-0"
                    ></input>
                  </div>

                  <div class="mailArea col-sm-12">
                    <table>
                      <thead>
                        <tr>
                          <th width="50%">Subject</th>
                          <th width="50%">Ask The Doctor - TEST PATIENT</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="info infoDivSec">
                              <span class="name">Me</span>
                              <span class="date" style={{ fontSize: "12px" }}>
                                Tue May 19 2020 4:24 PM
                              </span>
                            </div>
                            <div class="attachment attachmentDivSec">
                              <h5>File attachments</h5>
                              <a
                                href="#"
                                target="_blank"
                                class="color-maintext"
                                style={{ fontSize: "12px" }}
                              >
                                1589885652778_bg.png
                              </a>
                            </div>
                          </td>
                          <td>
                            <p>wew</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="col-sm-12 text-center">
                    <input
                      type="button"
                      value="Close"
                      className="formButton"
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
