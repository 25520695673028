import { Component } from "react";
import Chart from "react-apexcharts";
export default class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
          overviewInPersonData:this.props.overviewInPersonData,
          overviewVideoCallData:this.props.overviewVideoCallData,
          overviewTextChatData:this.props.overviewTextChatData,
          overviewAudioChatData:this.props.overviewAudioChatData,
          dateData:this.props.dateData,
        }
      }
  render() {
    var series = [{
      name: 'In Person',
      data: this.props.overviewInPersonData
    }, {
      name: 'Video Call',
      data: this.props.overviewVideoCallData
    },
    {
      name: 'Text Chat',
      data: this.props.overviewTextChatData
    }, {
      name: 'Audio Chat',
      data: this.props.overviewAudioChatData
    }]

    var options = {
      chart: {
      height: 350,
      type: 'area',
      toolbar: {
          show: true
        }
    },
    legend: {
      position: 'top'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 2,
    },
    xaxis: {
      type: 'datetime',
      categories: this.state.dateData
    },
    yaxis: {
      title: {
        text: "No of Appointments",
      },
      min: 0,
      max: this.props.overViewMax + 5,
    },
  //   tooltip: {
  //     x: {
  //       format: 'dd/MM/yy HH:mm'
  //     },
  //   },
    }

    return (
      <div id="chart">
        <Chart
          options={options} series={series} type="area" height={200}
        />
      </div>
    );
  }
}
