import { Component } from "react";
import Chart from "react-apexcharts";

export default class SelectDoct extends Component {
    constructor(props) {
        super(props);

        this.state = {
          pieChartPercentageCount:this.props.pieChartPercentageCount,
          pieChartlabels:this.props.pieChartlabels
             
        }
      }
      componentDidMount(){
      }
  render() {
    var series= this.state.pieChartPercentageCount
    var options = {                
      chart: {
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
      },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true, 
              label: 'Patient',
              fontSize: '14px',
              fontWeight: 600,
              color: '#000',
            },                
          }
        }
      }
    },
    labels: this.state.pieChartlabels,
    colors:['#800080', '#00bd14'],
    legend: {
      position: 'left'
    },
    responsive: [{
      breakpoint: 991,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    }
    return (
      <div id="chart">
        <Chart
          options={options} series={series} type="donut" height={200}
        />
      </div>
    );
  }
}
