import React, { Component } from 'react';
import { apiCalling } from "../apiService";
import $ from "jquery";

export default class LanguagesKnown extends Component {

  constructor(props) {
    super(props);

    this.state = {

      languageNames: '',
      languageList: [],
      languageDetails: [],
      categoryData: []
    }
    this.saveHandle = this.saveHandle.bind(this)
    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount() {
    this.getDetails();
    this.languageType();

  }

  getDetails() {

    const getDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("userId"),
      }),
    }
    apiCalling(getDetails).then((data) => {
      if (data.success === "1") {        
        this.setState({ categoryData: data.doctorData[0].languages }); 
        // this.setState({ educationList: data.doctorData[0].education});
      }
    });

    // let languageList = this.props.languageList
    // languageList.map((obj) => {
    //   var data = {
    //     "languageId": obj.languageId,
    //     "languages": obj.languages
    //   }
    //   this.state.categoryData.push(data)
    //   this.setState({ categoryData: this.state.categoryData })
    // })
  }

  saveHandle() {
    var language = "";
    var catAppnddData = []
    let errormessage="";
    for (var j = 0; j < this.state.categoryData.length; j++) {
      catAppnddData.push(this.state.categoryData[j].languageId)
      language = catAppnddData.toString().replace(/[\[\]']+/g, '')
    }
      if($("#language").val() === "0" && this.state.categoryData.length === 0){
        errormessage="Select Language Category"
        $("#language").focus();
      }else{  
      }
      if(errormessage==""){
      const saveDatas = {
        method: 'POST',
        body: JSON.stringify({
          functionName: "updateDoctorDetails",
          siteId: window.sessionStorage.getItem("siteId"),
          doctorId: window.sessionStorage.getItem("userId"),
          emailId: window.sessionStorage.getItem("emailId"),
          subFunctionName: "updateLanguageDetails",
          languageId: language,
        })
      }
      $(".loader").show();
      // console.log("saveDatas",saveDatas)
      apiCalling(saveDatas).then((data) => {
        // console.log("language",data)
        $(".loader").hide();
        if (data.success == "1") {
          alert("Details updated successfully")
           window.location.reload(false);
        }
      })

    }else{
      $("#messageLanguageEdit").text(errormessage);
    
      $("#messageLanguageEdit").css("color", "red");
        }
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    })
  }

  languageType() {
    const getData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "masterDetails",
      }),
    };
    apiCalling(getData).then((data) => {
      if (data.success == "1") {
        this.setState({ languageDetails: data.masterData[0].languageData });
      }
    });
  }

  handleAppend() {
    let errormessage="";

    if($("#language").val() === "0"){
      errormessage="Select Language Category"
      $("#language").focus();
    }else{  
    }
    if(errormessage==""){
      var data = {
        "languageId": $("#language").val(),
        "languages": $("#language option:selected").text()
      }
       $('#language').prop('selectedIndex',0);
      this.state.categoryData.push(data)
      this.setState({ categoryData: this.state.categoryData })
    }else{
      $("#messageLanguageEdit").text(errormessage);
      $("#messageLanguageEdit").css("color", "red");
        }
  }

  categoryRemove(index) {
    const list = this.state.categoryData;
    list.splice(index, 1)
    this.setState({ categoryData: list })
  }

  // RemoveData(index) {
  //   const data = this.state.languageList;
  //   data.splice(index, 0)
  //   alert(data)
  //   this.setState({ categoryData: data })
  //   //console.log("tttttt", this.state.categoryData)
  // }

  render() {
    return (

      <div class="row px-2">
             <div class="col-sm-12">
        <div class="col-sm-12 my-2">
            <p id="messageLanguageEdit"></p>
            </div>
      </div>
        <div class="col-sm-12  px-0  px-sm-3">
          <div class="row my-2">
            <div class="col-sm-7 mb-2">
              <select class="input-design w-75"
                id="language"
                name="language"
                // value={this.state.languageNames}
                onChange={this.handleChange}
              >
                <option value="0">Languages Known </option>
                {this.state.languageDetails.map((obj, i) => (
                  <option value={obj.languageId}>{obj.description}</option>
                ))}
              </select>
              <button class="btn-icons" id="btnSearch"><span class=" fa fa-plus" onClick={() => this.handleAppend()}></span></button></div>
          </div>
        </div>
        <div class="col-sm-12  px-0  px-sm-3">
          <h6 class="border-bottom pb-2">Languages Known</h6>
        </div>
        <div class="col-sm-12  px-0  px-sm-3">
          {this.state.categoryData.map((obj, index) => (
            <div class="row my-2">
              <div class="col-sm-7 mb-2" id={index}>
                <input disabled="disabled" type="text" value={obj.languages} class="input-design w-75" />
                <span class=" fa fa-trash color-maintext ml-5" onClick={(e) => this.categoryRemove(index)}></span>
                </div>
            </div>
          ))}

        </div>
        <div className="col-sm-12">
          <input type="submit" value="Save" className="formButton" onClick={() => this.saveHandle()} ></input>
        </div>
      </div>
    )
  }
}
