import React, { Component, Suspense } from "react";

import Dashboardleftmenu from "./dashboardleftmenu";
import { apiCalling } from "../apiService";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drCurrentPassword: "",
      drNewPassword: "",
      drConfirmPassword: "",
      drMessages: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitPassword = this.submitPassword.bind(this);
  }
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  submitPassword() {
    let drCurrentPassword = this.state.drCurrentPassword;
    let drNewPassword = this.state.drNewPassword;
    let drConfirmPassword = this.state.drConfirmPassword;
    let flags = false;
    let msg = "";
    if (!drCurrentPassword) {
      msg = "Please enter your current password properly";
      $("#drCurrentPassword").focus();
    } else if (!drNewPassword) {
      msg = "Please enter your new password properly";
      $("#drNewPassword").focus();
    } else if (!drConfirmPassword) {
      msg = "Please enter your confirm password properly";
      $("#drConfirmPassword").focus();
    } else if (drNewPassword !== drConfirmPassword) {
      msg = "New password and confirm password are not matched ";
      $("#drConfirmPassword").focus();
    } else {
      flags = true;
    }
    if (flags == false) {
      $("#drmessagebloc").css("color", "red");
      this.setState({ drMessages: msg });
    } else {
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "changePassword",
          userId: window.sessionStorage.getItem("userId"),
          siteId: window.sessionStorage.getItem("siteId"),
          bundleId: "",
          currentPassword:drCurrentPassword,
          newPassword: drConfirmPassword,
        }),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          alert(data.successMessage);
          window.location.reload();
        }else if(data.success === "0"){
          alert(data.errorMessage);
          $("#drCurrentPassword").focus();
        }
      });
    }
  }

  componentDidMount() {
    $("#changePassword").addClass("dashBoardLeftMenu-active");
  }
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper1 mt-2 pt-0">
            <div class="row">
              <div class="col-lg-3">
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div class="col-lg-9">
                <div class="form-head mt-4">Change Password</div>
                <div class="dashboard-right form-section">
                  <p class="text-muted px-3">
                    * Provide your new password
                  </p>
                  <p class="error-msg px-3" id="drmessagebloc">
                    {this.state.drMessages}
                  </p>

                  <div class="row row-margin px-3">
                    <label class="col-sm-4 label-text">Current Password</label>
                    <div class="col-sm-6">
                      <input
                        type="password"
                        name="drCurrentPassword"
                        id="drCurrentPassword"
                        class="input-design"
                        placeholder="Type your Current Password"
                        onChange={this.handleInputChange}
                        defaultValue=""
                      />
                    </div>
                  </div>
                  <div class="row row-margin px-3">
                    <label class="col-sm-4 label-text">New Password</label>
                    <div class="col-sm-6">
                      <input
                        type="password"
                        class="input-design"
                        name="drNewPassword"
                        id="drNewPassword"
                        placeholder="New Password"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="row row-margin px-3">
                    <label class="col-sm-4 label-text">Confirm Password</label>
                    <div class="col-sm-6">
                      <input
                        type="password"
                        class="input-design"
                        placeholder="Confirm Password"
                        name="drConfirmPassword"
                        id="drConfirmPassword"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="row px-4">
                    <a href="#">
                      <input
                        type="submit"
                        value="Change Password"
                        className="formButton formButtonBold"
                        onClick={() => this.submitPassword()}
                      ></input>
                    </a>
                    <input
                      type="button"
                      value="cancel"
                      className="formButtonBold cancelButton"
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
