import React, { Component, Suspense, useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import "../css/dashboard.css";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class headerLinks extends Component {

  constructor(props) {
    super(props)
    this.state = {
     
    }
   
  }

  componentDidMount() {  
this.loadPage();
  }

  dashboardclick = () => {
    window.location.href = "/admin/dashboard";
  }
  footerclick(details) {
    this.setState({ footerDetailsVisible: true, details: details })   
  }
  subheaderclick(headerDeatils) {  
    this.setState({ headerSubVisible: true, headerDeatils: headerDeatils })
  }

  loadPage() {
    const LazyPage = React.lazy(() => import("../" + folderName + "/ChangeColor"));
    ReactDOM.render(
      <div>
        <Suspense fallback={<div></div>}>
          <section>
            <LazyPage />
          </section>
        </Suspense>
      </div>,
      document.getElementById("colorModule")
    );
  }
  render() {

    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div className="divBtn" onClick={this.dashboardclick}>Dash Board</div>
        <div class="container">
          <div class="main-wrapper-smwidth">
            <div class="form-section">
              <div className="col-md-12 mt-4">
                <div className="form-subhead mb-0"> Theme Color</div>
                <div class="table-responsive tableBorderOnly" id="colorModule">                 
                </div> 
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>


        {/* <FooterPopup></FooterPopup> */}
      </div>
    );
  }
}
