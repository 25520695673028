import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import tick from "../image/theme/tick.png"

export default class EmailSuccess extends Component {

  render() {
    return (
      <div class="purpleWrap">
        <Header></Header>
        <div class="container">
          <div class="main-wrapper">
            <div class="col-sm-12 text-center">
              <img src={tick} width="50"></img>
              <div class="form-head">Your Request Send Successfully</div>
            </div>
              <div class="form-section-bgcolor form-section-smwidth px-2 px-md-0">
              <div class="text-center form-subhead-brdr">Summary</div>
                <div class="row">
                  <label class="col-sm-6 col-5 label-text-right label-text-cln">Request for</label>
                  <label class="col-sm-6 col-7">Ask the Doctor</label>
                </div>

                <div class="row">
                  <label class="col-sm-6 col-5 label-text-right label-text-cln">Transaction ID</label>
                  <label class="col-sm-6 col-7">071287-190</label>
                </div>
              </div>
                <div class="col-sm-12 text-center pt-3" style={{fontWeight:"500"}}>You will reaceive a response from the Doctor within 24-48 hours.</div>

              <div className="col-sm-12 text-center mt-4">
                <input type="button" value="Close" className="formButtonBold cancelButton"></input>
              </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
