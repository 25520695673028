import React, { Component } from 'react';

export default class About extends Component {
    render(){
        return(
            <div className="aboutSec" id="about-section">
                <div className="mainHeading">About Us</div>
                <p>
                    Samad Hospital is  one of the  leading women wellness centers in India since  1989. It is
                    India’s first NABH accredited hospital for  fertility treatment. Samad Hospitals   has its headquarters  in
                    Thiruvananthapuram,  the capital city of Kerala, also known as   God’s own Country. By  placing  your
                    trust  in  us you are availing  not only  our    medical  or  technical skills but  also requesting  us to  help  
                    you with your emotional and physical needs. We assure you that we are  committed to  your well-being
                    by supporting you with  accredited therapeutic  procedures along with guidance and counselling and
                    medication.
                </p>
            </div>
        )
    }
}