import React, { Component, Suspense } from "react";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //siteId: this.props.match.params.siteId,
    };
    this.changeTheme = this.changeTheme.bind(this);
    this.ChangeHeader = this.ChangeHeader.bind(this);
    this.ChangeReports = this.ChangeReports.bind(this);
  }

  componentDidMount() { }

  changeTheme = (e) => {
    window.location.href = "/home/themeEdit/0";
  };
  changeThemeColor = (e) => {
    window.location.href = "/themeColor";
  };

  ChangeHeader = (e) => {
    window.location.href = "/dashboard/HeaderLinks";
  };

  ChangeReports = (e) => {
    window.location.href =
      "/dashboard/reportDashboard/" +
      window.sessionStorage.getItem("groupHospitalAdminId");
  };

  promoCodeClick = (e) => {
    window.location.href = "/hospital/promocodeDetails";
  };

  ChangeIDABanner = (e) => {
    window.location.href = "/dashboard/BannerIDAListing";
  };
  ChangAnalystics = (e) => {
    window.location.href = "/dashboard/analysis";
  };
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
            <div className="container">
              <div className="sectionItemDiv sectionItemDivBorder">
                {window.sessionStorage.getItem("role") === "ROLE_ADMIN" ? (
                  <div className="sectionItemContentBorder text-center">
                    <span className="sectionTag" onClick={this.changeTheme}>
                      Theme
                    </span>
                    <span
                      className="sectionTag"
                      onClick={this.changeThemeColor}
                    >
                      Theme Color
                    </span>
                    <span className="sectionTag" onClick={this.ChangeHeader}>
                      Header / New Page
                    </span>
                    <span className="sectionTag" onClick={this.ChangeReports}>
                      Reports
                    </span>
                    <span className="sectionTag" onClick={this.ChangeIDABanner}>
                      IDA Banner
                    </span>
                    <span className="sectionTag" onClick={this.ChangAnalystics}>
                      Analytics
                    </span>
                    <span
                      className="sectionTag"
                      onClick={(e) =>
                        (window.location.href = "/admin/dataMigration")
                      }
                    >
                      Data Migration
                    </span>
                    <span
                      className="sectionTag"
                      onClick={(e) =>
                        (window.location.href = "/analytics/dashboard")
                      }
                    >
                      Business Insights
                    </span>
                    <span
                      className="sectionTag"
                      onClick={(e) =>
                        (window.location.href = "/adminManagement/poll-list")
                      }
                    >
                      Poll List
                    </span>
                    {/* <span
                      className="sectionTag"
                      onClick={(e) =>
                        (window.location.href = "/adminManagement/poll-report")
                      }
                    >
                      Poll Report
                    </span> */}
                  </div>
                ) : (
                  <div className="sectionItemContentBorder text-center">
                    <span className="sectionTag" onClick={this.promoCodeClick}>
                      Promocode
                    </span>
                    <span className="sectionTag" onClick={this.ChangeReports}>
                      Reports
                    </span>
                    <span
                      className="sectionTag"
                      onClick={(e) =>
                        (window.location.href = "/admin/adApproval")
                      }
                    >
                      Newsfeeds
                    </span>
                    <span
                      className="sectionTag"
                      onClick={(e) =>
                      (window.location.href =
                        "/adminManagement/idaStoresList")
                      }
                    >
                      Privilege Partners
                    </span>
                    <span
                      className="sectionTag"
                      onClick={(e) =>
                      (window.location.href =
                        "/adminManagement/journalListing")
                      }
                    >
                      Journals
                    </span>
                    <span
                      className="sectionTag"
                      onClick={(e) =>
                      (window.location.href =
                        "/adminManagement/categoriesList")
                      }
                    >
                      Categories List
                    </span>
                    <span
                      className="sectionTag"
                      onClick={(e) =>
                        (window.location.href = "/adminManagement/offerAddPage")
                      }
                    >
                      Add Offer
                    </span>
                    <span
                      className="sectionTag"
                      onClick={(e) =>
                        (window.location.href = "/adminManagement/poll-list")
                      }
                    >
                      Poll List
                    </span>
                  </div>
                )}
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
