import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import tick from "../image/icons/tick.png";
import removeicon from "../image/icons/close-sm.png"
import { apiCalling,apiUrlRelianceFunction } from "../apiService";
import Pagination from "react-js-pagination";
import moment from "moment";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class PharmaNetworkReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            networkData: [],
            totalCount: 0,
            activePage: 1,
            showMessage: "Waiting for data... "
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.pharmaReport();
    }

    handlePageChange(pageNumber) {
         window.scrollTo(0, 0);
        this.setState({ activePage: pageNumber });
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        else {
            offset = parseInt(pageNumber - 1) * 10;
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "pharmaNetworkReport",
                repId: "",
                siteId:window.sessionStorage.getItem("siteId"),
                Offset: JSON.stringify(offset),
                max:max
            }),
        };
        $(".loader").show();
        let apiFunction=folderName!== null ? apiCalling : apiUrlRelianceFunction
        apiFunction(apiJson).then((data) => {
            if (data.success === "1") {
                this.setState({
                    networkData: data.networkData,
                    totalCount: data.totalCount,
                });
                $(".loader").hide();
            }
        });
    }

    pharmaReport() {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "pharmaNetworkReport",
                repId: "",
                siteId:window.sessionStorage.getItem("siteId"),
                Offset: "0",
                max: "10",
            }),
        };
        let apiFunction=folderName!== null ? apiCalling : apiUrlRelianceFunction
        apiFunction(apiJson).then((data) => {
            $(".loader").hide();
            if (data.success === "1") {
                this.setState({
                    networkData: data.networkData,
                    totalCount: data.totalCount,
                    showMessage:data.networkData.length > 0 ? "" : "No data found"
                });
            }
            if (data.success === "0") {
                $(".loader").hide();
                this.setState({
                    networkData: [],
                    showMessage: data.errorMessage
                });
            }
        });
    }


    render() {
        return (
            <div class="purpleWrap">
                <Suspense fallback={<div >Waiting...</div>}>
                    <section>
                    {folderName!==null &&
                     <Header />
                    }
                        <div className='loader'></div>
                        <div class="container">
                            <div class="main-wrapper1">
                                <div class="col-md-12 p-0">
                                    <ul class="breadcrumb">
                                        <li>
                                            <a href= {"/dashboard/reportDashboard/"+this.props.match.params.groupHospitalId}>Reports</a>
                                            <i class="fa fa-chevron-right"></i>
                                        </li>
                                      <li>Pharma Network Report</li>
                                    </ul>
                                </div>
                                <div class="form-head">Pharma Network Report </div>
                                {this.state.networkData.length > 0 ?
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style={{minWidth:"150px"}}>Pharma Rep Name</th>
                                                    <th style={{minWidth:"125px"}}>Pharma Rep Phone Number</th>
                                                    <th style={{minWidth:"150px"}}>Pharma Rep Company Name</th>
                                                    <th style={{minWidth:"150px"}}>Pharma Rep Email-Id</th>
                                                    <th style={{minWidth:"200px"}}>Description</th>
                                                    <th style={{minWidth:"150px"}}>Network</th>
                                                    <th style={{minWidth:"125px"}}>Created Date</th>
                                                    <th style={{minWidth:"150px"}}>Modified Date</th>
                                                    <th style={{minWidth:"150px"}}>Status</th>
                                                    <th style={{minWidth:"150px"}}>Doctor Details</th>
                                                    <th style={{minWidth:"150px"}}>Doctor Name</th>
                                                    <th style={{minWidth:"150px"}}>Hospital Id</th>
                                                    <th style={{minWidth:"150px"}}>Hospital Name</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ fontSize: "12px" }}>
                                                {this.state.networkData.map((data) => {
                                                    let createdOnDate = moment(data.createdOnDate,"DD-MM-YYYY").format("DD-MMM-YYYY")
                                                    let modifiedOnDate = moment(data.modifiedOnDate,"DD-MM-YYYY").format("DD-MMM-YYYY")
                                                    return (
                                                        <tr>
                                                            <td>{data.pharmaRepName}</td>
                                                            <td >{data.pharmaRepPhoneNo}</td>
                                                            <td>{data.pharmaRepCompanyName}</td>
                                                            <td>{data.pharmaRepEmailId}</td>
                                                            <td>{data.profileDescription}</td>
                                                            <td>{data.hasNetwork === true ? <img src={tick} width="20" alt=""></img> : <img src={removeicon} alt="" width="20" ></img>}</td>
                                                            <td>{createdOnDate ==='Invalid date'?'':createdOnDate}</td>
                                                            <td>{modifiedOnDate ==='Invalid date'?'':modifiedOnDate}</td>
                                                            <td>{data.status}</td>
                                                            <td>{data.doctorId}</td>
                                                            <td>{data.doctorName}</td>
                                                            <td>{data.hospitalId}</td>
                                                            <td>{data.hospitalName}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        {this.state.totalCount > 5 ?
                                            <div className="paginationSection orderm-paginationSection">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={this.state.totalCount}
                                                    pageRangeDisplayed={10}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                            : ""}
                                    </div>
                                    :
                                    <p className="error-msg text-center mb-0"> {this.state.showMessage} </p>
                                }
                            </div>
                        </div>
                        {folderName!==null &&
                        <Footer />
    }
                    </section>
                </Suspense>
            </div>
        );
    }
}
