import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import htpdficon from "../image/icons/ht-pdficon.png";
import HtbabyImg1 from "../image/icons/babymilestoneImg1.png";
import HtbabyImg2 from "../image/icons/babymilestoneImg2.png";
import HtbabyImg3 from "../image/icons/babymilestoneImg3.png";
import HtbabyImg4 from "../image/icons/babymilestoneImg4.png";
import HtbabyImg5 from "../image/icons/babymilestoneImg5.png";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { createPortal } from "react-dom";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class HealthTrackbabymilestnsdwdpdf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      babyName: window.sessionStorage.getItem("babyName"),
      babyDOB: window.sessionStorage.getItem("dob"),
      healthScheduleData: [],
    };
  }
  componentDidMount() {
    this.babyPDFDetails();
  }

  babyPDFDetails() {
    const Listing = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHealthSchedule",
        healthTrackerTypeId: "2",
        userId: "", //window.sessionStorage.getItem("userId"),
        dateOfBirth: this.state.babyDOB,
      }),
    };
    $(".loader").show();
    apiCalling(Listing).then((data) => {
      $(".loader").hide(data);
      if (data.success === "1") {
        this.setState({
          healthScheduleData: data.healthScheduleData,
        });
      }
    });
  }

  // printData() {
  //   var divContents = document.getElementById("printTable").innerHTML;
  //   var a = window.open("", "", "height=500, width=500");
  //   a.document.write(`<title>${"Baby Milestones"}</title>`, divContents);
  //   a.document.close();
  //   a.print();
  //   a.close();
  // }

  printData = () => {
    let note1 = [
      [
        "Please note: No two babies are exactly alike. Your baby will develop at his/ her own pace. Most babies reach certain milestones at similar ages. However, it's not unusual for a healthy, “normal” baby to fall behind in some areas or race ahead in others.",
      ],
    ];
    let note2 = [
      [
        "This information is not intended to, and does not constitute, medical or other health advice or diagnosis and should not be used as such. You should always consult with a qualified medical practitioner about your specific circumstances for the right guidance. If your baby was born prematurely (before 37 weeks of pregnancy), you need to look at the milestone guidelines a little differently. The age at which your baby is expected to reach various milestones is based on his/ her due date, not the birthday. So if your baby was born two months early, he/ she will most likely achieve milestones two months later than the guidelines mentioned. Source: Medline, MedIndia, IAP, TheBump",
      ],
    ];

    let bodyData = [];
    this.state.healthScheduleData.map((hs) => {
      let newMonth = hs.fromPeriod % 12;
      newMonth = newMonth == 0 ? 12 : newMonth;
      let fromPeriod = moment(newMonth, "MM").format("MMM");
      let endMonth = hs.toPeriod % 12;
      endMonth = endMonth == 0 ? 12 : endMonth;
      let toPeriod = moment(endMonth, "MM").format("MMM");
      let activity = fromPeriod + " - " + toPeriod + " " + hs.yearData;
      bodyData.push([activity, hs.vaccineName]);
    });

    var pdf = new jsPDF("p", "pt", "letter");
    pdf.text("Baby Milestones For " + this.state.babyName, 40, 50);
    pdf.setFontSize(12);
    pdf.text(
      "Date Of Birth : " +
        moment(this.state.babyDOB, "DD/MM/YYYY").format("DD MMM YYYY"),
      40,
      65
    );
    pdf.setFontSize(12);
    pdf.autoTable({
      theme: "plain",
      margin: { top: 80, bottom: 0 },
      head: [["Period", "Activity"]],
      body: bodyData,
    });

    pdf.autoTable({
      theme: "plain",
      margin: { top: 5, bottom: 0 },
      body: note1,
    });
    pdf.autoTable({
      theme: "plain",
      margin: { top: 5, bottom: 0 },
      body: note2,
    });

    pdf.save("Baby_Milestones ("+this.state.babyName+").pdf");
  };

  // downloadPDF() {

  //   htmlToImage.toPng(document.getElementById('printTable'), { quality: 2.00 })
  //     .then(function (dataUrl) {
  //       const pdf = new jsPDF();
  //       const imgProps = pdf.getImageProperties(dataUrl);
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //       pdf.addImage(dataUrl, 'PNG', 10, 10, pdfWidth + 20, pdfHeight + 10);
  //       pdf.save("Baby Milestones" + ".pdf");
  //     });
  // }

  render() {
    return (
      <div class="purpleWrap ph-selectDisable ph_htPdf ph-mainWrap ">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="loader"></div>
            <Header />
            <div class="container container-auto">
              <div class="main-wrapper1">
                <div className="row ht-pdfcenter">
                  <div className="col-lg-12  ph-milestne-div ">
                    <div className="">
                      <div class="form-section mt-2">
                        <div
                          class="row"
                          style={{ justifyContent: "space-around" }}
                        >
                          <div
                            class="col-lg-10 row pl-3 d-center"
                            id="printTable"
                            style={{
                              width: "1170px",
                              margin: "20px auto 0",
                              padding: "5px 0px",
                              fontSize: "16px",
                            }}
                          >
                            <label class="col-12">
                              {" "}
                              <div className="row">
                                {" "}
                                <h1  style={{ marginBottom: "10px" }} className="telehealthfeaturehead text-center"><span>
                                  Baby Milestones For {this.state.babyName}</span>
                                </h1>
                              </div>
                            </label>

                            <h1 style={{ margin: "0" }} className="telehealthfeaturehead text-left  ph_dob_info" >
                              Date of Birth :{" "}
                                  {moment(
                                    this.state.babyDOB,
                                    "DD/MM/YYYY"
                                  ).format("DD MMM YYYY")}
                            </h1>
                            <div className="col-md-12">
                               <div className="row ph_row">
                                <div className="col-md-12 row">
                            {/* ====== OLD TABLE CODE BEGINS HERE ====== */}

                            {/* <table id="ActivityTable">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{ textAlign: "left", width: "37%" }}
                                  >
                                    Period
                                  </th>
                                  <th scope="col" style={{ textAlign: "left" }}>
                                    Activity
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <div className="tbody">
                                  {this.state.healthScheduleData.map((data) => {
                                    let newMonth = data.fromPeriod % 12;
                                    newMonth = newMonth == 0 ? 12 : newMonth;
                                    let fromPeriod = moment(
                                      newMonth,
                                      "MM"
                                    ).format("MMM");
                                    let endMonth = data.toPeriod % 12;
                                    endMonth = endMonth == 0 ? 12 : endMonth;
                                    let toPeriod = moment(
                                      endMonth,
                                      "MM"
                                    ).format("MMM");
                                    return (
                                      <tr>
                                        <td
                                          scope="row"
                                          data-label="Period :-&nbsp; "
                                        >
                                          {" "}
                                          {fromPeriod} - {toPeriod}{" "}
                                          {data.yearData}
                                        </td>
                                        <td data-label="Activity :-&nbsp; ">
                                          {data.vaccineName}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </div>
                              </tbody>
                            </table> */}

                            {/* ====== OLD TABLE CODE ENDS HERE ====== */}

                                  <div className="ph_tableOuterBaby">
                                  <div className="ph_tableOuterBaby_Wrap">
                                     <table className="ph_tblWrap ph_tblmilestne " id="ph_immunization">
                              <tbody >
                              {this.state.healthScheduleData.map((data) => {
                                    let newMonth = data.fromPeriod % 12;
                                    newMonth = newMonth == 0 ? 12 : newMonth;
                                    let fromPeriod = moment(
                                      newMonth,
                                      "MM"
                                    ).format("MMM");
                                    let endMonth = data.toPeriod % 12;
                                    endMonth = endMonth == 0 ? 12 : endMonth;
                                    let toPeriod = moment(
                                      endMonth,
                                      "MM"
                                    ).format("MMM");
                                    return (
                                <tr>
                                  <td scope="row" >{fromPeriod} - {toPeriod}{" "}
                                          {data.yearData}</td>
                                  <td > <span>{data.vaccineName}</span> </td>
                                  {/* <td rowspan="2" className="ph_babyImg ph_milestone_img"><img src={HtbabyImg1}  /></td> */}
                                </tr>
                                 );
                                })}

                              </tbody>
                                    </table>
                                    </div>
                                     <div className="ph_babyImageRight ph_bbymlstne">
                                            <div   className="ph_babyImg"  ><img src={HtbabyImg1} style={{width: "80px"}}/></div>
                                            <div   className="ph_babyImg"  ><img src={HtbabyImg2} style={{width: "80px"}}/></div>
                                            <div   className="ph_babyImg"  ><img src={HtbabyImg3} style={{width: "80px"}}/></div>
                                            <div   className="ph_babyImg"  ><img src={HtbabyImg4} style={{width: "80px"}}/></div>
                                            <div   className="ph_babyImg"  ><img src={HtbabyImg5} style={{width: "80px"}}/></div>

                                    </div>
                              </div>

                              </div>

                             </div>
                             </div>

                             <div className="row">
                              <div className="col-lg-12">
                                <div className="ht-terms ph_disclaimer_info">
                                  <p><b>Disclaimer</b></p>
                                  <br />
                                  <p> Please note: No two babies are exactly
                                    alike. Your baby will develop at his/ her
                                    own pace. Most babies reach certain
                                    milestones at similar ages. However, it's
                                    not unusual for a healthy, “normal” baby to
                                    fall behind in some areas or race ahead in
                                    others.</p>
                                  <br />
                                  <p>
                                    This information is not intended to, and
                                    does not constitute, medical or other health
                                    advice or diagnosis and should not be used
                                    as such. You should always consult with a
                                    qualified medical practitioner about your
                                    specific circumstances for the right
                                    guidance.
                                     <br />
                                    <p>If your baby was born prematurely
                                    (before 37 weeks of pregnancy), you need to
                                    look at the milestone guidelines a little
                                    differently. The age at which your baby is
                                    expected to reach various milestones is
                                    based on his/ her due date, not the
                                    birthday. So if your baby was born two
                                    months early, he/ she will most likely
                                    achieve milestones two months later than the
                                      guidelines mentioned.</p>
                                    <br />
                                    <p>Source: Medline,
                                    MedIndia, IAP, TheBump</p>
                                  </p>
                                </div>
                              </div>
                               <div className="health-track-dwd">
                           <div className="ht-terms">
                            <button style={{margin: "15px"}} className="formButton formButtonBold ht-dwbttn htnewdwnlod"
                              onClick={this.printData}>Download</button>

                        </div>

                      </div>
                            </div>


                          </div>
                        </div>
                      </div>


                         {/* <div className="health-track-dwd">
                           <div className="ht-terms">
                            <button style={{margin: "0px"}} className="formButton formButtonBold ht-dwbttn htnewdwnlod"
                              onClick={this.printData}>Download</button>

                        </div>

                      </div> */}


                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
