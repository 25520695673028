import React, { Component, Suspense } from "react";
import htbaby from "../image/icons/ht-baby.png";
import htimmune from "../image/icons/ht-immune.png";
import { Link } from "react-router-dom";

export default class HealthTrackers extends Component {

  render() {
    return (
      <div class="purpleWrap ph-selectDisable ph-ht-head ">

            <div class="container container-auto">
              <div class="main-wrapper1">
                {/* <div class="col-sm-12 form-head text-center">Health Trackers </div> */}
              <h4 class="mainHead text-center ph-smd-head"><span>Health Trackers</span> </h4>
                <div className="row ht-biimgs-center">
                  <div className="col-lg-5">
                    <div className="health-track-card ht-biimgs">
                      <Link to={{
                        pathname: "/health-trackers-immunisation",
                      }}>
                      <div className="health-trck-img">
                        <img src={htimmune}/>
                      </div>
                        <p>Immunization Schedule</p>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-5 d-flex-end">

                    <div className="health-track-card ht-biimgs">
                      <Link to={{
                        pathname: "/health-trackers-babymilestone",
                      }}>
                      <div className="health-trck-img">
                          <img src={htbaby } />
                      </div>
                        <p>Baby Milestones</p>
                      </Link>
                  </div>

                  </div>
                </div>

              </div>
            </div>
      </div>
    );
  }
}
