import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
const siteId= window.sessionStorage.getItem("siteId")
export default class Enroll extends Component {
  sendClinicInfo(e) {
    e.preventDefault();
    if ($("#Name").val() === "") {
      alert("Please enter name of clinic");
      return false;
    } else if ($("#Mobile").val() === "") {
      alert("Please enter Mobile Number");
      return false;
    }
    else if(!this.validateMobile($("#Mobile").val())){
      alert("Please enter valid Mobile Number")
      $("#mobileno").focus()
      return false
    }
    else if ($("#Message").val() === "") {
      alert("Please enter Message");
      return false;
    }
    else{
      const joinClinicAPI={
        method: "POST",
        body: JSON.stringify({
          "functionName": "userJoinAsClinic",
          "name": $("#Name").val(),
          "mobileNumber": $("#Mobile").val(),
          "message": $("#Message").val(),
          "siteId": siteId
        })
        }
        apiCalling(joinClinicAPI).then((data) => {
          if(data.success === "1"){
            alert(data.successMessage)
            window.location.reload()
          }
        })
    }
  }
  validateMobile() {
    let validate = /^[0-9]{0,10}$/;
    let a = $("#Mobile").val().toString().slice(0, -1);
    if (!validate.test($("#Mobile").val())) {
      $("#Mobile").val(a);
      return false;
    } else {
      // $("#Mobile").val(a)
      return true;
    }
  }
  render() {
    return (
      <div className="main">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <main role="main">
          <div className="subPage_container entroll">
            <div className="container">
              <div className="content_help">
                <div className="help_border">
                  <h1 className="main_header">
                    YOU CAN JOIN AS A MEMBER OF NETWORK THROUGH ANY HOSPITAL IN
                    THE NETWORK.
                  </h1>
                  <h2 className="leveltwo_head">
                    Kindly send us the details of your Hospital or contact
                    Network hospitals
                  </h2>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          required
                          id="Name"
                          type="text"
                          placeholder="Name"
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          required
                          id="Mobile"
                          type="text"
                          placeholder="Mobile Number"
                          onChange={() => this.validateMobile()}
                        />
                      </div>
                      <div className="col-md-12">
                        <textarea
                          id="Message"
                          rows="4"
                          placeholder="Text your message"
                        ></textarea>
                      </div>
                      <div className="col-md-12 text-right">
                        <button
                          type="submit"
                          className="send_btn"
                          onClick={(e) => this.sendClinicInfo(e)}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
