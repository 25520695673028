import React, { Component } from 'react';
import "../css/purpleresponsive.css"
import "../css/style.css"
import Header from "../layout/header"
import Footer from "../layout/footer"
import TrustedBy from "../home/trustedBy"
import ContactForm from "../home/contactus-form"
const branded = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/branded.png";
const keyfeatures = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/keyfeatures.png";
const reviewer1 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/reviewer1.png";
const reviewer2 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/reviewer-1.png";
const reviewer3 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/reviewer-2.png";

export default class BrandedAppsWebsite extends Component {
    render() {
        return (
            <div className="purpleWrap bg-clr-wthHead yellow-shade">
                <Header></Header>
            <div className="fullabtus">
                <div className="aboutus">
                    <div className="container">
                            <div className="telehealthfeaturehead telehealthfeaturehead-mb">Branded Apps & websites</div>
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="aboutuspara font-20">Are you looking to stand out from the crowd? Are you tired of sending your patients to third party portals that feature other doctors or clinics? If so, Purple Health has amazing solutions for you.</p>
                                <br></br>
                                <p className="aboutuspara font-20">Purple Health has a brand management solution that lets you have your own branded website and mobile app that lets patients book in person or telemedicine appointments directly to you. No more middlemen between you and your patients. Take control and be noticed. </p>
                            </div>
                            <div className="col-lg-1"></div>
                            <div className="col-lg-5 mt-5 image-center">
                                <img src={branded} class="side-image"></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="telehealthfeaturehead telehealthfeaturehead-mb">Key Features</div>
                    <div className="row">
                        <div className="col-lg-6 image-center">
                            <img src={keyfeatures} class="side-image"></img>
                        </div>
                        <div className="col-lg-6 rightaboutuspara">
                            <ul className="ul-liike-p">
                                <li class="font-20">Your own-branded website</li>
                                <li class="font-20">Your own-branded mobile app (Android & iOS available)</li>
                                <li class="font-20">Mobile browser-friendly Responsive Design</li>
                                <li class="font-20">Websites are Search Engine Optimization (SEO) friendly</li>
                                <li class="font-20">Telemedicine Technologies - Video, Chat, Phone, Email</li>
                                <li class="font-20">In-person appointment booking</li>
                                <li class="font-20">Website & Apps integration with Practice Management software</li>
                                <li class="font-20">Single or multiple doctor listings</li>
                                <li class="font-20">Health Articles & Content options</li>
                                <li class="font-20">Secure Website & App Hosting</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="container mb-5">
                    <div className="telehealthfeaturehead  telehealthfeaturehead-mb">Pricing</div>
                    <div className="row price-section-list-Main">
                        <div className="col-lg-6">
                            <div className="price-section-list">
                                <div className="price-section-list-head">Individual Practitioner</div>
                                <div className="price-section-list-oldrate cross-price">Rs. 20,000/yr</div>
                                <div className="price-section-list-newrate">Rs. 5,000/yr !</div>
                                <div class="price-section-list-MainDiv">
                                    <p class="text-capitalize">Taxes Extra</p>
                                    <p class="text-capitalize">Terms & Conditions Apply</p>
                                    <p class="text-capitalize">Limited Time Special Offer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="price-section-list">
                                <div className="price-section-list-head">Hospitals & Enterprise</div>
                                <div className="price-section-list-oldrate price-section-list-contact">Contact <br></br> For Pricing</div>
                                
                            </div>
                        </div>
                    </div>
                </div> */}
                <div class="bgbannerImg">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="bgbannerImg-text">Don't wait!<br></br> Contact PurpleHealth Today!</div>
                            <div class="contactusbtn mt-5">
                                <a href="#" class="text-decoration-none">
                                <span class="commonButton-whitebg">Get Started</span>
                                </a>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                {/* <TrustedBy></TrustedBy> */}
                {/* <ContactForm></ContactForm> */}
            </div>
            <Footer></Footer>
        </div>

      
  
    )
  }
}
