import React, { Component, Suspense } from "react";
import Dashboardleftmenu from "./dashboardleftmenu";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import PromocodeList from "./promocodeList";
import PromocodeAdd from "./promocodeAdd";
import $ from "jquery";
import { apiCalling, changeDateFormat2, changeDateFormat } from "../apiService";

const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class PromocodeDetail extends Component {
  state = {
    promoCodeData: [],
    hospitalData: JSON.parse(
      window.sessionStorage.getItem("footerhospitalData")
    ),
    groupHospitalAdminId: window.sessionStorage.getItem("groupHospitalAdminId"),
    totalCount: 0,
    activePage: 1,
    offset: "0",
    maxRow: "10",
    searchKeyWord: "",
    cmbSelect: "1",
    cmbStatus: "",
    fromDate: "",
    toDate: "",
    hospitalId: "",
    promoCodeId: "",
    promoCodeName: "",
    discountPercentage: "",
    activeFrom: "",
    activeTo: "",
    isAcordionItemExpand: false,
  };

  componentDidMount() {
    this.promoCodeListing();
  }

  promoCodeListing = () => {
    $(".loader").show();
    const promoCodeListing = {
      method: "POST",
      body: JSON.stringify({
        functionName: "promoCodeListing",
        groupId: this.state.groupHospitalAdminId,
        cmbSelect: this.state.cmbSelect,
        txtInput: this.state.searchKeyWord,
        cmbStatus: this.state.cmbStatus,
        max: this.state.maxRow,
        Offset: this.state.offset,
        cmbDate: true,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      }),
    };

    apiCalling(promoCodeListing).then((data) => {
      if (data.success === "1") {
        this.setState({
          promoCodeData: data.promoCodeData,
          totalCount: data.totalCount,
        });
      } else if (data.success === "0") {
        this.setState({ promoCodeData: [], totalCount: 0 });
      }
      $(".loader").hide();
    });
  };

  promoCodeActiveDeactive = (promoCodeId, isActive) => {
    $(".loader").show();
    const activateOrDeactivatePromoCode = {
      method: "POST",
      body: JSON.stringify({
        functionName: "activateOrDeactivatePromoCode",
        promoCodeId: promoCodeId,
        isActive: isActive,
      }),
    };

    apiCalling(activateOrDeactivatePromoCode).then((data) => {
      if (data.success === "1") {
        this.promoCodeListing();
      }
      $(".loader").hide();
    });
  };

  handlePageChange = (pageNumber) => {
    this.pageChange(pageNumber);
  };

  async pageChange(pageNumber) {
    await this.setState({
      activePage: pageNumber,
      offset: (parseInt(pageNumber) - 1) * 10,
    });
    this.promoCodeListing();
  }

  handleCmbSerchChange = () => {
    var cmbSelectVal = $("#cmbSerch").val() !== "0" ? $("#cmbSerch").val() : "";
    $("#searchKeyWord").prop("disabled", cmbSelectVal !== "" ? false : true);
    this.setState({ cmbSelect: cmbSelectVal, searchKeyWord: "" });
    $("#searchKeyWord").focus();
  };

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  cancelPromo = () => {
    this.setState({
      hospitalId: "",
      promoCodeId: "",
      promoCodeName: "",
      discountPercentage: "",
      activeFrom: "",
      activeTo: "",
    });
  };

  validateForm() {
    if (this.state.promoCodeName == "") {
      alert("Promo Code required.");
      $("#promoCodeName").focus();
      return false;
    } else if (this.state.discountPercentage == "") {
      alert("Discount Percentage required.");
      $("#discountPercentage").focus();
      return false;
    } else if (this.state.activeFrom == "") {
      alert("Active From Date required.");
      $("#activeFrom").focus();
      return false;
    } else if (this.state.activeTo == "") {
      alert("Active To Date required.");
      $("#activeTo").focus();
      return false;
    } else {
      return true;
    }
  }

  savePromo = () => {
    if (this.validateForm()) {
      $(".loader").show();
      const savePromoCode = {
        method: "POST",
        body: JSON.stringify({
          functionName: "savePromoCode",
          groupId: this.state.groupHospitalAdminId,
          promoCodeId: this.state.promoCodeId,
          discountPercentage: this.state.discountPercentage,
          hospitalId: this.state.hospitalId,
          promoCodeName: this.state.promoCodeName,
          activeFrom: changeDateFormat(this.state.activeFrom),
          activeTo: changeDateFormat(this.state.activeTo),
        }),
      };
      apiCalling(savePromoCode).then((data) => {
        if (data.success === "1") {
          this.cancelPromo();
          this.promoCodeListing();
          this.setState({ isAcordionItemExpand: false });
        }
        $(".loader").hide();
      });
    }
  };

  handleAccordionItem = () => {
    this.cancelPromo();
    this.setState({
      isAcordionItemExpand: !this.state.isAcordionItemExpand,
    });
  };

  editPromo = (data) => {
    this.setState({
      hospitalId: data.hospitalId,
      promoCodeId: data.promoCodeId,
      promoCodeName: data.promoCodeName,
      discountPercentage: data.discountPercentage,
      activeFrom: changeDateFormat2(data.fromDate),
      activeTo: changeDateFormat2(data.toDate),
      isAcordionItemExpand: true,
    });
  };

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container-fluid">
          <div class="main-wrapper1 mt-2 pt-0">
            <div class="row">
              <div class="col-lg-3">
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div class="col-lg-9">
                <div class="form-head mt-4">Promo Codes</div>
                <div class="dashboard-right form-section">
                  <Accordion allowZeroExpanded="true">
                    <AccordionItem
                      dangerouslySetExpanded={this.state.isAcordionItemExpand}
                    >
                      <AccordionItemHeading onClick={this.handleAccordionItem}>
                        <AccordionItemButton>
                          Add Promo code
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <PromocodeAdd
                          changeHandler={this.changeHandler}
                          cancelPromo={this.cancelPromo}
                          savePromo={this.savePromo}
                          state={this.state}
                        ></PromocodeAdd>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                  <PromocodeList
                    changeHandler={this.changeHandler}
                    handleCmbSerchChange={this.handleCmbSerchChange}
                    handlePageChange={this.handlePageChange}
                    promoCodeActiveDeactive={this.promoCodeActiveDeactive}
                    promoCodeListing={this.promoCodeListing}
                    state={this.state}
                    editPromo={this.editPromo}
                  ></PromocodeList>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
