import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { apiCalling } from "../apiService";
import DatePicker from "react-datepicker";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class DoctorPrescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
          doctorPrescription: [],
          memberId: this.props.match.params.memberId,
          hospitalId: this.props.match.params.hospitalId,
          scheduleId: this.props.match.params.scheduleId,
        }
      }
      componentDidMount() {
        this.getData();
      }
    
      getData() {
        $(".loader").show();
        const apiJsonq = {
          method: "POST",
          body: JSON.stringify({
                functionName: "prescriptionList",
                scheduledTimeSlotId:this.state.scheduleId,
                memberId:this.state.memberId,
                doctorId:window.sessionStorage.getItem("userId"),
                hospitalId:this.state.hospitalId
          }),
        };
        //console.log(apiJsonq.body)
        apiCalling(apiJsonq).then((data) => {
          //console.log("dataPrescript",data)
          if (data.success == "1") {
            $(".loader").hide();
            this.setState({ doctorPrescription: data.prescriptionData });
          }
        });
      }
      render() {
        return (
          <div class="purpleWrap">
            <div className='loader'></div>
            <Suspense fallback={<div class="loader"></div>}>
              <section>
                <Header />
                <div class="container">
                  <div class="main-wrapper1">
                  <div class="col-md-12 p-0">
                                    <ul class="breadcrumb">
                                        <li>
                                            <a href="/doctor/dashboard">Dashboard</a>
                                            <i class="fa fa-chevron-right"></i>
                                        </li>
                                        <li>PRESCRIPTION LIST</li>
                                    </ul>
                                </div>
                    <div class="form-head mt-4">PRESCRIPTION LIST </div>
                    {this.state.doctorPrescription.length > 0 ?
                    <div class="col-md-12 p-0">
                    {this.state.doctorPrescription.map((data, i) => (
                      <div class="prescription-main">
                        <div class="prescription-maindate">
                          <div class="prescription-date">{data.visit_date}</div>
                        </div>
                        <div class="prescription-Subdiv">
                          <div class="prescription-Submain">
                            <div class="prescription-drName">Patient Name : &nbsp;{this.props.match.params.patientName}</div>
                            <div class="prescription-head">
                              <div class="prescription-subhead">Medicine</div>
                              <div class="prescription-subhead">Frequency & Dosage</div>
                              <div class="prescription-subhead">Intake</div>
                              <div class="prescription-subhead">Duration</div>
                              <div class="prescription-subhead">Instruction</div>
                            </div>
                            {data.prescriptionDetails.map((data1, j) => (
                            <div class="prescription-det">
                          <div class="prescription-subdet">{data1.medicineName}</div>
                          <div class="prescription-subdet">{data1.frequency} {data1.dosage}</div>
                          <div class="prescription-subdet">{data1.intake}</div>
                          <div class="prescription-subdet">{data1.days} {data1.duration}</div>
                          <div class="prescription-subdet">{data1.note}</div>
                        </div>
                        ))} 
                          </div>
                        </div>
                      </div>
                      ))}
                    </div>
                       :
                      <p className="error-msg text-center mb-0"> No Records Found </p>
                    } 
    
    
                    {/* {this.state.prescriptionList.length > 0 ?
                      <div class="col-md-12 p-0">
                        {this.state.prescriptionList.map((data, i) => (
                          <div class="table-responsive mb-3">
                            <table class="table table-border-main mb-0">
                              <tr style={{ backgroundColor: "#cae5ff" }}>
                                <th>{data.visitDate}</th>
                                {data.prescriptionByVisit.map((data1, j) => (
                                  <div>
                                  <th colSpan="4">{'Doctor Name:- '+data1.displayName}</th>
                              <tr>
                                <th>Medicine</th>
                                <th>Frequency & Dosage</th>
                                <th>Intake</th>
                                <th>Qty</th>
                                <th>Days</th>
                              </tr>
                                  {data1.prescriptionDetails.map((data2, k) => (
                                      <tr>
                                      <td>{data2.medicineName}</td>
                                      <td>{data2.dosage}</td>
                                      <td>{data2.intake}</td>
                                      <td>{data2.quantity}</td>
                                      <td>{data2.duration}</td>
                                    </tr>
                                  ))}
                                  </div>
                                ))}
                              </tr>
                            </table>
                          </div>
                        ))}
    
                      </div>
                      :
                      <p className="error-msg text-center mb-0"> No Records Found </p>
                    } */} 
                    
                    {/* <div class="col-md-12 p-0">
                    {this.state.prescriptionList.map((data, i) => (
                      <div class="prescription-main">
                        <div class="prescription-maindate">
                          <div class="prescription-date">{data.visitDate}</div>
                        </div>
                        <div class="prescription-Submain">
                          <div class="prescription-drName">Doctor Name</div>
                          <div class="prescription-head">
                            <div class="prescription-subhead">Medicine</div>
                            <div class="prescription-subhead">Frequency & Dosage</div>
                            <div class="prescription-subhead">Intake</div>
                            <div class="prescription-subhead">Qty</div>
                            <div class="prescription-subhead">Days</div>
                          </div>
                          <div class="prescription-det">
                            <div class="prescription-subdet">medicineName</div>
                            <div class="prescription-subdet">medicineName</div>
                            <div class="prescription-subdet">medicineName</div>
                            <div class="prescription-subdet">medicineName</div>
                            <div class="prescription-subdet">medicineName</div>
                          </div>
                        </div>
                      </div>
                      ))}
                    </div> */}
    
                    
                    
    {/* <div class="table-responsive mb-3">
                            <table class="table table-border-main mb-0">
                                <tr style={{backgroundColor:"#cae5ff"}}>
                                    <th>18 jan 2021</th>
                                    <th colSpan="4">Doctor Name: Dr. Rahul Knot</th>
                                </tr>
                                <tr>
                                    <th>Medicine</th>
                                    <th>Frequency & Dosage</th>
                                    <th>Intake</th>
                                    <th>Qty</th>
                                    <th>Days</th>
                                </tr>
                                <tr>
                                    <td>Medicine
                                        <a data-tip data-for='prescription-view'> 
                                            <span class="fa fa-eye ml-3"></span>
                                            <ReactTooltip id='prescription-view' className='presctooltip' effect='solid' type="light">
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Interactions</span>
                                                </div>
                                                <div className="mb-1 ml-2">Not suitable for diabetics</div>
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Drug Reactions</span>
                                                </div>
                                                <div className="mb-1 ml-2">Check if diabetic</div>
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Major Side Effects</span>
                                                </div>
                                                <div className="mb-1 ml-2">AAA</div>
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Precautions</span>
                                                </div>
                                                <div className="mb-1 ml-2">Is patient diabetic</div>
                                            </ReactTooltip> 
                                        </a>
                                    </td>
                                    <td>Frequency & Dosage</td>
                                    <td>Intake</td>
                                    <td>Qty</td>
                                    <td>Days</td>
                                </tr>
                                <tr>
                                    <td>Medicine
                                        <a data-tip data-for='prescription-view'> 
                                            <span class="fa fa-eye ml-3"></span>
                                            <ReactTooltip id='prescription-view' className='presctooltip' effect='solid' type="light">
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Interactions</span>
                                                </div>
                                                <div className="mb-1 ml-2">Not suitable for diabetics</div>
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Drug Reactions</span>
                                                </div>
                                                <div className="mb-1 ml-2">Check if diabetic</div>
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Major Side Effects</span>
                                                </div>
                                                <div className="mb-1 ml-2">AAA</div>
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Precautions</span>
                                                </div>
                                                <div className="mb-1 ml-2">Is patient diabetic</div>
                                            </ReactTooltip> 
                                        </a>
                                    </td>
                                    <td>Frequency & Dosage</td>
                                    <td>Intake</td>
                                    <td>Qty</td>
                                    <td>Days</td>
                                </tr>
                            </table>
                        </div>  */}
                  </div>
                </div>
    
                <Footer />
              </section>
            </Suspense>
          </div>
        );
      }
    }
    
    
    