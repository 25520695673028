import React, { Component, Suspense } from "react";
import user from "../image/theme/user.png";
import { apiCalling } from "../apiService";
import { Link } from "react-router-dom";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

let recorder, stream;
export default class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };


    }
    async startRecording() {
        const video = document.querySelector("video");
        stream = await navigator.mediaDevices.getDisplayMedia({
            video: { mediaSource: "screen" }
        });
        recorder = new MediaRecorder(stream);
        const chunks = [];
        recorder.ondataavailable = e => chunks.push(e.data);
        recorder.onstop = e => {
            const completeBlob = new Blob(chunks, { type: chunks[0].type });
            video.src = URL.createObjectURL(completeBlob);
        };
        recorder.start();
    }
    componentDidMount() {
        const start = document.getElementById("start");
        const stop = document.getElementById("stop");
        const video = document.querySelector("video");

        start.addEventListener("click", () => {
            start.setAttribute("disabled", true);
            stop.removeAttribute("disabled");
            this.startRecording();
        });

        stop.addEventListener("click", () => {
            stop.setAttribute("disabled", true);
            start.removeAttribute("disabled");
            recorder.stop();
            stream.getVideoTracks()[0].stop();
        });
    }

    render() {
        return (
            <div class="purpleWrap">
                <Suspense fallback={<div></div>}>
                    <section>
                        <Header />
                    </section>
                </Suspense>
                <div class="container">
                    <button id="start"> Start Recording </button>
                    <button id="stop" disabled>Stop Recording</button>
                    <video autoplay controls />
                </div>
                <Suspense fallback={<div></div>}>
                    <section>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }
}
