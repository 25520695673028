import {
  awsUrl,
  awsUrlcommon,
  apiUrlReliance,
  apiUrlRelianceElasticSearch,
} from "./config";
import axios from "axios";
var CryptoJS = require("crypto-js");
const apiUrl = awsUrl();
const apiUrlcommon = awsUrlcommon();
const apiUrlRelianceConst = apiUrlReliance();
const apiUrlRelianceElasticSearchConst = apiUrlRelianceElasticSearch();
const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

export function Captcha() {
  var alpha = new Array(
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z"
  );
  var i;
  for (i = 0; i < 6; i++) {
    var a = alpha[Math.floor(Math.random() * alpha.length)];
    var b = alpha[Math.floor(Math.random() * alpha.length)];
    var c = alpha[Math.floor(Math.random() * alpha.length)];
    var d = alpha[Math.floor(Math.random() * alpha.length)];
    var e = alpha[Math.floor(Math.random() * alpha.length)];
    var f = alpha[Math.floor(Math.random() * alpha.length)];
    var g = alpha[Math.floor(Math.random() * alpha.length)];
  }
  var code = a + " " + b + " " + c + " " + d + " " + e + " " + f;
  return code;
}
export function ValidCaptcha(mainCaptcha, txtInput) {
  var string1 = mainCaptcha;
  var string2 = txtInput;
  if (string1 == string2) {
    return true;
  } else {
    return false;
  }
}

export function removeSpaces(string) {
  return string.split(" ").join("");
}
export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export function phonenumberValidation(inputtxt) {
  var mob = /^[1-9]{1}[0-9]{9}$/;
  if (mob.test(inputtxt) == false) {
    return false;
  }
  return true;
}

export function signupValidation(
  firstName,
  // lastName,
  emailId,
  mobileNo,
  password,
  cpassword,
  textCaptcha,
  hiddenCaptcha
) {
  var message = "";
  var field = "xxx";
  let checkCaptch = ValidCaptcha(textCaptcha, hiddenCaptcha);
  if (!firstName) {
    message = "Please Enter Name Properly";
    field = "firstName";
  }
  // else if (!lastName) {
  //   message = "Please Enter Last Name Properly";
  //   field = "lastName";
  // }
  else if (!emailId) {
    message = "Please Enter  Email Id ";
    field = "emailId";
  } else if (!mobileNo) {
    message = "Please Enter  Mobile Number";
    field = "mobileNo";
  } else if (!password) {
    message = "Please Enter  Password ";
    field = "password";
  } else if (!cpassword) {
    message = "Please Enter Confirm Password ";
    field = "cpassword";
  } else if (password != cpassword) {
    message = "Password and confirm password are not matched ";
  } else if (!checkCaptch) {
    message = "Captcha validation failed , please try again";
  } else if (validateEmail(emailId) == false) {
    message = "Please Enter Valid Email ID ";
    field = "emailId";
  } else if (phonenumberValidation(mobileNo) == false) {
    message = "Please Enter valid Mobile Number ";
    field = "mobileNo";
  } else {
    message = "";
  }

  return [message, field];
}

export function contactValidation(
  name,
  // lastName,
  emailId,
  mobileNo
) {
  var message = "";
  var field = "xxx";

  if (!name) {
    alert("Please Enter Name Properly");
    field = "name";
  }
  // else if (!lastName) {
  //   message = "Please Enter Last Name Properly";
  //   field = "lastName";
  // }
  else if (!emailId) {
    alert("Please enter valid emailId");
    field = "emailId";
  } else if (!mobileNo) {
    alert("Please Enter your mobile no");
    field = "mobileNo";
  } else if (validateEmail(emailId) == false) {
    alert("Please enter emailId properly");
    field = "emailId";
  } else if (phonenumberValidation(mobileNo) == false) {
    alert("Check your mobile no properly");
    field = "mobileNo";
  } else {
    message = "";
  }

  return [message, field];
}

export function changeDateFormat(appDate) {
  let newAppDate = "";
  if (appDate) {
    const a = appDate.split("-");
    newAppDate = a[2] + "/" + a[1] + "/" + a[0];
  }
  return newAppDate; //dd/mm/yyyy
}
export function changeDateFormat2(appDate) {
  let newAppDate = "";
  if (appDate) {
    const a = appDate.split("/");
    newAppDate = a[2] + "-" + a[1] + "-" + a[0];
  }
  return newAppDate; //yyyy-mm-dd-
}
// export function apiCalling(requestOptions) {
//   return fetch(apiUrl, requestOptions)
//     .then((data) => data.json())
//     .then((res) => res);
// }
// export function apiUrlRelianceFunction(requestOptions) {
//   return fetch(apiUrlRelianceConst, requestOptions)
//     .then((data) => data.json())
//     .then((res) => res);
// }
// export function awsAPIUrlcommon(req) {
//   return fetch(apiUrlcommon, req)
//     .then((data) => data.json())
//     .then((res) => res);
// }

export function apiCalling(requestOptions) {
  var parameters = JSON.parse(requestOptions.body);

  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(JSON.stringify(parameters)),
    ENC_KEY
  ).toString();
  console.log("body:", requestOptions.body);
  let tokenData = {
    method: "POST",
    body: JSON.stringify({ token: ciphertext }),
  };
  return fetch(apiUrl, requestOptions)
    .then((data) => data.json())
    .then((res) => {
      var bytes = CryptoJS.AES.decrypt(res.token, ENC_KEY);
      var decryptedData = JSON.parse(
        JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      );


     console.log(`apiDataHere - ${requestOptions.body}`, {
        api: requestOptions.body,
        encrypted: {
          token: decryptedData,
        },
        data: res,
      });






      return decryptedData;
    });
}
export function apiUrlRelianceFunction(requestOptions) {
  var parameters = JSON.parse(requestOptions.body);
  console.log("body:", requestOptions.body);
  var cipher = CryptoJS.AES.encrypt(
    JSON.stringify(JSON.stringify(parameters)),
    ENC_KEY
  ).toString();
  let requestParameter = {
    method: "POST",
    body: JSON.stringify({ token: cipher }),
  };
  console.log("requestParameter:", requestOptions.body);

  return fetch(apiUrlRelianceConst, requestParameter)
    .then((data) => data.json())
    .then((res) => {
      var bytes = CryptoJS.AES.decrypt(res.token, ENC_KEY);
      var decryptedData = JSON.parse(
        JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      );
      console.log("decryptedData", decryptedData);
      return decryptedData;
    });
}

export function awsAPIUrlcommon(req) {
  var parameters = JSON.parse(req.body);
  // var cipher = CryptoJS.AES.encrypt(
  //   JSON.stringify(JSON.stringify(parameters)),
  //   ENC_KEY
  // ).toString();
  // console.log("parameters", parameters);
  // // decryptData(cipher);
  // let requestParameter = {
  //   method: "POST",
  //   body: JSON.stringify({ token: cipher }),
  // };
  // console.log(requestParameter,"requestParameterrequestParameter");
  return fetch(apiUrlcommon, req)
    .then((data) => data.json())
    .then((res) => {
      var bytes = CryptoJS.AES.decrypt(res.token, ENC_KEY);
      var decryptedData = JSON.parse(
        JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      );
      // console.log("decryptedData", decryptedData);
      return decryptedData;
    });
}

export async function asyncAwsAPIUrlcommon(req) {
  var parameters = JSON.parse(req.body);
  var cipher = CryptoJS.AES.encrypt(
    JSON.stringify(JSON.stringify(parameters)),
    ENC_KEY
  ).toString();
  // console.log("parameters", cipher);
  // decryptData(cipher);
  let requestParameter = {
    method: "POST",
    body: JSON.stringify({ token: cipher }),
  };

  return await fetch(apiUrlcommon, requestParameter)
    .then((data) => {
      return data.json();
    })
    .then((res) => {
      var bytes = CryptoJS.AES.decrypt(res.token, ENC_KEY);
      var decryptedData = JSON.parse(
        JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      );
      // console.log("decryptedData", decryptedData);
      return decryptedData;
    })
    .catch((err) => {
      return { success: 0, errorMessage: "Something went wrong." };
    });
}

export async function asyncApiUrlRelianceElasticSearch(req) {
  return await fetch(apiUrlRelianceElasticSearchConst, req)
    .then((data) => data.json())
    .then((res) => res);
}

// export async function asyncAwsAPIUrlcommon(req) {
//   return await fetch(apiUrlcommon, req)
//     .then((data) => {
//       return data.json();
//     })
//     .catch((err) => {
//       return { success: 0, errorMessage: "Something went wrong." };
//     });
// }

export function prependZero(v) {
  if (v < 9) return "0" + v;
  else return v;
}

var currentHours = 0;
var currentMinutes = 0;
var currentSeconds = 0;
currentMinutes = (currentMinutes < 10 ? "0" : "") + currentMinutes;
currentHours = (currentHours < 10 ? "0" : "") + currentHours;
export function updateClock() {
  currentSeconds++;
  var currentTimeString = "00:00:00";
  if (currentSeconds > 59) {
    currentMinutes++;
    currentSeconds = 0;
    currentMinutes = (currentMinutes < 10 ? "0" : "") + currentMinutes;
  }
  if (currentMinutes > 59) {
    currentHours++;
    currentMinutes = 0;
  }
  currentSeconds = (currentSeconds < 10 ? "0" : "") + currentSeconds;

  currentTimeString =
    currentHours + ":" + currentMinutes + ":" + currentSeconds;
  return currentTimeString;
}

export function formatDatePickerToddMMyyyy(date) {
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month = date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth();
  return day + "/" + month + "/" + date.getFullYear();
}
export function sendMailOrder(postData) {
  return axios.post(
    "https://2yhrka7axi.execute-api.us-west-2.amazonaws.com/prod/contactus",
    postData
  );
  // return fetch(awsLambdUrl+'/sendmail?subject='+subject+'&mail_text='+mail_text+'&to='+to, {
  //method: 'POST',
}
// export function apiCalling(requestOptions) {
//   let dataToBeEncrypted = JSON.stringify(requestOptions);
//   var cipher = CryptoJS.AES.encrypt(dataToBeEncrypted, ENC_KEY).toString();
//   let requestParameter = { token: cipher };
//   return fetch(apiUrl, requestParameter)
//     .then((data) => data.json())
//     .then((res) => res);
// }
// export function apiUrlRelianceFunction(requestOptions) {
//   let dataToBeEncrypted = JSON.stringify(requestOptions);
//   var cipher = CryptoJS.AES.encrypt(dataToBeEncrypted, ENC_KEY).toString();
//   let requestParameter = { token: cipher };
//   return fetch(apiUrlRelianceConst, requestParameter)
//     .then((data) => data.json())
//     .then((res) => res);
// }
// export function awsAPIUrlcommon(req) {
//   let dataToBeEncrypted = JSON.stringify(req);
//   var cipher = CryptoJS.AES.encrypt(dataToBeEncrypted, ENC_KEY).toString();
//   let requestParameter = { token: cipher };
//   return fetch(apiUrlcommon, requestParameter)
//     .then((data) => data.json())
//     .then((res) => res);
// }
// export function decryptData(reqBody) {
//   try {
//     //   let reqBody = {
//     //     functionName: "getsitedetail",
//     //     siteUrl: "",
//     //     storeId: "",
//     //     sellerId: "",
//     //     categoryId: "1",
//     //   };
//     let dataTobeDecrypted = reqBody;
//     dataTobeDecrypted = JSON.stringify(reqBody);
//     var decryptedData = CryptoJS.AES.decrypt(
//       JSON.stringify(dataTobeDecrypted),
//       ENC_KEY
//     ).toString();
//     console.log(decryptedData);
//     return decryptedData;
//   } catch (e) {
//     return e.errorMessage;
//   }
// }
