import React, { Component, Suspense } from "react";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import InnerHTML from "dangerously-set-html-content";
import { apiCalling } from "../apiService";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class TermsConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAndCondition: window.sessionStorage.getItem("termsAndCondition"),
    };
  }
  componentDidMount() {
    if (folderName !== null) {
    } else {
      // let siteid = this.props.match.params.siteid;
      const jsonReq = {
        method: "POST",
        body: JSON.stringify({
          functionName: "getSiteDetails",
          siteId: "",
          siteUrl: window.location.host,
        }),
      };
      apiCalling(jsonReq).then((data) => {
        if (data.success === "1") {
          this.setState({
            termsAndCondition:
              data.siteDetailsData[0].siteData[0].termsAndCondition,
          });
        }
      });
    }
  }
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div class="loader"></div>}>
          <section>
            {folderName && <Header />}
            <div class="container">
              <div class="main-wrapper1 terms-wrapper">
                <div className="terms-head pb-4">TERMS AND CONDITIONS</div>

                <InnerHTML html={this.state.termsAndCondition} />
              </div>
            </div>
            {folderName && <Footer />}
          </section>
        </Suspense>
      </div>
    );
  }
}
