import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling, awsAPIUrlcommon } from "../../apiService";
import View from "../../image/icons/view.png";
import Pagination from "react-js-pagination";

class dataMigrationList extends React.Component {
  renderMigrationData() {
    const navigation = [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      {
        pageName: "Data Migration",
        url: "/adminManagement/dataMigration",
        current: true,
      },
    ];

    const navigationEdit = [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      {
        pageName: "Data Migration",
        url: "/admin/dataMigration",
      },
      {
        pageName: "Edit Data Migration",
        url: "#",
        current: true,
      },
    ];

    if (this.props.mainDataList.length > 0) {
      return this.props.mainDataList.map((dtaMigrn) => (
        <tr key={dtaMigrn.patientMigrationMainId}>
          <td>{dtaMigrn.patientMigrationMainId}</td>
          <td>{dtaMigrn.fileName}</td>
          <td>{dtaMigrn.createdOn}</td>
          <td>
            <span
              style={{
                color:
                  dtaMigrn.status == 2
                    ? "black"
                    : dtaMigrn.status == 1
                    ? "orange"
                    : "red",
              }}
            >
              {dtaMigrn.status == 2
                ? "Processed"
                : dtaMigrn.status == 1
                ? "Processing"
                : "Pending"}
            </span>
          </td>
          <td style={{ width: "10vw" }} className="nowrap ph_siteedit">
            <div>
              <span>
                <a
                  className="mr-2"
                  onClick={() => {
                    this.props.changeMode(
                      "edit",
                      navigationEdit,
                      dtaMigrn.patientMigrationMainId,
                      "Edit Patient Data Migration",
                      dtaMigrn.fileName,
                      dtaMigrn.status
                    );
                  }}
                >
                  <img src={View} alt="View" width="18" className="img-fluid" />
                </a>
              </span>
            </div>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="6" className="text-center">
            No Data Found
          </td>
        </tr>
      );
    }
  }

  render() {
    const navigationFileUpload = [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      {
        pageName: "Data Migration",
        url: "/admin/dataMigration",
      },
      {
        pageName: "File Upload",
        url: "#",
        current: true,
      },
    ];

    return (
      <div className="HosptlMangemntTable">
        <div className="AddNewHspbtnDiv">
          <a
            className="addnewhspbtn"
            onClick={() =>
              this.props.changeMode(
                "fileUploadView",
                navigationFileUpload,
                0,
                "New Patient Data Migration"
              )
            }
          >
            New Data Migration
          </a>
        </div>

        <table className="table table-bordered-hsp">
          <thead>
            <tr>
              <th className="tablehead-hspmng">Id.</th>
              <th className="tablehead-hspmng">File Name</th>
              <th className="tablehead-hspmng">Created On</th>
              <th className="tablehead-hspmng">File Status</th>
              <th className="tablehead-hspmng">Actions</th>
            </tr>
          </thead>
          <tbody>{this.renderMigrationData()}</tbody>
        </table>
        {this.props.state.mainDataListTotalCount > 5 ? (
          <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth">
            <Pagination
              activePage={this.props.state.activePage}
              itemsCountPerPage={10}
              totalItemsCount={this.props.state.mainDataListTotalCount}
              pageRangeDisplayed={10}
              onChange={(pageNumber) => this.props.handlePageChange(pageNumber)}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default dataMigrationList;
