import React, { Component, Suspense } from "react";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class HealthTrackimmunisationreminders extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div ></div>}>
          <section>
            <div className='loader'></div>
            <Header />
            <div class="container container-auto">
              <div class="main-wrapper1">
                {/* <div class="col-sm-12 form-head text-center"></div> */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="health-track-dwd">
                      <div className="ht-dwnldbutton ht-download">
                        <input type="submit" class="formButton formButtonBold" value="Set Reminders"></input>
                      </div>
                      <p>Pre-login section:</p>
                      <ol>


                        <li> Patient enters mobile number</li>
                        <li> Enters received OTP</li>
                        <li>New user (additional steps):
                          <ol>
                            <li> Enters any additional registration details needed</li>
                            <li> New user created</li>
                          </ol>
                        </li>

                        <li> Gets a confirmation message for reminders</li>
                        <li> Gets reminder SMS 2 days before due date</li>
                        <li>Immunization schedule saved as a pdf in health records</li>
                      </ol>
                      <p>Post login section:</p>
                      <ol>


                        <li> Gets a confirmation message for reminders</li>
                        <li> Gets reminder SMS 2 days before due date</li>
                        <li>Immunization schedule saved as a pdf in health records</li>
                      </ol>


                      <p> Sample SMS:</p>
                      <p> The next vaccine for  Name of patient  is due on  dd-mmm-yyyy . Please visit  Clinic url  to book appointment.</p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
