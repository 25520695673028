import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import { isMobile, isBrowser, isAndroid, isIOS } from "react-device-detect";
import $ from "jquery";
import tick from "../image/theme/tick.png";
export default class DoctorRegSuccess extends Component {
  //   windowClose(){
  //     window.open("about:blank", "_self");
  //     window.close();
  //   }
  constructor(props) {
    super(props);
    this.state = {
      isIDALogin: this.props.location.state,
    };
  }
  // componentDidMount() {
  //   console.log("prrroooops", this.props);
  // }

  render() {
    return (
      <div className="purpleWrap">
        <div className="container">
          <div
            className="main-wrapper pt-5 mt-5"
            style={{ backgroundColor: "#fbfbfb" }}
          >
            <div className="col-sm-12 text-center pt-5">
              <img src={tick} width="50"></img>
              {this.state.isIDALogin.toString() === "true" && (
                <>
                  <div className="col-sm-12 form-head">Thank You</div>
                  <div
                    className="col-sm-12 form-subhead"
                    style={{ fontWeight: "400" }}
                  >
                    Your Clinic Registration with IDA Assist has been completed.
                  </div>
                  <div
                    className="col-sm-12 form-subhead"
                    style={{ fontWeight: "400" }}
                  >
                    You may now proceed with using the Practice Management addon
                    - IDA Assist
                  </div>
                  {isAndroid ? (
                    <div>
                      <Link to="https://purplehealth.page.link/QcRv">
                        <button className="reg_close_button">
                          Proceed to IDA CONNECT.
                        </button>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {isIOS ? (
                    <div>
                      <Link to="https://purplehealth.page.link/oySD">
                        <button className="reg_close_button">Close</button>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
              {this.state.isIDALogin.toString() === "false" && (
                <>
                  <div className="col-sm-12 form-head">Thank You</div>
                  <div
                    className="col-sm-12 form-subhead"
                    style={{ fontWeight: "400" }}
                  >
                    Your Clinic Registration with IMA Hospitals has been
                    completed.
                  </div>
                  <div
                    className="col-sm-12 form-subhead"
                    style={{ fontWeight: "400" }}
                  >
                    You may now proceed with using the Practice Management addon
                    - IMA Hospitals
                  </div>
                </>
              )}

              {this.state.isIDALogin.toString() != "false" &&
              this.state.isIDALogin.toString() != "true" ? (
                <>
                  <div className="col-sm-12 form-head">Thank You</div>
                  <div
                    className="col-sm-12 form-subhead"
                    style={{ fontWeight: "400" }}
                  >
                    Your Clinic Registration has been completed.
                  </div>
                  <div
                    className="col-sm-12 form-subhead"
                    style={{ fontWeight: "400" }}
                  >
                    You may now proceed with using the Practice Management addon
                  </div>
                </>
              ) : (
                ""
              )}

              {/* <button  className="formButton formButtonBold mt-4" onClick={()=>this.windowClose()} >Ok </button> */}
              {/* <a href="#" onclick="close_window();return false;">close</a> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
