import React, { Component } from 'react';
import "../css/purpleresponsive.css"
import "../css/style.css"
import Fortis from "../image/theme/fortis.png"
import Sevenhills from "../image/theme/oliver-peoples-1.png"
import Spartish from "../image/theme/oliver-peoples-3.png"
import Rockland from "../image/theme/oliver-peoples-5.png"
import Sagarhospital from "../image/theme/oliver-peoples-7.png"
import Hcgimg from "../image/theme/oliver-peoples-9.png"
import Agarwaleye from "../image/theme/oliver-peoples-25.png"
import Global from "../image/theme/oliver-peoples-13.png"

export default class trustedBy extends Component {
    render() {
        return (
          <div className="container telehealthtrusted">
          <div className="telehealthfeaturehead">Trusted by</div>
          <div className="row fulltrusted">
            <div className="col-md-3 trustedbottomimg">
              <div className="bottomimg">
             <a href=""><img src={Fortis}></img></a>
             </div>
            </div>
            <div className="col-md-3 trustedbottomimg">
            <div className="bottomimg">
            <a href=""><img src={Sevenhills}></img></a>
              </div>
            </div>
            <div className="col-md-3 trustedbottomimg">
            <div className="bottomimg">
            <a href=""><img src={Spartish}></img></a>
              </div>
            </div>
            <div className="col-md-3 trustedbottomimg">
            <div className="bottomimg">
            <a href=""><img src={Rockland}></img></a>
              </div>
            </div>
            <div className="col-md-3 trustedbottomimg">
            <div className="bottomimg">
            <a href=""><img src={Sagarhospital}></img></a>
              </div>
            </div>
            <div className="col-md-3 trustedbottomimg">
            <div className="bottomimg">
            <a href=""><img src={Hcgimg }></img></a>
              </div>
            </div>
            <div className="col-md-3 trustedbottomimg">
            <div className="bottomimg">
            <a href=""><img src={ Agarwaleye}></img></a>
              </div>
            </div>
            <div className="col-md-3 trustedbottomimg">
            <div className="bottomimg">
            <a href=""><img src={Global}></img></a>
              </div>
            </div>
          </div>
        </div>
          )
        }
      }
      