import React, { Component } from "react";
import { apiCalling } from "../apiService";
import $ from "jquery";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recMobileNo: "",
      recUserId: "",
      textedEmail: "",
      submitOtp: "",
    };
  }
  componentDidMount() {
    $("#emailMobno").focus();
    let self = this;
    
    $("#resetPasswordBut").click(function () {
      let emailMob = $("#emailMobno").val();
      if (!emailMob) {
        $("#resetPswMessage").text("Please Enter Your Email Id or Mobile No");
        $("#resetPswMessage").css("color", "red");
      } else {
        const resetPassword = {
          method: "POST",
          body: JSON.stringify({
            functionName: "forgotPassword",
            siteId: window.sessionStorage.getItem("siteId"),
            username: emailMob,
            mobileNumber: "",
            otp: "",
            newPassword: "",
            userId: "",
          }),
        };
//console.log(resetPassword.body)
        apiCalling(resetPassword).then((data) => {
          if (data.success === "1") {
            // alert("Please enter otp");
            $("#resetPswMessage").show();
            $("#resetPswMessage").text(data.successMessage);
            $("#resetPswMessage").css("color", "green");
            $("#forgotPasswordFirstDiv").hide();
            $("#forgotPasswordOTPDiv").show();
            $("#otpTxt").focus();
           // alert(data.userId)
            self.setState({ recUserId: data.userId });
            self.setState({ recMobileNo: data.mobileNumber });
            self.setState({ textedEmail: emailMob });
          } else {
            alert("Please enter valid Email ID / Mobile No");
          }
        });
      }

      //setTimeout(() => {
      // $("#resetPswMessage").text("");
      // }, 4000);
    });

    $("#emailMobno").keyup(function(event) {
      if (event.keyCode === 13) {
          $("#resetPasswordBut").click();
          $("#otpTxt").focus(); 
      }
     });
       $("#otpTxt").keyup(function(event) {
      if (event.keyCode === 13) {
          $("#resetPasswordButOTP").click();
          $("#forgotpasswordNew").focus(); 
      }
     });
     $("#forgotpasswordConfirm").keyup(function(event) {
      if (event.keyCode === 13) {
          $("#resetPasswordButFinal").click();
      }
     });

    $("#resetPasswordButOTP").click(function () {
      let otpTxt = $("#otpTxt").val();
      if (!otpTxt) {
        $("#resetPswMessage").text("Please Enter Your OTP");
        $("#resetPswMessage").css("color", "red");
      } else {
        const password = {
          method: "POST",
          body: JSON.stringify({
            functionName: "forgotPassword",
            siteId: window.sessionStorage.getItem("siteId"),
            username: self.state.textedEmail,
            mobileNumber: self.state.recMobileNo,
            otp: otpTxt,
            newPassword: "",
            userId: self.state.recUserId,
          }),
        };

        apiCalling(password).then((data) => {
          if (data.success === "1") {
            $("#forgotPasswordOTPDiv").hide();
            $("#forgotPasswordChangePsd").show();
            $("#resetPswMessage").text("Please Change Your Password");
            $("#resetPswMessage").css("color", "green");
            $("#forgotpasswordNew").focus();
            self.setState({ otpTxt: otpTxt });
          } else {
            $("#resetPswMessage").text(data.successMessage);
            $("#resetPswMessage").css("color", "red");
          }
        });
      }
      setTimeout(() => {
        $("#resetPswMessage").text("");
      }, 4000);
    });
    //----------------------------------------------------
    $("#resetPasswordButFinal").click(function () {
      let password1 = $("#forgotpasswordNew").val();
      let password2 = $("#forgotpasswordConfirm").val();
      if (!password1) {
        $("#resetPswMessage").text("Please enter  password ");
        $("#resetPswMessage").css("color", "red");
      } else if (!password2) {
        $("#resetPswMessage").text("Please enter confirm password ");
        $("#resetPswMessage").css("color", "red");
      } else if (password1 !== password2) {
        $("#resetPswMessage").text(" Your password's are not matched !");
        $("#resetPswMessage").css("color", "red");
      } else {
        const password = {
          method: "POST",
          body: JSON.stringify({
            functionName: "forgotPassword",
            siteId: window.sessionStorage.getItem("siteId"),
            username: self.state.textedEmail,
            mobileNumber: self.state.recMobileNo,
            otp: self.state.otpTxt,
            newPassword: password1,
            userId: self.state.recUserId,
          }),
        };
       // console.log(password.body)
        apiCalling(password).then((data) => {
          if (data.success === "1") {
            $("#resetPswMessage").text("Password Changed Successfully");
            $("#resetPswMessage").css("color", "green");
          
          }
          window.location.reload();
        });
      }
  

      setTimeout(() => {
        $("#resetPswMessage").text("");
      }, 4000);
    });

    $("#forgotPasswordOTPDiv").hide();
    $("#forgotPasswordChangePsd").hide();

    // $(".popup-overlay").click(function () {
    //   $(".signInWrap").hide();
    // });
    $(".popup-login-hide").click(function () {
      $(".signInWrap").hide();
    });
    $(".popup-content").click(function (event) {
      event.stopPropagation();
    });
  }
  render() {
    return (
      <div class="popup-overlay">
        <div className="popup-content">
          <div className="modal-popup">
            <a className="close-popup popup-login-hide">&times;</a>
            <div class="signInHead">
              <h2>Forgot Password</h2>
              <p>
                Enter email id or mobile number associated with your account to receive 
                OTP.
              </p>
            </div>
            <span id="resetPswMessage"></span>
            <div class="signInBody">
              <div id="forgotPasswordFirstDiv">
                <input
                  type="text"
                  class="input-design my-2"
                  placeholder="Enter Your Email ID or Mobile Number"
                  id="emailMobno"
                  autoFocus
                />

                <div class="signInButton">
                  <input
                    type="button"
                    value="Continue"
                    class="formButtonBg"
                    id="resetPasswordBut"
                  />
                </div>
              </div>
              <div id="forgotPasswordOTPDiv">
                <input
                  type="text"
                  class="input-design my-2"
                  placeholder="Enter Your OTP"
                  id="otpTxt"
                />

                <div class="signInButton">
                  <input
                    type="button"
                    value="Submit"
                    class="formButtonBg"
                    id="resetPasswordButOTP"
                  />
                </div>
              </div>
              <div id="forgotPasswordChangePsd">
                <input
                  type="password"
                  id="forgotpasswordNew"
                  name="forgotpasswordNew"
                  class="input-design my-2"
                  placeholder="New Password"
                />
                <input
                  type="password"
                  id="forgotpasswordConfirm"
                  name="forgotpasswordConfirm"
                  class="input-design my-2"
                  placeholder="Confirm Password"
                />

                <div class="signInButton">
                  <input
                    type="button"
                    value="Continue"
                    class="formButtonBg"
                    id="resetPasswordButFinal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
