import React, { Component, Suspense } from "react";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class HealthRecordLogin extends Component {
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper">
            <div class="text-center form-head">Health Records</div>
            <div class="form-section-bgcolor form-section-smwidth px-2">
              <div class="mb-4 form-subhead text-center">
                For your data security, please do the following
              </div>
              <div class="col-sm-8 text-center m-auto pb-4">
                <small>
                  Your One Time Pin (OTP) has been send to your Mobile Number
                  Please Enter the OTP below
                </small>
              </div>
              <div class="row">
                <label class="col-sm-6 col-5 label-text-right label-text-cln">
                  Mobile Number
                </label>
                <label class="col-sm-6 col-7">4567823410</label>
              </div>

              <div class="row row-margin">
                <label class="col-sm-6 col-5 label-text-right label-text-cln">
                  One Time Pin
                </label>
                <label class="col-sm-6 col-7">
                  <input type="text" class="input-design input-box-w-150" />
                  <i class="fa fa-refresh ml-2"></i>
                </label>
              </div>
            </div>

            <div className="col-sm-12 text-center">
              <a href="./health-record">
                <input
                  type="button"
                  value="Verify"
                  className="formButton formButtonBold"
                ></input>
              </a>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
