import { Component } from "react";


export default class AnalyticsReport extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount(){
    }
    render(){
        return(
            <div>
                Hi
            </div>
        )
    }
}