import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { apiCalling } from "../apiService";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class paymentSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sucessMessage: "",
      transactionId: "",
      paymentId: "",
      orderNo: "",
      paymentStatus: "",
      appointmentId: "",
      amount: "",
    };
    this.closeAppointment = this.closeAppointment.bind(this);
  }
  closeAppointment() {
    window.location.href = "/home";
  }

  componentDidMount() {
    let dataSet = JSON.parse(window.sessionStorage.getItem("appointmentSuccessArray"));
    let dataSet2 = JSON.parse(window.sessionStorage.getItem("appointmentSuccessArray2"));
    let data=JSON.parse(window.sessionStorage.getItem("smsAndEmailFunction"));
    this.confirmVideoAppointmentMail(data);
   // console.log(">" + dataSet);
   // console.log("....." + dataSet2);
    if (dataSet.success === "1") {
      let message=dataSet.successMessage
      message=message.split("and")
      this.setState({
        sucessMessage: message[1],
        transactionId: dataSet.result[0].transactionId,
        paymentId: dataSet.result[0].paymentId,
        orderNo: dataSet.result[0].orderNo,
        paymentStatus: dataSet.result[0].paymentStatus,
        appointmentId: dataSet.result[0].appointmentId,
        amount: dataSet.result[0].amount,
      });
    }
  }

  async confirmVideoAppointmentMail(data){
   // console.log(data);
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "confirmVideoAppointmentMail",
        siteId: "",
        jsonMailOptions: data.result[0].jsonMailOptions,
        jsonAdminDocMailOptions: data.result[0].jsonAdminDocMailOptions,
        smsObj: data.result[0].smsObj,
        smsDoctorObj: data.result[0].smsDoctorObj,
        smsAdminObj: data.result[0].smsAdminObj,
       
      }),
    };
   // console.log(apiJson.body);
    apiCalling(apiJson).then((data) => {

    });

  }
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <Helmet>
          <title>{"Thank You | "+window.sessionStorage.getItem("siteName")}</title>
          <meta
            name="description"
            content={"Thank You | In-person | Video Chat | Audio Chat | Text Chat | "+window.sessionStorage.getItem("siteName")}
          />        
        </Helmet>
        <div class="container">
          <div class="main-wrapper main-wrapper-smwidth">
            <div class="col-sm-12 text-center">
              {/* <h4 class="my-4">Transaction Summary</h4> */}
            </div>
            <div class="form-section">
              <p class="" style={{ textAlign: "center",color:"Green" }}>
               <b> Your {window.sessionStorage.getItem("appTypeShow")} appointment request has been sent. </b>
              </p>
              <p style={{ textAlign: "center" }}>Please wait for confirmation.</p>
              <p style={{ textAlign: "center" }}>{this.state.sucessMessage}</p>
              {window.sessionStorage.getItem("appTypeShow")!=="In Person" &&
              <span>
              <p style={{ textAlign: "center",color:"GREEN" }}>
               <b> Please use the latest version of Chrome browser for having the {window.sessionStorage.getItem("appTypeShow")}.
               </b>
               </p>
               <p style={{ textAlign: "center",color:"#bf3b5a" }}>
               <b>Please login to this website using your username and password a few minutes before the video or audio appointment.</b>
               </p></span>}
              {/* <div class="row row-margin">
                <label class="col-sm-6 label-text label-text-right">
                  Payment ID{" "}
                </label>
                <label class="col-sm-6 ">{this.state.paymentId}</label>
              </div>

              <div class="row row-margin">
                <label class="col-sm-6 label-text label-text-right">
                  Transaction ID
                </label>
                <label class="col-sm-6 ">{this.state.transactionId}</label>
              </div>

              <div class="row row-margin">
                <label class="col-sm-6 label-text label-text-right">
                  Order No.
                </label>
                <label class="col-sm-6 ">{this.state.orderNo}</label>
              </div>

              <div class="row row-margin">
                <label class="col-sm-6 label-text label-text-right">
                  Amount(Rs)
                </label>
                <label class="col-sm-6 ">{this.state.amount}</label>
              </div>*/}
            </div>

            <div className="col-sm-12 text-center mt-4">
              <Link to="/"> 
                <input
                  type="submit"
                  value="Home"
                  className="formButton "
                ></input>
              </Link>
              {window.sessionStorage.getItem("appTypeShow")=="In Person"?  (
              <Link to="/patient/appointmentlist">
                <input
                  type="submit"
                  value="Appointments"
                  className="formButton "
                ></input>
              </Link>) : ''}
              {window.sessionStorage.getItem("appTypeShow")=="Video Chat"||window.sessionStorage.getItem("appTypeShow")=="Audio" ?  (
              <Link to="/patient/videocalllist">
                <input
                  type="submit"
                  value="Video / Audio Appointments"
                  className="formButton "
                ></input> 
              </Link>   ) : ''}
            </div>   
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
