import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import doctor from "../image/media/doctor.jpg";
import profile from "../image/theme/profile1.png";
import purple from "../image/theme/practice.png";
import video from "../image/theme/video1.png";
import chat from "../image/theme/text.png";
import inbox from "../image/theme/mail1.png";
import article from "../image/theme/health.png";
import shnewsfeedicon from "../image/theme/shnewsfeedicon.png";
import password from "../image/theme/pw.png";
import time from "../image/theme/time1.png";
import inperson from "../image/theme/inperson.png";
import practice from "../image/theme/practice.png";
import service from "../image/theme/service1.png";
import prescriptionImg from "../image/theme/prescription.png";
import info from "../image/theme/info.png";
import { apiCalling } from "../apiService";
import { Link } from "react-router-dom";
export default class DashboardLeftmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drFName: "",
      drLName: "",
      drSpec: "",
      drImg: "",
      textChatCount: "",
      videoOrAudioChatCount: "",
      pulseSiteUrl: "",
      newsFeedCount: "",
      pulsePaymentDone: false,
    };
  }
  doctorProfile() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorProfile",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("userId"),
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          drName: data.doctorData[0].firstName,
          drLName: data.doctorData[0].lastName,
          drSpec: data.doctorData[0].primarySpeciality,
          drImg: data.doctorData[0].image,
        });
      }
    });
  }
  doctorDashboard() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorDashboard",
        doctorId: window.sessionStorage.getItem("userId"),
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          textChatCount: data.textChatCount,
          videoOrAudioChatCount: data.videoOrAudioChatCount,
          pulseSiteUrl: data.pulseSiteUrl,
          pulsePaymentDone: data.pulsePaymentDone,
          newsFeedCount: data.newsFeedCount,
        });
      }
    });
  }
  componentDidMount() {
    this.doctorProfile();
    this.doctorDashboard();
  }

  render() {
    return (
      <div class="dashbrd-menu-leftt">
        <div class="col-md-12 pl-0 mt-3">
          <div class="QckLinksNew">
            <div class="LinksList">
              <ul class="dashBoardMenu quicklinks">
                <li id="profile" class="book hvr-float-shadow greyHeaderClr">
                  <Link to="/hospital/promocodeDetails" class="practiceImg">
                    <img src={profile} alt="" />
                    <span class="videoSpan">Promo code</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
