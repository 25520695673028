import React, { Component } from "react";
export default class timeComponent extends Component {

   
    render() {

        return (
           
                 <div class="col-md-6 d-inline-block mb-2">
                        <select id={this.props.idValue} name={this.props.nameValue} class="input-design input-designBold" >
                          <option value="0">{this.props.timeType}</option>
                          <option value="12:00 AM">12:00 AM</option>
                          <option value="12:30 AM">12:30 AM</option>
                          <option value="01:00 AM">01:00 AM</option>
                          <option value="01:30 AM">01:30 AM</option>
                          <option value="02:00 AM">02:00 AM</option>
                          <option value="02:30 AM">02:30 AM</option>
                          <option value="03:00 AM">03:00 AM</option>
                          <option value="03:30 AM">03:30 AM</option>
                          <option value="04:00 AM">04:00 AM</option>
                          <option value="04:30 AM">04:30 AM</option>
                          <option value="05:00 AM">05:00 AM</option>
                          <option value="05:30 AM">05:30 AM</option>
                          <option value="06:00 AM">06:00 AM</option>
                          <option value="06:30 AM">06:30 AM</option>
                          <option value="07:00 AM">07:00 AM</option>
                          <option value="07:30 AM">07:30 AM</option>
                          <option value="08:00 AM">08:00 AM</option>
                          <option value="08:30 AM">08:30 AM</option>
                          <option value="09:00 AM">09:00 AM</option>
                          <option value="09:30 AM">09:30 AM</option>
                          <option value="10:00 AM">10:00 AM</option>
                          <option value="10:30 AM">10:30 AM</option>
                          <option value="11:00 AM">11:00 AM</option>
                          <option value="11:30 AM">11:30 AM</option>
                          <option value="12:00 PM">12:00 PM</option>
                          <option value="12:30 PM">12:30 PM</option>
                          <option value="01:00 PM">01:00 PM</option>
                          <option value="01:30 PM">01:30 PM</option>
                          <option value="02:00 PM">02:00 PM</option>
                          <option value="02:30 PM">02:30 PM</option>
                          <option value="03:00 PM">03:00 PM</option>
                          <option value="03:30 PM">03:30 PM</option>
                          <option value="04:00 PM">04:00 PM</option>
                          <option value="04:30 PM">04:30 PM</option>
                          <option value="05:00 PM">05:00 PM</option>
                          <option value="05:30 PM">05:30 PM</option>
                          <option value="06:00 PM">06:00 PM</option>
                          <option value="06:30 PM">06:30 PM</option>
                          <option value="07:00 PM">07:00 PM</option>
                          <option value="07:30 PM">07:30 PM</option>
                          <option value="08:00 PM">08:00 PM</option>
                          <option value="08:30 PM">08:30 PM</option>
                          <option value="09:00 PM">09:00 PM</option>
                          <option value="09:30 PM">09:30 PM</option>
                          <option value="10:00 PM">10:00 PM</option>
                          <option value="10:30 PM">10:30 PM</option>
                          <option value="11:00 PM">11:00 PM</option>
                          <option value="11:30 PM">11:30 PM</option>
                          <option value="12:00 AM">12:00 AM</option>
                        </select>
                      </div>
           
        )
    }
}