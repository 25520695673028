import React, { Component, Suspense } from "react";
import AppointmentEditPopup from "../patient/appointment-edit-popup";
import $ from "jquery";
import { Link } from "react-router-dom";
import { apiCalling, changeDateFormat } from "../apiService";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import PrescriptionPopup from "../patient/prescriptionPopup";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Appointmentlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hospitals: [],
      hospitalType: "",
      doctors: [],
      openAppointments: [],
      appType: "In Person",
      hospitalName: "",
      doctorName: "",
      toDate: "",
      fromDate: "",
      startDate: "",
      startDateTo: "",
      prescriptionData: [],
      medicine: "",
      showMessage: "Waiting for data... ",
    };
    this.cancelAppointment = this.cancelAppointment.bind(this);
  }

  cancelAppointment(id, type) {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "cancelAppoinment",
        userId: window.sessionStorage.getItem("userId"),
        appoinmentId: id,
        appointmentType: type == "Audio" || type == "Video Chat" ? "v" : "p",
      }),
    };
    $(".loader").show();
    // console.log("apiJson",apiJson.body)
    apiCalling(apiJson).then((data) => {
      // console.log("data",data)
      if (data.success == "1") {
        alert(data.successMessage);
        $(".loader").hide();
        this.loadFirstData();
      }
    });
  }
  componentDidMount() {
    $("body").removeClass("body-ovrflw-hdn-menu");
    $("#appointment-edit-main").hide();

    this.loadFirstData();
  }

  appointmentEdit(obj) {
    $("#appointment-edit-main" + obj.appointmentId).show();
  }

  loadFirstData() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "userMyAppointment",
        userId: window.sessionStorage.getItem("userId"),
        siteId: window.sessionStorage.getItem("siteId"),
        max: "10",
        offset: "0",
        appointmentType: "",
        doctorId: "",
        hospitalId: "",
        fromDate: "",
        toDate: "",
      }),
    };
    $(".loader").show();
    //console.log("apiJson",apiJson.body)
    apiCalling(apiJson).then((data) => {
      // console.log("userMyAppointment..........",data);
      if (data.success == "1" && data.openAppointments.length === 0) {
        this.setState({
          openAppointments: [],
          showMessage: "No Data available",
        });
      } else {
        this.setState({
          doctors: data.doctors,
          openAppointments: data.openAppointments,
          showMessage: data.openAppointments.length > 0 ? "" : "No data found",
        });
      }
      if (data.success == "1" && data.hospitals.length > 0) {
        this.setState({
          hospitals: data.hospitals,
        });
      }
      if (data.success == "1" && data.hospitals.length === 1) {
        this.setState({
          hospitalType: data.hospitals[0].hospitalName,
        });
      }
      $(".loader").hide();
    });
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper" style={{ overflow: "hidden" }}>
            <div class="form-head"> Open Appointment(s) </div>
            {/* <div class="pendingLink">
                <Link to="/patient/appointmentlist">
                Appointment(s)
                </Link>
              </div> */}
            <div className=" sh-links">
              <div class="pendingLink">
                <Link to="/patient/appointmentlist">Appointment(s)</Link>
              </div>
            </div>
            <div class="list-section mt-0">
              {this.state.openAppointments.length > 0 ? (
                <div>
                  {this.state.openAppointments.map((obj) => {
                    let appD = moment(obj.startTime);

                    return (
                      <div class="practiceTimeline-panel practiceTimelineWrap row">
                        <div className="col-lg-2 col-md-4 text-center appnt-date-time appnt-brdr">
                          <div className="appmt-month">
                            {appD.format("MMM")}
                          </div>
                          <div className="appmt-date">{appD.format("DD")}</div>
                          <div className="appmt-time">
                            {moment(appD).format("h:mm a")}
                          </div>
                          {obj.appointmentType === "In Person" ? (
                            <i
                              class="fa fa-edit ml-2 appmt-edit"
                              title="Edit date and time"
                              onClick={() => this.appointmentEdit(obj)}
                            ></i>
                          ) : (
                            ""
                          )}
                        </div>
                        <AppointmentEditPopup
                          appointmentObj={obj}
                        ></AppointmentEditPopup>
                        <div className="col-lg-3 col-md-8 appnt-brdr">
                          <span class="appnt-drName">{obj.doctorName} </span>
                          <br></br>
                          <span class="appnt-hospitalName">
                            ({obj.hosname})
                          </span>
                        </div>
                        <div className="col-lg-3 appnt-center appnt-brdr">
                          <div className="mb-2">
                            Mobile Number{" "}
                            <span className="appnt-span-det">
                              {obj.mobNumber}
                            </span>{" "}
                          </div>
                          <div className="mb-2">
                            MR No{" "}
                            <span
                              className="appnt-span-det"
                              title={
                                obj.memberMrNo === "undefined"
                                  ? ""
                                  : obj.memberMrNo
                              }
                            >
                              {obj.memberMrNo === "undefined"
                                ? ""
                                : obj.memberMrNo}
                            </span>{" "}
                          </div>
                          <div className="mb-2">
                            Transaction ID{" "}
                            <span className="appnt-span-det">
                              {obj.transactionId}
                            </span>{" "}
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6 appnt-type appnt-brdr">
                          Type :{" "}
                          <span className="appnt-type-text">
                            {" "}
                            {obj.appointmentType}
                          </span>
                        </div>
                        <div className="col-lg-2 col-md-6 appnt-status appnt-brdr">
                          {/* Status :{" "} */}
                          <span class="appnt-status-text"></span>
                          {obj.appointmentType === "Video Chat" ||
                          obj.appointmentType === "Audio" ? (
                            ""
                          ) : (
                            <input
                              type="button"
                              value="Cancel"
                              className="formButton cancelButton appnt-btn"
                              onClick={() =>
                                this.cancelAppointment(
                                  obj.appointmentId,
                                  obj.appointmentType
                                )
                              }
                            ></input>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className="error-msg text-center mb-0   hiddenTestDiv">
                  {" "}
                  {this.state.showMessage}{" "}
                </p>
              )}
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
        {/* <PrescriptionPopup></PrescriptionPopup> */}
      </div>
    );
  }
}
