import React, { Component } from "react";
import "../css/purpleresponsive.css";
import Footerlogo from "../image/theme/purplelogo.png";
// import SocialFB from "../image/theme/facebook-logo.png";
// import SocialInsta from "../image/theme/instagram.png";
// import SocialTWITR from "../image/theme/twitter-2.png";
// import SocialUTUBE from "../image/theme/youtube.png";
import { Link } from "react-router-dom";
import { apiCalling } from "../apiService";

export default class footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningTime: 3000 * 60 * 10,
      signoutTime: 3000 * 60 * 15,
      siteId: window.sessionStorage.getItem("siteId"),
    };
  }

  componentDidMount() {
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  clearTimeoutFunc = () => {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  setTimeout = () => {
    this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
    this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
  };

  resetTimeout = () => {
    this.clearTimeoutFunc();
    this.setTimeout();
  };

  warn = () => {
    // window.alert("You will be logged out automatically in 1 minute");
    //console.log("You will be logged out automatically in 1 minute.");
  };

  logout = () => {
    // Send a logout request to the API

   // console.log("Sending a logout request to the API...");
    this.destroy();
  };

  destroy = () => {
    //clear the session
    //browserHistory.push('/');
    //sessionStorage.clear();
    const logoutReq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "logout",
        siteId: window.sessionStorage.getItem("siteId"),
        userId: window.sessionStorage.getItem("userId"),
      }),
    };
    apiCalling(logoutReq).then((data) => {
      if (data.success === "1") {
        // sessionStorage.clear();
        // window.location.href = "/:" + this.state.siteId;

        window.sessionStorage.setItem("userId", "");
        window.sessionStorage.setItem("emailId", "");
        window.sessionStorage.setItem("userName", "");
        window.sessionStorage.setItem("isLogin", "");
        window.sessionStorage.setItem("role","")
        alert("Login Timeout");
        const urlPath = window.location.origin.toString();
        window.location.href = urlPath + "/home/home";
      }
    });
    //window.location.assign("/");
  };
  render() {
    return (
      <div className="footer">
        <div className="footer-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4 footercontent1">
                <div className="logowidth">
                  <div className="footerlogoimg">
                    <img src={Footerlogo} alt=""></img>
                  </div>
                </div>
                {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem ipsum has been the industry's
                </p> */}
              </div>
              <div className="col-lg-3 col-md-4"></div>
              <div className="col-lg-6 col-md-4 footercontent2 columndvd mt-2">
                <label className="footercontent">
                  <Link to="/">HOME</Link>
                </label>
                <label className="footercontent">
                  <Link to="/home/aboutus">ABOUT US</Link>
                </label>
                {/* <label className="footercontent"><Link to="/home/price">PLANS & PRICING</Link></label> */}
                {/* <label className="footercontent">
                  <Link>SUPPORT</Link>
                </label> */}
                <label className="footercontent">
                  <Link to="/home/contactus">CONTACT US</Link>
                </label>
              </div>
              {/* <div className="col-lg-3 col-md-4 footercontent2">
                <label className="footercontent">FOLLOW US</label>
                <div className="fullsocialmedia">
                  <span className="socialmedia ">
                    <img src={SocialFB}></img>
                  </span>
                  <span className="socialmedia "><img src={SocialInsta}></img></span>
                  <span className="socialmedia "><img src={SocialTWITR}></img></span>
                  <span className="socialmedia "><img src={SocialUTUBE}></img></span>
                </div>
              </div> */}
            </div>
            <div className="copyright">
              Purple Health <span>@</span> copyright 2020
            </div>
          </div>
        </div>
      </div>
    );
  }
}
