import React, { useState,useEffect,useRef,Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import rightarrow from "../image/icons/ht-arrow-right.png";
import htbaby from "../image/icons/ht-baby.png";


// const Header = React.lazy(() => import("../" + folderName + "/header"));
// const Footer = React.lazy(() => import("../" + folderName + "/footer"));

const HealthTrackbabymilestns = () => {
  const [babyTxtName, setBabyTxtName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [Header, setHeader] = useState(null);
  const [Footer, setFooter] = useState(null);
  const babyTxtNameRef = useRef(null);
const folderName = window.sessionStorage.getItem("homeName");
  useEffect(() => {
    const loadComponents = async () => {
      if (folderName) {
        const HeaderComp = React.lazy(() => import(`../${folderName}/header`));
        const FooterComp = React.lazy(() => import(`../${folderName}/footer`));
        setHeader(() => HeaderComp);
        setFooter(() => FooterComp);
      }
    };
    loadComponents();
  }, [folderName]);
  
  
  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === "babyTxtName") {
      setBabyTxtName(value);
    }
  };

  const handleChangeDate = (date) => {
    setDateOfBirth(date);
  };

  const disableDateField = () => {
    $("#txtDateOfBirth").attr('readonly', 'readonly');
    setDateOfBirth(null);
  };

  const checkHandleDetails = () => {
    const babyName = $("#babyTxtName").val();
    const babyDob = $("#txtDateOfBirth").val();

    if (babyName === "") {
      alert("Please Enter Name of the Baby");
      // $("#babyTxtName").focus();
      babyTxtNameRef.current.focus();
      return false;
    }
    if (babyDob === "") {
      alert("Please Select Date of Birth");
      $("#txtDateOfBirth").focus();
      // dateOfBirthRef.current.focus();
      return false;
    }

    const detailsCheck = {
      method: 'POST',
      body: JSON.stringify({
        functionName: "getHealthSchedule",
        healthTrackerTypeId: "2",
        userId: "",
        dateOfBirth: babyDob
      })
    };

    $(".loader").show();
    apiCalling(detailsCheck).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        window.location.href = "/health-trackers-babymilestone-download-pdf";
        window.sessionStorage.setItem('babyName', babyName);
        window.sessionStorage.setItem('dob', babyDob);
      }
      if (data.success === "0") {
        alert("No Data Found");
      }
    });
  };

  return (
    <div class="purpleWrap ph-selectDisable ph-mainWrap">
      <Suspense fallback={<div></div>}  key={Header}>
        <section>
          <div className='loader'></div>
          {Header && <Header />}
          <div class="container container-auto">
            <div class="main-wrapper1 ">

              <h1 class="  text-center telehealthfeaturehead"><span>Baby Milestones</span> </h1>
              <div className="row">
                <div className="col-lg-6">
                  <div className="health-track-card">
                    <div className="health-trck-img">
                      <img src={htbaby} alt="Baby Milestones" />
                    </div>
                    <p>Baby Milestones</p>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="health-track-card-right">
                    <div class="form-section mt-2">
                      <div class="row">
                        <div class="col-lg-12 row pl-3 d-center">
                          <label class="col-5 label-text label-text-cln text-right">
                            Name of the Baby
                          </label>
                          <label class="col-5 text-uppercase">
                            <input   ref={babyTxtNameRef} type="text" class="input-design mb-0" id="babyTxtName" name="babyTxtName" value={babyTxtName} onChange={handleChange} />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="form-section mt-2">
                      <div class="row">
                        <div class="col-lg-12 row pl-3 d-end-center">
                          <label class="col-5 label-text label-text-cln text-right">
                            Date of Birth
                          </label>
                          <div class="col-sm-5 col-5 ph-calendar">
                            <DatePicker
                              placeholderText="Date of Birth"
                              id="txtDateOfBirth" name="txtDateOfBirth"
                              maxDate={new Date()}
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                              dropdownMode="scroll"
                              peekNextMonth
                              disabledKeyboardNavigation
                              selected={dateOfBirth}
                              onSelect={handleChangeDate}
                              dateFormat="dd/MM/yyyy"
                              calendarClassName="react-date-picker"
                              autoComplete="off"
                              tabIndex="8"
                              // ref={dateOfBirthRef}
                              onKeyDown={disableDateField}
                            />
                          </div>
                          <label className="col-2">
                            <button className="ht-button" onClick={checkHandleDetails}>
                              <img src={rightarrow} alt="Submit" />
                            </button>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          {Footer && <Footer />}
        </section>
      </Suspense>
    </div>
  );
};

export default HealthTrackbabymilestns;
