import React, { Component } from "react";
import $ from 'jquery'
const vision = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/vision.png";
const mission = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/mision.png";
const goals = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/goals.png";

export default class Mission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      missionData: [],
      missionTitle: "",
      missionSubList: [],
    };
  }
  componentDidMount() {
    const missionData = JSON.parse(window.sessionStorage.getItem("Mission"));
    if (missionData) {
      //this.setState({ missionData: missionData });
      // this.setState({ missionTitle: missionData[0].parentName });
      //this.setState({ missionSubList: missionData[0].sectionList });
    } else {
      //window.location.reload(false);
    }
  }
  editClick() {
    $("#vistionList").addClass('editActive');
  }
  cancelClick() {
    $("#vistionList").removeClass('editActive');
  }
  render() {
    return (
      <div className="visionSection">
        <div className="mainHeading">Mission & Vision Goals</div>
        <div className="visionContentBg">
          <div className="visionContent">
            <div className="vistionList" id="vistionList">
              <div className="visionTitle blueBg">
                Vision
                <span>
                  <img src={vision} alt="vision"/>
                </span>
              </div>
              <p>
                In the early 80’s when Dr Sathy M Pillai was practicing as an
                obstetrician and gynecologist in hospitals in India and Abroad,
                her research showed that infertility was a social stigma mainly.
                She also found that globally infertility was on the rise due to
                life style changes and more often women had to bear the brunt of
                the problem. She decided to focus and be part of the solution.
                She trained herself under the best experts in the world
                including Singapore and Mumbai ( India) and set up Samad
                Hospitals in 1989 with a vision
              </p>
            </div>
            <div className="vistionList">
              <div className="visionTitle roseBg">
                Mision
                <span>
                  <img src={mission} alt="mission"/>
                </span>
              </div>
              <p>
                This is done through a well defined treatment methodology which
                involves analysis ofmedical history of both husband and the
                wife, physical examination, investigation,diagnosis, counselling
                , medical and interventional procedures along with
                continuous follow –ups.
              </p>
            </div>
            <div className="vistionList">
              <div className="visionTitle orangeBg">
                Goals
                <span>
                  <img src={goals} alt="goals"/>
                </span>
              </div>
              <p>
                Continuously improve all our services through quality management
                Focus on our patients and deliver high quality service Involve
                all our partners in our quality improvement activities Maintain
                the highest ethical standards in protecting the public and the
                environment Measure the effectiveness of our activities and
                monitor progress towards achievingour Vision
              </p>
            </div>
          </div>
        </div>
        {/*<div className="visionSection">
        <div className="mainHeading">{this.state.missionTitle}</div>
        <div className="visionContentBg">
          <div className="visionContent">
            {this.state.missionSubList.map((obj, index) => {
              return (
                <div className="vistionList">
                  <div className="visionTitle blueBg">
                    {obj.parentName}
                    <span>
                      <img src={obj.childImage} />
                    </span>
                  </div>
                  <p>{obj.childDescription}</p>
                </div>
              );
            })}
          </div>
        </div>
          </div>*/}
      </div>
    );
  }
}
