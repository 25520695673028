import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import Dashboardleftmenu from "./dashboardleftmenu"
import $ from "jquery";
import InboxMessage from "../doctor/inboxmessage" 

export default class DcotorInbox extends Component {
  componentDidMount()
  {
    $("#inbox_btn").addClass("active")
    $("#mailBox").addClass("dashBoardLeftMenu-active");
  }
 
  render() {
    return (
      <div class="purpleWrap">
        <Header></Header>
        <div class="container">
          <div class="main-wrapper1 mt-2 pt-0">
              <div class="row">
                <div class="col-lg-3">
                  <Dashboardleftmenu></Dashboardleftmenu>
                </div>
                <div class="col-lg-9">
                  <div class="form-head mt-4">Message Center</div>
                  <InboxMessage></InboxMessage>
                </div>
              </div>
           </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
