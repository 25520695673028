import React, { Component, Suspense } from "react";
import phoneImg from "../image/theme/phone-call.png";
import locationImg from "../image/theme/location.png";
//import hours from "../image/dentaldesigns/wall-clock.png";
// import chatImg from "../image/theme/chat.png";
// import Whatsapp from "../image/theme/whatsapp.png";
// import Facebook from "../image/theme/facebook-sm.png";
// import Pinterest from "../image/theme/pinterest-sm.png";
// import instagram from "../image/theme/instagram-sm.png";
// import twitter from "../image/theme/twitter-12.png";
// import youtube from "../image/theme/youtube-sm.png";
import $ from "jquery";
import InnerHTML from "dangerously-set-html-content";
import { apiCalling } from "../apiService";
import Pagination from "react-js-pagination";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class MoreLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalData: [],
      hospitalDataLength: 0,
      footerDetails: [],
      footerData: [],
      activePage: 1,
      totalCount: 0,
      offset: 0,
      footerTiming: window.sessionStorage.getItem("hospital_working_time"),
      hospitalTotalLocationCount: window.sessionStorage.getItem(
        "hospitalLocationCount"
      ),
    };
  }
  componentDidMount() {
    this.getHospitalList(0);

    // const hospitalData = JSON.parse(
    //   window.sessionStorage.getItem("footerhospitalData")
    // );
    const footerData = JSON.parse(window.sessionStorage.getItem("footerData"));
    if (footerData) {
      this.setState({ footerData: footerData });
    }
    // if (hospitalData) {
    //   this.setState({ hospitalData: hospitalData });
    //   this.setState({ hospitalDataLength: hospitalData.length });
    // } else {
    // }
  }
  getHospitalList = (offset) => {
    $(".loader").show();
    const apiReq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalLocationDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        offset: offset,
        max: "10",
      }),
    };
    apiCalling(apiReq).then((data) => {
      $(".loader").hide();
      if (data.success == "1") {
        this.setState({
          hospitalData: data.result,
          totalCount: data.totalCount,
        });
      } else {
        this.setState({
          hospitalData: [],
          totalCount: 0,
        });
      }
      // console.log(
      //   "🚀 ~ file: moreLocations.jsx:56 ~ MoreLocations ~ data:",
      //   data
      // );
    });
  };
  componentWillMount() {
    const footerList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getFooterLinks",
        groupHospitalAdminId: window.sessionStorage.getItem(
          "groupHospitalAdminId"
        ),
      }),
    };

    apiCalling(footerList).then((data) => {
      if (data.success === "1") {
        this.setState({ footerDetails: data.footerLinkData });
      }
    });
  }
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
        offset: 0,
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
        offset: offset,
      });
    }
    this.getHospitalList(offset);
  }
  render() {
    const urlPath = window.location.origin.toString();
    return (
      <div className="purpleWrap analytics_dash">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>

        <div class="main-wrapper1">
          {this.state.totalCount > 10 && (
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={10}
              totalItemsCount={this.state.totalCount}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
            />
          )}
          <div class="">
            {this.state.hospitalData.map((obj) => {
              var location =
                obj.googleAddressPin === ""
                  ? obj.latitude + "," + obj.longitude
                  : obj.googleAddressPin;
              return (
                // <div class="footerContainer container">
                <div
                  class={
                    this.state.hospitalData.length === 1
                      ? "footerContainer footerContainer1"
                      : this.state.hospitalData.length === 2
                      ? "footerContainer footerContainer2"
                      : this.state.hospitalData.length === 3
                      ? "footerContainer footerContainer3"
                      : "footerContainer footerContainer2"
                  }
                >
                  <div class="footerMenuList">
                    <div class="addressSec footerListSub mb-0">
                      <img
                        src={locationImg}
                        alt=""
                        width="15"
                        className="mr-2 addressSecImg"
                      />
                      <span className="footerListSubSpan">
                        <strong>{obj.hospitalName}</strong>
                        {obj.address}, &nbsp;
                        {obj.cityName}, &nbsp;
                        {obj.stateName}, &nbsp;
                        {obj.zipCode}
                      </span>
                      <br></br>

                      <div className="footerListSub">
                        <img
                          src={phoneImg}
                          alt=""
                          width="15"
                          className="mr-2 addressSecImg"
                        />
                        <span className="footerListSubSpan">{obj.phone}</span>
                      </div>
                      {/* <div className="footerListSub">
                <img src={chatImg} alt="" width="15" className="mr-2 addressSecImg"/> 
                <span  className="footerListSubSpan">{obj.emailId} </span>
              </div> */}
                    </div>
                  </div>
                  <div class="footerMenuList">
                    <div className="footerListSub mapDiv">
                      <iframe
                        src={
                          "https://maps.google.com/maps?q=" +
                          location +
                          "&ll=" +
                          obj.latitude +
                          "," +
                          obj.longitude +
                          "&z=15&output=embed"
                        }
                        width="100%"
                        height="200"
                        frameborder="0"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense> */}
      </div>
    );
  }
}
