import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { apiCalling, apiUrlRelianceFunction } from "../apiService";
import moment from "moment";
import Pagination from "react-js-pagination";
import csv from "../image/icons/csv.png";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class inPersonReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentData: [],
      toDate: new Date(),
      fromDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      totalCount: 0,
      activePage: 1,
      fuctionName: "Inperson Reports",
      csvDetails: [],
      showMessage: "Waiting for data... ",
      offset: "0",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.inPersonReport("0");
    // this.inPersonReportsCSV();
  }

  handleChange = (date) => {
    this.setState({
      fromDate: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      toDate: date,
    });
  };

  changeHandler = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handlePageChange(pageNumber) {
    window.scrollTo(0, 0);
    // var startDate = $("#fromDate").val();
    // var toDate = $("#toDate").val();
    // var newDate = moment(startDate, "DD-MM-YYYY").format("MM-DD-YYYY");
    // var SearchDate = new Date(newDate);
    // var milliStartDate = SearchDate.getTime();
    // if (newDate === "Invalid date") {
    //   SearchDate = "";
    //   milliStartDate = "";
    // }
    // var endDate = moment(toDate, "DD-MM-YYYY").format("MM-DD-YYYY");
    // var SearchEndDate = new Date(endDate);
    // var milliEndDate = SearchEndDate.getTime() + 86400000;
    // if (endDate === "Invalid date") {
    //   SearchEndDate = "";
    //   milliEndDate = "";
    // }

    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
        offset: offset,
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
        offset: offset,
      });
    }
    this.inPersonReport(offset.toString());
    // const apiJson = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     functionName: "paymentReport",
    //     subFunctionName: "inpersonPayment",
    //     userId: window.sessionStorage.getItem("userId"), //"19238",
    //     role: window.sessionStorage.getItem("role"),
    //     max: max,
    //     offset: JSON.stringify(offset),
    //     cmbCategory1:
    //       $("#inpersonSelect").val() === "0" ? "" : $("#inpersonSelect").val(),
    //     searchKeyWord: $("#txtInperson").val(),
    //     exportFromDate: milliStartDate,
    //     exportToDate: milliEndDate,
    //     fromDate: milliStartDate,
    //     toDate: milliEndDate,
    //   }),
    // };
    // $(".loader").show();
    // let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
    // apiFunction(apiJson).then((data) => {
    //   if (data.success == "1") {
    //     this.setState({
    //       paymentData: data.result.paymentData,
    //       totalCount: data.result.count,
    //     });
    //     $(".loader").hide();
    //   }
    // });
  }

  inPersonReport(offset) {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "paymentReport",
        subFunctionName: "inpersonPayment",
        fromDate: new Date(this.state.fromDate).getTime(),
        toDate: new Date(this.state.toDate).getTime(),
        exportFromDate: new Date(this.state.fromDate).getTime(),
        exportToDate: new Date(this.state.toDate).getTime(),
        userId: window.sessionStorage.getItem("userId"), //"19238",
        role: window.sessionStorage.getItem("role"),
        max: "10",
        offset: offset,
        cmbCategory1:
          $("#inpersonSelect").val() === "0" ? "" : $("#inpersonSelect").val(),
        searchKeyWord: $("#txtInperson").val(),
      }),
    };
    let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
    apiFunction(apiJson).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        // console.log(
        //   "🚀 ~ file: inPersonReports.jsx:153 ~ inPersonReports ~ apiFunction ~ data.result.paymentData:",
        //   data.result.paymentData
        // );
        this.setState({
          paymentData: data.result.paymentData,
          csvDetails: data.result.paymentExportData,
          totalCount: data.result.count,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          paymentData: [],
          csvDetails: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }

  changeText = () => {
    if ($("#inpersonSelect").val() !== "0") {
      document.getElementById("txtInperson").disabled = false;
      $("input[name=txtInperson]").focus();
    } else {
      document.getElementById("txtInperson").disabled = true;
    }
    document.getElementById("txtInperson").value = "";
  };

  searchHandler() {
    // var startDate = $("#fromDate").val();
    // var toDate = $("#toDate").val();
    // var newDate = moment(startDate, "DD-MM-YYYY").format("MM-DD-YYYY");
    // var SearchDate = new Date(newDate);
    // var milliStartDate = SearchDate.getTime();
    // if (newDate === "Invalid date") {
    //   SearchDate = "";
    //   milliStartDate = "";
    // }
    // var endDate = moment(toDate, "DD-MM-YYYY").format("MM-DD-YYYY");
    // var SearchEndDate = new Date(endDate);
    // var milliEndDate = SearchEndDate.getTime() + 86400000;
    // if (endDate === "Invalid date") {
    //   SearchEndDate = "";
    //   milliEndDate = "";
    // }
    this.setState({
      offset: "0",
      activePage: 1,
    });
    if ($("#inpersonSelect").val() !== "0" && $("#txtInperson").val() === "") {
      var t = document.getElementById("inpersonSelect");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#txtInperson").focus();
      return false;
    }
    if (this.state.fromDate > this.state.toDate) {
      alert("To date should be greater than or equal to from date");
      return false;
    } else {
      this.inPersonReport("0");
      // const searchData = {
      //   method: "POST",
      //   body: JSON.stringify({
      //     functionName: "paymentReport",
      //     subFunctionName: "inpersonPayment",
      //     userId: window.sessionStorage.getItem("userId"), //"19238",
      //     role: window.sessionStorage.getItem("role"),
      //     max: "10",
      //     offset: "0",
      //     cmbCategory1:
      //       $("#inpersonSelect").val() === "0"
      //         ? ""
      //         : $("#inpersonSelect").val(),
      //     searchKeyWord: $("#txtInperson").val(),
      //     exportFromDate: milliStartDate,
      //     exportToDate: milliEndDate,
      //     fromDate: milliStartDate,
      //     toDate: milliEndDate,
      //   }),
      // };
      // console.log(
      //   "🚀 ~ file: inPersonReports.jsx:221 ~ inPersonReports ~ searchHandler ~ searchData:",
      //   searchData.body
      // );
      // $(".loader").show();
      // let apiFunction =
      //   folderName !== null ? apiCalling : apiUrlRelianceFunction;
      // apiFunction(searchData).then((data) => {
      //   // this.setState({ toDate: "", fromDate: "" })
      //   if (data.success == "1") {
      //     $(".loader").hide();
      //     this.setState({
      //       paymentData: data.result.paymentData,
      //       csvDetails: data.result.paymentExportData,
      //       totalCount: data.result.count,
      //       activePage: 1,
      //     });
      //   }
      //   if (data.success === "0") {
      //     $(".loader").hide();
      //     this.setState({
      //       paymentData: [],
      //       showMessage: data.errorMessage,
      //       csvDetails: [],
      //     });
      //   }
      // });
    }
  }

  // inPersonReportsCSV() {
  //     const apiJson = {
  //         method: "POST",
  //         body: JSON.stringify({
  //             functionName: "paymentReport",
  //             subFunctionName: "inpersonPayment",
  //             userId: window.sessionStorage.getItem("groupHospitalAdminId"), // "19238",
  //         }),
  //     };
  //     apiCalling(apiJson).then((data) => {
  //         if (data.success === "1") {
  //             this.setState({
  //                 csvDetails: data.result.paymentData,
  //             });
  //         }
  //     });
  // }

  csvFileDownload() {
    const csvRow = [];
    var columnDetails = [
      [
        "Patient Name",
        "Patient Email Id",
        "Patient Mobile Number",
        "Hospital Name",
        "Doctor Name",
        "Transaction Id",
        "Appointment Status",
        "Appointment Requested Date",
        "Appointment Time",
      ],
    ];
    var results = this.state.csvDetails;

    for (var item = 0; item < results.length; item++) {
      columnDetails.push([
        results[item].memName,
        results[item].emailId,
        results[item].mobNo,
        results[item].hospitalName,
        results[item].docName,
        results[item].transactionId,
        results[item].statusName,
        moment(results[item].requestedDate).format("DD-MMM-YYYY"),
        moment(results[item].requestedDate).format("hh:mm A"),
      ]);
    }

    for (var i = 0; i < columnDetails.length; ++i) {
      csvRow.push(columnDetails[i].join(","));
    }

    var csvString = csvRow.join("%0A");

    var dataType = document.createElement("a");

    dataType.href = "data:attachment/csv," + csvString;
    dataType.target = "_Blank";
    dataType.download = this.state.fuctionName + ".csv";
    document.body.appendChild(dataType);
    dataType.click();
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            {folderName !== null && <Header />}
            <div className="loader"></div>
            <div class="container">
              <div class="main-wrapper1">
                <div class="col-md-12 p-0">
                  <ul class="breadcrumb">
                    <li>
                      <a
                        href={
                          "/dashboard/reportDashboard/" +
                          this.props.match.params.groupHospitalId
                        }
                      >
                        Reports
                      </a>
                      <i class="fa fa-chevron-right"></i>
                    </li>
                    <li> IN Person Appointment Report</li>
                  </ul>
                </div>
                <div class="form-head">IN Person Appointment Report </div>
                <div class="custom-search row">
                  <div class="col-md-4 col-6 mb-3 custom-search-sub">
                    <select
                      class="input-design"
                      id="inpersonSelect"
                      name="inpersonSelect"
                      onChange={() => this.changeText()}
                    >
                      <option value="0">- - Select - -</option>
                      <option value="Patient Name">Patient Name</option>
                      <option value="Patient Mobile Number">
                        Patient Mobile
                      </option>
                      <option value="Patient EmailId">Patient Email</option>
                      <option value="Doctor Name"> Doctor Name</option>
                      <option value="Hospital Name">Hospital Name</option>
                      <option value="Transaction Id"> Transaction ID</option>
                      <option value="Status">Appointment Status</option>
                    </select>
                  </div>
                  <div class="col-md-4 col-6 mb-3 custom-search-sub">
                    <input
                      type="text"
                      class="input-design"
                      id="txtInperson"
                      name="txtInperson"
                      disabled
                    />
                  </div>
                  <div class="col-md-4 col-6 mb-3 custom-search-sub">
                    <DatePicker
                      placeholderText="From Date"
                      id="fromDate"
                      name="fromDate"
                      selected={this.state.fromDate}
                      onChange={this.handleChange}
                      dateFormat="dd/MM/yyyy"
                      calendarClassName="react-date-picker"
                    />
                  </div>
                  <div class="col-md-4 col-6 mb-3 custom-search-sub">
                    <DatePicker
                      placeholderText="To Date"
                      id="toDate"
                      name="toDate"
                      selected={this.state.toDate}
                      onChange={this.handleChangeTo}
                      dateFormat="dd/MM/yyyy"
                      calendarClassName="react-date-picker"
                    />
                  </div>
                  <div class="col-md-3 col-10 mb-3 custom-search-sub">
                    <input
                      type="submit"
                      value="Search"
                      class="formButton m-0"
                      onClick={() => this.searchHandler()}
                    />
                  </div>
                  <div className="col-md-1 col-2 mb-3 custom-search-sub p-0 ml-2">
                    <a
                      onClick={() => this.csvFileDownload()}
                      className="cursor-pointer"
                    >
                      <img
                        src={csv}
                        alt="csv"
                        width="25"
                        title="Export CSV"
                        className="mt-1"
                      />
                    </a>
                  </div>
                </div>
                {this.state.paymentData.length > 0 ? (
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Patient Name</th>
                          <th>Patient Email Id</th>
                          <th>Patient Mobile Number</th>
                          <th>Hospital Name</th>
                          <th>Doctor Name</th>
                          <th>Transaction Id</th>
                          <th>Appointment Status</th>
                          <th> Appointment Created On</th>
                          <th> Appointment Date</th>
                          <th> Appointment Time</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "12px" }}>
                        {this.state.paymentData.map((data) => {
                          let requestedDate = moment(data.requestedDate).format(
                            "DD-MMM-YYYY"
                          );
                          let appointmentTime = moment(
                            data.requestedDate
                          ).format("hh:mm A");
                          return (
                            <tr>
                              <td>{data.memName}</td>
                              <td>{data.emailId}</td>
                              <td>{data.mobNo}</td>
                              <td>{data.hospitalName}</td>
                              <td>Dr.{data.docName}</td>
                              <td>{data.transactionId}</td>
                              <td>{data.statusName}</td>
                              <td>
                                {moment(new Date(data.createdOnDate)).format(
                                  "DD-MMM-YYYY hh:mm A"
                                )}
                              </td>
                              <td>
                                {requestedDate === "01-Jan-1970"
                                  ? ""
                                  : requestedDate}
                              </td>
                              <td>{appointmentTime}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {this.state.totalCount > 5 ? (
                      <div className="paginationSection orderm-paginationSection">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <p className="error-msg text-center mb-0">
                    {" "}
                    {this.state.showMessage}{" "}
                  </p>
                )}
              </div>
            </div>
            {folderName !== null && <Footer />}
          </section>
        </Suspense>
      </div>
    );
  }
}
