import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { apiCalling } from "../apiService";
import AnalyticsListAlert from "./analyticsListAlert";
import AnalyticsListevent from "./analyticsListEvents";
import AnalyticsListNewsFeedView from "./analyticsListNewsFeedView";
import AnalyticsListNewsFeedDownload from "./analyticsListNewsFeedDownload";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Analysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventViewListDataAlert: [],
            eventViewListDataEvents: [],
            newsFeedListData: [],
            newsFeedDownloadData: [],
            hospitalArray: [],
            HospitalIdsArrayset: [],
            eventAlertCount: "",
            eventViewListDataEventsCount: "",
            newsFeedCount: "",
            newsFeedDownloadcount: "",
            offset: 0,
            activepage: 1,
        };
        // this.handlePageChange = this.handlePageChange.bind(this);

    }

    componentDidMount() {
        this.getHospitalDetails();
    }
    getHospitalDetails() {
        //    $("#listOfsite").attr("hidden",true)
        $("#analisePopup").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "analyticsList",
                "siteId":window.sessionStorage.getItem("siteId"),
            //  "siteId": window.sessionStorage.getItem('role') === "ROLE_GROUPHOSPITALADMIN" ? window.sessionStorage.getItem("siteId")
            //  : "",
                "max": "10",
                "offset": this.state.offset,
            }),
        };
        $(".loader").show();
        apiCalling(apiJson).then((data) => {
            $("#analisePopup").hide();
            if (data.success === "1") {
                $(".loader").hide();
                this.setState({
                    // Dataflag:true,
                    eventViewListDataAlert: data.result[0].alertList,
                    eventViewListDataEvents: data.result[0].eventViewList,
                    newsFeedListData: data.result[0].newsFeedList,
                    newsFeedDownloadData: data.result[0].newsFeedDownload,
                    eventAlertCount: data.result[0].alertListCount,
                    eventViewListDataEventsCount: data.result[0].eventViewCount,
                    newsFeedCount: data.result[0].newsFeedListCount,
                    newsFeedDownloadcount: data.result[0].newsFeedDownloadCount
                });
            }
            else {
                // Dataflag:false,
                alert(data.errorMessage)
                $(".loader").hide();
            }
            // else {
            //     return (
            //       <tr>
            //         <td colSpan="10" className="text-center">
            //           No Data Found
            //         </td>
            //       </tr>
            //     );
            //   }
        });
    }
    // handlePageChange() {
    //     $(".loader").show();
    //     const apiJson = {
    //         method: "POST",
    //         body: JSON.stringify({

    //             "functionName": "analyticsList",
    //             "siteId": window.sessionStorage.getItem('role') === "ROLE_GROUPHOSPITALADMIN" ? window.sessionStorage.getItem("siteId")
    //                 : ""
    //         }),
    //     };
    //     apiCalling(apiJson).then((data) => {
    //         if (data.success === "1") {
    //             $(".loader").hide();
    //             this.setState({
    //                 eventViewListDataAlert: data.result[0].alertList,
    //                 eventViewListDataEvents: data.result[0].eventViewList,
    //                 newsFeedListData: data.result[0].newsFeedList,
    //                 newsFeedDownloadData: data.result[0].newsFeedDownload,
    //             });
    //         }
    //     });
    // }
    // SettingHospitalIds() {
      
    //     if ($("#cmbHospitalName").val() === "") {
    //            $("#listOfsite").attr("hidden",true)
    //         $("#txtHospitalIdNew").val("")
    //         this.setState({
    //             activepage: 1,
    //             offset: 0,
    //             //  hospitalArray:"",
    //         }, () => {
    //             this.getHospitalDetails()
    //         })
    //     }
    //     if ($("#cmbHospitalName").val().length > 2) {
    //         $("#listOfsite").attr("hidden",false)
    //         const apiJson = {
    //             method: "POST",
    //             body: JSON.stringify({
    //                 "functionName": "addOrEditSite",
    //                 "cmbSelect": "siteName",
    //                 "searchKeyWord": $("#cmbHospitalName").val(),
    //                 "max": "",
    //                 "Offset": ""
    //             }),
    //         };
    //         apiCalling(apiJson).then((data) => {
    //             if (data.success === "1") {
    //                 //  console.log( data.hospitalData[0].associationData," data.hospitalData[0].associationData");
    //                 console.log(data.siteData);
    //                 this.setState({
    //                     hospitalArray: data.siteData,
    //                 });
    //             }
    //         });
    //     }
    // }
    TabHandler() {
        // alert(id)
        this.setState({
            activepage: 1,
            offset: 0,
        }, () => {
            this.getHospitalDetails()
        })
        // this.getHospitalDetails()
        // window.sessionStorage.setItem('analysticpageNumber', 1)
        // window.sessionStorage.setItem('AnalyticSearchText',"")
        // $("#AnalysticTxt").val("")

    }
    // setHospitalId(hospitalId, hospitalName) {
    //     this.setState({
    //         hospitalArray: []
    //     })
    //     $("#cmbHospitalName").val(hospitalName)
    //     $("#txtHospitalIdNew").val(hospitalId)
    //     this.getHospitalDetails()
    // }
    render() {
        return (
            <div className="purpleWrap bg-clr-wthHead">
                <Suspense fallback={<div></div>}>
                    <section>
                        <Header /> <div className="loader"></div>
                    </section>
                </Suspense>
                <div className="loader"></div>
                <div className="container mb-5">
                    <div class="col-md-12 p-0">
                        <ul class="breadcrumb mb-4">
                            <li>
                                <a href="/admin/dashboard">Dashboard</a>
                                <i class="fa fa-chevron-right"></i>
                            </li>
                            <li> Analytics</li>
                        </ul>
                    </div>
                        <div class="form-head mb-4">Analytics </div>
                    <div className="addHspPage-Wrapper">
                        <Tabs>
                            <TabList className="mt-3 mb-0 pl-0 reactTab-ul"  onClick={(e) => this.TabHandler()} >
                                <Tab>Alert Details</Tab>
                                <Tab>Event Details</Tab>
                                <Tab>NewsFeedView Details</Tab>
                                <Tab>NewsFeedDownload Details</Tab>
                            </TabList>
                            {/* <div class="custom-search mt-3 pt-3" style={{backgroundColor:"#ededed"}}>
                                    <div class="col-md-4 custom-search">
                                        <input
                                            class="input-design"
                                            name="cmbHospitalName"
                                            type="text"
                                            autoComplete="off"
                                            id="cmbHospitalName"
                                            placeholder="Site Name"
                                            onKeyUp={(e) => this.SettingHospitalIds(e)}
                                        />
                                        
                                        <ul className="auto-complete-wrapper" hidden  id="listOfsite">
                                    {this.state.hospitalArray.map((timeObj, i) => {
                                        return (
                                            // <li className="hosptalList" onClick={() => this.setHospitalId(timeObj.hospitalId, timeObj.hospitalName,timeObj.address)}>{timeObj.hospitalName}({timeObj.address})</li>
                                            <li
                                                className="hosptalList"  autocomplete="off" 
                                                onClick={() =>
                                                    this.setHospitalId(
                                                        timeObj.siteId,
                                                        timeObj.siteName,
                                                    )
                                                }
                                            >
                                                {timeObj.siteName}
                                            </li>
                                        );
                                    })}
                                </ul>
                                    </div>
                                </div> */}
                            <div className="doctr-wraper-border">
                                {/* <TabPanel  >
                                    <AnalyticsListAlert eventViewListDataAlert={this.state.eventViewListDataAlert}
                                      eventAlertCount={this.state.eventAlertCount}
                                      activepage={this.state.activepage}
                                      setPagination={(offset) => {
                                          this.setState({
                                              activepage: offset,
                                              offset: (offset - 1) * 10
                                          }, () => {
                                              this.getHospitalDetails();
                                          })
                                      }}
                                    ></AnalyticsListAlert>
                                </TabPanel> */}
                            </div>
                            <div>
                                <TabPanel  >
                                    <AnalyticsListAlert eventViewListDataAlert={this.state.eventViewListDataAlert}
                                        eventAlertCount={this.state.eventAlertCount}
                                        activepage={this.state.activepage}
                                        setPagination={(offset) => {
                                            this.setState({
                                                activepage: offset,
                                                offset: (offset - 1) * 10
                                            }, () => {
                                                this.getHospitalDetails();
                                            })
                                        }}

                                    ></AnalyticsListAlert>
                                </TabPanel>
                                <TabPanel id="0AnalyticsListevent" >
                                    <AnalyticsListevent
                                        eventViewListDataEvents={this.state.eventViewListDataEvents}
                                        eventViewListDataEventsCount={this.state.eventViewListDataEventsCount}
                                        activepage={this.state.activepage}
                                        setPagination={(offset) => {
                                            this.setState({
                                                activepage: offset,
                                                offset: (offset - 1) * 10
                                            }, () => {
                                                this.getHospitalDetails();
                                            })
                                        }}
                                    // PaginationCountEvent={this.state.eventViewListDataEventsCount}
                                    ></AnalyticsListevent>
                                </TabPanel >
                                <TabPanel id="0AnalyticsListNewsFeedView">
                                    <AnalyticsListNewsFeedView newsFeedListData={this.state.newsFeedListData}
                                        newsFeedCount={this.state.newsFeedCount}
                                        activepage={this.state.activepage}
                                        setPagination={(offset) => {
                                            this.setState({
                                                activepage: offset,
                                                offset: (offset - 1) * 10
                                            }, () => {
                                                this.getHospitalDetails();
                                            })
                                        }}
                                    ></AnalyticsListNewsFeedView>
                                </TabPanel>
                                <TabPanel id="0AnalyticsListNewsFeedDownload">
                                    <AnalyticsListNewsFeedDownload newsFeedDownloadData={this.state.newsFeedDownloadData}
                                        newsFeedDownloadcount={this.state.newsFeedDownloadcount}
                                        activepage={this.state.activepage}
                                        setPagination={(offset) => {
                                            this.setState({
                                                activepage: offset,
                                                offset: (offset - 1) * 10
                                            }, () => {
                                                this.getHospitalDetails();
                                            })
                                        }}
                                    ></AnalyticsListNewsFeedDownload>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
                <Suspense fallback={<div></div>}>
                    <section>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }
}
