import React, { Component } from 'react';
import messegeicon from "../image/theme/messege.png"
import search from "../image/theme/search.png"


export default class DoctorInboxMessage extends Component {

  render() {
    return (
          
            <div class="messageCenterDiv px-3 px-md-0">
                  <div class="row">
                    <div class="col-md-3 col-sm-12">
                      <ul class="messageBlock">
                        <li class="inbox_btn" id="inbox_btn"><a href="/doctor/doctorinbox">Inbox </a></li>
                        <li class="sent_btn" id="sent_btn"><a href="/doctor/doctorsend">Sent</a></li>
                      </ul>
                    </div>
                    <div class="col-md-9 col-sm-12 emailbox-brdr">
                      <div class="searchMessage mt-3">
                          <input type="text" placeholder="Search Messages" class="search_message input-design" />
                        <button type="submit" class="formButtonsearch"><img src={search} width="20"></img> Search</button>
                      </div>
                      <div class="emailBox py-5 text-center">   
                      No messages to display
                      </div>
                      <div class="emailBox">  
                        <ul>
                            <li>
                              <div class="sender">
                                <div className="inbox-name">TEST PATIENT (1)</div>
                                <span className="inboxdate-time">Tue May 05 2020 10:03 PM</span>
                              </div>
                              <div class="subject">
                                <a href="/doctor/messgedetail">
                                  <div className="inbox-sendto">Send To : Dr. Foggy Nelson</div>
                                  <div className="inbox-askdr">Ask the Doctor - SONALI  GURAV .</div>
                                  <span className="inbox-text">	 test	</span>
                                </a>
                              </div>
                            </li>  
                            <li>
                              <div class="sender">
                                <div className="inbox-name">TEST PATIENT (1)</div>
                                <span className="inboxdate-time">Tue May 05 2020 10:03 PM</span>
                              </div>
                              <div class="subject">
                                <a href="/doctor/messgedetail">
                                  <div className="inbox-sendto">Send To : Dr. Foggy Nelson</div>
                                  <div className="inbox-askdr">Ask the Doctor - SONALI  GURAV .</div>
                                  <span className="inbox-text">	 test	</span>
                                </a>
                              </div>
                            </li>  
                            <li>
                              <div class="sender">
                                <div className="inbox-name">TEST PATIENT (1)</div>
                                <span className="inboxdate-time">Tue May 05 2020 10:03 PM</span>
                              </div>
                              <div class="subject">
                                <a href="/doctor/messgedetail">
                                  <div className="inbox-sendto">Send To : Dr. Foggy Nelson</div>
                                  <div className="inbox-askdr">Ask the Doctor - SONALI  GURAV .</div>
                                  <span className="inbox-text">	 test	</span>
                                </a>
                              </div>
                            </li>    
                                                      
                        </ul>
                      </div>
                    </div>
                  </div>
            </div>


          
    )
  }
}
