import React, { Component, Suspense } from "react";
import { apiCalling } from "../apiService";
import tick from "../image/theme/tick.png";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class TextchatSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sucessMessage: "",
      transactionId: "",
      paymentId: "",
      orderNo: "",
      paymentStatus: "",
      appointmentId: "",
      amount: "",
    };
    this.closeAppointment = this.closeAppointment.bind(this);
  }
  closeAppointment() {
    window.location.href = "../home/doctor-list-main";
  }

  componentDidMount() {
    this.setState({
      sucessMessage: "Payment saved successfully",
    });
    //let dataSet = JSON.parse(window.sessionStorage.getItem("appointmentSuccessArray"));
    //alert(JSON.stringify(dataSet));
    //$("#transactionId").text(JSON.stringify(dataSet));
    // if (dataSet.success === "1") {
    //   this.setState({
    //     sucessMessage: dataSet.successMessage,
    //     transactionId: dataSet.result[0].transactionId,
    //     paymentId: dataSet.result[0].paymentId,
    //     orderNo: dataSet.result[0].orderNo,
    //     paymentStatus: dataSet.result[0].paymentStatus,
    //     appointmentId: dataSet.result[0].appointmentId,
    //     amount: dataSet.result[0].amount,
    //   });
    // }
  }

  getStartChat(chatGroupId, memberId, doctorId, hospitalId) {
    $(".loader").show();
    const json = {
      method: "POST",
      body: JSON.stringify({
        functionName: "startTextChat",
        chatGroupId: chatGroupId,
        userId: window.sessionStorage.getItem("userId"),
        hospitalId: hospitalId,
      }),
    };
   // console.log(json.body);
    apiCalling(json).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        //console.log("Data: ", data);
        window.sessionStorage.setItem("chatStore", data.result[0]);
        window.sessionStorage.setItem("API_KEY", data.result[0].API_KEY);
        window.sessionStorage.setItem("sessionId", data.result[0].sessionId);
        window.sessionStorage.setItem("token", data.result[0].token);
        window.sessionStorage.setItem("appDocId", doctorId);
        window.sessionStorage.setItem("appHosId", hospitalId);
        window.sessionStorage.setItem("chatDoctorPanelId", chatGroupId);
        window.sessionStorage.setItem("defaultMessages", data.result[0].defaultMessages);
        window.location.href = "/chat/chatmessage";
      }
    });
  }
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper">
            <div class="col-sm-12 text-center">
              <img src={tick} width="50"></img>
              <div class="col-sm-12 form-subhead">
                {this.state.sucessMessage}
              </div>
            </div>
            <div class="form-section-bgcolor form-section-smwidth px-2 px-md-0">
              <div class="my-4 form-subhead text-center form-subhead-brdr">
                Transaction Summary
              </div>
              <div class="row">
                <label class="col-sm-6 col-5 label-text-right label-text-cln">
                  Payment Status
                </label>
                <label class="col-sm-6 col-7 " id="transactionId">
                  {window.sessionStorage.getItem("paymentStatus")}
                </label>
              </div>
              <div class="row">
                <label class="col-sm-6 col-5 label-text-right label-text-cln">
                  Transaction Id
                </label>
                <label class="col-sm-6 col-7 " id="transactionId">
                  {window.sessionStorage.getItem("transactionId")}
                </label>
              </div>
              <div class="row">
                <label class="col-sm-6 col-5 label-text-right label-text-cln">
                  Order Id{" "}
                </label>
                <label class="col-sm-6 col-7 ">
                  {window.sessionStorage.getItem("orderNo")}
                </label>
              </div>

              <div class="row">
                <label class="col-sm-6 col-5 label-text-right label-text-cln">
                  Payment Id
                </label>
                <label class="col-sm-6 col-7 " id="transactionId">
                  {window.sessionStorage.getItem("paymentId")}
                </label>
              </div>

              {/* <div class="row">
                <label class="col-sm-6 col-5 label-text-right label-text-cln">
                  Order No.
                </label>
                <label class="col-sm-6 col-7 ">
                  {window.sessionStorage.getItem("orderNo")}
                </label>
              </div> */}

              <div class="row">
                <label class="col-sm-6 col-5 label-text-right label-text-cln">
                  Amount(Rs)
                </label>
                <label class="col-sm-6 col-7 ">
                  {window.sessionStorage.getItem("amount")}
                </label>
              </div>
            </div>

            <div className="col-sm-12 text-center mt-4">
              <input
                type="submit"
                value="Chat Now"
                onClick={() =>
                  this.getStartChat(
                    window.sessionStorage.getItem("chatGroupId"),
                    window.sessionStorage.getItem("userId"),
                    window.sessionStorage.getItem("appDocId"),
                    window.sessionStorage.getItem("appHosId")
                  )
                }
                className="formButton formButtonBold"
              ></input>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
