import React, { Component } from 'react';
import $ from "jquery";
import Slider from "react-slick";
import { apiCalling } from "../apiService";
import { isAndroid, isIOS } from 'react-device-detect';

export default class Splash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      splashId: this.props.match.params.splashId,
      networkId:this.props.match.params.networkId,
      android:"",
      iosId:"",

    }
  }
  componentDidMount() {
    if(isAndroid===true){
      $("#androidSpan").show();
    }
    if(isIOS===true){
     $("#iosSpan").show();
   }
  //alert(this.props.match.params.splashId)
  // alert(this.props.match.params.networkId)
   let splashId=this.props.match.params.splashId;
   splashId=splashId.split("~")
  // alert(splashId[0]);
  // alert(splashId[1])
   this.setState({android:splashId[0],iosId:splashId[1]})
  }
  notNow(){
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "networkRequestDeclined",
        networkId: this.state.networkId,        
      }),
    };
    apiCalling(apiJson).then((data) => {

    });
  }

  render() {
    var settings = {
      dots: true,
      infinite: false,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 13000,
      pauseOnHover: false,
    };
    return (
      <div className="pharmacyapp-wrapper p-0" >
          <Slider {...settings}>
            <div className="splash-banner splash-banner1">
              <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/doctors.png" alt="banner" className="img-fluid sp-banner-img"/>
              <div className="sp-banner-content">
                  <p className="sp-banner-content-p">Create a network</p>
                  <p className="sp-banner-content-p">Refer patients and track referrals</p>
                  <p className="sp-banner-content-p">Connect with colleagues</p>
              </div>
            </div>
            <div className="splash-banner splash-banner2">
              <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/doctorSplash.png" alt="banner" className="img-fluid sp-banner-img"/>
              <div className="sp-banner-content">
                  <p className="sp-banner-content-p">Make your practice digital & modern</p>
                  <p className="sp-banner-content-p">Use telehealth to connect with patients</p>
                  <p className="sp-banner-content-p">Full-featured yet simple to use</p>
              </div>
            </div>
            <div className="splash-banner splash-banner3">
              <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/splashimg1.png" alt="banner" className="img-fluid sp-banner-img"/>
              <div className="sp-banner-content">
                  <p className="sp-banner-content-p">Supercharge your practice</p>
                  <p className="sp-banner-content-p">Take your practice with you anywhere</p>
                  <p className="sp-banner-content-p">Join the app for free!</p>
              </div>
            </div>
            <div className="splash-banner splash-banner4">
              <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/splash4.png" alt="banner" className="img-fluid sp-banner-img"/>
              <div className="sp-banner-content">
                  <div className="pharmacyapp-splashhead">Sign Up for Telehealth It's Free!</div>
                  <ul>
                    <li className="sp-banner-content-p mb-2">Attract new Patients</li>
                    <li className="sp-banner-content-p mb-2">Set your own fees</li>
                    <li className="sp-banner-content-p mb-2">Simple & Convenient!</li>
                  </ul>
                  <div className="pharmacy-splash-btnmain">
                    <button className="pharmacy-splash-button pharmacy-splash-button-b mr-2"
                    onClick={() =>this.notNow()}> Not Now</button>
                     
                   
                    <span id="androidSpan" style={{display:"none"}}>
                     <a className="pharmacy-splash-button pharmacy-splash-button-r" href={"https://purplehealth.page.link/"+this.state.android}>I'm Interested</a> 
                     </span>
                    <span id="iosSpan" style={{display:"none"}}>
<a className="pharmacy-splash-button pharmacy-splash-button-r" href={"https://purplehealth.page.link/"+this.state.iosId}>I'm Interested</a> 
</span>


                    
                  </div>
              </div>
            </div>
          </Slider>
      </div>
    )
  }
}
