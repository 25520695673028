import { Component } from "react";
// import CanvasJSReact from "./canvasjs.react";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import $ from "jquery";
import moment from "moment";
var xAxisLabels = [];
var seriesData = [];

export default class LineGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      seriesData: this.props.seriesData,
      fromYear: "",
      year: this.props.year,
      month: this.props.month,
      week: this.props.week,
      report: "",
      xaxis: this.props.xAxisLabels,
      monthTable: [
        {
          id: "1",
          month: "January",
        },
        {
          id: "2",
          month: "February",
        },
        {
          id: "3",
          month: "March",
        },
        {
          id: "4",
          month: "April",
        },
        {
          id: "5",
          month: "May",
        },
        {
          id: "6",
          month: "June",
        },
        {
          id: "7",
          month: "July",
        },
        {
          id: "8",
          month: "August",
        },
        {
          id: "9",
          month: "September",
        },
        {
          id: "10",
          month: "October",
        },
        {
          id: "11",
          month: "November",
        },
        {
          id: "12",
          month: "December",
        },
      ],

      // options: {
      //   chart: {
      //     type: "line",
      //     zoom: {
      //       enabled: false
      //     },
      //     toolbar: {
      //       show: true
      //     },
      //   },
      //   colors: ["#77B6EA", "#545454"], //* line colour
      //   dataLabels: {
      //     enabled: false,
      //   },
      //   stroke: {
      //     curve: "straight",
      //     show: true,
      //     width: 2,
      //     colors:'#008bcd'
      //   },
      //   // title: {
      //   //   text: "Average High & Low Temperature",
      //   //   align: "left",
      //   // },
      //   grid: {
      //     borderColor: "#e7e7e7",
      //     row: {
      //       colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      //       opacity: 0.5,
      //     },
      //   },
      //   markers: {
      //     size: 6,
      //   },
      //   xaxis: {
      //     categories:this.props.xAxisLabels,
      //     title: {
      //       text: "Date",
      //     },
      //     type: 'text'
      //   },
      //   yaxis: {
      //     title: {
      //       text: "No of Registrations",
      //     },
      //   },
      // }
    };
  }
  componentDidMount() {
    if (this.state.month === true) {
      var array = [];
      let string = "";
      this.setState({ xaxis: [] });
      for (let i = 0; i < this.props.xAxisLabels.length; i++) {
        var object = this.state.monthTable.find((obj) => {
          if (obj.id.toString() === this.props.xAxisLabels[i]) {
            return obj.month;
          }
        });
        array.push(object.month);
      }
      this.setState({ xaxis: array });
    } else if (this.state.week === true) {
      var array = [];
      this.setState({ xaxis: [] });
      for (let i = 0; i < this.props.xAxisLabels.length; i++) {
        var date = moment(this.props.xAxisLabels[i]).format("YYYY-MM-DD");
        array.push(date);
      }
      this.setState({ xaxis: array });
    }
    // if(this.state.monthFlag===true){
    //   var object = this.state.monthTable.filter((obj) => {
    //       console.log(obj.month);
    //       if(obj.id === data.result.countResult[i].cretedDate)
    //       return obj.month
    //   })
    //   doctorXAxisLabels.push(object[0].month)
    // }
    // if(this.state.graphData.length !== 0){
    //   alert()
    //   for(let i=0;i<this.state.graphData.length;i++){
    //     console.log("xAxisLabels",new Date(moment(this.state.graphData[i].cretedDate).format("YYYY-MM-DD")).getTime())
    //        xAxisLabels.push(new Date(moment(this.state.graphData[i].cretedDate).format("YYYY-MM-DD")).getTime())
    //        seriesData.push(this.state.graphData[i].createdCount)
    //     }
    // }
    // for(let i=0;i<this.state.graphData.length;i++){
    //    xAxisLabels.push(this.state.graphData[i].cretedDate)
    // }
    // var xaxisCategories = this.state.graphData.filter((obj)=>{
    //   return obj.cretedDate
    // })
  }
  clickHandler(config) {
    this.setState(
      {
        report: config.config.series[config.seriesIndex].name,
        fromYear: config.config.xaxis.categories[config.dataPointIndex],
      },
      () => {
        $("#reportsPageLink")[0].click();
      }
    );
  }
  render() {
    // console.log(
    //   "🚀 ~ file: line_graph.jsx:185 ~ LineGraph ~ render ~ this.state.seriesData:",
    //   this.state.seriesData
    // );
    const MinValue = Math.min(...this.state.seriesData) - 1;
    const MaxValue = Math.max(...this.state.seriesData) + 1;
    var series = [
      {
        name: this.props.seriesName,
        data: this.state.seriesData,
      },
    ];
    var options = {
      chart: {
        type: "line",
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: true,
        },
        events: {
          dataPointSelection: function (event, chartContext, config) {
            //this.clickHandler(event, chartContext, config);
          },
        },
      },
      colors: ["#77B6EA", "#545454"], //* line colour
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        show: true,
        width: 2,
        colors: "#008bcd",
      },
      // title: {
      //   text: "Average High & Low Temperature",
      //   align: "left",
      // },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: this.state.xaxis,
        title: {
          text: "Date",
        },
        type: "text",
      },
      yaxis: {
        title: {
          text: "No of Registrations",
        },
        labels: {
          formatter: function (value) {
            return Math.round(value); // Use Math.round() to convert to integers
          },
        },
        min: MinValue,
        max: MaxValue,
      },
    };
    return (
      <div id="chart">
        <Chart options={options} series={series} type="line" height={200} />
        {/* <Link
          hidden
          id="reportsPageLink"
          to = {{
            pathname:"/analytics/finalReports",
            state: {
              link: this.state.report,
              monthFlag: this.props.month,
              dayFlag: this.props.week,
              yearFlag: this.props.year,
              fromYear: this.state.fromYear
            }
          }}
        /> */}
      </div>
    );
  }
}
