import React, { Component, Suspense } from "react";
import Dashboardleftmenu from "./dashboardleftmenu";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import MemberDetail from "./member-detail";
import EducationalDetails from "./educational-details";
import LanguagesKnown from "./languages-known";
import HospitalDetails from "./hospital-details";
import ProfessionalDetail from "./professional-details";
import SpecialityDetail from "./speciality-details";
import ExperienceDetail from "./experience-details";
import Achievements from "./achievements";
import DoctorImage from "./doctor-image";
import $ from "jquery";
import { apiCalling } from "../apiService";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class Doctorprofile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languageList:[], 
      personalDetails:[],
    }
 
  }
  componentDidMount(){
    $("#profile").addClass("dashBoardLeftMenu-active");
   
  }
  
  render() {  
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container container-auto">
          <div class="main-wrapper1 mt-2 pt-0">
            <div class="row">
              <div class="col-lg-3">
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div class="col-lg-9" id="doctorprofile">
                <div class="form-head mt-4">Personal Details</div>
                <div class="dashboard-right form-section">
                  <Accordion allowZeroExpanded='true'>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Member Details
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <MemberDetail ></MemberDetail>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Educational Details
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <EducationalDetails   ></EducationalDetails>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Languages Known
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {/* {this.state.languageList.length>0 && */}
                        <LanguagesKnown ></LanguagesKnown>
                        {/* } */}
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Your Hospital Details
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <HospitalDetails ></HospitalDetails>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Professional Details
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ProfessionalDetail  ></ProfessionalDetail>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Speciality Details
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                   
                        <SpecialityDetail  ></SpecialityDetail>
                   
                      </AccordionItemPanel>
                    </AccordionItem>

                     <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Experience Details
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ExperienceDetail ></ExperienceDetail>
                      </AccordionItemPanel>
                    </AccordionItem> 

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>Achievements</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <Achievements  ></Achievements>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>Doctor Image</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <DoctorImage ></DoctorImage>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
