import React, { Component, Suspense } from "react";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import QrcodeImg from "../../image/icons/qrcode.png";
import Deactivate from "../../image/icons/deactvate.png";
import { apiCalling } from "../../apiService";
import moment from "moment";
import { Link } from "react-router-dom";
import $ from "jquery";
import Pagination from "react-js-pagination";
// import Header from "../../layout/header";
// import Footer from "../../layout/footer";
import Logoimg from "../../image/icons/image-preview.png";
import "../../css/admin.css"
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../../" + folderName + "/header"));
const Footer = React.lazy(() => import("../../" + folderName + "/footer"));
export default class OfferAddPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeResult: [],
      totalCount: 0,
      activePage: 1,
      showMessage: "Waiting for data... ",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "repApprovalListing",
        cmbSelect: $("#searchType").val() === "0" ? "" : $("#searchType").val(),
        txtInput: $("#resultTxt").val(),
        Offset: JSON.stringify(offset),
        max: max,
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          approvalResult: data.RepApprovalSearchData,
          totalCount: data.totalCount,
        });
        $(".loader").hide();
      }
    });
  }

  componentDidMount() {
    this.getIdaStoreDetails();
  }

  getIdaStoreDetails() {
    $(".loader").show();
    const idaStoreList = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "getStoreOfferListing",
        "idaStoreDetailsId":"",
        "keyWord": ""
    }),
    };
    apiCalling(idaStoreList).then((data) => {
        // console.log(data,"ggggggggggggggggggg");
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          storeResult: data.result,
        //   totalCount: data.totalCount,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          storeResult: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }

//   changeText = () => {
//     if ($("#searchType").val() !== "0") {
//       document.getElementById("resultTxt").disabled = false;
//       $("input[name=resultTxt]").focus();
//     } else {
//       document.getElementById("resultTxt").disabled = true;
//     }
//     document.getElementById("resultTxt").value = "";
//   };

  searchHandler() {
    // if ( $("#resultTxt").val() === "") {
    // //   var t = document.getElementById("searchType");
    // //   var selectedText = t.options[t.selectedIndex].text;
    //   alert( " Field Cannot Be Empty");
    //   $("#resultTxt").focus();
    //   return false;
    // } 
    // else {
      const searchData = {
        method: "POST",
        body: JSON.stringify({
            "functionName": "getStoreOfferListing",
            "idaStoreDetailsId": "",
            "keyWord":$("#resultTxt").val().replace(/\s/g,'')
        }),
      };
      $(".loader").show();
      apiCalling(searchData).then((data) => {
        // console.log(data);
        if (data.success === "1") {
          $("#paginationType").show();
          $(".loader").hide();
          this.setState({
            storeResult: data.result,
            // totalCount: data.totalCount,
            // activePage: 1,
          });
        }
        if (data.success === "0") {
          $("#paginationType").hide();
          $(".loader").hide();
          this.setState({
            storeResult: [],
            showMessage: data.errorMessage,
          });
        }
      });
    // }
  }

//   statusChangeHandler(id, Status) {
//     var x = false;
//     if (Status === true) {
//       x = window.confirm("Do you want to Disable this?");
//     } else {
//       x = window.confirm("Do you want to Enable this?");
//     }
//     if (x === true) {
//       const SatusData = {
//         method: "POST",
//         body: JSON.stringify({
//           functionName: "storeListingStatusChange",
//           idaStoreDetailsId: id,
//           status: Status === true ? "false" : "true",
//         }),
//       };
//       $(".loader").show();
//       apiCalling(SatusData).then((data) => {
//         if (data.success === "1") {
//           $(".loader").hide();
//           alert("Status Changed Successfully");
//           this.getIdaStoreDetails();
//         }
//         if (data.success === "0") {
//           $(".loader").hide();
//           alert(data.errorMessage);
//         }
//       });
//     }
//   }

//   srcTypeImage(ev) {
//     ev.target.src = Logoimg;
//   }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />
       
        <div className="loader"></div>
        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboard">Dashboard</a>
              </li>
              {/* <li>
                <a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a>
              </li> */}
              <li>
                <a href="" className="current">
                  IDA Offer List
                </a>
              </li>
            </ul>
          </div>
          <div className="head-hospMangmntList">IDA Offer List</div>

          <div className="HosptlMangemntTable">
            <div className="AddNewHspbtnDiv">
              <a
                href="/adminManagement/addEditofferPage/"
                className="addnewhspbtn"
              >
                {" "}
                Add New Offer
              </a>
            </div>
            {/* {this.state.totalCount > 5 ? (
              <div className="pagination-qualList" id="paginationType">
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalCount}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange}
                />
              </div>
            ) : (
              ""
            )} */}
            <div className="tableHeader-HspMangemnt">
              {/* <div className="selectField-HspMangmnt">
                <select
                  className="slecthsp slecthspMob"
                  id="searchType"
                  name="searchType"
                  onChange={() => this.changeText()}
                >
                  <option value="0">Search In</option>
                  <option value="1">Store Code</option>
                  <option value="2">Store Name</option>
                  <option value="3">Contact Number</option>
                </select>
              </div> */}
              <div className="selectField-HspMangmnt">
                <input
                  type="text"
                  className="hspmngInput hspmngInputmob"
                  name="resultTxt"
                  id="resultTxt"
                  placeholder="Please Choose A Title"
                //   disabled
                />
              </div>
              {/* <div className="selectField-HspMangmnt">
                <select className="slecthsp slecthspMob" id="searchStatusType" name="searchStatusType">
                  <option value="0">Status</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div> */}
              <div className="selectField-HspMangmnt">
                <button
                  className="searchbtn-hspMng"
                  onClick={() => this.searchHandler()}
                >
                  Search
                </button>
              </div>
            </div>
            {this.state.storeResult.length > 0 ? (
              <div class="table-responsive">
                <table className="table table-bordered-hsp">
                  <thead>
                    <tr>
                      <th className="tablehead-hspmng">ID</th>
                      {/* <th className="tablehead-hspmng">Store Code</th> */}
                      <th className="tablehead-hspmng">Store Name </th>
                      <th className="tablehead-hspmng">Title</th>
                      <th className="tablehead-hspmng">Description</th>
                      <th className="tablehead-hspmng">Expiry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.storeResult.map((data) => (
                      <tr>
                        <td>{data.idaStoreDetailsId}</td>
                        {/* <td>{data.storeCode}</td> */}
                        <td>{data.storeName}</td>
                        <td>{data.title}</td>
                        <td>{data.description}</td>
                        {/* <td></td> */}
                        <td className="nowrap">
                        {moment(data.expiryDate).format('DD/MM/YY')}
                            <span className="ml-4">
                              <Link
                                to={
                                  "/adminManagement/addEditofferPage/" +
                                  data.idaStoreDetailsId+"/"+data.idaStoreOfferId
                                }
                              >
                                <img
                                  src={Edit}
                                  alt=""
                                  width="18"
                                  className="img-fluid"
                                />
                              </Link>
                            </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* {this.state.totalCount > 5 ? (
                  <div className="pagination-qualList pagination-qualListwidth" id="paginationType">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={10}
                      onChange={this.handlePageChange}
                    />
                  </div>
                ) : (
                  ""
                )} */}
              </div>
            ) : (
              <p className="error-msg text-center mb-0">
                {" "}
                {this.state.showMessage}{" "}
              </p>
            )}
          </div>
        </div>
        {folderName !== null && <Footer />}
          </section>
        </Suspense>
        {/* <Suspense fallback={<div></div>}>
          <section>
          
          </section>
        </Suspense> */}
      </div>
    );
  }
}
