import React from "react";

export default function dataInput({ handleFile }) {
  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) handleFile(files[0]);
  };

  return (
    <div className="row">
      <label className="col-md-3" htmlFor="file">
        Drag or choose spreadsheet file
      </label>
      <input
        type="file"
        className="col-md-9 "
        id="file"
        accept={AcceptFileFormats}
        onChange={handleChange}
      />
    </div>
  );
}

const AcceptFileFormats = ["xlsx", "xlsb", "xlsm", "xls"];
