import React, { Component } from "react";
import { apiCalling } from "../../apiService";
import QRcode from "react-qr-code";
import $ from "jquery";

export default class GenerateQRcodeIda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeName: "",
      storeLocation: "",
      storeContact: "",
      storeCode: "",
    };
  }

  componentDidMount() {
    this.qrIdaStoreDetails();
  }

  cancelHandle() {
    window.location.href = "/adminManagement/idaStoresList";
  }

  qrIdaStoreDetails() {
    const idaStoreData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getStoreListingByCategory",
        idaStoreCategoryId: "",
        keyWord: "",
        idaStoreDetailsId: this.props.match.params.storeId,
        Offset: "0",
        max: "10",
      }),
    };
    $(".loader").show();
    apiCalling(idaStoreData).then((data) => {
      if (data.success === "1") {
        // console.log(data);
        this.setState({
          storeName: data.result[0].storeName,
          storeLocation: data.result[0].address,
          storeContact: data.result[0].contactNo,
          storeCode: data.result[0].storeCode,
        });
      }
      $(".loader").hide();
    });
  }

  printQrcode() {
    document.getElementById("printButton").style.opacity = "0";
    document.getElementById("cancelButton").style.opacity = "0";
    window.print();
    document.getElementById("printButton").style.opacity = "1";
    document.getElementById("cancelButton").style.opacity = "1";
  }
  render() {
    const objectString = JSON.stringify(this.state);
    return (
      <div>
        <br />
        <br />
        <div className="loader"></div>
        {this.state.name !== "" ? (
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              maxWidth: "30%",
              width: "100%",
            }}
            className="qrcode-container"
          >
            <label>QR Code For:{this.state.name}</label>
            <QRcode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={objectString}
              viewBox={`0 0 256 256`}
            />
            <br />
            <br />
            <div className="col-md-12 text-right mb-3 p-0">
              <input
                type="submit"
                id="printButton"
                className="smssetng-svebtn"
                value="Print"
                onClick={() => this.printQrcode()}
              />
              <input
                type="submit"
                value="Cancel"
                id="cancelButton"
                className="smssetng-cancelbtn"
                onClick={this.cancelHandle}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
