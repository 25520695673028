import React, { Component } from 'react';
import "../css/purpleresponsive.css"
import "../css/style.css"
import keyfeaturesclinic from "../image/theme/clinic.png"
import Header from "../layout/header"
import Footer from "../layout/footer"
import TrustedBy from "../home/trustedBy"
import ContactForm from "../home/contactus-form"
const clinic = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/home3.png"

export default class ClinicManagement extends Component {
    render() {
        return (
            <div className="purpleWrap bg-clr-wthHead">
                <Header></Header>
            <div className="fullabtus">
                <div className="aboutus">
                    <div className="container">
                            <div className="telehealthfeaturehead telehealthfeaturehead-mb">Practice Management Solutions</div>
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="aboutuspara font-20">Looking to go digital or go paperless? Needing to comply to government requirements for electronic health records for patients? Wanting to modernize your practice? If so, Purple Health has a terrific solution for you!</p>
                                <br></br>
                                <p className="aboutuspara font-20">From individual practitioners to a polyclinic, from health records to prescriptions and billing, our software lets you take charge of your clinic operations immediately.  </p>
                            </div>
                            <div className="col-lg-1"></div>
                            <div className="col-lg-5 mt-4 image-center">
                                <img src={clinic} class="side-image"></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="telehealthfeaturehead  telehealthfeaturehead-mb">Key Features</div>
                    <div className="row">
                        <div className="col-lg-6 mb-5 image-center">
                            <img src={keyfeaturesclinic} class="side-image"></img>
                        </div>
                        <div className="col-lg-6 rightaboutuspara">
                            <ul className="ul-liike-p">
                                <li class="font-20">Patient Electronic Health Records (EHR)</li>
                                <li class="font-20">Appointment calendar</li>
                                <li class="font-20">Online & In-person appointment</li>
                                <li class="font-20">Speciality & configurable case sheets</li>
                                <li class="font-20">Precriptions</li>
                                <li class="font-20">Procedure Management</li>
                                <li class="font-20">Treatment plan creation</li>
                                <li class="font-20">Automated SMS reminders</li>
                                <li class="font-20">Patient CRM management</li>
                                <li class="font-20">Full analytics and reporting</li>
                                <li class="font-20">Medical consent forms</li>
                                <li class="font-20">Patient health cards</li>
                                <li class="font-20">Multiple Doctor & staff roles</li>
                                <li class="font-20">Lab Order Management</li>
                                <li class="font-20">Pharmacy Order Management</li>
                                <li class="font-20">Inventory, Income & Expense tracking</li>
                                <li class="font-20">Billing & GST options</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="container mb-5">
                    <div className="telehealthfeaturehead  telehealthfeaturehead-mb">Pricing</div>
                    <div className="row price-section-list-Main">
                        <div className="col-lg-6">
                            <div className="price-section-list">
                                <div className="price-section-list-head">Individual Practitioner</div>
                                <div className="price-section-list-oldrate cross-price">Rs. 15,000/yr</div>
                                <div className="price-section-list-newrate">Rs. 5,000/yr !</div>
                                <div class="price-section-list-MainDiv">
                                    <p class="text-capitalize">Taxes Extra</p>
                                    <p class="text-capitalize">Terms & Conditions Apply</p>
                                    <p class="text-capitalize">Limited Time Special Offer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="price-section-list">
                                <div className="price-section-list-head">Hospitals <br></br> & Enterprise</div>
                                <div className="price-section-list-oldrate price-section-list-contact">Contact <br></br> For Pricing</div>
                                
                            </div>
                        </div>
                    </div>
                </div> */}
                <div class="bgbannerImg">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="bgbannerImg-text">Don't wait!<br></br> Contact PurpleHealth Today!</div>
                            <div class="contactusbtn mt-5">
                                <a href="#" class="text-decoration-none">
                                <span class="commonButton-whitebg">Get Started</span>
                                </a>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                {/* <TrustedBy></TrustedBy> */}
                {/* <ContactForm></ContactForm> */}
            </div>
            <Footer></Footer>
        </div>

      
  
    )
  }
}
