import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { apiCalling, apiUrlRelianceFunction } from "../apiService";
import Pagination from "react-js-pagination";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class DoctorRegistrationReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportData: [],
      totalCount: 0,
      activePage: 1,
      showMessage: "Waiting for data... ",
      option: "0",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.registrationReport();
  }

  handlePageChange(pageNumber) {
    window.scrollTo(0, 0);
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorRegistrationReport",
        siteId:
          window.sessionStorage.getItem("role") == "ROLE_ADMIN"
            ? ""
            : window.sessionStorage.getItem("siteId"),
        offset: JSON.stringify(offset),
        max: max,
        doctorName: "",
        hospitalName: "",
      }),
    };
    $(".loader").show();
    let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
    apiFunction(apiJson).then((data) => {
      if (data.success == "1") {
        this.setState({
          reportData: data.result,
          totalCount: data.resultCount,
        });
        $(".loader").hide();
      }
    });
  }
  changeText = (e) => {
    if ($("#videoAudioSelect").val() !== "0") {
      document.getElementById("txtVideoAudio").disabled = false;
      $("input[name=txtVideoAudio]").focus();
      this.setState({ option: e.target.value });
    } else {
      document.getElementById("txtVideoAudio").disabled = true;
    }
    document.getElementById("txtVideoAudio").value = "";
  };
  searchHandler() {
    // console.log("data.....", this.state.option);
    if ($("#txtVideoAudio").val() === "") {
      alert("Please enter value in search field");
      return false;
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorRegistrationReport",
        siteId:
          window.sessionStorage.getItem("role") == "ROLE_ADMIN"
            ? ""
            : window.sessionStorage.getItem("siteId"),
        offset: "0",
        max: "10",
        doctorName: this.state.option === "1" ? $("#txtVideoAudio").val() : "",
        hospitalName:
          this.state.option === "2" ? $("#txtVideoAudio").val() : "",
        mobileNumber:
          this.state.option === "3" ? $("#txtVideoAudio").val() : "",
        emailId: this.state.option === "4" ? $("#txtVideoAudio").val() : "",
      }),
    };
    let apiFunction = folderName != null ? apiCalling : apiUrlRelianceFunction;
    apiFunction(apiJson).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        this.setState({
          reportData: data.result,
          totalCount: data.resultCount,
          showMessage: data.result.length > 0 ? "" : "No data found",
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          reportData: [],
          showMessage: data.errorMessage,
        });
      }
    });
    // console.log("apiJson",apiJson.body)
    // if($("#txtVideoAudio").val()==="1"){
    //     console.log("1")
    //     this.setState({
    //         option:"1"
    //     })
    // }
    // else if($("#txtVideoAudio").val()==="2"){
    //     console.log("2")
    //     this.setState({
    //         option:"2"
    //     })
    // }
    // else if($("#txtVideoAudio").val()==="3"){
    //     console.log("3")
    //     this.setState({
    //         option:"3"
    //     })
    // }
    // else if($("#txtVideoAudio").val()==="4"){
    //     console.log("4")
    //     this.setState({
    //         option:"4"
    //     })
    // }
  }
  registrationReport() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorRegistrationReport",
        siteId:
          window.sessionStorage.getItem("role") == "ROLE_ADMIN"
            ? ""
            : window.sessionStorage.getItem("siteId"), //window.sessionStorage.getItem("siteId"),
        offset: "0",
        max: "10",
      }),
    };
    let apiFunction = folderName != null ? apiCalling : apiUrlRelianceFunction;
    apiFunction(apiJson).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        this.setState({
          reportData: data.result,
          totalCount: data.resultCount,
          showMessage: data.result.length > 0 ? "" : "No data found",
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          reportData: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            {folderName != null && <Header />}
            <div className="loader"></div>
            <div class="container">
              <div class="main-wrapper1">
                <div class="col-md-12 p-0">
                  <ul class="breadcrumb">
                    <li>
                      <a
                        href={
                          "/dashboard/reportDashboard/" +
                          this.props.match.params.groupHospitalId
                        }
                      >
                        Reports
                      </a>
                      <i class="fa fa-chevron-right"></i>
                    </li>
                    <li>Doctor Registration Report</li>
                  </ul>
                </div>
                <div class="form-head"> Doctor Registration Report </div>
                <div class="custom-search row">
                  <div class="col-md-4 col-6 mb-3 custom-search-sub custom-search-subWidth">
                    <select
                      class="input-design"
                      id="videoAudioSelect"
                      name="videoAudioSelect"
                      onChange={(e) => this.changeText(e)}
                    >
                      <option value="0">- - Select - -</option>
                      <option value="1"> Doctor Name</option>
                      <option value="2">Hospital Name</option>
                      <option value="3"> Mobile Number</option>
                      <option value="4"> Email ID</option>
                    </select>
                  </div>
                  <div class="col-md-4 col-6 mb-3 custom-search-sub custom-search-subWidth">
                    <input
                      type="text"
                      class="input-design"
                      id="txtVideoAudio"
                      name="txtVideoAudio"
                      disabled
                    />
                  </div>
                  <div class="col-md-1 col-10 mb-3 custom-search-sub custom-search-subWidth">
                    <input
                      type="submit"
                      value="Search"
                      class="formButton m-0 formButtonsearch-width"
                      onClick={() => this.searchHandler()}
                    />
                  </div>
                  {/* <div className="mb-3 custom-search-sub p-0 ml-2">
                                        <a onClick={() => this.csvFileDownload()} className="cursor-pointer"><img src={csv} alt="csv" width="25" title="Export CSV" className="mt-1" /></a>
                                    </div> */}
                </div>
                {this.state.reportData.length > 0 ? (
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Hospital Id</th>
                          <th>Hospital Name</th>
                          <th>Doctor Id</th>
                          <th>Doctor Name</th>
                          <th>Mobile Number</th>
                          <th>Email-Id</th>
                          <th>Created Date</th>
                          <th>Modified Date</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "12px" }}>
                        {this.state.reportData.map((data) => {
                          return (
                            <tr>
                              <td>{data.hospitalId}</td>
                              <td>{data.hospitalName}</td>
                              <td>{data.doctorId}</td>
                              <td>{data.displayName}</td>
                              <td>{data.mobileNumber}</td>
                              <td>{data.emailId}</td>
                              <td>{data.createdDate}</td>
                              <td>{data.modifiedDate}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {this.state.totalCount > 5 ? (
                      <div className="paginationSection orderm-paginationSection">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <p className="error-msg text-center mb-0">
                    {" "}
                    {this.state.showMessage}{" "}
                  </p>
                )}
              </div>
            </div>
            {folderName !== null && <Footer />}
          </section>
        </Suspense>
      </div>
    );
  }
}
