import React, { Component, Suspense } from "react";
import { apiCalling } from "../../apiService";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
// import Header from "../../layout/header";
// import Footer from "../../layout/footer";
import Logoimg from "../../image/icons/image-preview.png";
import Plus from "../../image/icons/plus.png";
import Closebtn from "../../image/icons/close-sm.png";
import DatePicker from "react-datepicker";
import moment from "moment";
import AWS from "aws-sdk";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { FormSelect } from "semantic-ui-react";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../../" + folderName + "/header"));
const Footer = React.lazy(() => import("../../" + folderName + "/footer"));
var s3;
var imageFile;
var copyBranches
var copystate
var copySpecialisation
export default class AddapprovalEdit extends Component {
  constructor(props) {
    super(props);
    // this.handleAddBranches = this.handleAddBranches.bind(this);
    this.state = {
      adApprovalResult: [],
      adCompanyList: [],
      adName: "",
      description: "",
      imageLogo: "",
      brochureLink: "",
      companyList: [],
      displaySpeciality: [],
      displayStates: [],
      startDate: "",
      expiryDate: "",
      companyData: [],
      tempCompanyId: 0,
      tempCompanyData: { companyId: "", companyName: "" },
      companyLogoWithPath: "",
      imagePath: "",
      s3bucketAccessKey: "",
      pharmaImagePath: "",
      companyLogoName: "",
      AWSAccessKeyId: "",
      region: "us-east-1",
      bucket: "",
      bucketFolder: "",
      statesDropdown: [],
      specialityDropdown: [],
      tempStateId: 0,
      // tempStateData: { stateId: "", stateName: "" },
      stateData: [],
      branchesDropdown: [],
      tempBranchId: 0,
      // tempBranchData: { branchId: "", branchName: "" },
      branchData: [],
      tempSpecializationId: 0,
      tempSpecializationData: { specializationId: "", specialityName: "" },
      specializationData: [],
      displayForSpecialities: false,
      displayForStates: false,
      displayForBranches: false,
    };
  }
  endDate = (date) => {
    this.setState({
      expiryDate: date,
    });
  };
  fromDate = (date) => {
    // let newDate = moment(date).format('DD/MM/YYYY')
    this.setState({
      startDate: date,
    });
  };
  componentDidMount() {
    if (this.props.match.params.pharmaRepAdsId) {
      this.adApprovalList();
    }
    this.stateDropdownList();
    this.dropdownSpecializationData();
    this.adApprovalCompanyList();
    this.imageUploadHandler();
    this.branchDropdownList();
  }
  stateDropdownList() {
    const statesDropdown = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDropdownLists",
      }),
    };
    $(".loader").show();
    apiCalling(statesDropdown).then((data) => {
      if (data.success == "1") {
        $(".loader").hide();
        this.setState({ statesDropdown: data.result[0].stateData });
      }
      if (data.success == "0") {
        $(".loader").hide();
        this.setState({ statesDropdown: [] });
      }
    });
  }
  branchDropdownList() {
    const branchDropdown = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "branchListing",
        "keyWord": ""
      }),
    };
    $(".loader").show();
    apiCalling(branchDropdown).then((data) => {
      if (data.success == "1") {
        $(".loader").hide();
        this.setState({ branchesDropdown: data.result });
      }
      if (data.success == "0") {
        $(".loader").hide();
        this.setState({ branchesDropdown: [] });
      }
    });
  }
  adApprovalList() {
    const approvalData = {
      method: "POST",
      body: JSON.stringify(
        // {
        //   "token": "U2FsdGVkX19Tlss1b6s4APUhJM2WBovBoNoFQt4SBZyw8WvlRrISuBXaQn9mDLYtP/v+kSuop8LJ7dlPkxLEXQxSdLCd1RD8g9cZ9vKu36g="
        // }
        {
          functionName: "editAdApproval",
          pharmaRepAdsId: this.props.match.params.pharmaRepAdsId,
        }
      ),
    };
    $(".loader").show();
    apiCalling(approvalData).then((data) => {
        // console.log(data,"kkkkkkkkkkkkkkkk");
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          adName: data.pharmaRepAdsData[0].adName,
          description: data.pharmaRepAdsData[0].description,
          companyLogoWithPath: data.pharmaRepAdsData[0].imageLogo,
          brochureLink: data.pharmaRepAdsData[0].brochureLink,
          companyList: data.pharmaRepAdsData[0].companyList,
          displaySpeciality: data.pharmaRepAdsData[0].displaySpeciality,
          stateData: data.pharmaRepAdsData[0].displayStates,
          displayForSpecialities:
            data.pharmaRepAdsData[0].displayForSpecialities,
          displayForStates: data.pharmaRepAdsData[0].displayForStates,
          branchData: data.pharmaRepAdsData[0].branchList,
          displayForBranches:data.pharmaRepAdsData[0].displayForBranches
          // startDate: data.pharmaRepAdsData[0].startDate,
          // expiryDate: data.pharmaRepAdsData[0].expiryDate,
        });
        if (data.pharmaRepAdsData[0].startDate !== "") {
          var startDate = data.pharmaRepAdsData[0].startDate
            .toString()
            .split("/");
          var startDateConcat =
            startDate[2].toString().trim() +
            "," +
            startDate[1].toString().trim() +
            "," +
            startDate[0].toString().trim();
          var finalStartDate = new Date(startDateConcat);
        } else finalStartDate = "";
        if (data.pharmaRepAdsData[0].expiryDate !== "") {
          var endDate = data.pharmaRepAdsData[0].expiryDate
            .toString()
            .split("/");
          var endDateConcat =
            endDate[2].toString().trim() +
            "," +
            endDate[1].toString().trim() +
            "," +
            endDate[0].toString().trim();
          var finalEndDate = new Date(endDateConcat);
        } else finalEndDate = "";
        this.setState({
          startDate: finalStartDate,
          expiryDate: finalEndDate,
        });
        if (data.pharmaRepAdsData[0].isMedicalAd === true) {
          $("#isMedicalAd").prop("checked", true);
        } else {
          $("#isMedicalAd").prop("checked", false);
          this.setState({ statesDropdown: [] });
        }


        if (this.state.displayForStates === true) {
          $("#displayForStates").prop("checked", true);
          $("#StatesList").hide();
          this.setState({ specialityDropdown: [] });
        } else {
          $("#displayForStates").prop("checked", false);
          this.setState({
            displayStates: data.pharmaRepAdsData[0].displayStates,
          });
          $("#StatesList").show();
        }
        // if (this.state.displayForBranches === true) {
        //   $("#displayForBranches").prop("checked", true);
        //   $("#BranchList").hide();
        //   this.setState({ specialityDropdown: [] });
        // } else {
        //   $("#displayForBranches").prop("checked", false);
        //   this.setState({
        //     displayForBranches: data.pharmaRepAdsData[0].displayBranches,
        //   });
        //   $("#BranchList").show();
        // }
        if (this.state.displayForSpecialities === true) {
          $("#displayForSpecialities").prop("checked", true);
          this.setState({ specialityDropdown: [] });
          // $("#SpecializationList").hide();
        } else {
          $("#displayForSpecialities").prop("checked", false);
          this.setState({
            displaySpeciality: data.pharmaRepAdsData[0].displaySpeciality,
          });
          $("#displayForSpecialities").show();
        }
        if (this.state.displayForBranches === true) {
          $("#displayForBranches").prop("checked", true);
           this.setState({ displayForBranches: [] });
        } else {
          $("#displayForBranches").prop("checked", false);
          this.setState({
            displayForBranches: data.pharmaRepAdsData[0].displayForBranches,
          });
        }
        for (let i = 0; i < this.state.companyList.length; i++) {
          var tempCompanyData = { ...this.state.tempCompanyData };
          tempCompanyData.companyId = this.state.companyList[i].siteId;
          tempCompanyData.companyName = this.state.companyList[i].siteName;
          this.setState({ tempCompanyData });
          this.state.companyData.push(this.state.tempCompanyData);
          this.setState({
            tempCompanyData: {
              companyId: "",
              companyName: "",
            },
          });
        }
        // for (let i = 0; i < this.state.displayStates.length; i++) {
        //   var tempStateData = { ...this.state.tempStateData };
        //   tempStateData.branchId = this.state.displayStates[i].branchId;
        //   tempStateData.branchName = this.state.displayStates[i].branchName;
        //   this.setState({ tempStateData });
        //   this.state.stateData.push(this.state.tempStateData);
        //   this.setState({
        //     tempStateData: {
        //       branchId: "",
        //       branchName: "",
        //     },
        //   });
        // }
        for (let i = 0; i < this.state.displaySpeciality.length; i++) {
          var tempSpecializationData = { ...this.state.tempSpecializationData };
          tempSpecializationData.specializationId =
            this.state.displaySpeciality[i].specialityId;
          tempSpecializationData.specialityName =
            this.state.displaySpeciality[i].specialityName;
          this.setState({ tempSpecializationData });
          this.state.specializationData.push(this.state.tempSpecializationData);
          this.setState({
            tempSpecializationData: {
              specializationId: "",
              specialityName: "",
            },
          });
        }
        // this.state.companyData.push(this.state.companyList)
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          showMessage: data.errorMessage,
        });
      }
    });
  }
  dropdownSpecializationData() {
    const specializationData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalDropdown",
      }),
    };
    apiCalling(specializationData).then((data) => {
      if (data.success === "1") {
        this.setState({
          specialityDropdown: data.hospitalData[0].specializationData,
        });
      }
    });
  }
  adApprovalCompanyList() {
    const companyData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getAdApprovalCompanyList",
      }),
    };
    apiCalling(companyData).then((data) => {
      if (data.success === "1") {
        // console.log(data,"hhhhhhhhhhhhhhhhhhhhhhhhh");
        this.setState({
          adCompanyList: data.adApprovalCompanyListData,
        });
      }
    });
  }
  handleStatesAutoFill = (e) => {
    if (e.target.value) {
      // copystate = [...this.state.stateData];
      copystate = this.state.statesDropdown.filter(x => x.stateId == e.target.value)
      this.setState((obj) => ({
        stateData: [...obj.stateData, { stateId: copystate[0].stateId, stateName: copystate[0].stateName }]
      }), () => {
        // console.log("this.sttttt", this.state.stateData)
      })
      // copystate.push({ stateName: e.target.value })
      // console.log(copystate, "copycopy");
    }
    // this.setState({ tempStateId: e.target.value });
    // var value = e.target.value;
    // var intValue = parseInt(value);
    // // var name = ""
    // for (let i = 0; i < this.state.statesDropdown.length; i++) {
    //   if (this.state.statesDropdown[i].stateId === intValue) {
    //     var loopId = i;
    //     break;
    //   }
    // }
    // var tempStateData = { ...this.state.tempStateData };
    // tempStateData.stateId = e.target.value;
    // tempStateData.stateName = this.state.statesDropdown[loopId].stateName;
    // this.setState({ tempStateData });
  };
  // handleAddStates() {
  //   if (copystate) {
  //     this.setState((obj) => ({
  //       stateData: [...obj.stateData, { stateId: copystate[0].stateId, stateName: copystate[0].stateName }]
  //     }), () => {
  //       console.log("this.sttttt", this.state.stateData)
  //     })
  //   }
    // let index = this.state.stateData.findIndex(
    //   (x) => x.stateId === this.state.tempStateData.stateId
    // );
    // let stateData = this.state.stateData;
    // if (this.state.tempStateData.stateId !== "" && index < 0) {
    //   stateData.push(this.state.tempStateData);
    // }
    // for (let i = 0; i < this.state.statesDropdown.length; i++) {
    //   if (
    //     this.state.statesDropdown[i].stateId ===
    //     this.state.tempStateData.stateId
    //   ) {
    //     this.state.statesDropdown.splice(i, 1);
    //   }
    // }
    // this.setState({
    //   stateData: stateData,
    //   tempStateData: {
    //     stateId: "",
    //     stateName: "",
    //   },
    // });
  // }
  handleCancelStates(id, i) {
    // let stateData = this.s
    let copyStateData = [...this.state.stateData];
    copyStateData.splice(i, 1);
    this.setState({ stateData: copyStateData });
    // if(copyBranches){tate.stateData.filter((e) => e.stateId !== id);
    // this.setState({ stateData });
  }
  ////////////////////////////////////////////////////
  handleBranchesAutoFill = (e) => {
    if (e.target.value) {
      copyBranches = [...this.state.branchData];
      copyBranches.push({ branchName: e.target.value })
      this.setState({ branchData: copyBranches });
    }
    // console.log(copyBranches,"copyBranchescopyBranches");
  };
  // handleAddBranches() {
  //   if (copyBranches) {
  //     this.setState({ branchData: copyBranches });
  //   }
    
  // }
  handleCancelBranch(id, i) {
    let copyBranchData = [...this.state.branchData];
    copyBranchData.splice(i, 1);
    this.setState({ branchData: copyBranchData });
  }
  handleSpecializationAutoFill = (e) => {
    if (e.target.value) {
      // copystate = [...this.state.stateData];
      copySpecialisation = this.state.specialityDropdown.filter(x => x.specializationId == e.target.value)
      this.setState((obj) => ({
        specializationData: [...obj.specializationData, { specializationId: copySpecialisation[0].specializationId, specialityName: copySpecialisation[0].specialityName }]
      }), () => {
        // console.log("this.sttttt", this.state.specializationData)
      })
      // copySpecialisation.push({ stateName: e.target.value })
      // console.log(copystate, "copycopy");
    }
    // this.setState({ tempSpecializationId: e.target.value });
    // var value = e.target.value;
    // var intValue = parseInt(value);
    // // var name = ""
    // for (let i = 0; i < this.state.specialityDropdown.length; i++) {
    //   if (this.state.specialityDropdown[i].specializationId === intValue) {
    //     var loopId = i;
    //     break;
    //   }
    // }
    // var tempSpecializationData = { ...this.state.tempSpecializationData };
    // tempSpecializationData.specializationId = e.target.value;
    // tempSpecializationData.specialityName =
    //   this.state.specialityDropdown[loopId].specialityName;
    // this.setState({ tempSpecializationData });
  };
  // handleAddSpecialization() {
  //   let index = this.state.specializationData.findIndex(
  //     (x) =>
  //       x.specializationId ===
  //       this.state.tempSpecializationData.specializationId
  //   );
  //   let specializationData = this.state.specializationData;
  //   if (
  //     this.state.tempSpecializationData.specializationId !== "" &&
  //     index < 0
  //   ) {
  //     specializationData.push(this.state.tempSpecializationData);
  //   }
  //   for (let i = 0; i < this.state.specialityDropdown.length; i++) {
  //     if (
  //       this.state.specialityDropdown[i].specializationId ===
  //       this.state.tempSpecializationData.specializationId
  //     ) {
  //       this.state.specialityDropdown.splice(i, 1);
  //     }
  //   }
  //   this.setState({
  //     specializationData: specializationData,
  //     tempSpecializationData: {
  //       specializationId: "",
  //       stateName: "",
  //     },
  //   });
  // }
  // }
  handleCancelSpecialization(id,i) {
    let copySpesialisationData = [...this.state.specializationData];
    copySpesialisationData.splice(i, 1);
    this.setState({ specializationData:copySpesialisationData });

    // let specializationData = this.state.specializationData.filter(
    //   (e) => e.specializationId !== id
    // );
    // this.setState({ specializationData });
  }
  // handleCompanyAutoFill = (e) => {
  //   this.setState({ tempCompanyId: e.target.value });
  //   var value = e.target.value;
  //   var intValue = parseInt(value);
  //   for (let i = 0; i < this.state.adCompanyList.length; i++) {
  //     if (this.state.adCompanyList[i].companyId === intValue) {
  //       var loopId = i;
  //       break;
  //     }
  //   }
  //   var tempCompanyData = { ...this.state.tempCompanyData };
  //   tempCompanyData.companyId = e.target.value;
  //   tempCompanyData.companyName = this.state.adCompanyList[loopId].companyName;
  //   this.setState({ tempCompanyData });
  // };
  handleAddAdApproval() {
    let index = this.state.companyData.findIndex(
      (x) => x.companyId === this.state.tempCompanyData.companyId
    );
    let companyData = this.state.companyData;
    if (this.state.tempCompanyData.companyId !== "" && index < 0) {
      companyData.push(this.state.tempCompanyData);
    }
    for (let i = 0; i < this.state.adCompanyList.length; i++) {
      if (
        this.state.adCompanyList[i].companyId ==
        this.state.tempCompanyData.companyId
      ) {
        this.state.adCompanyList.splice(i, 1);
      }
    }
    this.setState({
      companyData: companyData,
      tempCompanyData: {
        companyId: "",
        companyName: "",
      },
    });
  }
  handleCancelCompany(id) {
    let companyData = this.state.companyData.filter((e) => e.companyId !== id);
    this.setState({ companyData });
  }
  descChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }
  cancelHandle() {
     window.location.href = "/admin/adApproval";
  }
  srcTypeImage(ev) {
    ev.target.src = Logoimg;
  }
  fileChange = (e) => {
    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format");
      return false;
    }
    if (imageFile.size > 3145728) {
      alert("File size must under 3 MB!");
      return false;
    } else {
      this.fileUpload(imageFile);
    }
  };
  imageUploadHandler() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
        });
        this.setState({
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
        });
        this.setState({
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
        });
        this.setState({
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
        });
        this.setState({
          pharmaImagePath: data.configPathDetails[0].pharmaImagePath,
        });
        // alert("hiiiii"+data.configPathDetails[0].companyLogoWithPath)
        // this.setState({
        //   companyLogoWithPath: data.configPathDetails[0].companyLogoWithPath,
        // });
      }
    });
  }
  fileUpload(file) {
    $(".loader").show();
    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = {
      Bucket:
        this.state.bucket +
        "/" +
        this.state.bucketFolder +
        "/" +
        this.state.pharmaImagePath,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "";
    let fileName = this.uuidv4() + file.name;
    let fullFilePath = S3BucketMainFolder + fileName;
    imageFile = fileName;
    this.setState({ companyLogoWithPath: fileName });
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          this.setState({
            companyLogoWithPath: data.Location,
            companyLogoName: fileName,
          });
          $(".loader").hide();
          return null;
        }
      }
    );
  }
  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  handleStatesChange = (e) => {
    if (!e.target.checked) {
      $("#StatesList").fadeOut("fast");
      this.setState({ displayForStates: false });
    } else {
      $("#StatesList").fadeIn("fast");
      this.setState({ displayForStates: true });
    }
  };
  handleSpecializationChange = (e) => {
    if (!e.target.checked) {
      $("#SpecializationList").fadeIn("fast");
      this.setState({ displayForSpecialities: false });
    } else {
      $("#SpecializationList").fadeOut("fast");
      this.setState({ displayForSpecialities: true });
    }
  };
  handleBranchesChange = (e) => {
    if (!e.target.checked ) {
      $("#BranchList").fadeIn("fast");
      this.setState({ displayForBranches: false });
    } else {
      $("#BranchList").fadeOut("fast");
      this.setState({ displayForBranches: true });
    }
  };
  saveHandle = () => {
    // let companyId = [];
    let stateId = [];
    let branchId = []
    let specializationId = [];
    var startDate = moment(this.state.startDate).format("DD/MM/YYYY");
    var endDate = moment(this.state.expiryDate).format("DD/MM/YYYY");
    // for (let i = 0; i < this.state.companyData.length; i++) {
    //   companyId.push(this.state.companyData[i].companyId);
    // }
    if (this.state.displayForStates === false) {
      for (let i = 0; i < this.state.stateData.length; i++) {
        stateId.push(this.state.stateData[i].stateId);
      }
    }
    if (this.state.displayForBranches === false) {
      for (let i = 0; i < this.state.branchData.length; i++) {
        branchId.push(this.state.branchData[i].branchName);
      }
    }
    if (this.state.displayForSpecialities === false) {
      for (let i = 0; i < this.state.specializationData.length; i++) {
        specializationId.push(
          this.state.specializationData[i].specializationId
        );
      }
    }
    // let companyString = companyId.toString();
    // console.log(companyString,"companyStringcompanyString");
    // if (companyString === "") {
    //   alert("Companies List Required");
    //   return false;
    // }
    let branchString = branchId.toString();
    let stateString = stateId.toString();
    let specializationString = specializationId.toString();
    // if (
    //   this.state.displayForSpecialities === false &&
    //   specializationString === ""
    // ) {
    //   alert("Specialites List Required");
    //   return false;
    // }
    // if (this.state.displayForStates === false && stateString === "") {
    //   alert("States List Required");
    //   return false;
    // }
    // if (this.state.displayForBranches === false && branchString === "") {
    //   alert("Branch List Required");
    //   return false;
    // }
    let isMedicalAd =
      $("#isMedicalAd").is(":checked") === true ? "true" : "false";
    // let displayForStates = ($("#displayForStates input").is(":checked") === true ? 'true' : 'false')
    // let displayForSpecialities = ($("#displayForSpecialities input").is(":checked") === true ? 'true' : 'false')
    $(".loader").show();
    var companyLogoName = this.state.companyLogoName;
    if (this.state.companyLogoName === "") {
      companyLogoName = /[^/]*$/.exec(this.state.companyLogoWithPath)[0];
    }
    const saveData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "updatePharmaRepAds",
        pharmaRepAdsId: this.props.match.params.pharmaRepAdsId,
        adName: this.state.adName,
        description: this.state.description,
        imageLogo:
          this.state.companyLogoWithPath === "" ||
            this.state.companyLogoWithPath === null
            ? " "
            : companyLogoName,
        brochureLink: this.state.brochureLink,
        isMedicalAd: isMedicalAd,
        displayForStates: this.state.displayForStates,
        displayForSpecialities: this.state.displayForSpecialities,
        displayForBranches:this.state.displayForBranches,

        // displayForSpecialities:true,
        // displayForStates:true,
        // displayForBranches:true,

        startDate: startDate,
        expiryDate: endDate,
        companyList:"1",//hence it is ida
        specialities:specializationString.toString(),
        targetStates:stateId.toString(),
        branches: branchId.toString(),
      }),
    };
    apiCalling(saveData).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        alert("Updated successfully");
         this.cancelHandle();
      } else if (data.success === "0") {
        alert(data.errorMessage);
        $(".loader").hide();
        this.cancelHandle();
      }
    });
  };
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />
        <div className="loader"></div>
        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboard">Dashboard</a>
              </li>
              {/* <li>
                <a href="/admin/dashboard">Pharma Ad Approval</a>
              </li> */}
              {/* <li>
                <a href="/adminManagement/pharmadashboardList/adApproval">
                  Ad Approval List
                </a>
              </li> */}
              <li>
                <a href="" className="current">
                  Ad Approval Edit
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="addrepwrapperborder container pt-5 m-auto text-left purple_edit">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Ad Name<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    name="adName"
                    value={this.state.adName}
                    onChange={(e) => this.descChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="row">
                <div className="col-md-2">
                  Description<span className="text-danger">*</span>
                </div>
                <div className="col-md-10">
                  <textarea
                    className="form-control"
                    name="description"
                    value={this.state.description}
                    onChange={(e) => this.descChange(e)}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Logo<span className="text-danger">*</span>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <div className="hspinfo-btnupload mb-auto">
                    <span>Upload</span>
                    <input
                      type="file"
                      className="hspinfoInputfieldupload"
                      onChange={this.fileChange}
                    />
                  </div>
                  {this.state.companyLogoWithPath ? (
                    <div className="file_img">
                      <img
                        id="companyLogoWithPath"
                        src={this.state.companyLogoWithPath}
                        onError={this.srcTypeImage}
                        width="50"
                        alt="Upload"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Broucher Link<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    name="brochureLink"
                    value={this.state.brochureLink}
                    onChange={(e) => this.descChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex my-3">
              <label className="d-flex align-items-center mb-0">
                Medical Ad
              </label>
              <input
                type="checkbox"
                id="isMedicalAd"
                name="isMedicalAd"
                className="mx-3"
              />
            </div>
            <div className="col-md-6 d-flex my-3">
              <label className="d-flex align-items-center mb-0 label_classMain">
                Display For All States
                <input
                  type="checkbox"
                  name="displayForStates"
                  id="displayForStates"
                  className="mx-3"
                  onChange={this.handleStatesChange}
                />
              </label>
              <label className="d-flex align-items-center m-auto mb-0 label_classMain">
                Display For All Specialities
                <input
                  type="checkbox"
                  name="displayForSpecialities"
                  id="displayForSpecialities"
                  className="mx-3"
                  onChange={this.handleSpecializationChange}
                />
              </label>
              <label className="d-flex align-items-center mb-0 label_classMain">
                Display For All Branches
                <input
                  type="checkbox"
                  name="displayForBranches"
                  id="displayForBranches"
                  className="mx-3"
                  onChange={this.handleBranchesChange}
                />
              </label>
            </div>
            {/* <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Company<span className="text-danger">*</span>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <select
                    className="form-control"
                    onChange={(e) => this.handleCompanyAutoFill(e)}
                  >
                    <option defaultChecked>Select Company</option>
                    {this.state.adCompanyList.map((data) => (
                      <option value={data.companyId}>{data.companyName}</option>
                    ))}
                  </select>
                  <div className="input_icon">
                    <a onClick={() => this.handleAddAdApproval()}>
                      <img src={Plus} />
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-12 mb-3">
              {this.state.companyList.length > 0 ? 
                                        <div className="col-md-3">
                                        {this.state.companyList.map((data)=>(
                                            <>
                                                <label>Company list</label>
                                                <div className="d-flex align-items-center">
                                                    <input type="text" key={data.siteId} value={data.siteName} className="form-control"/>
                                                    <div className="input_icon">
                                                        <img src={Closebtn}/>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>:
                                    <span hidden></span>} 
              {this.state.companyData.length > 0 ? (
                <div className="row">
                  {this.state.companyData.map((data) => (
                    <div className="col-md-3">
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          key={data.companyId}
                          readOnly
                          className="form-control"
                          value={data.companyName}
                        />
                        <div className="input_icon">
                          <a
                            onClick={() =>
                              this.handleCancelCompany(data.companyId)
                            }
                          >
                            <img src={Closebtn} />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <span hidden></span>
              )}
            </div> */}
            {this.state.displayForStates === false ? (
            <div className="col-md-12">
              <div id="StatesList" className="row">
                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-4">
                      States List<span className="text-danger">*</span>
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <select
                        className="form-control"
                        onChange={(e) => this.handleStatesAutoFill(e)}
                      >
                        <option defaultChecked>Select States</option>
                        {this.state.statesDropdown.map((data) => (
                          <option value={data.stateId}>{data.stateName}</option>
                        ))}
                      </select>
                      {/* <div className="input_icon">
                        <a onClick={() => this.handleAddStates()}>
                          <img src={Plus} />
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  {/* {this.state.companyList.length > 0 ? 
                                        <div className="col-md-3">
                                        {this.state.companyList.map((data)=>(
                                            <>
                                                <label>Company list</label>
                                                <div className="d-flex align-items-center">
                                                    <input type="text" key={data.siteId} value={data.siteName} className="form-control"/>
                                                    <div className="input_icon">
                                                        <img src={Closebtn}/>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>:
                                    <span hidden></span>}  */}
                  {/* {this.state.stateData.length > 0 ? ( */}
                 
                    <div className="row">
                      {this.state.stateData.map((data, i) => (
                        <div className="col-md-3">
                          {/* <label className="invisible">States List list</label> */}
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              key={data.stateId}
                              readOnly
                              className="form-control"
                              value={data.stateName}
                            />
                            <div className="input_icon">
                              <a
                                onClick={() =>
                                  this.handleCancelStates(data.stateId, i)
                                }
                              >
                                <img src={Closebtn} />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  {/* // ) : (
                  //   <span hidden></span>
                  // )} */}
                </div>
              </div>
            </div>):""}
            {this.state.displayForBranches === false ? (
              <div className="col-md-12">
                <div id="BranchList" className="row">
                  <div className="col-md-6 mb-3">
                    <div className="row">
                      <div className="col-md-4">
                        Branch List<span className="text-danger">*</span>
                      </div>
                      <div className="col-md-8 d-flex align-items-center">
                        <select
                          className="form-control"
                          onChange={(e) => this.handleBranchesAutoFill(e)}
                        >
                          <option value="" defaultChecked>Select Branches</option>
                          {this.state.branchesDropdown.map((data) => (
                            <option value={data.branchName}>{data.branchName}</option>
                          ))}
                        </select>
                        {/* <div className="input_icon">
                          <a onClick={() => this.handleAddBranches()}>
                            <img src={Plus} />
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    {/* {this.state.companyList.length > 0 ? 
                                        <div className="col-md-3">
                                        {this.state.companyList.map((data)=>(
                                            <>
                                                <label>Company list</label>
                                                <div className="d-flex align-items-center">
                                                    <input type="text" key={data.siteId} value={data.siteName} className="form-control"/>
                                                    <div className="input_icon">
                                                        <img src={Closebtn}/>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>:
                                    <span hidden></span>}  */}
                    {/* {this.state.branchData.length > 0 ? ( */}
                    <div className="row">
                      {this.state.branchData.map((data, i) => (
                        <div className="col-md-3">
                          {/* <label className="invisible">States List list</label> */}
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              key={data.branchId}
                              readOnly
                              className="form-control"
                              value={data.branchName}
                            />
                            <div className="input_icon">
                              <a
                                onClick={() =>
                                  this.handleCancelBranch(data.branchName, i)
                                }
                              >
                                <img src={Closebtn} />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* // ) : (
                  //   <span hidden></span>
                  // )} */}
                  </div>
                </div>
              </div>) : ""}
              {this.state.displayForSpecialities===false?
            <div className="col-md-12">
              <div id="SpecializationList" className="row mb-3">
                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-4">
                      Specialization List<span className="text-danger">*</span>
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <select
                        className="form-control"
                        onChange={(e) => this.handleSpecializationAutoFill(e)}
                      >
                        <option defaultChecked>Select Specialization</option>
                        {this.state.specialityDropdown.map((data) => (
                          <option value={data.specializationId}>
                            {data.specialityName}
                          </option>
                        ))}
                      </select>
                      {/* <div className="input_icon">
                        <a onClick={() => this.handleAddSpecialization()}>
                          <img src={Plus} />
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  {/* {this.state.companyList.length > 0 ? 
                                        <div className="col-md-3">
                                        {this.state.companyList.map((data)=>(
                                            <>
                                                <label>Company list</label>
                                                <div className="d-flex align-items-center">
                                                    <input type="text" key={data.siteId} value={data.siteName} className="form-control"/>
                                                    <div className="input_icon">
                                                        <img src={Closebtn}/>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>:
                                    <span hidden></span>}  */}
                  {this.state.specializationData.length > 0 ? (
                    <div className="row">
                      {this.state.specializationData.map((data,i) => (
                        <div className="col-md-3">
                          {/* <label className="invisible">Specialization list</label> */}
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              key={data.specialityId}
                              readOnly
                              className="form-control"
                              value={data.specialityName}
                            />
                            <div className="input_icon">
                              <a
                                onClick={() =>
                                  this.handleCancelSpecialization(
                                    data.specialityId,i
                                  )
                                }
                              >
                                <img src={Closebtn} />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span hidden></span>
                  )}
                </div>
              </div>
            </div>:""}
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">Start Date</div>
                <div className="col-md-8">
                  <DatePicker
                    selected={this.state.startDate}
                    className="mb-0 form-control"
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    peekNextMonth
                    showYearDropdown
                    showMonthDropdown
                    onSelect={this.fromDate}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">End Date</div>
                <div className="col-md-8">
                  <DatePicker
                    selected={this.state.expiryDate}
                    onChange={this.endDate}
                    className="mb-0 form-control"
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    showYearDropdown
                    showMonthDropdown
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 text-right mb-3">
              <input
                type="submit"
                className="smssetng-svebtn"
                onClick={() => this.saveHandle()}
              />
              <input
                type="submit"
                value="Cancel"
                className="smssetng-cancelbtn"
                onClick={this.cancelHandle}
              />
            </div>
          </div>
        </div>
        {folderName !== null && <Footer />}
          </section>
        </Suspense>
      </div>
    );
  }
}
