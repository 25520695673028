import React, { Component, Suspense } from "react";
import { apiCalling } from "../apiService";
import $ from "jquery";
import Close from "../image/icons/close-sm.png";
export default class Analysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      GetingAnalyticsDetails: [],
    };
  }
  componentDidMount() {
    this.getPopUpDetails();
  }
  getPopUpDetails() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "getAnalyticsDetailsByTypeId",
        "messageId": this.props.PopupnewsFeedDownloadDataId,
        "type": this.props.PopupnewsFeedDownloadType,
        "siteId":window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          GetingAnalyticsDetails: data.result,
        });
      }
    });
  }
  cancelPopup() {
    $("#analisePopup").hide();
  }
  render() {
    return (
      <div class="popup-overlay edit_modal" id="analisePopup">
        <div className="popup-contentDiv">
          <div className="modal-popup">
            <span
              className="closepopupqual" id="txtCancel"
              onClick={() => this.cancelPopup()}
            >
              <img src={Close} alt="Close" className="img-fluid" width="25" />
            </span>
            <div className="content">
              <div class="HosptlMangemntTable">
                <div class="table-responsive">
                  <table className="table table-bordered-hsp table_custom">
                    <thead>
                      <tr>
                        <th>
                          User Name
                        </th>
                        <th>
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.GetingAnalyticsDetails.map((data) => (
                        <tr>
                          <td>{data.userName}</td>
                          <td>{data.createdDate}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}