import React, { Component } from "react";
import { apiCalling } from "../apiService";
import $ from "jquery";
export default class headerPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // menuName:window.sessionStorage.getItem("menuName"),
      // menuLink:window.sessionStorage.getItem("menuLink"),
      // htmlCont:window.sessionStorage.getItem("htmlCont"),
      // sortOrder:window.sessionStorage.getItem("sortOrder"),
      // headerId:window.sessionStorage.getItem("headerId"),
      headerId: this.props.headerDeatils.headerLinksId,
      menuName: this.props.headerDeatils.headerlinkName,
      menuLink: this.props.headerDeatils.url,
      htmlCont: this.props.headerDeatils.headerlinkContent,
      sortOrder: this.props.headerDeatils.sortOrder,
      isActive: this.props.headerDeatils.isActive,
      headerData: []
    }
    this.saveData = this.saveData.bind(this);
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(event) {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  }

  saveData = () => {
    const saveApi = {
      method: "POST",
      body: JSON.stringify({
        functionName: "saveHeaderLinksDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        headerSectionObj: {
          headerLinksId: this.state.headerId,
          headerlinkName: $("#menuName").val(),//this.state.menuName,
          headerlinkContent: $("#htmlCont").val(),//this.state.htmlCont,
          groupHospitalAdminId: window.sessionStorage.getItem("groupHospitalAdminId"),
          hospitalId: "",
          headerUrl: $("#menuLink").val(),//this.state.menuLink,
          menu: "",
          subMenu: "",
          menuType: "",
          isActive: "1",
          headerSortOrder: $("#sortOrder").val(),//this.state.sortOrder,
          headerSubList: []
        }
      })
    }
    apiCalling(saveApi).then((data) => {
      if (data.success === "1") {
        alert("Saved successfully");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        alert("update failed...")
      }
      this.props.headerDeatilsVisible(false)
      // window.location.reload(false);
    });
  }

  componentDidMount() {
    //alert(this.state.headerId)
    // $(".popup-hide").click(function () {
    //   $(".popup-overlay").hide();
    // });

    const headerList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "headerLinksDetails",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    }

    apiCalling(headerList).then((data) => {
      if (data.success === "1") {
        this.setState({ headerData: data.headerLinkContent })
      }
    });
  }

  cancelPopup() {
    this.props.headerDeatilsVisible(false)
  }

  render() {

    return (
      <div class="popup-overlay headerPopup" style={{ display: 'block' }}>
        <div className="popup-contentDiv">
          <div className="modal-popup">
            <a className="close-popup popup-hide" onClick={() => this.cancelPopup()}>&times;</a>
            <div className="header border-0">Menu Header</div>
            <div className="content">
              <div class="row">
                <div class="row-margin col-md-6">
                  <label class="col-md-12 label-text">Menu Name</label>
                  <div class="col-md-12">
                    <input type="text" class="input-design" id="menuName" name="menuName" value={this.state.menuName} onChange={(event) => this.handleChange(event)} /></div>
                </div>
                <div class="row-margin col-md-6">
                  <label class="col-md-12 label-text">Menu Link</label>
                  <div class="col-md-12"><input type="text" class="input-design" id="menuLink" name="menuLink" value={this.state.menuLink} onChange={(event) => this.handleChange(event)} /></div>
                </div>
                <div class="row-margin col-md-12">
                  <label class="col-md-12 label-text">HTML Content</label>
                  <div class="col-md-12"><textarea class="input-design" style={{ height: "100px" }} id="htmlCont" name="htmlCont" onChange={(event) => this.handleChange(event)}>{this.state.htmlCont}</textarea></div>
                </div>
                <div class="row-margin col-md-12">
                  <pre >
                    <code class="language-html" data-lang="html">
                    &lt;div class=&quot;container&quot;&gt;<br></br>
                    &nbsp;&lt;div class=&quot;container-page&quot;&gt;<br></br>
                      &nbsp;&nbsp;&lt;h1&gt;Main Heading here...&lt;/h1&gt;<br></br>
                      &nbsp;&nbsp;&lt;h2&gt;Sub Heading here...&lt;/h2&gt;<br></br>
                      &nbsp;&nbsp;&lt;h3&gt;Small Heading here...&lt;/h3&gt;<br></br>
                      &nbsp;&nbsp;&lt;p&gt;Paragraph here &lt;b&gt;We can use for bold&lt;/b&gt;&lt;/p&gt;<br></br>
                      &nbsp;&nbsp;&lt;ul&gt;<br></br>
                      &nbsp;&nbsp;&nbsp;&lt;li&gt;  we can use dot list here&lt;/li&gt;<br></br>
                      &nbsp;&nbsp;&lt;/ul&gt;<br></br>
                      &nbsp;&nbsp;&lt;ol&gt;<br></br>
                      &nbsp;&nbsp;&nbsp;&lt;li&gt;  we can use number list here&lt;/li&gt;<br></br>
                      &nbsp;&nbsp;&lt;/ol&gt;<br></br>
                      &nbsp;&lt;/div&gt;<br></br>
                      &lt;/div&gt;
                    </code>
                  </pre>
                </div>
                <div class="row-margin col-md-6">
                  <label class="col-md-12 label-text">Sort Order</label>
                  <div class="col-md-12">

                    <select class="input-design"
                      id="sortOrder"
                      name="sortOrder"
                      value={this.state.sortOrder}
                      onChange={(event) => this.handleChange(event)}
                    >
                      <option>Select</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="text-center">
                {/* <a href="#">
                  <input
                    type="submit"
                    value="Save"
                    className="formButtonBg mx-1"
                  ></input>
                </a> */}
                <span className="divBtn" onClick={() => this.saveData()}>Save</span>
                <input
                  type="button"
                  value="cancel"
                  className="formButtonBg cancelButtonBg mx-1 popup-hide"
                  onClick={() => this.cancelPopup()}
                ></input>
              </div>
              {/* <div class="table-responsive tableBorderOnly mt-5">
                <table class="table table-border">
                  <thead>
                    <th>Sl No.</th>
                    <th>Name</th>
                  </thead>
                  <tbody>
                  {this.state.headerData.map((headerDeatils,i) => (
                    <tr>
                      <td>{headerDeatils.headerLinksId}</td>
                      <td>{headerDeatils.headerlinkName}</td>
                    </tr>
                     ))}
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
        <div >

        </div>
      </div>
    );
  }
}
