import React, { Component } from 'react';
import $ from "jquery";

export default class DoctorNetwork extends Component {
  editText()
  {
    $(".invite-network-dr-content").addClass("pharmacy-editActive")
  }  
  saveText()
  {
    $(".invite-network-dr-content").removeClass("pharmacy-editActive")
  }

  render() {
    return (
      <div className="pharmacyapp-wrapper p-0">
        <div className="invite-network-wrapper">
            <div className="pharmacyapp-head">Invite to my network</div>
            <div className="invite-network-banner">
              <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/doctors.png" alt="" className="img-fluid invite-network-img"/>
            </div>
            <div className="invite-network-dr-main">
              <div className="invite-network-dr-edit" onClick={() => this.editText()}>
                  <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/edit-bg.png" alt="edit" ></img>
              </div>
              <div className="invite-network-dr-det">
                <div className="invite-network-dr-image">
                  <img width="55" src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/doctor-img.png"alt="" ></img>
                </div>
                <div className="invite-network-dr-name">Doctor Name</div>
              </div>
              <div className="invite-network-dr-content">
                Hello Dr.Name, <br></br>
                <p className="pharmacy-content-p">I would like to add you to my network in order to better communicate & refer patients more easily to you.</p>
                <textarea className="pharmacy-input" value="I would like to add you to my network in order to better communicate & refer patients more easily to you."></textarea>
                <button className="pharmacy-btn"  onClick={() => this.saveText()}>Save</button>

              </div>
            </div>
            
            <div className="invite-network-btn-main">
              <button className="invite-network-button">
                Invite
                <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/back.png" alt="" ></img>
              </button>
            </div>
        </div>
      </div>
    )
  }
}
