import React, { Component, Suspense } from "react";
import { apiCalling } from '../apiService'
import $ from "jquery";
import book from "../image/theme/inperson.png";
import video from "../image/theme/video1.png";
import chat from "../image/theme/text.png";
import phone from "../image/theme/call.png";
import email from "../image/theme/email.png";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Docservices extends Component {
  constructor() {
    super()
    this.state = {
      serviceDataList: [],
      serviceDataListResult: [],
    }
    this.changePrice = this.changePrice.bind(this)
  }
  componentDidMount() {
    this.serviceData();
  }
  serviceData() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorService",
        doctorId: window.sessionStorage.getItem("userId"),
        //hospitalId: hospitalId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      //console.log("data",data)
      if (data.success == "1") {
        this.setState({
          serviceDataList: data.result,
          serviceDataListResult: data.result[0]
        })
        //alert(data.successMessage);
       // console.log("serviceDataList", this.state.serviceDataList)
      } else {
        alert(data.errorMessage);
      }
    });
  }
  changePrice = (type, hospitalId, serviceName, index) => {
    // var priceText = $("#service" + type).val();
    let callAmount =  $("#serviceCall" + index).val();
    let chatAmount =  $("#serviceChat" + index).val();
    let videoAmount =  $("#serviceVideo" + index).val();
    let inPersonAmount =  $("#servicePerson" + index).val();
    var apiJsonUpdate;
    if (type == "Call") {
      apiJsonUpdate = {
        method: "POST",
        body: JSON.stringify({
          functionName: "updateDoctorService",
          doctorId: window.sessionStorage.getItem("userId"),
          hospitalId: hospitalId,
          serviceName: serviceName,
          phoneConsultPrice: callAmount != '' ? callAmount : "0",
        }),
      };
    } else if (type == "Chat") {
      apiJsonUpdate = {
        method: "POST",
        body: JSON.stringify({
          functionName: "updateDoctorService",
          doctorId: window.sessionStorage.getItem("userId"),
          hospitalId: hospitalId,
          serviceName: serviceName,
          messagingCost: chatAmount != '' ? chatAmount : "0",
        }),
      };
    } else if (type == "Video") {
      apiJsonUpdate = {
        method: "POST",
        body: JSON.stringify({
          functionName: "updateDoctorService",
          doctorId: window.sessionStorage.getItem("userId"),
          hospitalId: hospitalId,
          serviceName: serviceName,
          videoRateSession: videoAmount != '' ? videoAmount : "0",
          videoRateMinute: '0'
        }),
      };
    } else {
      apiJsonUpdate = {
        method: "POST",
        body: JSON.stringify({
          functionName: "updateDoctorService",
          doctorId: window.sessionStorage.getItem("userId"),
          hospitalId: hospitalId,
          serviceName: serviceName,
          consultationPrice: inPersonAmount != '' ? inPersonAmount : "0",
        }),
      };
    }
    $(".loader").show();
    // console.log("apiJsonUpdate", apiJsonUpdate)
    apiCalling(apiJsonUpdate).then((data) => {
      // console.log("service doc data", data)
      $(".loader").hide();
      if (data.success == "1") {
        alert(data.successMessage);
         window.location.reload(false);
      } else {
        alert(data.errorMessage);
      }
    });
  }

  disableButton(type, serviceName) {
    var apiJsonDisable;
    if (type == "Call") {
      apiJsonDisable = {
        method: 'POST',
        body: JSON.stringify({
          functionName: "enableService",
          siteId: window.sessionStorage.getItem("siteId"),
          doctorId: window.sessionStorage.getItem("userId"),
          status: "Disable",
          serviceName: serviceName
        }),
      };
    } else if (type == "Chat") {
      apiJsonDisable = {
        method: "POST",
        body: JSON.stringify({
          functionName: "enableService",
          siteId: window.sessionStorage.getItem("siteId"),
          doctorId: window.sessionStorage.getItem("userId"),
          status: "Disable",
          serviceName:serviceName
        }),
      };
    } else if (type == "Video") {
      apiJsonDisable = {
        method: "POST",
        body: JSON.stringify({
          functionName: "enableService",
          siteId: window.sessionStorage.getItem("siteId"),
          doctorId: window.sessionStorage.getItem("userId"),
          status: "Disable",
          serviceName:serviceName
        }),
      };
    } else {
      apiJsonDisable = {
        method: "POST",
        body: JSON.stringify({
          functionName: "enableService",
          siteId: window.sessionStorage.getItem("siteId"),
          doctorId: window.sessionStorage.getItem("userId"),
          status: "Disable",
          serviceName: serviceName
        }),
      };
    }
    $(".loader").show();
    // console.log("apiJsonUpdate", apiJsonDisable)
    apiCalling(apiJsonDisable).then((data) => {
      //console.log("data", data)
      $(".loader").hide();
      if (data.success == "1") {
        alert("Request sent successfully");
         window.location.reload(false);
      } else {
        alert(data.errorMessage);
      }
    });
  }


  enableButton(type, serviceName) {
    var apiJsonEnable;
    if (type == "Call") {
      apiJsonEnable = {
        method: 'POST',
        body: JSON.stringify({
          functionName: "enableService",
          siteId: window.sessionStorage.getItem("siteId"),
          doctorId: window.sessionStorage.getItem("userId"),
          status: "Enable",
          serviceName:serviceName
        }),
      };
    } else if (type == "Chat") {
      apiJsonEnable = {
        method: "POST",
        body: JSON.stringify({
          functionName: "enableService",
          siteId: window.sessionStorage.getItem("siteId"),
          doctorId: window.sessionStorage.getItem("userId"),
          status: "Enable",
          serviceName:serviceName
        }),
      };
    } else if (type == "Video") {
      apiJsonEnable = {
        method: "POST",
        body: JSON.stringify({
          functionName: "enableService",
          siteId: window.sessionStorage.getItem("siteId"),
          doctorId: window.sessionStorage.getItem("userId"),
          status: "Enable",
          serviceName: serviceName
        }),
      };
    } else {
      apiJsonEnable = {
        method: "POST",
        body: JSON.stringify({
          functionName: "enableService",
          siteId: window.sessionStorage.getItem("siteId"),
          doctorId: window.sessionStorage.getItem("userId"),
          status: "Enable",
          serviceName:serviceName
        }),
      };
    }
    $(".loader").show();
    //console.log("apiJsonUpdate", apiJsonEnable)
    apiCalling(apiJsonEnable).then((data) => {
      //console.log("data", data)
      $(".loader").hide();
      if (data.success == "1") {
        alert("Request sent successfully");
         window.location.reload(false);
      } else {
        alert(data.errorMessage);
      }
    });
    
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <div className='loader'></div>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper1">
            <div class="col-md-12 p-0">
              <ul class="breadcrumb">
                <li>
                  <a href="/doctor/dashboard">Dashboard</a>
                  <i class="fa fa-chevron-right"></i>
                </li>
                <li>
                  <a>Services</a>
                </li>
              </ul>
            </div>
            <div class="serviceWrap pt-3">
              <ul>
                <li>
                  <a class="icon tabIcon">
                    <img src={phone} />
                    <span>Audio Chat</span>
                  </a>
                  {this.state.serviceDataListResult.callaDoctorEnabled == true && this.state.serviceDataListResult.isPhoneConsultAvailableDoctor == true ?
                    <button className="cancelButton formButton-sm" onClick={() => this.disableButton("Call", "Audio Chat")} >Disable</button>
                    :
                    <button className="cancelButton formButton-sm" style={{color:"green",border: '1px solid green'}} onClick={() => this.enableButton("Call", "Audio Chat")}>Enable</button>
                  }
                  {this.state.serviceDataList.map((data,index) => (
                    <div>
                      {/* {data.callaDoctorEnabled == true && data.isPhoneConsultAvailableDoctor == true && */}
                        <div>
                          <div class="hospBox hospBoxCover">
                            <div className="hospName">{data.hospitalName}</div>
                            <div class="priceCover priceCoverDiv">
                              <label className="servicePriceLabel">Price per session</label>
                              <input placeholder="Price" class="popField servicePriceInput" type="number" pattern="^[0-9]*$" defaultValue={data.audioRatePerSession} id={"serviceCall"+index} />
                            </div>
                          </div>
                          <button class="formButton formButton-sm priceButton" onClick={() => this.changePrice("Call",data.hospitalId, "Audio Chat", index)}>Change Price</button>
                        </div>
                      {/* } */}
                    </div>
                  ))}
                </li>
                <li>
                  <a class="icon tabIcon">
                    <img src={chat} />
                    <span>Text Chat</span>
                  </a>
                  {this.state.serviceDataListResult.isTextChatDoctor == "1" ?
                    <button className="cancelButton formButton-sm" onClick={() => this.disableButton("Chat", "Text Chat")}>Disable</button>
                    :
                    <button className="cancelButton formButton-sm" style={{color:"green",border: '1px solid green'}} onClick={() => this.enableButton("Chat", "Text Chat")}>Enable</button>
                  }
                  {this.state.serviceDataList.map((data,index) => (
                    <div>
                      {data.isTextChatDoctor == "1" &&
                        <div>
                          <div class="hospBox hospBoxCover">
                            <div className="hospName">{data.hospitalName}</div>
                            <div class="priceCover priceCoverDiv">
                              <label className="servicePriceLabel">Price per session</label>
                              <input placeholder="Price" class="popField servicePriceInput" type="number" defaultValue={data.textMessageCost} id={"serviceChat"+index} />
                            </div>
                          </div>
                          <button class="formButton formButton-sm priceButton" onClick={() => this.changePrice("Chat",data.hospitalId, "Text Chat",index)}>Change Price</button>
                        </div>
                      }
                    </div>
                  ))}
                </li>

                <li>
                  <a class="icon tabIcon">
                    <img src={video} />
                    <span>Video Chat</span>
                  </a>
                  {this.state.serviceDataListResult.isVideoEnabledDoctor == "1" && this.state.serviceDataListResult.videEnabled == true ?
                    <button className="cancelButton formButton-sm" onClick={() => this.disableButton("Video", "Video Chat")}>Disable</button>
                    :
                    <button className="cancelButton formButton-sm" style={{color:"green",border: '1px solid green'}} onClick={() => this.enableButton("Video", "Video Chat")}>Enable</button>
                  }
                  {this.state.serviceDataList.map((data,index) => (
                    <div>
                      {data.isVideoEnabledDoctor == "1" && data.videEnabled == true &&
                        <div>
                          <div class="hospBox hospBoxCover">
                            <div className="hospName">{data.hospitalName}</div>
                            <div class="priceCover priceCoverDiv">
                              <label className="servicePriceLabel">Price per session</label>
                              <input placeholder="Price" class="popField servicePriceInput" type="number" defaultValue={data.videoRatePerSession} id={"serviceVideo"+index} />
                            </div>
                          </div>
                          <button class="formButton formButton-sm priceButton" onClick={() => this.changePrice("Video", data.hospitalId,"Video Chat",index)}>Change Price</button>
                        </div>
                      }
                    </div>
                  ))}
                </li>

                {/* <li>
                  <a class="icon tabIcon">
                    <img src={email} />
                    <span> Email Consult</span>
                  </a>
                  <button className="cancelButton formButton-sm">
                    Disable
                  </button>
                  <div class="hospBox">
                    <div class="priceCover">
                      <label>Price</label>
                      <input placeholder="Price" class="popField" type="text" />
                    </div>
                  </div>
                  <button class="formButton formButton-sm"> Change Price</button>
                </li> */}
                <li>
                  {/* <a class="icon tabIcon serviceEnable"> */}
                  <a class="icon tabIcon">
                    <img src={book} />
                    <span>In Person</span>
                  </a>
                  {this.state.serviceDataListResult.isHospitalOnline == "1" ?
                    <button className="cancelButton formButton-sm" onClick={() => this.disableButton("Person", "Book Appointment")}>Disable</button>
                    :
                    <button className="cancelButton formButton-sm" style={{color:"green",border: '1px solid green'}} onClick={() => this.enableButton("Person", "Book Appointment")}>Enable</button>
                  }
                  {this.state.serviceDataList.map((data,index) => (
                    <div>
                      {data.isHospitalOnline == "1" &&
                        <div>
                          <div class="hospBox hospBoxCover">
                            <div className="hospName">{data.hospitalName}</div>
                            <div class="priceCover priceCoverDiv">
                              <label className="servicePriceLabel">Price</label>
                              <input placeholder="Price" class="popField servicePriceInput" type="number" defaultValue={data.bookAppointmentPrice} id={"servicePerson"+index} />
                            </div>
                          </div>
                          <button class="formButton formButton-sm priceButton" onClick={() => this.changePrice("Person", data.hospitalId, "Book Appointment",index)}>Change Price</button>
                        </div>
                      }
                    </div>
                  ))}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
