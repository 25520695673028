import React, { Component, Suspense } from 'react'
import { Link } from 'react-router-dom'
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class HelpLine extends Component {
  render() {
    return (
      <div className="main">
      <Suspense fallback={<div></div>}>
  <section>
    <Header />
  </section>
</Suspense>
      <main role="main">
      <div className='subPage_container'>
        <div className='container'>
            <div className='content_help'>
                <div className='help_border'>
                <h1 className='main_header'>HELP LINE</h1>
                <h2 className='leveltwo_head'>HELP LINE : 8078478278, support@imahospitals.com</h2>
                <div className='helpLink'>Kindly log on to <Link to="www.imahospitals.com">www.imahospitals.com</Link> for details</div>
              <div className='address_help'>
                  <div className='addressHelp'>
                      <div className='left_addressHelp'>Address <span>:</span></div>
                      <div className='right_addressHelp'>IMA Kerala Headquarters<br/>
                      Anayara P.O.<br/>
                      Thiruvananthapuram - 695029</div>
                  </div>
                  <div className='addressHelp'>
                      <div className='left_addressHelp'>Phone no <span>:</span></div>
                      <div className='right_addressHelp'>8078478278</div>
                  </div>
              </div>
              <div className='helpLinemb'>IMA Hospital Network</div>
              <div className='help_paraGraph'>Enabled by IMA PEPS, HBI</div>
            </div>
            </div>
        </div>
      </div>
      </main>
      <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
     
    )
  }
}
