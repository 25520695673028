import React, { Component, Suspense } from "react";
import axios from "axios";
import Dashboardleftmenu from "./dashboardleftmenu";
import $ from "jquery";
import avatar from "../image/theme/user.png";
import doctors from "../image/media/doctors.jpg";
import like from "../image/media/icon4.png";
import { apiCalling } from "../apiService";
import InnerHTML from "dangerously-set-html-content";
// const axios = require("axios");
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashBoardBulletin: [],
      htmlContent: "",
    };
  }
  componentWillMount() {
    const doctorDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getSiteDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        siteUrl: window.sessionStorage.getItem("siteUrl"),
      }),
    };
    apiCalling(doctorDetails).then(async (data) => {
      if (data.success === "1") {
        console.log("data", data.siteDetailsData[0].siteData[0].dashboard_html);
        if (
          !data.siteDetailsData[0].siteData[0].dashboard_html.includes("</div>")
        ) {
          const response = await axios.get(
            data.siteDetailsData[0].siteData[0].dashboard_html
          );
          const urlCalling = response.data.replaceAll("''", "'");
          this.setState({ dashBoardBulletin: urlCalling });
          // return urlCalling;
          //  const urlCalling= await axios.get(data.siteDetailsData[0].siteData[0].dashboard_html)
          //   .then(response => {
          //     return response.data.replaceAll("''", "'");
          //   })
          //   .catch(error => {
          //     console.log(error);
          //   });
        } else {
          this.setState({
            dashBoardBulletin:
              data.siteDetailsData[0].siteData[0].dashboard_html,
          });
        }
      }
    });
  }
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper1 mt-2 pt-0">
            <div class="row">
              <div class="col-lg-3">
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div class="col-lg-9">
                <InnerHTML html={this.state.dashBoardBulletin} />
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
