import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import htpdficon from "../image/icons/ht-pdficon.png";
import HtbabyImg1 from "../image/icons/htbabyImg1.png";
import HtbabyImg2 from "../image/icons/htbabyImg2.png";
import HtbabyImg3 from "../image/icons/htbabyImg3.png";
import HtbabyImg4 from "../image/icons/htbabyImg4.png";
import HtbabyImg5 from "../image/icons/htbabyImg5.png";
import HtbabyImg6 from "../image/icons/htbabyImg6.png";

import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class HealthTrackimmunisationdwdpdf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      babyName: window.sessionStorage.getItem("babyName"),
      babyDOB: window.sessionStorage.getItem("dob"),
      healthScheduleData: [],
    };
  }
  componentDidMount() {
    this.babyPDFDetails();
  }

  babyPDFDetails() {
    const Listing = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHealthSchedule",
        healthTrackerTypeId: "1",
        userId: "", //window.sessionStorage.getItem("userId"),
        dateOfBirth: this.state.babyDOB,
      }),
    };
    $(".loader").show();
    apiCalling(Listing).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        this.setState({
          healthScheduleData: data.healthScheduleData,
        });
      }
    });
  }

  printData = () => {
    let note1 = [
      [
        "Please note: This information is not intended to, and does not constitute, medical or other health advice or diagnosis and should not be used as such. The recommendations may vary based on region, person and change with time. You should always consult with a qualified medical practitioner about your specific circumstances for the right guidance. Immunization for infants and children are very crucial for their healthy development and growth. Failing to get your child vaccinated might leave him or her exposed to a myriad of diseases and infections, against which his or her body may not have developed an immunity naturally. Immunizations and vaccinations help the child’s body in developing antibodies that protect the child from various infections at the time of being attacked.",
      ],
    ];
    let note2 = [["Source: IAP, NHM, MoHFW."]];

    let bodyData = [];
    this.state.healthScheduleData.map((hs) => {
      let dayToVisit = moment(hs.dayToVisit, "DD-MM-YYYY").format(
        "DD MMM YYYY"
      );
      bodyData.push([
        dayToVisit,
        hs.vaccineName,
        hs.diseasesName,
        hs.nis,
        hs.iap,
      ]);
    });

    var pdf = new jsPDF("p", "pt", "letter");
    pdf.text("Immunization Schedule For " + this.state.babyName, 40, 30);
    pdf.setFontSize(12);
    pdf.text(
      "Date Of Birth : " +
        moment(this.state.babyDOB, "DD/MM/YYYY").format("DD MMM YYYY"),
      40,
      45
    );
    pdf.setFontSize(12);
    pdf.autoTable({
      theme: "plain",
      margin: { top: 50, bottom: 0 },
      head: [["Date", "Vaccines", "Diseases", "NIS", "IAP"]],
      body: bodyData,
    });

    pdf.autoTable({
      theme: "plain",
      margin: { top: 1, bottom: 0 },
      body: note1,
    });
    pdf.autoTable({
      theme: "plain",
      margin: { top: 0, bottom: 0 },
      body: note2,
    });

    pdf.save("Immunization_Schedule (" + this.state.babyName + ").pdf");
  };

  //   printData(){
  //     var divContents = document.getElementById("printTable").innerHTML;
  //     var a = window.open('', '', 'height=500, width=500');
  //     a.document.write(`<title>${'Immunisation Schedule'}</title>`,divContents);
  //     a.document.close();
  //     a.print();
  //     a.close()
  // }

  // downloadPDF() {

  //   htmlToImage.toPng(document.getElementById('printTable'), { quality: 10.00 })
  //     .then(function (dataUrl) {
  //       const pdf = new jsPDF();
  //       const imgProps = pdf.getImageProperties(dataUrl);
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //       pdf.addImage(dataUrl, 'PNG', 10, 15, pdfWidth - 15, pdfHeight + 40);
  //       pdf.save("Immunisation Schedule" + ".pdf");
  //     });
  // }

  render() {
    return (
      <div class="purpleWrap ph-selectDisable ph_htPdf ph-mainWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="loader"></div>
            <Header />
            <div class="container container-auto">
              <div class="main-wrapper1">
                <div className="row ht-pdfcenter">
                  <div className="col-lg-12 ph-immune-div ">
                    <div className="  ">
                      <div
                        class="form-section mt-2"
                        id="printTable"
                        style={{
                          margin: "20px auto 0",
                          padding: "5px 0px",
                          fontSize: "16px",
                        }}
                      >
                        <div
                          class="row"
                          style={{ justifyContent: "space-around" }}
                        >
                          <div class="col-lg-12 row pl-3 d-center">
                            <label class="col-12">
                              {" "}
                              <div className="row">
                                <h1 class="telehealthfeaturehead text-center">
                                  <span>
                                    {" "}
                                    Immunization Schedule For{" "}
                                    {this.state.babyName}
                                  </span>
                                </h1>
                              </div>
                            </label>
                            <h1
                              style={{ margin: "0" }}
                              class="telehealthfeaturehead text-left  ph_dob_info"
                            >
                              {" "}
                              Date of Birth :{" "}
                              {moment(this.state.babyDOB, "DD/MM/YYYY").format(
                                "DD MMM YYYY"
                              )}
                            </h1>

                            <div className="col-md-12">
                              <div className="row ph_row">
                                <div className="col-md-12 row">
                                  {/* ====== OLD TABLE CODE BEGINS HERE ====== */}

                                  {/* <table className="ph_tblWrap ">
                                      <thead >
                                        <tr >

                                            <th scope="col"  style={{ textAlign: "left" }}><span className=" ph_tbl_head ph_tbl_head1 ph_date_td">Date</span></th>
                                            <th scope="col"  style={{textAlign:"left"}}> <span className="ph_tbl_head ph_tbl_head2">Vaccines</span></th>
                                            <th scope="col"  style={{textAlign:"left", width:"350px"}} className="ph_res_tbl_disease"> <span className="ph_tbl_head ph_tbl_head3">Diseases</span></th>
                                            <th scope="col" style={{textAlign:"left" , width: "90px"}}> <span className="ph_tbl_head4">NIS</span></th>
                                            <th scope="col" style={{ textAlign: "left", width: "90px" }}> <span className="ph_tbl_head4">IAP</span></th>


                                        </tr>
                                      </thead>
                                      <tbody >

                                          {this.state.healthScheduleData.map((data) => {
                                            let dayToVisit = moment(data.dayToVisit, 'DD-MM-YYYY').format("DD MMM YYYY");
                                            return (
                                              <tr>

                                                <td scope="row" data-label="Date">{dayToVisit}</td>
                                                <td data-label="Vaccines">{data.vaccineName}</td>
                                                <td data-label="Diseases">{data.diseasesName}</td>
                                                <td data-label="NIS">{data.nis}</td>
                                                <td data-label="IAP">{data.iap}</td>
                                              </tr>

                                            )
                                          })}

                                      </tbody>
                                    </table> */}

                                  {/* ====== OLD TABLE CODE ENDS HERE ====== */}
                                  <div className="ph_tableOuter">
                                    <div className="ph_tableOuterBaby_Wrap">
                                      <table
                                        className="ph_tblWrap "
                                        id="ph_immunization"
                                      >
                                        <thead>
                                          <tr>
                                            <th
                                              scope="col"
                                              style={{ textAlign: "left" }}
                                            >
                                              <span className=" ph_tbl_head ph_tbl_head1 ph_date_td">
                                                Date
                                              </span>
                                            </th>
                                            <th
                                              scope="col"
                                              style={{ textAlign: "left" }}
                                            >
                                              {" "}
                                              <span className="ph_tbl_head ph_tbl_head2">
                                                Vaccines
                                              </span>
                                            </th>
                                            <th
                                              scope="col"
                                              style={{
                                                textAlign: "left",
                                                width: "400px",
                                              }}
                                              className="ph_res_tbl_disease"
                                            >
                                              {" "}
                                              <span className="ph_tbl_head ph_tbl_head3">
                                                Diseases
                                              </span>
                                            </th>
                                            <th
                                              scope="col"
                                              style={{
                                                textAlign: "left",
                                                width: "110px",
                                              }}
                                            >
                                              {" "}
                                              <span className="ph_tbl_head4">
                                                NIS
                                              </span>
                                            </th>
                                            <th
                                              scope="col"
                                              style={{
                                                textAlign: "left",
                                                width: "90px",
                                              }}
                                            >
                                              {" "}
                                              <span className="ph_tbl_head4">
                                                IAP
                                              </span>
                                            </th>
                                            {/* <th scope="col" style={{ textAlign: "left", width: "90px" }}></th> */}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.healthScheduleData.map(
                                            (data) => {
                                              let dayToVisit = moment(
                                                data.dayToVisit,
                                                "DD-MM-YYYY"
                                              ).format("DD MMM YYYY");
                                              return (
                                                <tr>
                                                  <td
                                                    scope="row"
                                                    data-label="Date"
                                                  >
                                                    {dayToVisit}
                                                  </td>
                                                  <td data-label="Vaccines">
                                                    {data.vaccineName}
                                                  </td>
                                                  <td data-label="Diseases">
                                                    {data.diseasesName}
                                                  </td>
                                                  <td
                                                    data-label="NIS"
                                                    className={
                                                      data.nis == "No"
                                                        ? "ph_staus_no"
                                                        : ""
                                                    }
                                                  >
                                                    {data.nis}
                                                  </td>
                                                  <td
                                                    data-label="IAP"
                                                    className={
                                                      data.iap == "No"
                                                        ? "ph_staus_no"
                                                        : ""
                                                    }
                                                  >
                                                    {data.iap}
                                                  </td>
                                                  {/* <td rowspan="2" className="ph_babyImg"  ><img src={HtbabyImg1} style={{width: "80px"}}/></td> */}
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="ph_babyImageRight">
                                      <div className="ph_babyImg">
                                        <img
                                          src={HtbabyImg1}
                                          style={{ width: "80px" }}
                                        />
                                      </div>
                                      <div className="ph_babyImg">
                                        <img
                                          src={HtbabyImg2}
                                          style={{ width: "80px" }}
                                        />
                                      </div>
                                      <div className="ph_babyImg">
                                        <img
                                          src={HtbabyImg3}
                                          style={{ width: "80px" }}
                                        />
                                      </div>
                                      <div className="ph_babyImg">
                                        <img
                                          src={HtbabyImg4}
                                          style={{ width: "80px" }}
                                        />
                                      </div>
                                      <div className="ph_babyImg">
                                        <img
                                          src={HtbabyImg5}
                                          style={{ width: "80px" }}
                                        />
                                      </div>
                                      <div className="ph_babyImg">
                                        <img
                                          src={HtbabyImg6}
                                          style={{ width: "80px" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="ht-terms ph_disclaimer_info">
                                  <p>
                                    <b>Disclaimer</b>
                                  </p>
                                  <br />
                                  <p>
                                    Please note: This information is not
                                    intended to, and does not constitute,
                                    medical or other health advice or diagnosis
                                    and should not be used as such. The
                                    recommendations may vary based on region,
                                    person and change with time. You should
                                    always consult with a qualified medical
                                    practitioner about your specific
                                    circumstances for the right guidance.{" "}
                                  </p>
                                  <br />
                                  <p>
                                    Immunization for infants and children are
                                    very crucial for their healthy development
                                    and growth. Failing to get your child
                                    vaccinated might leave him or her exposed to
                                    a myriad of diseases and infections, against
                                    which his or her body may not have developed
                                    an immunity naturally. Immunizations and
                                    vaccinations help the child’s body in
                                    developing antibodies that protect the child
                                    from various infections at the time of being
                                    attacked.
                                  </p>
                                  <br />
                                  <p>Source: IAP, NHM, MoHFW".</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="health-track-dwd">
                        <div className="ht-terms">
                          <button
                            style={{ margin: "0px" }}
                            className="formButton formButtonBold ht-dwbttn htnewdwnlod"
                            onClick={() => this.printData()}
                          >
                            Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
