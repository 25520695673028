import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import newsfeedimg from "../image/icons/newsfeedimg.png";

import edit from "../image/icons/edit-bg.png";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class BannerIDAListing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      BannerData: [],
      totalCount: 0,
      activePage: 1,
      showMessage: "Waiting for data... "
    }
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    window.scrollTo(0, 0);
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
        offset = "0";
        max = "10";
        this.setState({
            activePage: pageNumber,
            isLoading: '0'
        })
    }
    else {
        offset = parseInt(pageNumber - 1) * 10;
        max = "10";
        this.setState({
            activePage: pageNumber,
            isLoading: '0'
        })
    }
   const pageList = {
    method: 'POST',
    body: JSON.stringify({
      functionName:"getIdaBanner",
      userId: "",
      offset:JSON.stringify(offset),
      max: max
    })
  }
    $(".loader").show();
    apiCalling(pageList).then((data) => {
      if (data.success === "1") {
        this.setState({
          BannerData: data.result,
          totalCount: data.totalCount,
         });
        $(".loader").hide();
      }
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.BannerListing();
  }

  BannerListing() {
    const dataList = {
      method: 'POST',
      body: JSON.stringify({
        "functionName":"getIdaBanner",
        "userId": "",
        "offset": "0",
        "max": "10"
      })
    }
    $(".loader").show();
    apiCalling(dataList).then((data) => {
      $(".loader").hide();
      if (data.success === "1" && data.result.length > 0) {
        this.setState({
          BannerData: data.result,
          totalCount: data.totalCount,
          activePage: 1,
        });
      } else if (data.success === "0") {
        this.setState({ showMessage: "No Data Found " });
      }
    });
  }

  srcTypeImage(ev) {
    ev.target.src = newsfeedimg
  }

  addNewBanner() {
    window.location.href = "/dashboard/BannerIDASave";
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />{" "}
            <div className='loader'></div>
          </section>
        </Suspense>
        <div className="container blog-wrapper" id="feedList">

          <h1 className="telehealthfeaturehead text-center ph-maintitle"> IDA Banner Mobile App Listing </h1>
          <ul class="breadcrumb">
            <li>
              <a href={"/admin/dashboard"}>IDA Banner</a>
              <i class="fa fa-chevron-right"></i>
            </li>
            <li>IDA Banner Mobile App Listing</li>
          </ul>
          <div className="col-md-12">
            <button className="addNew-btn" onClick={() => this.addNewBanner()}>Add New IDA Banner</button>
          </div>
          {this.state.BannerData.length > 0 ?
            <div class="table-responsive sh-table-sec">
              <table class="table">
                <thead>
                  <tr>
                    <th>Banner Name</th>
                    <th>Banner Description</th>
                    <th>Image</th>
                    <th>All Branches</th>
                    <th>Edit </th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "12px" }}>
                  {this.state.BannerData.map((data) => (
                    <tr>
                      <td>{data.bannerName}</td>
                      <td className="whitespace-nowrap">{data.bannerdescription}</td>
                      <td><img className="sh-img-tb" onError={this.srcTypeImage} src={data.bannerimagepath} /></td>
                      <td>
                        <form>
                          <input type="checkbox" id="fruit1" name="fruit-1"  defaultChecked={data.bannershowbranch=== true ? true:false} />
                          <label for="fruit1"> </label>
                        </form>
                      </td>
                     <td> <Link className="whitespace-nowrap" to={"/dashboard/BannerIDASave"+ data.bannerId}> <img src={edit} /></Link></td>
                    </tr>
                  ))}
                </tbody>
              </table>
                {this.state.totalCount > 5 ?
                <div className="paginationSection orderm-paginationSection">
                <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={10}
                totalItemsCount={this.state.totalCount}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange}
                />
                </div>
                : ""}
            </div>
            :
            <p className="error-msg text-center mb-0"> {this.state.showMessage} </p>
          }
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
