import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import { apiCalling } from "../apiService";
import $ from "jquery";
import "../css/dashboard.css";
// import edit from "../image/media/edit.png"
// import del from "../image/icons/delete.png"
import HeaderPopup from "../dashboard/headerPopup";
import SubheaderPopup from "../dashboard/subheaderPopup";
import FooterPopup from "../dashboard/footerPopup";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
const editIcon = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/media/edit.png";
const deleteIcon = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/icons/delete.png";

export default class headerLinks extends Component {

  constructor(props){
    super(props);
    this.state={
      headerData:[],
      SubList:[],
      footerDetails:[],
      details:[],
      headerDeatils:[],
      headerDeatilsVisible:false,
      headerSubVisible:false,
      footerDetailsVisible:false
    }
    this.handleSubmitClicked = this.handleSubmitClicked.bind(this);
    this.handlefooterClicked = this.handlefooterClicked.bind(this);
    this.footerclick = this.footerclick.bind(this);
    this.subheaderclick = this.subheaderclick.bind(this);
    this.deleteFooter = this.deleteFooter.bind(this);
  }
  componentDidMount(){
  

    const headerList = {
      method: "POST",
      body: JSON.stringify({
      functionName: "headerLinksDetails",
      siteId:window.sessionStorage.getItem("siteId"),
    }),
  }
  apiCalling(headerList).then((data) => {
    if (data.success == "1") {
      this.setState({headerData:data.headerLinkContent});
      this.setState({SubList:data.headerLinkContent.headerSubList})
    }
  });

   const footerList = {
    method: "POST",
    body: JSON.stringify({
    functionName:"getFooterLinks",
    groupHospitalAdminId:window.sessionStorage.getItem("groupHospitalAdminId"),
    }),
   }
   apiCalling(footerList).then((data) => {
    if (data.success == "1") {
      this.setState({footerDetails:data.footerLinkData});
    }
  });
  }

  dashboardclick = () => {
    window.location.href = "/admin/dashboard";
  }
  
  headerEditclick = (headerDeatils) => {
    this.setState({headerDeatilsVisible:true, headerDeatils:headerDeatils})
    //$(".headerPopup").show();
 }
  // headerclick() {
  //   $("#headerPopup").show();
  // }

  footerclick(details) {
    this.setState({footerDetailsVisible:true, details:details})
    // $("#footerPopup").show();
  }

  subheaderclick(headerDeatils) {
   
    //$("#subheaderPopup"+id).show();
    this.setState({headerSubVisible:true, headerDeatils:headerDeatils})
  }

  handlefooterClicked(id,isActive){

  const saveFooter = {
  method: "POST",
  body: JSON.stringify({
      functionName: "activateOrDeactivateFooterLink",
      footerLinkId:id,
      isActive:isActive
    })
  }
  $(".loader").show();
apiCalling(saveFooter).then((data) => {
  $(".loader").show();
  if (data.success == "1") {
    alert("Saved successfully");
  } else {
    alert("update failed...")
  }
  window.location.reload(false);
});
}

deleteFooter = (id) =>{
  const apiDelete = {
    method: "POST",
    body: JSON.stringify({
      functionName: "deleteFooterLink",
      footerLinkId:id
    })
}
apiCalling(apiDelete).then((data) => {
  if (data.success == "1") {
    alert("Deleted successfully");
  } else {
    alert("update failed...") 
  }
  window.location.reload(false);
});
}

handleSubmitClicked(name,content,url,sort,id,isActive) {
const saveApi = {
  method: "POST",
  body: JSON.stringify({
      functionName: "saveHeaderLinksDetails",
      siteId:window.sessionStorage.getItem("siteId"),
      headerSectionObj: {
      headerLinksId:id,
      headerlinkName:name,
      headerlinkContent:content,
      groupHospitalAdminId:window.sessionStorage.getItem("groupHospitalAdminId"),
      hospitalId: "",
      headerUrl:url,
      menu: "",
      subMenu: "",
      menuType: "",
      isActive: isActive,
      headerSortOrder:sort,
      headerSubList:[]
}
})
}
apiCalling(saveApi).then((data) => {
  if (data.success == "1") {
    alert("Saved successfully");
  } else {
    alert("update failed...")
  }
  window.location.reload(false);
});
  }

  render() {
        return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div className="divBtn" onClick={this.dashboardclick}>Dash Board</div>
        <div class="container">
          <div class="main-wrapper-smwidth">
            <div class="form-section">
              <div class="col-sm-12 form-head mb-2">header Links</div>
              <div class="col-sm-12 pl-0">
                  <div className="divBtn" onClick={() =>this.headerEditclick("")}>New Header</div>
                  <div className="divBtn" onClick={() =>this.footerclick("")}>New Footer</div>
              </div>
              <div  id="headerpopUpBlock"></div> 
              <div className="col-md-12 mt-4">
                <div className="form-subhead mb-0"> Header</div>
                <div class="table-responsive tableBorderOnly">
                  <table class="table">
                    <thead>
                      <th>Sl No:</th>
                      <th style={{width:"40%"}}>Name</th>
                      <th>Active / Deactive</th>
                      <th>Edit</th>
                      <th>Submenu</th>
                    </thead>
                    <tbody>
                    {this.state.headerData.map((headerDeatils,i) => (
                      <tr>
                         <td>{headerDeatils.headerLinksId}</td>
                         <td>
                           {headerDeatils.headerlinkName}
                           {headerDeatils.headerSubList.length !== "0" ?
                              <div>
                                {headerDeatils.headerSubList.map((data) => (
                                    <div>{data.subName}</div>

                                ))}
                              </div>
                              : ''
                           }
                           
                        </td>
                        <td>
                          {
                            headerDeatils.isActive == false ?
                         
                        <a href="#"  onClick={() => this.handleSubmitClicked(headerDeatils.headerlinkName,headerDeatils.headerlinkContent,headerDeatils.url,headerDeatils.sortOrder,headerDeatils.headerLinksId,"true")}  id="active"  style={{color:"green"}}>Active </a>
                      :
                      <a href="#"  onClick={() => this.handleSubmitClicked(headerDeatils.headerlinkName,headerDeatils.headerlinkContent,headerDeatils.url,headerDeatils.sortOrder,headerDeatils.headerLinksId, "false")} id="active" style={{color:"green"}}>Deactive </a>
                      }
                        {/* <span onClick={this.handleSubmitClicked} disabled={this.state.isButtonDisabled} style={{color:"green"}}>Active</span> */}
                         
                        </td>
                        <td onClick={() =>this.headerEditclick(headerDeatils)}><img src={editIcon}></img></td>
                        <td>
                          <button className="formButton formButton-sm m-0" onClick={() =>this.subheaderclick(headerDeatils)}>Submenu</button>
                        </td>
                      </tr>
                        ))}
                      {/* <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <span style={{color:"red"}}>Deactive</span>
                        </td>
                        <td onClick={this.headerclick}><img src={edit}></img></td>
                        <td>
                          <button className="formButton formButton-sm m-0" onClick={this.subheaderclick}>Submenu</button>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
                {this.state.headerSubVisible == true &&
                  <SubheaderPopup headerSubVisible={(data) => this.setState({headerSubVisible: data})} headerDeatils={this.state.headerDeatils}></SubheaderPopup>
                } 
                {this.state.headerDeatilsVisible == true && <HeaderPopup headerDeatilsVisible={(data) => this.setState({headerDeatilsVisible: data})} headerDeatils={this.state.headerDeatils}/>}
                <div className="form-subhead mb-0 mt-4"> Footer</div>
                <div class="table-responsive tableBorderOnly">
                  <table class="table table-border">
                    <thead>
                      <th>Sl No:</th>
                      <th style={{width:"40%"}}>Name</th>
                      <th>Active / Deactive</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </thead>
                    <tbody>
                    {this.state.footerDetails.map((details,i) => (
                      <tr>
                         <td>{details.footerLinkId}</td>
                         <td>{details.linkName}</td>
                         <td>
                          {
                            details.isActive == false ?
                         
                        <a href="#"  onClick={() => this.handlefooterClicked(details.footerLinkId,"true")}  id="active1"  style={{color:"green"}}>Active </a>
                      :
                      <a href="#"  onClick={() => this.handlefooterClicked(details.footerLinkId,"false")} id="active1" style={{color:"green"}}>Deactive </a>
                      }
                       
                        </td>
                         <td onClick={() =>this.footerclick(details)}><img src={editIcon}></img></td>
                         <td  onClick={() => this.deleteFooter(details.footerLinkId)}><img src={deleteIcon} style={{width:18}}/></td>
                      </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {this.state.footerDetailsVisible == true && 
                <FooterPopup  details={this.state.details} footerDetailsVisible={(data) => this.setState({footerDetailsVisible: data})}/>
                }
              </div>
            </div>
          </div>
        </div>
        
         <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
            
            
          {/* <FooterPopup></FooterPopup> */}
      </div>
    );
  }
}
