import React, { Component, Suspense } from "react";
import { apiCalling } from "../../apiService";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
// import Header from "../../layout/header";
// import Footer from "../../layout/footer";
import Logoimg from "../../image/icons/image-preview.png";
import Plus from "../../image/icons/plus.png";
import Closebtn from "../../image/icons/close-sm.png";
import DatePicker from "react-datepicker";
import moment from "moment";
import AWS from "aws-sdk";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { FormSelect } from "semantic-ui-react";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../../" + folderName + "/header"));
const Footer = React.lazy(() => import("../../" + folderName + "/footer"));
var s3;
var imageFile;
export default class AddEditIdaStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      title: "",
      imageLogo: "",
      description: "",
      adName: "",
      expiryDate: "",
      seviceContactNumber: "",
      idaStoreDetailsId: "",
      idaStoreCategoryId: "",
      storeList:[],
      hiddenValue:false
    };
    // this.numberHandleChange = this.numberHandleChange.bind(this);
    // this.numberHandleChangeService = this.numberHandleChangeService.bind(this);
    // this.textType = this.textType.bind(this);
  }

  componentDidMount() {
    // alert()
    // this.imageUploadHandler();
     this.getStoreCategoryDetails();
    if (this.props.match.params.storeId) {
      this.editIdaStoreDetails();
      this.setState({
        hiddenValue:true,
      });
    }
  }
  getStoreCategoryDetails() {
    const apiReq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getStoreListingByCategory",
        keyWord: $("#resultTxt").val(),
        status:true,
        idaStoreDetailsId: "",
        Offset: "0",
        max: "10",
      }),
    };
    apiCalling(apiReq).then((data) => {
      // console.log(data,"fffffffffffffffffffffffff");
      this.setState({
        storeList: data.result,
      });
    });
  }

  editIdaStoreDetails() {
    const idaStoreData = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "getStoreOfferListing",
        "idaStoreDetailsId":this.props.match.params.storeId,
        "keyWord": ""
    }),
    };
    $(".loader").show();
    apiCalling(idaStoreData).then((data) => {
      if (data.success === "1") {
        // console.log(data);
        this.setState({
          name: data.result[0].idaStoreDetailsId,
          title: data.result[0].title,
          expiryDate: data.result[0].expiryDate,
          description: data.result[0].description,
          seviceContactNumber: data.result[0].seviceContactNumber,
          idaStoreCategoryId: data.result[0].idaStoreCategoryId,
          idaStoreDetailsId: data.result[0].idaStoreDetailsId,
          imageNameOnly: data.result[0].imageNameOnly,
          imageNameWithPath: data.result[0].imagePathOnly + data.result[0].imageNameOnly,
        });
        // if (data.repApprovalData[0].hasWritePermission === true) {
        //   $("#PermissionStatus").prop("checked", true);
        // } else {
        //   $("#PermissionStatus").prop("checked", false);
        // }
      }
      $(".loader").hide();
    });
  }
  textType(e) {
    var code = "charCode" in e ? e.charCode : e.keyCode;
    if (!(code > 47 && code < 58)) {
      // lower alpha (a-z)
      alert("Only Numbers Accepted");
      e.preventDefault();
    }
  }
  descChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }
  cancelHandle() {
    window.location.href = "/adminManagement/offerAddPage/";
  }
  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  validateForm() {
    if (this.state.name === "") {
      alert("Store Name Required");
      $("#Storename").focus();
      return false;
    }
    else if (this.state.title === "") {
      alert("Title Required");
      $("#title").focus();
      return false;
    }
    else if (this.state.description === "") {
    alert("Description Required");
    $("#description").focus();
    return false;
  }
    else if (this.state.expiryDate === "") {
      alert("ExpiryDate Required");
      $("#expiryDate").focus();
      return false;
    } 
    else {
      return true;
    }
  }
  saveHandle = () => {
    if (this.validateForm()) {
      const apiReq = {
        method: "POST",
        body: JSON.stringify(
          {
            functionName: "saveOrEditOfferDetails",
           idaStoreDetailsId:this.props.match.params.storeId?this.props.match.params.storeId:this.state.name,
          title: this.state.title,
            description: this.state.description,
            expiryDate:(this.state.expiryDate).valueOf(),
            idaStoreOfferId:this.props.match.params.offerId?this.props.match.params.offerId:"",
        }
        )
      }
      $(".loader").show();
      // console.log(apiReq,"apiReqapiReqapiReq");
      apiCalling(apiReq).then((data) => {
        $(".loader").hide();
        if (data.success == 1) {
          alert("Saved Successfully");
          window.location.href =
            "/adminManagement/offerAddPage/";
        } else {
          alert("Something went wrong while saving");
        }
      });
    }
  };
  fromDate = (date) => {
    this.setState({
      expiryDate: date,
    });
  };
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
         <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />
        <div className="loader"></div>
        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboard">Dashboard</a>
              </li>
              {/* <li>
                <a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a>
              </li> */}
              {/* <li>
                <a href="/adminManagement/pharmadashboardList/idaStoresList">
                  IDA Stores List
                </a>
              </li> */}
              <li>
                {this.props.match.params.storeId > 0 ? <a href="" className="current">Edit IDA Store
                </a> : <a href="" className="current">Add New Offer Details</a>}
              </li>
            </ul>
          </div>
        </div>
        <div className="addrepwrapperborder container pt-5 m-auto text-left purple_edit">
          <div className="row">
        
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                Store Name<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                <div className="selectField-HspMangmnt">
                <select
                  className="slecthsp slecthspMob w-100"
                  id="Storename"
                  name="name"
                  value={this.state.name}
                  onChange={(e) => this.descChange(e)}
                  disabled={this.state.hiddenValue}
                >
                  <option value="">Select Store</option>
                    {this.state.storeList.map((storeNamesData, i) => (
                      <option  key={storeNamesData.storeName}
                      value={storeNamesData.idaStoreDetailsId}>
                         {storeNamesData.storeName}
                      </option>
                    ))}
                </select>
              </div>
                </div>
                 
                {/* <div className="col-md-8">
                  <input
                    type="text"
                    id="storeName"
                    className="form-control"
                    name="name"
                    value={this.state.name}
                    onChange={(e) => this.descChange(e)}
                  />
                </div> */}
              </div>
            </div>
            {this.props.match.params.storeId > 0 ? <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Ida Store Id<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    disabled
                    value={this.state.idaStoreDetailsId}
                  />
                </div>
              </div>
            </div> : ""}
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                Title<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="title"
                    className="form-control"
                    placeholder="Title"
                    name="title"
                    value={this.state.title}
                    onChange={(e) => this.descChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                Description<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    id="description"
                    placeholder="Description"
                    value={this.state.description}
                    onChange={(e) => this.descChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                 Expiry Date<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <DatePicker
                     selected={this.state.expiryDate}
                     dateFormat="dd/MM/yy"
                    className="mb-0 form-control"
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    peekNextMonth
                    showYearDropdown
                    showMonthDropdown
                   onSelect={this.fromDate}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 text-right mb-3">
              <input
                type="submit"
                className="smssetng-svebtn"
                value={this.props.match.params.storeId > 0 ? "Update" : "Save"}
                onClick={() => this.saveHandle()}
              />
              <input
                type="submit"
                value="Cancel"
                className="smssetng-cancelbtn"
                onClick={this.cancelHandle}
              />
            </div>
          </div>
        </div>
        {folderName !== null && <Footer />}
          </section>
        </Suspense>
      </div>
    );
  }
}
