import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../css/style.css";
import "../css/purpleresponsive.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import DoctorListSub from "./online-doctors-sub";
import search from "../image/theme/magnifying-glass.png";
import filterimg from "../image/theme/filter.png";
import Pagination from "react-js-pagination";
import { apiCalling } from "../apiService";
import $ from "jquery";

export default class OnlineDoctors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      doctorlist: [],
      doctorDataCount: "",
      max: 0,
      offset: "24",
      loading: false,
    };
    setInterval(async () => {
      const loginRequest = {
        method: "POST",
        body: JSON.stringify({
          functionName: "availableOnlineDoctors",
          siteId: window.sessionStorage.getItem("siteId"),
          // max: this.state.max,
          // offset: this.state.offset,
        }),
      };

      apiCalling(loginRequest).then((data) => {
        if (data.success == "1") {
         // console.log("mmm", data);
          this.setState({
            doctorlist: data.doctorList[0].otherAvailableDoctors,
          });
          this.setState({ doctorDataCount: data.doctorDataCount });
          //alert(this.state.doctorDataCount);
          $(".loader").hide();
        }
      });
    }, 5000);
  }
  hideLoader = () => {
    this.setState({ loading: false });
  };

  doctorListAPI(max, offset) {
    window.scrollTo(0, 0);
    $(".loader").show();
    const loginRequest = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorList",
        siteId: window.sessionStorage.getItem("siteId"),
        max: max,
        offset: offset,
      }),
    };
    apiCalling(loginRequest).then((data) => {
      if (data.success == "1") {
        this.setState({
          doctorlist: data.doctorData,
        });
        $(".loader").hide();
      } else {
        alert(data.errorMessage);
      }
    });
  }
  handlePageChange(pageNumber) {
    let max = this.state.max;
    let offset = this.state.offset;
    const newmax = Number(pageNumber - 1) * Number(offset);
    this.setState({ max: newmax });
    this.doctorListAPI(newmax, offset);
    this.setState({ activePage: pageNumber });
  }

  profileAvailable() {
    $("#drServiceMain1").slideDown(400);
  }
  profileAvailableCancl() {
    $("#drServiceMain1").slideUp(400);
  }
  filtersearch() {
    $("#filter-search-details").slideToggle();
  }
  componentDidMount() {
    let self = this;
    $(".loader").show();
    //this.handlePageChange(4);
  }
  render() {
    return (
      <div className="purpleWrap">
        <div className="container drListMain">
          <div className="telehealthfeaturehead text-md-left text-center">
            Online Doctors
            <div className="doctors-list-tabmain">
              <a href="/home/doctor-list-main" className="doctors-list-tab">
                Doctors{" "}
              </a>
              <a
                href="/home/online-doctors"
                className="doctors-list-tab doctors-list-tab-active"
              >
                Online Doctors{" "}
              </a>
            </div>
          </div>
          <div className="filter-search-wrapper">
            <div className="filter-search" onClick={() => this.filtersearch()}>
              <img src={filterimg}></img>search Filter
            </div>
            <div className="drSearch">
              <input
                type="text"
                className="drSearchBox"
                placeholder="Search doctor..."
              ></input>
              <img src={search} width="16"></img>
            </div>
          </div>
          <div
            className="row"
            id="filter-search-details"
            style={{ display: "none" }}
          >
            <div className="col-md-3 mt-3">
              <select class="input-design">
                <option value="">Doctor Type</option>
              </select>
            </div>
            <div className="col-md-3 mt-3">
              <select class="input-design">
                <option value="">Find a Doctor by Speciality</option>
              </select>
            </div>
            <div className="col-md-3 mt-3">
              <select class="input-design">
                <option value="">Hospital Name </option>
              </select>
            </div>
            <div className="col-md-3 mt-3">
              <select class="input-design">
                <option value="">Gender</option>
              </select>
            </div>
            <div className="col-md-3 mt-3">
              <select class="input-design">
                <option value="">Doctor Name</option>
              </select>
            </div>
            <div className="col-md-3 mt-3">
              <select class="input-design">
                <option value="">Speaks </option>
              </select>
            </div>
            <div className="col-md-3 mt-3">
              <select class="input-design">
                <option value="">State </option>
              </select>
            </div>
            <div className="col-md-3 mt-3">
              <select class="input-design">
                <option value="">Type Your City</option>
              </select>
            </div>
            <div className="col-sm-12 mt-1">
              <input
                type="button"
                value="Find Doctors"
                className="formButtonBold cancelButton-black ml-0"
              ></input>
            </div>
          </div>

          <div className="doctorDetailMain my-4">
            {this.state.doctorlist.map((obj, index) => {
              return (
                <DoctorListSub
                  docData={obj}
                  drService={obj.services}
                  consti={index}
                ></DoctorListSub>
              );
            })}
          </div>
          <div className="text-center">
            <br></br>
            {/*<Pagination
              hideFirstLastPages
              activePage={this.state.activePage}
              itemsCountPerPage="24"
              totalItemsCount={this.state.doctorDataCount}
              pageRangeDisplayed="10"
              onChange={this.handlePageChange.bind(this)}
            />*/}
          </div>
        </div>
      </div>
    );
  }
}
