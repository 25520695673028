import React, { Component } from 'react';
import { apiCalling } from "../apiService";
import moment from "moment";
import $ from "jquery";

export default class MemberDetail extends Component {

  constructor(props){
    super(props);

    this.state = {    
      emailIdT:'',
      mobileNumber:'',
      firstNameTxt:'',
      lastNameTxt:'',
      gender:'',
      doB:'',
      address:'',
      city:'',
      state:'',
      zip:'',
      country:'',
    }
  
  this.handleChange = this.handleChange.bind(this);
  this.numberHandleChange = this.numberHandleChange.bind(this);
  this.submitHandle = this.submitHandle.bind(this);
}

componentDidMount(){
  this.getProfileDeatils(); 
}

// let personalDetails=this.props.personalDetails 
//  personalDetails.map((obj)=>{
//  let dob = moment(obj.dateOfBirth, "DD/MM/YYYY").format("YYYY-MM-DD");
// this.setState({emailIdT:obj.emailId});
// this.setState({mobileNumber:obj.mobileNumber});
// this.setState({firstNameTxt:obj.doctorFirstName});
// this.setState({lastNameTxt:obj.doctorLastName});
// this.setState({gender:obj.gender});
// this.setState({doB:dob});
// this.setState({address:obj.address});
// this.setState({city:obj.city});
// this.setState({state:obj.state});
// this.setState({zip:obj.zip});
// this.setState({country:obj.country});
// }) ;

getProfileDeatils(){
  const getDetails = {
    method: "POST",
    body: JSON.stringify({
      functionName: "getDoctorDetails",
      siteId: window.sessionStorage.getItem("siteId"),
      doctorId: window.sessionStorage.getItem("userId"),
    }),
  }
  apiCalling(getDetails).then((data) => {
    if (data.success === "1") {        
      this.setState({ emailIdT:  data.doctorData[0].personalDetails[0].emailId,
                      mobileNumber: data.doctorData[0].personalDetails[0].mobileNumber,
                      firstNameTxt: data.doctorData[0].personalDetails[0].doctorFirstName,
                      lastNameTxt: data.doctorData[0].personalDetails[0].doctorLastName,
                      gender: data.doctorData[0].personalDetails[0].gender,
                      doB: moment(data.doctorData[0].personalDetails[0].dateOfBirth,"DD/MM/YYYY").format("YYYY-MM-DD"),
                      address: data.doctorData[0].personalDetails[0].address,
                      city: data.doctorData[0].personalDetails[0].city,
                      state: data.doctorData[0].personalDetails[0].state,
                      zip: data.doctorData[0].personalDetails[0].zip,
                      country: data.doctorData[0].personalDetails[0].country
      }); 
    
    }
  });
}


handleChange = (event) =>{

const{value, name} = event.target;
this.setState({
  [name]:value,
})
//console.log(value);
}

numberHandleChange(event) {
  const re = /^[0-9\b]+$/;
  if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({ mobileNumber:event.target.value })
  }
}

submitHandle(){
  
  let emailId = this.state.emailIdT;
  let mobileNumber = this.state.mobileNumber;
  let firstName = this.state.firstNameTxt;
  let lastName = this.state.lastNameTxt;
  let doB = this.state.doB;
  let errormessage="";
  if(!emailId){
    errormessage="Please Enter Email ID"
    $("#emailIdT").focus();
  }else if(!emailId.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
    errormessage="Please Enter Valid Email Id"
    $("#emailIdT").focus();
  }else if(!mobileNumber){
    errormessage="Please Enter the Mobile Number"
    $("#mobileNumber").focus();
  }else if(!mobileNumber.match( /^[0-9.\b]{10}$/)){
    errormessage="Please enter valid mobile number"
    $("#mobileNumber").focus();
  }else if (!firstName){
    errormessage="Please Enter First Name"
    $("#firstNameTxt").focus();
  }else if(!lastName){
    errormessage="Please Enter Last Name"
    $("#lastNameTxt").focus();
  }else if($("#doB").val() == ""){
    errormessage="Please Select Date Of Birth"
    $("#doB").focus();
  }else{  
}
 if(errormessage==""){
  const saveField ={
   
    method:'POST',
    body: JSON.stringify({

  functionName:"updateDoctorDetails",
  siteId: window.sessionStorage.getItem("siteId"),
  doctorId: window.sessionStorage.getItem("userId"),
  emailId:emailId,
  mobileNumber:mobileNumber,
  firstName:firstName,
  lastName:lastName,
  address:this.state.address,
  city:this.state.city,
  state:this.state.state,
  country:this.state.country,
  gender:this.state.gender,
  dateOfBirth:doB === 'Invalid date'?'':doB,
  zip:this.state.zip,

  })
}
$(".loader").show();
apiCalling(saveField).then((data) => {
  // console.log("data",data)
  $(".loader").hide();
  if(data.success=="1"){
    alert("Details updated successfully")
  }
   window.location.reload(false);
})
} 
  else{
$("#messageMemberEdit").text(errormessage);
$("#messageMemberEdit").css("color", "red");
  }
}

  render() {
    return (
      <div class="row px-2">
      <div class="col-sm-12">
        <div class="col-sm-12 my-2">
          <p id="messageMemberEdit"></p>
        </div>
      </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">Email ID (Username)<span class="star-red">*</span></label>
            <div class="col-sm-12  px-0 px-sm-3">
              <input type="text" class="input-design" id="emailIdT" name="emailIdT" value={this.state.emailIdT}  onChange={this.handleChange}/>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">Mobile Number <span class="star-red">*</span></label>
            <div class="col-sm-12  px-0  px-sm-3">
              <input type="text" class="input-design" id="mobileNumber"  maxLength="10" name="mobileNumber"  value={this.state.mobileNumber} onChange={this.numberHandleChange}/>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">First Name <span class="star-red">*</span></label>
            <div class="col-sm-12  px-0  px-sm-3">
              <input type="text" class="input-design" id="firstNameTxt" name="firstNameTxt"   value={this.state.firstNameTxt} onChange={this.handleChange}/>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">Last Name  <span class="star-red">*</span></label>
            <div class="col-sm-12  px-0  px-sm-3">
              <input type="text" class="input-design" id="lastNameTxt" name="lastNameTxt"   value={this.state.lastNameTxt} onChange={this.handleChange} />
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">Gender </label>
            <div class="col-sm-12  px-0  px-sm-3">
              <select class="input-design"
                  id="gender"
                  name="gender"
                  value={this.state.gender}
                  onChange={this.handleChange}
                  >
                <option value="">- - Select Gender - -</option>
                <option>Male</option>
                <option>Female</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">Date Of Birth <span class="star-red">*</span></label>
            <div class="col-sm-12  px-0  px-sm-3">
              <input type="date" class="input-design" id="doB" name="doB"  value={this.state.doB} onChange={this.handleChange} />
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">Address </label>
            <div class="col-sm-12  px-0  px-sm-3">
              <textarea  class="input-design textarea" style={{fontSize: "14px"}} id="address"  name="address"  value={this.state.address} onChange={this.handleChange}></textarea>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">City </label>
            <div class="col-sm-12  px-0  px-sm-3">
              <input type="text" class="input-design" id="city" name="city"  value={this.state.city} onChange={this.handleChange} />
            </div>
          </div>
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">State </label>
            <div class="col-sm-12  px-0  px-sm-3">
              <input type="text" class="input-design"id="state" name="state"  value={this.state.state} onChange={this.handleChange} />
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">Pin Code </label>
            <div class="col-sm-12  px-0  px-sm-3">
              <input type="text" class="input-design" id="zip" name="zip"  value={this.state.zip} onChange={this.handleChange}/>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">country </label>
            <div class="col-sm-12  px-0  px-sm-3">
              <input type="text" class="input-design" id="country" name="country"  value={this.state.country} onChange={this.handleChange}/>
            </div>
          </div> 
        </div>

        <div className="col-sm-12">
            <input type="submit" value="Save" className="formButton" onClick={() => this.submitHandle()}></input>
            <a href="/doctor/changepassword">
              <input type="button" value="Change Password" className="formButton"></input>
            </a>
        </div>
      </div>
    )
  }
}
