import React, { Component, Suspense } from 'react'
import { S3ImagePath } from "../config";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
const imgPath = S3ImagePath();

export default class Facilities extends Component {
  render() {
    return (
      <div className="main">
              <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
      <main role="main">
      <div className='subPage_container'>
        <div className='container'>
            <div className='content_help'>
                <div className='help_border'>
                    <h1 className='main_header'>Benefits</h1>
                    <div className='container-fluid div_benefit px-0'>
                        <div className='row align-items-center'>
                            <div className='col-md-6'>
                                <ol>
                                    <li>Sharing of Important medical information between network doctors enabling better care.</li>
                                    <li>Priority Consultation in the Referred Institution.</li>
                                    <li>Waiver on hospital registration fee to referred hospitals.</li>
                                    <li>Registration Fee to be paid only in the first institute in the network.</li>
                                    <li>10% Discount on all investigations in the network institutions.</li>
                                    <li>24 hours Ambulance on call</li>
                                    <li>Pick up &amp; Drop facility to the Network Hospital</li>
                                    <li>Doctors Home Visit</li>
                                    <li>Home Nursing and home blood collection on request</li>
                                    <li>Can attend common health Programmes &amp; Camps</li>
                                    <li>Special discounts and offers by Member Institutions</li>
                                    <li>24 hours HELP LINE : 8078478278 , support@imahospitals.com</li>				
                                </ol>
                                <div className='helpLinesupport'>
                                    <div className='supportHelp'>Helpline :</div>
                                    <div className='supportNumber'>8078478278</div>
                                    <div className='supportHospital'>support@imahospitals.com</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <img src={imgPath+"/facilityBanner.png"} alt="benefites" width="500" height="600"/>
                            </div>
                        </div>
                    </div>
            </div>
            </div>
        </div>
      </div>
      </main>
      <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
     
    )
  }
}
