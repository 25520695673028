import React, { Component, Suspense } from "react";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import QrcodeImg from "../../image/icons/qrcode.png";
import Deactivate from "../../image/icons/deactvate.png";
import { apiCalling } from "../../apiService";
import { Link } from "react-router-dom";
import $ from "jquery";
import Pagination from "react-js-pagination";
import Close from "../../image/icons/close-sm.png";
// import Header from "../../layout/header";
// import Footer from "../../layout/footer";
import Logoimg from "../../image/icons/image-preview.png";
import "../../css/admin.css";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../../" + folderName + "/header"));
const Footer = React.lazy(() => import("../../" + folderName + "/footer"));
export default class CategoriesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeResult: [],
      totalCount: 0,
      activePage: 1,
      showMessage: "Waiting for data... ",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  imagePreview = (image) => {
    this.setState({
      imagePreview: image,
    });
    $("#analisePopup").attr("hidden", false);
  };
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getIdaStoreCategoryList",
        idaStoreCategoryId: "",
        keyWord: $("#resultTxt").val(),
        Offset: offset,
        max: max,
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          approvalResult: data.RepApprovalSearchData,
          totalCount: data.totalCount,
        });
        $(".loader").hide();
      }
    });
  }

  componentDidMount() {
    this.getIdaStoreDetails();
  }

  getIdaStoreDetails() {
    $(".loader").show();
    const idaStoreList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getIdaStoreCategoryList",
        idaStoreCategoryId: "",
        keyWord: "",
        Offset: "0",
        max: "10",
      }),
    };
    apiCalling(idaStoreList).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          storeResult: data.result,
          totalCount: data.totalCount,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          storeResult: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }

  changeText = () => {
    if ($("#searchType").val() !== "0") {
      document.getElementById("resultTxt").disabled = false;
      $("input[name=resultTxt]").focus();
    } else {
      document.getElementById("resultTxt").disabled = true;
    }
    document.getElementById("resultTxt").value = "";
  };

  searchHandler() {
    // if ($("#searchType").val() !== "0" && $("#resultTxt").val() === "") {
    //   var t = document.getElementById("searchType");
    //   var selectedText = t.options[t.selectedIndex].text;
    //   alert(selectedText + " Required");
    //   $("#resultTxt").focus();
    //   return false;
    // } else {
    const searchData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getIdaStoreCategoryList",
        idaStoreCategoryId: "",
        keyWord: $("#resultTxt").val(),
        Offset: "0",
        max: "10",
      }),
    };
    $(".loader").show();
    apiCalling(searchData).then((data) => {
      if (data.success === "1") {
        $("#paginationType").show();
        $(".loader").hide();
        this.setState({
          storeResult: data.result,
          totalCount: data.totalCount,
          activePage: 1,
        });
      }
      if (data.success === "0") {
        $("#paginationType").hide();
        $(".loader").hide();
        this.setState({
          storeResult: [],
          showMessage: data.errorMessage,
        });
      }
    });
    // }
  }

  statusChangeHandler(data, Status) {
    var x = false;
    if (Status === true) {
      x = window.confirm("Do you want to Disable this?");
    } else {
      x = window.confirm("Do you want to Enable this?");
    }
    if (x === true) {
      const SatusData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "saveIdaStoreCategoryDetails",
          idaStoreCategoryId: data.idaStoreCategoryId,
          name: data.name,
          status: Status === true ? "false" : "true",
          imageName: data.imageNameOnly,
        }),
      };
      $(".loader").show();
      apiCalling(SatusData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Status Changed Successfully");
          this.getIdaStoreDetails();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  }

  srcTypeImage(ev) {
    ev.target.src = Logoimg;
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />

            <div className="loader"></div>
            <div className="container mb-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboard">Dashboard</a>
                  </li>
                  {/* <li>
                <a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a>
              </li> */}
                  <li>
                    <a href="" className="current">
                      IDA Category List
                    </a>
                  </li>
                </ul>
              </div>
              <div className="head-hospMangmntList">IDA Category List</div>

              <div className="HosptlMangemntTable">
                <div className="AddNewHspbtnDiv">
                  <a
                    href="/adminManagement/categoriesListEdit"
                    className="addnewhspbtn"
                  >
                    {" "}
                    Add New Category
                  </a>
                </div>
                {this.state.totalCount > 5 ? (
                  <div className="pagination-qualList" id="paginationType">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={10}
                      onChange={this.handlePageChange}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="tableHeader-HspMangemnt">
                  {/* <div className="selectField-HspMangmnt">
                <select
                  className="slecthsp slecthspMob"
                  id="searchType"
                  name="searchType"
                  onChange={() => this.changeText()}
                >
                  <option value="0">Search In</option>
                  <option value="1">Store Code</option>
                  <option value="2">Store Name</option>
                  <option value="3">Contact Number</option>
                </select>
              </div> */}
                  <div className="selectField-HspMangmnt">
                    <input
                      type="text"
                      className="hspmngInput hspmngInputmob"
                      name="resultTxt"
                      id="resultTxt"
                      placeholder="Enter Category Type"
                      //   disabled
                    />
                  </div>
                  {/* <div className="selectField-HspMangmnt">
                <select className="slecthsp slecthspMob" id="searchStatusType" name="searchStatusType">
                  <option value="0">Status</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div> */}
                  <div className="selectField-HspMangmnt">
                    <button
                      className="searchbtn-hspMng"
                      onClick={() => this.searchHandler()}
                    >
                      Search
                    </button>
                  </div>
                </div>
                {this.state.storeResult.length > 0 ? (
                  <div class="table-responsive">
                    <table className="table table-bordered-hsp">
                      <thead>
                        <tr>
                          <th className="tablehead-hspmng">ID</th>
                          <th className="tablehead-hspmng">Category Type</th>
                          <th className="tablehead-hspmng">Image</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.storeResult.map((data) => (
                          <tr>
                            <td>{data.idaStoreCategoryId}</td>
                            <td>{data.name}</td>
                            {/* <td>{data.storeName}</td> */}
                            <td className="nowrap">
                              <div>
                                <span>
                                  <span
                                    // to={
                                    //   "/adminManagement/dataMigration/generateQRcodeIda/" +
                                    //   data.idaStoreCategoryId
                                    // }
                                    className="mr-3"
                                    onClick={() =>
                                      this.imagePreview(data.imageNameWithPath)
                                    }
                                  >
                                    <img
                                      src={data.imageNameWithPath}
                                      alt=""
                                      title="Image Preview"
                                      width="18"
                                      className="img-fluid"
                                    />
                                  </span>
                                </span>
                                <span>
                                  <Link
                                    to={
                                      "/adminManagement/categoriesListEdit/" +
                                      data.idaStoreCategoryId
                                    }
                                    className="mr-3"
                                  >
                                    <img
                                      src={Edit}
                                      alt=""
                                      width="18"
                                      title="Edit"
                                      className="img-fluid"
                                    />
                                  </Link>
                                </span>
                                {data.status === true ? (
                                  <img
                                    src={Deactivate}
                                    width="20"
                                    title="Click to Deactivate "
                                    className="conf-cursorpointer"
                                    alt=""
                                    onClick={() => {
                                      this.statusChangeHandler(data, true);
                                    }}
                                  ></img>
                                ) : (
                                  <img
                                    src={tick}
                                    alt=""
                                    title="Click to Activate"
                                    className="conf-cursorpointer"
                                    width="18"
                                    onClick={() => {
                                      this.statusChangeHandler(data, false);
                                    }}
                                  ></img>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {this.state.totalCount > 5 ? (
                      <div
                        className="pagination-qualList pagination-qualListwidth"
                        id="paginationType"
                      >
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <p className="error-msg text-center mb-0">
                    {" "}
                    {this.state.showMessage}{" "}
                  </p>
                )}
              </div>
            </div>
            {folderName !== null && <Footer />}
          </section>
        </Suspense>
        {/* <Suspense fallback={<div></div>}>
          <section>
          
          </section>
        </Suspense> */}
        <div class="popup-overlay edit_modal" id="analisePopup" hidden>
          <div className="popup-contentDiv">
            <div className="modal-popup">
              <span
                className="closepopupqual"
                id="txtCancel"
                onClick={() => $("#analisePopup").attr("hidden", true)}
              >
                <img src={Close} alt="Close" className="img-fluid" width="25" />
              </span>
              <div className="content">
                <div class="HosptlMangemntTable text-center m-auto" >
                  <img src={this.state.imagePreview} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
