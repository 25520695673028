import React, { Component, Suspense } from "react";

import $ from "jquery";
import avatar from "../image/media/avatar.png";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Chatmessage extends Component {
  componentDidMount() {
    $("#promoApplySection").hide();
    $("#chat-upload").hide();
  }
  upload() {
    $("#chat-upload").show();
  }
  havePromoCode() {
    $("#promoApplySection").show();
    $("#havePromoCode").hide();
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper-smwidth1">
            <div class="col-md-12 p-0">
              <ul class="breadcrumb">
                <li>
                  <a href="/doctor/dashboard">Dashboard</a>
                  <i class="fa fa-chevron-right"></i>
                </li>
                <li>
                  <a href="/doctor/chatmessagegroup">Old Chat Questions</a>
                  <i class="fa fa-chevron-right"></i>
                </li>
                <li>
                  <a> Chats</a>
                </li>
              </ul>
            </div>
            <div class="chat-outer">
              <div class="chat-header">
                <span class="form-head">Chats</span>
                <span class="chat-span">
                  <a href="/doctor/chatmessagegroup">
                    <button type="button" class="btn-default">
                      View Question
                    </button>
                  </a>
                </span>
                <span class="chat-span">
                  <button type="button" class="btn-default">
                    Send As Mail
                  </button>
                </span>
              </div>
              <div class="chat-body">
                <ul class="messages">
                  <div
                    className="col-md-12 text-center cursor-pointer"
                    style={{ fontWeight: "600" }}
                  >
                    <i
                      class="fa fa-chevron-down mr-2"
                      style={{ fontSize: "10px" }}
                    ></i>{" "}
                    Load earlier messages
                  </div>
                  <li class="message left">
                    <div class="avatar">
                      <img src={avatar} class="avatarimg"></img>
                    </div>
                    <div class="text_wrapper">
                      <div class="text-header">
                        <h6 className="textchat-name">Ortho</h6>
                        {/* <time class="message-time">15 May 2020 06:05 PM</time> */}
                      </div>
                      <p class="text">Hello Name,</p>
                      <p class="text">
                        Dr. Matt Murdock will chat with you soon.
                      </p>
                    </div>
                  </li>
                  <li class="message right">
                    <div class="avatar">
                      <img src={avatar} class="avatarimg"></img>
                    </div>
                    <div class="text_wrapper">
                      <div class="text-header">
                        <h6 className="textchat-name">Name</h6>
                        {/* <time class="message-time"> 06:05 PM</time> */}
                      </div>
                      <p class="text">chat with you soon.</p>
                    </div>
                  </li>
                  <li class="message right">
                    <div class="avatar">
                      <img src={avatar} class="avatarimg"></img>
                    </div>
                    <div class="text_wrapper">
                      <div class="text-header">
                        <h6 className="textchat-name">Doctor</h6>
                        {/* <time class="message-time"> 06:05 PM</time> */}
                      </div>
                      <p class="text text-center">Do you smoke?</p>
                      <p class="text-center mb-0">
                        <input
                          type="button"
                          class="formButton formButton-sm mb-0"
                          value="Yes"
                          style={{ fontSize: "10px" }}
                        ></input>
                        <input
                          type="button"
                          class="formButton formButton-sm mb-0"
                          value="No"
                          style={{ fontSize: "10px" }}
                        ></input>
                        <input
                          type="button"
                          class="formButton formButton-sm mb-0"
                          value="May Be"
                          style={{ fontSize: "10px" }}
                        ></input>
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="col-md-12 pr-4">
                  <p class="textarea-count">10000 characters left</p>
                </div>
                <div class="col-md-12 textarea-wrapper chat-bottom-main">
                  <textarea
                    class="textarea"
                    placeholder="Send a message..."
                  ></textarea>
                </div>
                <div class="col-md-4 mt-3">
                  <select class="input-design">
                    <option value="0">Default Questions</option>
                  </select>
                </div>
                <div class="col-md-12 chat-buttonmain">
                  <div class="messageFileUpload" onClick={this.upload}>
                    <input
                      id="file1"
                      name="file1"
                      tabindex="33"
                      class="uploadattach formButtonBg"
                      type="file"
                    />
                  </div>
                  <input
                    type="button"
                    value="Upload"
                    class="chat-upload"
                    id="chat-upload"
                  ></input>
                  <input
                    type="submit"
                    value="Send"
                    class="chat-sendbtn formButton"
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
