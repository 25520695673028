import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import ReactTooltip from 'react-tooltip';
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class PrescriptionList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      prescriptionList: [],
      showMessage:"Waiting for data... ",
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    $(".loader").show()
    const apiJsonq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "patientPrescriptionList",
        scheduledTimeSlotId: "",
        memberId:window.sessionStorage.getItem("userId"), //"19909",
      }),
    };
   ;
    //console.log(apiJsonq.body)
    apiCalling(apiJsonq).then((data) => {
     // console.log("patientPrescriptionList",data)
      // for(var i = 0;i < data.result.length;i++) {
      //   console.log("data.result n  ",data.result[i])
      //   for(var j = 0;j < data.result[i].prescriptionByVisit.length;j++) {
      //     console.log("prescriptionByVisit n  ",data.result[i].prescriptionByVisit[j])
      //     for(var k = 0;k < data.result[i].prescriptionByVisit[j].prescriptionDetails.length;k++) {
      //       console.log("prescriptionDetails n  ",data.result[i].prescriptionByVisit[j].prescriptionDetails[k])
      //     }
      //   }
      // }
      if (data.success == "1") {
        $(".loader").hide();
        this.setState({ prescriptionList: data.result,
          showMessage: data.result.length > 0 ? "" : "No data found"
        
        });
      }
    });
  }
  render() {
    return (
      
      <div class="purpleWrap">
          <div className='loader'></div>
        <Suspense fallback={<div class="loader"></div>}>
          <section>
            <Header />
            <div class="container">
              <div class="main-wrapper1">
                <div class="form-head mt-4">PRESCRIPTION LIST </div>

                {this.state.prescriptionList.length > 0 ?
                <div class="col-md-12 p-0">
                {this.state.prescriptionList.map((data, i) => (
                  <div class="prescription-main">
                    <div class="prescription-maindate">
                      <div class="prescription-date">{data.visitDate}</div>
                    </div>
                    <div class="prescription-Subdiv">
                      {data.prescriptionByVisit.map((data1, j) => (
                      <div class="prescription-Submain">
                        <div class="prescription-drName"> Doctor Name:&nbsp; {data1.displayName}</div>
                        <div class="prescription-head">
                          <div class="prescription-subhead">Medicine</div>
                          <div class="prescription-subhead">Frequency & Dosage</div>
                          <div class="prescription-subhead">Intake</div>
                          {/* <div class="prescription-subhead">Qty</div> */}
                          <div class="prescription-subhead">Duration</div>
                          <div class="prescription-subhead">Instruction</div>
                        </div>
                        {data1.prescriptionDetails.map((data2, k) => (
                        <div class="prescription-det">
                          <div class="prescription-subdet">{data2.medicineName}</div>
                          <div class="prescription-subdet"> {data2.frequency}<br/>{data2.dosage}</div>
                          <div class="prescription-subdet">{data2.intake}</div>
                          {/* <div class="prescription-subdet">{data2.quantity}</div> */}
                          <div class="prescription-subdet">{data2.days}{data2.duration}</div>
                          <div class="prescription-subdet">{data2.instructions}</div>
                        </div>
                        ))}
                      </div>
                        ))}
                    </div>

                  </div>
                  ))}
                </div>
                  :
                  <p className="error-msg text-center mb-0"> {this.state.showMessage} </p>
                }


                {/* {this.state.prescriptionList.length > 0 ?
                  <div class="col-md-12 p-0">
                    {this.state.prescriptionList.map((data, i) => (
                      <div class="table-responsive mb-3">
                        <table class="table table-border-main mb-0">
                          <tr style={{ backgroundColor: "#cae5ff" }}>
                            <th>{data.visitDate}</th>
                            {data.prescriptionByVisit.map((data1, j) => (
                              <div>
                              <th colSpan="4">{'Doctor Name:- '+data1.displayName}</th>
                          <tr>
                            <th>Medicine</th>
                            <th>Frequency & Dosage</th>
                            <th>Intake</th>
                            <th>Qty</th>
                            <th>Days</th>
                          </tr>
                              {data1.prescriptionDetails.map((data2, k) => (
                                  <tr>
                                  <td>{data2.medicineName}</td>
                                  <td>{data2.dosage}</td>
                                  <td>{data2.intake}</td>
                                  <td>{data2.quantity}</td>
                                  <td>{data2.duration}</td>
                                </tr>
                              ))}
                              </div>
                            ))}
                          </tr>
                        </table>
                      </div>
                    ))}

                  </div>
                  :
                  <p className="error-msg text-center mb-0"> No Records Found </p>
                } */} 
                
                {/* <div class="col-md-12 p-0">
                {this.state.prescriptionList.map((data, i) => (
                  <div class="prescription-main">
                    <div class="prescription-maindate">
                      <div class="prescription-date">{data.visitDate}</div>
                    </div>
                    <div class="prescription-Submain">
                      <div class="prescription-drName">Doctor Name</div>
                      <div class="prescription-head">
                        <div class="prescription-subhead">Medicine</div>
                        <div class="prescription-subhead">Frequency & Dosage</div>
                        <div class="prescription-subhead">Intake</div>
                        <div class="prescription-subhead">Qty</div>
                        <div class="prescription-subhead">Days</div>
                      </div>
                      <div class="prescription-det">
                        <div class="prescription-subdet">medicineName</div>
                        <div class="prescription-subdet">medicineName</div>
                        <div class="prescription-subdet">medicineName</div>
                        <div class="prescription-subdet">medicineName</div>
                        <div class="prescription-subdet">medicineName</div>
                      </div>
                    </div>
                  </div>
                  ))}
                </div> */}

                
                
{/* <div class="table-responsive mb-3">
                        <table class="table table-border-main mb-0">
                            <tr style={{backgroundColor:"#cae5ff"}}>
                                <th>18 jan 2021</th>
                                <th colSpan="4">Doctor Name: Dr. Rahul Knot</th>
                            </tr>
                            <tr>
                                <th>Medicine</th>
                                <th>Frequency & Dosage</th>
                                <th>Intake</th>
                                <th>Qty</th>
                                <th>Days</th>
                            </tr>
                            <tr>
                                <td>Medicine
                                    <a data-tip data-for='prescription-view'> 
                                        <span class="fa fa-eye ml-3"></span>
                                        <ReactTooltip id='prescription-view' className='presctooltip' effect='solid' type="light">
                                            <div className="mb-1">
                                                <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Interactions</span>
                                            </div>
                                            <div className="mb-1 ml-2">Not suitable for diabetics</div>
                                            <div className="mb-1">
                                                <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Drug Reactions</span>
                                            </div>
                                            <div className="mb-1 ml-2">Check if diabetic</div>
                                            <div className="mb-1">
                                                <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Major Side Effects</span>
                                            </div>
                                            <div className="mb-1 ml-2">AAA</div>
                                            <div className="mb-1">
                                                <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Precautions</span>
                                            </div>
                                            <div className="mb-1 ml-2">Is patient diabetic</div>
                                        </ReactTooltip> 
                                    </a>
                                </td>
                                <td>Frequency & Dosage</td>
                                <td>Intake</td>
                                <td>Qty</td>
                                <td>Days</td>
                            </tr>
                            <tr>
                                <td>Medicine
                                    <a data-tip data-for='prescription-view'> 
                                        <span class="fa fa-eye ml-3"></span>
                                        <ReactTooltip id='prescription-view' className='presctooltip' effect='solid' type="light">
                                            <div className="mb-1">
                                                <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Interactions</span>
                                            </div>
                                            <div className="mb-1 ml-2">Not suitable for diabetics</div>
                                            <div className="mb-1">
                                                <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Drug Reactions</span>
                                            </div>
                                            <div className="mb-1 ml-2">Check if diabetic</div>
                                            <div className="mb-1">
                                                <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Major Side Effects</span>
                                            </div>
                                            <div className="mb-1 ml-2">AAA</div>
                                            <div className="mb-1">
                                                <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Precautions</span>
                                            </div>
                                            <div className="mb-1 ml-2">Is patient diabetic</div>
                                        </ReactTooltip> 
                                    </a>
                                </td>
                                <td>Frequency & Dosage</td>
                                <td>Intake</td>
                                <td>Qty</td>
                                <td>Days</td>
                            </tr>
                        </table>
                    </div>  */}
              </div>
            </div>

            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}


