import React, { Component } from "react";
import Pagination from "react-js-pagination";
import Edit from "../image/icons/edit.png";
import Deactivate from "../image/icons/deactvate.png";
import Check from "../image/icons/check.png";

export default class PromocodeList extends Component {
  renderPromoLists() {
    if (this.props.state.promoCodeData.length > 0) {
      return this.props.state.promoCodeData.map((promo) => (
        <tr key={promo.promoCodeId}>
          <td>{promo.promoCodeName}</td>
          <td>{promo.discountPercentage}</td>
          <td>{promo.fromDate}</td>
          <td>{promo.toDate}</td>
          <td className="nowrap">
            <div>
              <span className="mr-2 editbtn-qual">
                <a
                  onClick={() => {
                    this.props.editPromo(promo);
                  }}
                >
                  <img src={Edit} alt="" width="18" className="img-fluid" />
                </a>
              </span>
              {promo.isActive == true && (
                <a
                  onClick={() =>
                    this.props.promoCodeActiveDeactive(promo.promoCodeId, false)
                  }
                >
                  <img
                    src={Deactivate}
                    width="22"
                    title="Click to Deactivate"
                    className="mr-2 conf-cursorpointer"
                    alt=""
                  ></img>
                </a>
              )}
              {promo.isActive == false && (
                <a
                  onClick={() =>
                    this.props.promoCodeActiveDeactive(promo.promoCodeId, true)
                  }
                >
                  <img
                    src={Check}
                    width="22"
                    title="Click to Activate "
                    className="mr-2 conf-cursorpointer"
                    alt=""
                  ></img>
                </a>
              )}
            </div>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="5" className="text-center">
            No Data Found
          </td>
        </tr>
      );
    }
  }

  render() {
    return (
      <div class="row ">
        <div className="container mb-3">
          <div className="tableHeader-promoHsp mt-1">
            <div className="selectField-promoHsp">
              <select
                className="hspInput-promo"
                id="cmbSerch"
                onChange={() => this.props.handleCmbSerchChange()}
              >
                <option value="0">Search In</option>
                <option value="1">ID</option>
                <option value="2">Language</option>
              </select>
            </div>
            <div className="selectField-promoHsp">
              <input
                type="text"
                className="hspInput-promo"
                id="searchKeyWord"
                disabled
                value={this.props.state.searchKeyWord}
                onInput={this.changeHandler}
              />
            </div>
            <div className="selectField-promoHsp posrel-promo promopadng-date">
              <input
                type="date"
                className="hspInput-promo p-0"
                placeholder="From"
                id="fromDate"
                value={this.props.state.fromDate}
                onInput={this.props.changeHandler}
              />
            </div>
            <div className="selectField-promoHsp posrel-promo promopadng-date">
              <input
                type="date"
                className="hspInput-promo p-0"
                placeholder="To"
                id="toDate"
                value={this.props.state.toDate}
                onInput={this.props.changeHandler}
              />
            </div>

            <div className="selectField-promoHsp">
              <select
                className="hspInput-promo"
                id="cmbStatus"
                value={this.props.state.cmbStatus}
                onInput={this.props.changeHandler}
              >
                <option value="0">Status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
            <div className="selectField-promoHsp w-auto">
              <button
                className="searchbtn-promo"
                onClick={() => this.props.promoCodeListing()}
              >
                Search
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="tablehead-hspmng" width="20%">
                    Promo Code
                  </th>
                  <th className="tablehead-hspmng" width="20%">
                    Discount Percent
                  </th>
                  <th className="tablehead-hspmng" width="20%">
                    Active From Date
                  </th>
                  <th className="tablehead-hspmng" width="20%">
                    Active To Date
                  </th>
                  <th className="tablehead-hspmng" width="20%">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderPromoLists()}</tbody>
            </table>

            {this.props.state.totalCount > 5 ? (
              <div className="paginationSection orderm-paginationSection">
                <Pagination
                  activePage={this.props.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.props.state.totalCount}
                  pageRangeDisplayed={10}
                  onChange={(pageNumber) =>
                    this.props.handlePageChange(pageNumber)
                  }
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
