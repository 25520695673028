import React, { Component } from 'react';
import $ from "jquery";

export default class DoctorInvitation extends Component {

  render() {
    return (
      <div className="pharmacyapp-wrapper p-0">
        <div className="pharmacy-invitation-wrapper">
            <div className="pharmacy-invitation-banner"> 
              <p><strong>Hello Doctor,</strong></p>
              <p>Dr.Name has invited you to connect with his network. Being a connection allows you to communicate, refer patients, recieve refferals as well as access to other useful sevices.</p>
              <p>Install the app and connect now!</p>
              <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/doctors.png" alt="" className="img-fluid invitation-img"/>
            </div>
            
            <div className="pharmacy-invitation-main">
              <div className="pharmacyapp-head mt-3 mb-1 text-left">Get the App:</div>
              <div className="pharmacyapp-store">
                <div className="pharmacyapp-store-list">
                  <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/apple-app.png" alt="" className="img-fluid"/>
                </div>
                <div className="pharmacyapp-store-list">
                  <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/google-app.png" alt="" className="img-fluid"/>
                </div>
              </div>
            </div>
        </div>
      </div>
    )
  }
}
