import React, { Component, Suspense } from "react";
import Banner from "./banner";
import About from "./about";
import Doctorslider from "./doctorslider";
import Mission from "./mission";
import Centre from "./centre";
import Speciality from "./speciality";
import Gallery from "./gallery";
import $ from "jquery";
import { apiCalling } from "../apiService";
import InnerHTML from "dangerously-set-html-content";
import { Helmet } from "react-helmet";
const folderName = window.sessionStorage.getItem("homeName");

export default class Home extends Component {
  componentDidMount() {
    //alert(window.sessionStorage.getItem("mysession"))
    $("html, body").animate({ scrollTop: 0 }, "slow");
    $(".layoutPatientMenuTop").hide();
    $(".layoutDoctorMenuTop").hide();
  
  }

  render() {
    return (
      <div className="mentalHealthWrap ">
        <Helmet>
          <title>{window.sessionStorage.getItem("seo_title")}</title>        
          <meta name="description" content={window.sessionStorage.getItem("seo_description")} />
          <meta name="keywords" content={window.sessionStorage.getItem("seo_keywords")} ></meta>
          <meta name="twitter:title" content={window.sessionStorage.getItem("seo_title")}></meta>
          <meta name="twitter:description" content={window.sessionStorage.getItem("seo_description")}></meta>
          <meta name="twitter:image:src" content={window.sessionStorage.getItem("siteLogo")}></meta>
          <meta name="facebook:title" content={window.sessionStorage.getItem("seo_title")}></meta>
          <meta name="facebook:description" content={window.sessionStorage.getItem("seo_description")}></meta>
          <meta name="facebook:image:src" content={window.sessionStorage.getItem("siteLogo")}></meta>
          <meta property="og:title" content={window.sessionStorage.getItem("seo_title")} ></meta>
          <meta property="og:image" content={window.sessionStorage.getItem("siteLogo")} ></meta>
          <meta property="og:description" content={window.sessionStorage.getItem("seo_description")} ></meta>
          <meta property="og:url" content={window.sessionStorage.getItem("slug")} ></meta>         
           
        </Helmet>
        <div className="metalContent" id="">
          <Banner />
          <About />
          <Doctorslider /> 
          <Mission />
          <Centre />
          <Speciality />
          <Gallery />
        </div>
      </div>
    );
  }
}
