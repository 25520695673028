import React, { Component, Suspense } from "react";
import Slider from "react-slick";
import { apiCalling } from "../apiService";
import { Link } from "react-router-dom";
export default class Doctorslider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorlist: [],
      doctorDataCount: "",
      max: 0,
      offset: "24",
    };
  }
  componentDidMount() {
    if(window.sessionStorage.getItem("doctorDetails")!== 'undefined'){   
    const doctorDetails = JSON.parse(window.sessionStorage.getItem("doctorDetails"));
    if (doctorDetails) {
      this.setState({
        doctorlist: doctorDetails,
      });
    }
  }
  }

  render() {
    const itemSetting = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            swipeToSlide: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            swipeToSlide: true,
          },
        },
      ],
    };

    return (
      <div className="specialistSlider" id="our-expert">
        {window.sessionStorage.getItem("doctorDetails")!== 'undefined' &&
        <div className="mainHeading">Our Experts</div>}
        <Slider {...itemSetting}>
          {this.state.doctorlist.map((obj, index) => {
            return (
              <div>
                <Link
                  to={{
                    pathname: "/Doctor-"+obj.doctorId+"-"+obj.doctorName.replace(/\s/g, '')+"-"+obj.speciality.replace(/\s/g, ''),
                    doctorId: obj.doctorId,
                  }} 
                >
                  <div className="specialist">
                    <img src={obj.doctorImage}  alt=""/>
                    <div className="specialistName">
                      {obj.doctorName}
                      <span>{obj.speciality}</span>
                      <span>{obj.designation}</span>
                      <span>{obj.address}</span>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
