import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { apiCalling, apiUrlRelianceFunction } from "../apiService";
import moment from "moment";
import csv from "../image/icons/csv.png";
import Pagination from "react-js-pagination";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class hospitalReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refferenceReport: [],
      csvFileDetails: [],
      totalCount: 0,
      activePage: 1,
      fuctionName: "Referral Report",
      showMessage: "Waiting for data... ",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.hospitalReferrenceReport();
    this.hospitalCSVList();
  }

  handlePageChange(pageNumber) {
    window.scrollTo(0, 0);
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorReferrenceReport",
        userId: window.sessionStorage.getItem("userId"), //"19238",
        userRole: window.sessionStorage.getItem("role"),
        searchKeyWord: $("#txtHospital").val(),
        cmbCategory1:
          $("#hospitalSelect").val() === "0" ? "" : $("#hospitalSelect").val(),
        offset: offset,
        max: max,
      }),
    };
    $(".loader").show();
    let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
    apiFunction(apiJson).then((data) => {
      if (data.success == "1") {
        this.setState({
          refferenceReport: data.referredDoctorDetails,
          totalCount: data.totalCount,
        });
        $(".loader").hide();
      }
    });
  }

  hospitalReferrenceReport() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorReferrenceReport",
        userId: window.sessionStorage.getItem("userId"), //"19238",
        userRole: window.sessionStorage.getItem("role"),
        searchKeyWord: "",
        cmbCategory1: "",
        offset: "0",
        max: "10",
      }),
    };
    let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
    apiFunction(apiJson).then((data) => {
      $(".loader").hide();

      if (data.success === "1") {
        this.setState({
          refferenceReport: data.referredDoctorDetails,
          totalCount: data.totalCount,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          refferenceReport: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }

  changeText = () => {
    if ($("#hospitalSelect").val() !== "0") {
      document.getElementById("txtHospital").disabled = false;
      $("input[name=txtHospital]").focus();
    } else {
      document.getElementById("txtHospital").disabled = true;
    }
    document.getElementById("txtHospital").value = "";
  };

  searchHandler() {
    let searchKeyWord = $("#txtHospital").val();
    let cmbCategory1 =
      $("#hospitalSelect").val() === "0" ? "" : $("#hospitalSelect").val();
    this.hospitalCSVList(searchKeyWord, cmbCategory1);

    if ($("#hospitalSelect").val() !== "0" && $("#txtHospital").val() === "") {
      var t = document.getElementById("hospitalSelect");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#txtHospital").focus();
      return false;
    } else {
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "doctorReferrenceReport",
          userId: window.sessionStorage.getItem("userId"), //"19238",
          userRole: window.sessionStorage.getItem("role"),
          searchKeyWord: $("#txtHospital").val(),
          cmbCategory1:
            $("#hospitalSelect").val() === "0"
              ? ""
              : $("#hospitalSelect").val(),
          offset: "0",
          max: "10",
        }),
      };
      $(".loader").show();
      let apiFunction =
        folderName !== null ? apiCalling : apiUrlRelianceFunction;
      apiFunction(searchData).then((data) => {
        $(".loader").hide();
        if (data.success === "1") {
          this.setState({
            refferenceReport: data.referredDoctorDetails,
            totalCount: data.totalCount,
            activePage: 1,
          });
        }
        if (data.success === "0") {
          $(".loader").hide();
          this.setState({
            refferenceReport: [],
            showMessage: data.errorMessage,
          });
        }
      });
    }
  }

  hospitalCSVList(searchKeyWord, cmbCategory1) {
    const searchData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorReferrenceReport",
        userId: window.sessionStorage.getItem("userId"), //"19238",
        userRole: window.sessionStorage.getItem("role"),
        searchKeyWord: searchKeyWord,
        cmbCategory1: cmbCategory1,
        offset: "",
        max: "",
      }),
    };
    let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
    apiFunction(searchData).then((data) => {
      if (data.success === "1") {
        this.setState({
          csvFileDetails: data.referredDoctorDetails,
        });
      }
      if (data.success === "0") {
        this.setState({
          csvFileDetails: [],
        });
      }
    });
  }

  csvFileDownload() {
    const csvRow = [];
    var A = [
      [
        "Doctor Name",
        "Doctor Mobile",
        "Doctor Email",
        "Hospital Name",
        "Patient Name",
        "Patient Mobile Number",
        "Patient Email Id",
        "Referral Dr Name",
        "Referral Dr Email Id",
        "Doctor Notes",
        "     ",
        "     ",
        "Appointment Date",
      ],
    ];
    var re = this.state.csvFileDetails;

    for (var item = 0; item < re.length; item++) {
      A.push([
        re[item].doctorName,
        re[item].doctorMobile,
        re[item].doctorEmail,
        re[item].hospName,
        re[item].memberName,
        re[item].patientMobileNumber,
        re[item].patientEmailId,
        re[item].referDrName,
        re[item].referDrEmailId,
        re[item].doctorNotes,
        ,
        ,
        re[item].appointmentDate,
      ]);
    }

    for (var i = 0; i < A.length; ++i) {
      csvRow.push(A[i].join(","));
    }

    var csvString = csvRow.join("%0A");

    var a = document.createElement("a");

    a.href = "data:attachment/csv," + csvString;
    a.target = "_Blank";
    a.download = this.state.fuctionName + ".csv";
    document.body.appendChild(a);
    a.click();
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            {folderName !== null && <Header />}
            <div className="loader"></div>
            <div class="container">
              <div class="main-wrapper1">
                <div class="col-md-12 p-0">
                  <ul class="breadcrumb">
                    <li>
                      <a
                        href={
                          "/dashboard/reportDashboard/" +
                          this.props.match.params.groupHospitalId
                        }
                      >
                        Reports
                      </a>
                      <i class="fa fa-chevron-right"></i>
                    </li>
                    <li>Referral Report</li>
                  </ul>
                </div>
                <div class="form-head">Referral Report </div>
                <div class="custom-search row">
                  <div class="col-md-4 col-6 mb-3 custom-search-sub">
                    <select
                      class="input-design"
                      id="hospitalSelect"
                      name="hospitalSelect"
                      onChange={() => this.changeText()}
                    >
                      <option value="0">- - Select - -</option>
                      <option value="doctorName">Doctor Name</option>
                      <option value="doctorMobile">Doctor Mobile Number</option>
                      <option value="doctorEmail">Doctor Email</option>
                      <option value="patientName">Patient Name</option>
                      <option value="patientMobileNumber">
                        Patient Mobile Number
                      </option>
                      <option value="patientEmailId">Patient Email-Id</option>
                      <option value="hospitalName">Hospital Name</option>
                      <option value="referDoctorName"> Referral Dr Name</option>
                      <option value="referDrEmailId">
                        {" "}
                        Referral Dr Email-Id
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4 col-6 mb-3 custom-search-sub">
                    <input
                      type="text"
                      class="input-design"
                      id="txtHospital"
                      name="txtHospital"
                      disabled
                    />
                  </div>
                  <div class="col-md-3 col-10 mb-3 custom-search-sub">
                    <input
                      type="submit"
                      value="Search"
                      class="formButton m-0"
                      onClick={() => this.searchHandler()}
                    />
                  </div>
                  <div className="col-md-1 col-2 mb-3 custom-search-sub p-0">
                    <a
                      onClick={() => this.csvFileDownload()}
                      className="cursor-pointer"
                    >
                      <img
                        src={csv}
                        alt="csv"
                        width="25"
                        title="Export CSV"
                        className="mt-1"
                      />
                    </a>
                  </div>
                </div>
                {this.state.refferenceReport.length > 0 ? (
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th style={{ minWidth: "150px" }}>Doctor Name</th>
                          <th style={{ minWidth: "125px" }}>Doctor Mobile</th>
                          <th style={{ minWidth: "150px" }}>Doctor Email</th>
                          <th style={{ minWidth: "150px" }}>Hospital Name</th>
                          <th style={{ minWidth: "150px" }}>Patient Name</th>
                          <th style={{ minWidth: "125px" }}>
                            Patient Mobile Number{" "}
                          </th>
                          <th style={{ minWidth: "150px" }}>
                            Patient Email Id
                          </th>
                          <th style={{ minWidth: "150px" }}>
                            Referral Dr Name
                          </th>
                          <th style={{ minWidth: "150px" }}>
                            Referral Dr Email Id
                          </th>
                          <th style={{ minWidth: "200px" }}>Doctor Notes</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "12px" }}>
                        {this.state.refferenceReport.map((data) => {
                          //  let appointmentDate =moment(data.appointmentDate, "DD-MM-YYYY").format("DD-MMM-YYYY");
                          return (
                            <tr>
                              <td>{data.doctorName}</td>
                              <td>{data.doctorMobile}</td>
                              <td>{data.doctorEmail}</td>
                              <td>{data.hospName}</td>
                              <td>{data.memberName}</td>
                              <td>{data.patientMobileNumber}</td>
                              <td>{data.patientEmailId}</td>
                              <td>{data.referDrName}</td>
                              <td>{data.referDrEmailId}</td>
                              <td>{data.doctorNotes}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {this.state.totalCount > 5 ? (
                      <div className="paginationSection orderm-paginationSection">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <p className="error-msg text-center mb-0">
                    {" "}
                    {this.state.showMessage}{" "}
                  </p>
                )}
              </div>
            </div>
            {folderName !== null && <Footer />}
          </section>
        </Suspense>
      </div>
    );
  }
}
