import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import * as ReactDOM from "react-dom";
import $ from "jquery";
import { apiCalling } from "../apiService";
import user from "../image/theme/user.png";
import file from "../image/icons/file.png";
import broken from "../image/icons/bokenImg.png";
import newsfeedimg from "../image/icons/newsfeedimg.png";

import Pagination from "react-js-pagination";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class NewFeeds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newFeedData: [],
      mainDataCount: "",
      activePage: 1,
      showMessage: "Waiting for data... ",
    };
    // this.handlePageChange = this.handlePageChange.bind(this);
  }

  // handlePageChange(pageNumber) {
  //   this.setState({ activePage: pageNumber });
  //   var offset;
  //   var max;
  //   if (pageNumber === "1") {
  //       offset = "0";
  //       max = "10";
  //       this.setState({
  //           activePage: pageNumber,
  //           isLoading: '0'
  //       })
  //   }
  //   else {
  //       offset = parseInt(pageNumber - 1) * 10;
  //       max = "10";
  //       this.setState({
  //           activePage: pageNumber,
  //           isLoading: '0'
  //       })
  //   }
  //  const dataList = {
  //   method: 'POST',
  //   body: JSON.stringify({
  //     "functionName": "newsFeedListing",
  //     "userId": window.sessionStorage.getItem("userId"),
  //     "offset":JSON.stringify(offset),
  //     "max":max
  //   })
  // }
  //   $(".loader").show();
  //   console.log("pahe",dataList.body)
  //   apiCalling(dataList).then((data) => {
  //     if (data.success == "1") {
  //       this.setState({ newFeedData: data.mainData,
  //                        mainDataCount: data.mainDataCount,

  //        });
  //       $(".loader").hide();
  //     }
  //   });
  // }

  componentDidMount() {
    this.newsFeedDataListing();
  }

  newsFeedDataListing() {
    const dataList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "newsFeedListing",
        userId: window.sessionStorage.getItem("userId"),
        offset: "",
        max: "",
      }),
    };
    //console.log(dataList.body);
    $(".loader").show();
    apiCalling(dataList).then((data) => {
      $(".loader").hide();
      if (data.success == "1" && data.mainData.length > 0) {
        this.setState({
          newFeedData: data.mainData,
          mainDataCount: data.mainDataCount,
          activePage: 1,
        });
      } else if (data.success == "0") {
        this.setState({ showMessage: "No Data Found " });
      }
    });
  }

  srcTypeImage(ev) {
    ev.target.src = newsfeedimg;
  }

  readMoreList(data) {
    //alert(data.isMedicalAd)
    window.sessionStorage.setItem(
      "pharmaRepCompanyName",
      data.pharmaRepCompanyName
    );
    window.sessionStorage.setItem("createdOnDate", data.createdOnDate);
    window.sessionStorage.setItem("pharmaRepName", data.pharmaRepName);
    window.sessionStorage.setItem("adName", data.adName);
    window.sessionStorage.setItem("adImages", data.adImages);
    window.sessionStorage.setItem("description", data.description);
    window.sessionStorage.setItem("brochureLink", data.brochureLink);
    window.sessionStorage.setItem("isMedicalAd", data.isMedicalAd);
    window.sessionStorage.setItem("pharmaRepAdsId", data.pharmaRepAdsId);
    window.location.href = "/newFeedsListDetails/" + data.pharmaRepAdsId;
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>
        <div className="container blog-wrapper" id="feedList">
          <h1 className="telehealthfeaturehead text-center"> News Feed </h1>
          <div className="row ">
            {this.state.newFeedData.length > 0 ? (
              <div className="col-lg-12 col-md-12 sh-blg" id="blog-detailsId">
                <div className="row mt-1 mt-md-0">
                  {this.state.newFeedData.map((data) => (
                    <div className="blog-listbox">
                      <div className="blogtag-main sh-blog-infos">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="sh-blog-title">
                              <p>{data.pharmaRepCompanyName}</p>
                              <p>{data.createdOnDate}</p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 sh-d-center">
                            <div className="sh-blog-title ">
                              <div className="sh-user-pic">
                                <img src={user} />
                              </div>
                              <p>{data.pharmaRepName}</p>
                            </div>
                          </div>
                        </div>
                        <div className="sh-blog-head">
                          <div className="blogtag-name sh-blogtag-name">
                            {data.adName}
                          </div>
                        </div>
                      </div>
                      <div className="blog-listImgDiv">
                        <img
                          onError={this.srcTypeImage}
                          src={data.adImages}
                          alt="Blog Image Name"
                          className="img-fluid blog-listImg"
                        ></img>
                      </div>
                      <div className="blog-list-detils">
                        {/* <div className="blogtag-name">{data.description}</div> */}
                        <div className="bloguserList-text">
                          {data.description}{" "}
                        </div>
                      </div>
                      <div className="blogtag-main sh-blog-infos sh-newsfd">
                        <div className="col-lg-12">
                          <div className="row">
                            {/* <div className="col-lg-6 col-md-6 col-sm-6 col-6 sh-d-left">
                              <div className="sh-blog-title sh-dwnld "  >
                                <div className="sh-user-pic">
                                  <img src={file} />
                                </div>
                                <p>Download Brochure</p>
                              </div>
                            </div> */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 sh-d-center">
                              <div className=" sh-dwnld">
                                {/* <Link to="/newFeedsListDetails"> */}
                                <button
                                  class="formButton sh-rdmre m-0"
                                  onClick={() => this.readMoreList(data)}
                                >
                                  Read More
                                </button>
                                {/* </Link> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* {this.state.mainDataCount > 5 ?
                                            <div className="paginationSection orderm-paginationSection">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={this.state.mainDataCount}
                                                    pageRangeDisplayed={10}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                            : ""} */}
              </div>
            ) : (
              <div
                className="col-lg-12 col-md-12 py-5"
                id="blog-detailsId"
                style={{ textAlign: "center" }}
              >
                <p className="error-msg text-center mb-0">
                  {" "}
                  {this.state.showMessage}{" "}
                </p>
              </div>
            )}
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
