import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { apiCalling } from "../apiService";
import $ from "jquery";
import moment from "moment";
const siteId = window.sessionStorage.getItem("siteId");
export default class appontment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointmentSlotDay: [],
      hospialId: this.props.hospialId,
      hospitalName: this.props.hospitalName,
      doctorId: this.props.doctorId,
      doctorName: this.props.doctorName,
      modalOpen: this.props.modelShow,
      handleModalOpen: false,
      date: this.props.date,
      time: this.props.time,
      dp: this.props.date + " " + this.props.time,
      name: "",
      mobile: "",
      otp: "",
      validateForm: false,
    };
    this.showdown = this.showdown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChangeSlot = this.handleInputChangeSlot.bind(this);
  }
  resendOtp() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "sendOtp",
        mobileNumber: this.state.mobile,
        name: this.state.name,
        siteId: siteId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        this.setState({
          validateForm: true,
          successMessage: data.successMessage,
        });
        alert(data.successMessage);
      } else {
        this.setState({ errorMessage: data.errorMessage, successMessage: "" });
        alert(data.errorMessage);
      }
    });
  }
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  validateMobile() {
    var mobileNo = this.state.mobile;
    let validate = /^[0-9]{0,10}$/;
    if (!validate.test(mobileNo)) {
      return false;
    } else {
      // $("#Mobile").val(a)
      return true;
    }
  }
  bookAppointment() {
    if (this.state.name === "") {
      alert("Please enter Name");
      return false;
    } else if (this.state.mobile === "") {
      alert("Please enter Mobile Number");
      return false;
    } else if (!this.validateMobile(this.state.mobile)) {
      alert("Please enter valid Mobile Number");
      $("#mobile").focus();
      return false;
    } else {
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "sendOtp",
          mobileNumber: this.state.mobile,
          name: this.state.name,
          siteId: siteId,
        }),
      };
      apiCalling(apiJson).then((data) => {
        $(".loader").hide();
        if (data.success === "1") {
          this.setState({
            validateForm: true,
            successMessage: data.successMessage,
          });
          alert(data.successMessage);
        } else {
          this.setState({
            errorMessage: data.errorMessage,
            successMessage: "",
          });
          alert(data.errorMessage);
        }
      });
    }
  }
  validateOTP() {
    if (this.state.otp === "") {
      alert("Please enter OTP");
      return false;
    } else {
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "checkOtp",
          mobileNumber: this.state.mobile,
          userOtpValue: this.state.otp,
        }),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          const bookAppointmentapiJson = {
            method: "POST",
            body: JSON.stringify({
              functionName: "bookAppointmentDoctorProfilePage",
              mobileNumber: this.state.mobile,
              name: this.state.name,
              siteId: siteId,
              doctorId: this.state.doctorId,
              hospitalId: this.state.hospialId,
              appointmentDateTimeMillis: moment(
                this.state.dp,
                "DD/MM/YYYY h:mm a"
              ).toDate().getMilliseconds,
            }),
          };
          apiCalling(bookAppointmentapiJson).then((data2) => {
            if (data2.success === "1") {
              const bookAppointmentmailapiJson = {
                method: "POST",
                body: JSON.stringify({
                  functionName: "bookAppointmentSendMail",
                  mobileNumber: this.state.mobile,
                  name: this.state.name,
                  siteId: siteId,
                }),
              };
              apiCalling(bookAppointmentmailapiJson).then((data3) => {
                if (data3.success === "1") {
                  alert(data3.successMessage);
                  window.location.reload();
                } else {
                  alert(data3.errorMessage);
                }
              });
            } else {
              alert(data2.errorMessage);
            }
          });
        } else {
          alert(data.errorMessage);
          $("#otp").focus();
        }
      });
    }
  }
  handleInputChangeSlot = (event) => {};
  //   handleChange = (date) => {
  //     alert(date)
  //     this.setState({ startDate: date });
  //     $("#selectDate").val(moment(date).format("DD/MM/YYYY"));
  //   };
  componentDidMount() {
    if (this.state.modalOpen) {
      $("#modal").show();
    } else {
      $("#modal").hide();
    }
  }
  showdown() {
    this.props.closeButton();
    //$("#modal").hide()
  }
  render() {
    return (
      <>
        {this.state.modalOpen && (
          <div id="modal" className="modal_popup">
            <div className="modal_inner">
              <div className="modal_header">
                Book Appointment
                <span className="modal_close" onClick={() => this.showdown()}>
                  x
                </span>
              </div>
              <div className="modal_body">
                <div className="row">
                  <div className="col-md-6">
                    <span className="modal_headlight">Doctor</span>
                    <div className="modal_spanhead">
                      {this.state.doctorName}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <span className="modal_headlight">Hospital</span>
                    <div className="modal_spanhead">
                      {this.state.hospitalName}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      readOnly={this.state.validateForm}
                      type="text"
                      id="name"
                      onChange={this.handleChange}
                      className="form-control"
                    ></input>
                  </div>
                  <div className="col-md-6">
                    <label>
                      Mobile Number<span className="text-danger">*</span>
                    </label>
                    <input
                      readOnly={this.state.validateForm}
                      type="text"
                      id="mobile"
                      onChange={this.handleChange}
                      className="form-control"
                    ></input>
                  </div>
                  <div className="col-md-6">
                    <label>
                      Appointment Date<span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      id="selectDate"
                      name="selectDate"
                      value={this.state.dp}
                      readOnly
                    />
                    {/* <DatePicker
                    placeholder="Appointment Date"
                    id="appDate"
                    name="appDate"
                    
                    minDate={new Date()}
                    onChange={this.handleChange}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    autoComplete="off"
                    onSelect={this.handleInputChangeSlot}
                    calendarClassName="react-date-picker"
                    /> */}
                  </div>
                  {this.state.validateForm ? (
                    <div className="col-md-6">
                      <label>
                        OTP<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="otp"
                        onChange={this.handleChange}
                        className="form-control"
                      ></input>
                      <span
                        className="resendOtp"
                        onClick={() => this.resendOtp()}
                      >
                        Resend OTP
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-md-12 book_button">
                    {this.state.validateForm ? (
                      <button onClick={() => this.validateOTP()} type="button">
                        Validate OTP
                      </button>
                    ) : (
                      <button
                        onClick={() => this.bookAppointment()}
                        type="button"
                      >
                        Book Appointment
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
