import React, { Component } from 'react';
const img2 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/img2.jpg";
const img3 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/img3.jpg";
const img4 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/img4.jpg";
const img5 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/img5.jpg";


export default class Gallery extends Component {
    render(){
        return(
            <div className="detailSection">
                <div className="detailList">
                    <div className="detailListTitle">LANDMARK ACHIEVEMENTS</div>
                    <ul>
                        <li className="detailListHeading"><strong>Among the many IVF/ICSI births in our hospitals, the following are wrote mentioning :</strong></li>
                        <li>South Kerala’s First Test- Tube baby was born at Samad Hospital in 2000</li>
                        <li>South Kerala’s First     Blastocyst Test –Tube baby was born  at Samad Hospital in 2001</li>
                        <li>South India’s oldest post menopausal delivery (56 Yrs) through IVF-ICSI method in 2002</li>
                        <li>Primary amenorrhea/Ovum donation test    tube baby  born  in 2002</li>
                        <li>World’s  third  oldest  post  menopausal delivery ( 62 years) in 2004 well acclaimed by all the media?</li>
                        <li>Kerala’s First   Surrogacy ( Substitute motherhood), a rare method   of infertility treatment helped an  infertile couple to have a baby in 2005</li>
                        <li>More than  30 years of experience in Ultrasonography, Laparoscopy, Hysteroscopy and Genetics</li>
                    </ul>
                </div>
                <div className="detailGallery">
                    <div className="galleryTitle">Gallery</div>
                    <div className="galleryDiv">
                        <div className="galleryList popup-gallery">
                            <a href={img2}><img src={img2} alt=""/></a>
                        </div>
                        <div className="galleryList popup-gallery">
                            <a href={img3}><img src={img3} alt=""/></a>
                        </div>
                        <div className="galleryList popup-gallery">
                            <a href={img4}><img src={img4} alt=""/></a>
                        </div>
                        <div className="galleryList popup-gallery">
                            <a href={img5}><img src={img5} alt=""/></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}