import React, { Component, Suspense } from "react";
import Dashboardleftmenu from "../doctor/dashboardleftmenu";
import $ from "jquery";
import { apiCalling } from "../apiService";
import { Link } from "react-router-dom";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class videoRecordList extends Component {
    constructor(props) {
        super(props);
        this.state = {

            listRecordData: [],
        };
    }

    componentDidMount() {
        this.getRecordDetails();
    }

    getRecordDetails() {
        $(".loader").show();
        const getData = {
            method: "POST",
            body: JSON.stringify({
                functionName: "recordingDetails",
                appointmentId: this.props.match.params.appointmentId   //  "3294" 
            }),
        };
        //console.log(getData.body)
        apiCalling(getData).then((data) => {
            if (data.success == "1") {
                $(".loader").hide();
                this.setState({ listRecordData: data.result });
            }
        });
    }

    render() {
        return (
        <div class="purpleWrap">
        <Suspense fallback={<div ></div>}>
          <section>
          <div className='loader'></div>
            <Header />
                <div class="container">
                    <div class="main-wrapper1">
                        <div class="col-md-12 p-0">
                            <ul class="breadcrumb">
                                <li>
                                <Link to="/doctor/dashboard">Dashboard</Link>
                                <i class="fa fa-chevron-right"></i>
                                </li>
                                <li>
                                <Link to="/doctor/chat-history">Video chat history</Link>
                                <i class="fa fa-chevron-right"></i>
                                </li>
                                <li>Video Recorded List</li>
                            </ul>
                        </div>
                        <div>
                        {this.state.listRecordData.length > 0 ?
                            <div>
                                <div className="form-head pl-3 mb-2">Video Recorded List </div>
                                <div className="video-record-main">
                                    {this.state.listRecordData.map((data) => (
                                        <div className="video-record-list" >
                                            < video controls>
                                                <source src={data.fileName} type="video/mp4"></source>
                                            </video>
                                            <small className="text-muted">{data.createdOn} </small>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            :
                            <p className="error-msg text-center mb-0"> No Record(s) Found </p>
                            }
                        </div>
                    </div>
                </div>
                <Footer />
          </section>
        </Suspense>
            </div>

        );
    }
}
