import React, { Component, Suspense } from "react";
import user from "../image/theme/user.png";
import { apiCalling } from "../apiService";
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import moment from "moment";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      mobileNo: "",
      emailId: "",
      addressDetails: "",
      country: "",
      city: "",
      state: "",
      gender: "",
      zipCode: "",
      phoneExt: "",
      dateofBirth: "",
    };
  }


  getData() {

    const userAPI = {
      method: "POST",
      body: JSON.stringify({
        functionName: "userDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        userId: window.sessionStorage.getItem("userId"),
      }),
    };

    $(".loader").show();
    apiCalling(userAPI).then((data) => {
       //console.log("dataaa",data)
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({ firstName: data.userData[0].firstName });
        this.setState({ lastName: data.userData[0].lastName });
        this.setState({ mobileNo: data.userData[0].phoneNumber });
        this.setState({ emailId: data.userData[0].emailId });
        this.setState({ addressDetails: data.userData[0].address });
        this.setState({ city: data.userData[0].city });
        this.setState({ state: data.userData[0].state });
        this.setState({ country: data.userData[0].country });
        this.setState({ dateofBirth: data.userData[0].dob });
        this.setState({ gender: data.userData[0].gender });
        this.setState({ phoneExt: data.userData[0].phoneExtension });
        this.setState({ zipCode: data.userData[0].zip });
      }
    });
  }

  componentDidMount() {
    this.getData()
    $("body").removeClass("body-ovrflw-hdn-menu");
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div class="loader"></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper">
            <div class="form-head memberHead">
              <img src={user} alt=""></img>Member Profile
            </div>
            <br></br>
            <div class="form-section mt-2">
              {/* <div class="form-head-bg">Member Details</div> */}
              <div class="row">
                <div class="col-lg-6 row pl-3">
                  <label class="col-5 label-text label-text-cln">
                    First Name
                  </label>
                  <label class="col-7 text-uppercase">
                    {this.state.firstName}
                  </label>
                </div>
                <div class="col-lg-6 row pl-3">
                  <label class="col-5 label-text label-text-cln">
                    Last Name
                  </label>
                  <label class="col-7 text-uppercase">
                    {this.state.lastName === "null" ? "" : this.state.lastName}
                  </label>
                </div>
                <div class="col-lg-6 row pl-3">
                  <label class="col-5 label-text label-text-cln">
                    Email ID{" "}
                  </label>
                  <label class="col-7 text-truncate-no-mobile">
                    <a data-tip data-for={'emailId'+this.props.emailId}> 
                      {this.state.emailId}
                    <ReactTooltip id={'emailId'+this.props.emailId} className='presctooltip' effect='float' type="success" textColor="black">
                      {this.state.emailId}
                    </ReactTooltip>
                    </a>
                  </label>
                </div>
                <div class="col-lg-6 row pl-3">
                  <label class="col-5 label-text label-text-cln">
                    Mobile Number{" "}
                  </label>
                  <label class="col-7 ">{this.state.mobileNo}</label>
                </div>
                <div class="col-lg-6 row pl-3">
                  <label class="col-5 label-text label-text-cln">
                    Date Of Birth{" "}
                  </label>
                  <label class="col-7 ">
                    {this.state.dateofBirth === "00/00/0000"
                      ? ""
                      :  moment(this.state.dateofBirth,"DD/MM/YYYY").format("DD-MMM-YYYY") }
                  </label>
                </div>
                <div class="col-lg-6 row pl-3">
                  <label class="col-5 label-text label-text-cln">
                    Phone Extension{" "}
                  </label>
                  <label class="col-7 ">
                    {this.state.phoneExt === "null" ? "" : this.state.phoneExt}
                  </label>
                </div>
                <div class="col-lg-6 row pl-3">
                  <label class="col-5 label-text label-text-cln">Gender </label>
                  <label class="col-7 ">{this.state.gender}</label>
                </div>
                <div class="col-lg-6 row pl-3">
                  <label class="col-5 label-text label-text-cln">
                    Address{" "}
                  </label>
                  <label class="col-7 ">{this.state.addressDetails}</label>
                </div>
                <div class="col-lg-6 row pl-3">
                  <label class="col-5 label-text label-text-cln">
                    Country{" "}
                  </label>
                  <label class="col-7 ">
                    {this.state.country === "null" ? "" : this.state.country}
                  </label>
                </div>
                <div class="col-lg-6 row pl-3">
                  <label class="col-5 label-text label-text-cln">State </label>
                  <label class="col-7 ">
                    {this.state.state === "null" ? "" : this.state.state}
                  </label>
                </div>
                <div class="col-lg-6 row pl-3">
                  <label class="col-5 label-text label-text-cln">City </label>
                  <label class="col-7 ">
                    {this.state.city === "null" ? "" : this.state.city}
                  </label>
                </div>
                <div class="col-lg-6 row pl-3">
                  <label class="col-5 label-text label-text-cln">Pin Code </label>
                  <label class="col-7 ">
                    {this.state.zipCode === "null" ? "" : this.state.zipCode}
                  </label>
                </div>
                <div className="col-sm-12 px-0 text-center mt-5">
                  <Link to="/patient/profile-edit">
                    <input
                      type="submit"
                      value="Edit Profile"
                      className="formButton formButtonBold"
                    ></input>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
