import React, { Component, Suspense } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { apiCalling } from "../apiService";
import { getSiteData } from "../layout/getSiteDetails";
import bolgtemp from "../image/icons/blogging.png";
import renderHTML from "react-render-html";
import { Helmet } from "react-helmet";
import InnerHTML from "dangerously-set-html-content";
const folderName = window.sessionStorage.getItem("homeName");

const Home = React.lazy(() => import("../" + folderName + "/home"));
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class HealthblogPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectCategotyList: [],
      resultData: [],
      showMessage: [],
    };
  }

  componentDidMount() {
    if (folderName === null) {
      window.sessionStorage.setItem("pathname", window.location.pathname);
      window.location.href = "/";
    }
    this.categorySelectList();

    if (this.props.match.params.categoryId !== "0") {
      this.categorySearchByID(this.props.match.params.categoryId);
    } else {
      this.loadBlogs();
    }
  }
  //! get HealthInfo comman data
  categorySelectList() {
    $(".loader").show();
    const saveField = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHealthInfoCommonData",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    // console.log(saveField.body);
    apiCalling(saveField).then((data) => {
      $(".loader").hide();
      if (data.success == "1") {
        this.setState({
          selectCategotyList: data.result,
        });
      }
    });
  }
  loadBlogs() {
    const dataList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorHealthArticles",
        siteId: window.sessionStorage.getItem("siteId"),
        categoryId: "",
      }),
    };
    $(".loader").show();
    apiCalling(dataList).then((data) => {
      $(".loader").hide();
      if (data.success == "1" && data.result.length > 0) {
        this.setState({ resultData: data.result });
      } else if (data.success == "0") {
        this.setState({ showMessage: data.errorMessage });
      }
    });
  }
  categorySearch(obj) {
    const dataList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorHealthArticles",
        siteId: window.sessionStorage.getItem("siteId"),
        categoryId: obj.categoryId,
      }),
    };
    $(".loader").show();
    // console.log(dataList.body);
    apiCalling(dataList).then((data) => {
      $(".loader").hide();
      if (data.success == "1" && data.result.length > 0) {
        this.setState({ resultData: data.result });
      } else if (data.success == "0") {
        this.setState({ resultData: [] });
        this.setState({ showMessage: data.errorMessage });
      }
    });
    $(".category-sub").removeClass("category-sub-active");
    $("#healthBlogListId" + obj.categoryId).addClass("category-sub-active");
    // $("html,body").animate({"scrollTop": $("#blog-detailsId").offset().top})
  }
  categorySearchByID(id) {
    const dataList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorHealthArticles",
        siteId: window.sessionStorage.getItem("siteId"),
        categoryId: id,
      }),
    };
    $(".loader").show();
    apiCalling(dataList).then((data) => {
      $(".loader").hide();
      if (data.success == "1" && data.result.length > 0) {
        this.setState({ resultData: data.result });
      } else if (data.success == "0") {
        this.setState({ resultData: [] });
        this.setState({ showMessage: data.errorMessage });
      }
    });
  }
  blogCategoryList() {
    $(".category-list").toggleClass("category-list-active");
  }
  srcTypeImage(ev) {
    ev.target.src = bolgtemp;
  }

  render() {
    const urlPath = window.location.origin.toString();
    return (
      <div className="pamba-wrapper">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <Helmet>
          <title>
            {"HealthInfo | " + window.sessionStorage.getItem("siteName") ===
            null
              ? ""
              : window.sessionStorage.getItem("siteName")}
          </title>
          <meta
            name="description"
            content={
              "HealthInfo | " + window.sessionStorage.getItem("siteName") ===
              null
                ? ""
                : window.sessionStorage.getItem("siteName")
            }
          />
        </Helmet>
        <div className="container blog-wrapper">
          <h1 className="telehealthfeaturehead text-center"> Blog </h1>
          <div className="row mt-1">
            <div className="col-lg-3 col-md-4">
              <div className="category-main">
                <div className="category-list">
                  {this.state.selectCategotyList.map((obj) => (
                    <a href="#">
                      <div
                        className="category-sub"
                        id={"healthBlogListId" + obj.categoryId}
                        onClick={() => this.categorySearch(obj)}
                      >
                        {obj.categoryName}
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
            {this.state.resultData.length > 0 ? (
              <div className="col-lg-9 col-md-8" id="blog-detailsId">
                <div className="row mt-1 mt-md-0">
                  {this.state.resultData.map((data) => {
                    var slug = data.slug;
                    slug = slug
                      .replace(/\s+/g, "-")
                      .replace("/", "-")
                      .replace("|", "-");
                    slug = slug.replace("--", "-").replace("---", "-");
                    return (
                      <div className="blog-listbox">
                        <div className="blog-listImgDiv">
                          <img
                            onError={this.srcTypeImage}
                            src={data.imageName}
                            alt="Blog Image Name"
                            className="img-fluid blog-listImg"
                          ></img>
                        </div>
                        <div className="blog-list-detils">
                          <div className="blogtag-name">
                            {data.healthInfoTitle}
                          </div>
                          <div className="bloguserList-text bloguserList-text-custom">
                            <InnerHTML html={data.shortDescription} />
                            {/* {data.shortDescription}{" "} */}
                            <div />
                          </div>
                          {/* <div className="blogtag-main">
                          <div className="blogtag-sub"> Remote work</div>
                          <div className="blogtag-sub"> Webinar</div>
                        </div> */}
                          <Link
                            to={"/HealthBlog/" + data.healthInfoId + "/" + slug}
                            className="readmoreBlog"
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div
                className="col-lg-12 col-md-12 py-5"
                id="blog-detailsId"
                style={{ textAlign: "center" }}
              >
                <p className="error-msg text-center mb-0">
                  {" "}
                  {this.state.showMessage}{" "}
                </p>
              </div>
            )}
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
