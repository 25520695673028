import React, { Component, Suspense } from "react";

import $ from "jquery";
import { Link } from "react-router-dom";
import { apiCalling } from "../apiService";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import PrescriptionPopup from "../patient/prescriptionPopup";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Appointmentlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hospitals: [],
      hospitalType:'',
      doctors: [],
      appoinments: [],
      appType: "In Person",
      hospitalName: "",
      doctorName: "",
      toDate: "",
      fromDate: "",
      startDate: "",
      startDateTo: "",
      prescriptionData:[],
      medicine:'',
    };
    this.loadData = this.loadData.bind(this);
    this.cancelAppointment = this.cancelAppointment.bind(this);
    this.searchAction = this.searchAction.bind(this);
    this.resetAction = this.resetAction.bind(this);
    this.changeState = this.changeState.bind(this);
  }
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  changeState() {
    this.setState({ appType: "In Person" });
  }
  handleChange = (date) => {
    this.setState({
      fromDate: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      toDate: date,
    });
  };
  resetAction() {
    window.location.reload(false);
  }
  searchAction() {
    this.loadData();
  }
  cancelAppointment(id) {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "cancelAppoinment",
        userId: window.sessionStorage.getItem("userId"),
        appoinmentId: id,
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        alert(data.successMessage);
        $(".loader").hide();
        this.loadData();
      }
    });
  }
  componentDidMount() {
    $(".hiddenTestDiv").hide();
    $("body").removeClass("body-ovrflw-hdn-menu");
    $("#appointment-edit-main").hide();
   
    this.loadFirstData();
  }

  // appointmentEdit(obj) {
    
  //   $("#appointment-edit-main"+obj.appointmentId).show();
  //   // window.sessionStorage.setItem("appointmentType", obj.appointmentType)
  //   // window.sessionStorage.setItem("appointmentId", obj.appointmentId)
  //   // window.sessionStorage.setItem("memberId",obj.memberId)
  // }

  loadData() {
    //alert($("#hospitalName").val())
    let todateT = moment(this.state.toDate);
    let fromdateT = moment(this.state.fromDate);
    let todatex = "";

    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "userMyAppointment",
        userId: window.sessionStorage.getItem("userId"),
        siteId: window.sessionStorage.getItem("siteId"),
        max: "10",
        offset: "0",
        appointmentType: this.state.appType,
        doctorId: this.state.doctorName,
        hospitalId:$("#hospitalName").val(),
        fromDate:
          fromdateT.format("DD/MM/YYYY") === "Invalid date"
            ? ""
            : fromdateT.format("DD/MM/YYYY"),
        toDate:
          todateT.format("DD/MM/YYYY") == "Invalid date"
            ? ""
            : todateT.format("DD/MM/YYYY"),
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      // console.log("appointment",data)
      if (data.success === "1" && data.appoinments.length === 0) {
        $(".hiddenTestDiv").show();
        this.setState({
          appoinments: []
        });
    } else{
        this.setState({
          doctors: data.doctors,
          appoinments: data.appoinments,
        });
       } if(data.success === "1" && data.hospitals.length>0 ){
        this.setState({
          hospitals: data.hospitals,
        });
      }if(data.success === "1" && data.hospitals.length===1){
        this.setState({
          hospitalType: data.hospitals[0].hospitalName,
        });
      }
      $(".loader").hide();
    });
  }
  // popUpClick(){
 
  // }

  loadFirstData(){
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
          functionName: "userMyAppointment",
          userId:window.sessionStorage.getItem("userId"),
          siteId:window.sessionStorage.getItem("siteId"),
          max: "10",
          offset: "0",
          appointmentType: "In Person",
          doctorId: "",
          hospitalId: "",
          fromDate: "",
          toDate: ""

      }),
    };
    //console.log(apiJson.body);
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
    // console.log("userMyAppointment..........",data);
      if (data.success === "1" && data.appoinments.length === 0) {
         $(".hiddenTestDiv").show();
        this.setState({
          appoinments: []
        });
    } else{
        this.setState({
          doctors: data.doctors,
          appoinments: data.appoinments,
        });
       } if(data.success === "1" && data.hospitals.length>0 ){
        this.setState({
          hospitals: data.hospitals,
        });
      }if(data.success === "1" && data.hospitals.length===1){
        this.setState({
          hospitalType: data.hospitals[0].hospitalName,
        });
      }
      $(".loader").hide();
    });

  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper" style={{ overflow: "hidden" }}>
            <div class="form-head"> Appointment(s) </div>
            <div className=" sh-links"> 
                <div class="pendingLink">
                  <Link to="/patient/openAppointmentList">
                    Open Appointments
                  </Link> 
              </div> 
                <div class="pendingLink">
                  <Link to="/patient/videocalllist">
                    Video / audio Appointments
                  </Link> 
              </div>
            </div>
            <div class="custom-search row">
              <div class="col-md-4 mb-3 custom-search-sub">
                <select
                  class="input-design"
                  id="appType"
                  name="appType"
                  onChange={this.handleInputChange}
                >
                  <option value="In Person">In Person</option>
                  <option value="Video">Video</option>
                  <option value="Audio">Audio</option>
                </select>
              </div>
              <div class="col-md-4 mb-3 custom-search-sub">
                <select
                  class="input-design"
                  id="hospitalName"
                  name="hospitalName"
                  onChange={this.handleInputChange}
                >{this.state.hospitals.length > 1 ?
                  <option value="">Hospital Name </option>
                  : ''}
                  {this.state.hospitals.map((obj) => {
                    return (
                      
                      obj.hospitalName == this.state.hospitalType ?
                  <option value={obj.hospitalId} selected>{this.state.hospitalType}</option>
                  : 
                  <option value={obj.hospitalId}>{obj.hospitalName}</option>

                    );
                  })}
                </select>
              </div>
              <div class="col-md-4 mb-3 custom-search-sub">
                <select
                  class="input-design"
                  id="doctorName"
                  name="doctorName"
                  onChange={this.handleInputChange}
                >
                  <option value="">Doctor Name</option>
                  {this.state.doctors.map((obj) => {
                    return (
                      <option value={obj.doctorId}>{obj.doctorName}</option>
                    );
                  })}
                </select>
              </div>
              <div class="col-md-4 mb-3 custom-search-sub">
                <DatePicker
                  placeholder="Appointment Date"
                  id="fromDate"
                  name="fromDate"
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: {
                        behavior: ["bottom"] // don't allow it to flip to be above
                    }
                  }}
                  selected={this.state.fromDate}
                  onChange={this.handleChange}
                  dateFormat="dd/MM/yyyy"
                  calendarClassName="react-date-picker"
                  placeholderText="From Date"
                />
              </div>
              <div class="col-md-4 mb-3 custom-search-sub">
                <DatePicker
                  placeholder="Appointment Date"
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: {
                        behavior: ["bottom"] // don't allow it to flip to be above
                    }
                  }}
                  id="toDate"
                  name="toDate"
                  calendarClassName="react-date-picker"
                  placeholderText="To Date"
                  selected={this.state.toDate}
                  onChange={this.handleChangeTo}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div class="col-md-4 mb-3 custom-search-subtn">
                <button
                  class="btn btn-brdr"
                  id="btnSearch"
                  onClick={this.searchAction}
                >
                  <span class=" fa fa-search"></span>
                </button>
                <button
                  class="btn btn-brdr"
                  id="btnreset"
                  onClick={this.resetAction}
                >
                  <span class=" fa fa-undo"></span>
                </button>
              </div>
            </div>
             {/* <div class="pendingLink">
                <Link to="/patient/openAppointmentList">
                  Open Appointments
                </Link>
              </div> */}
            <div class="list-section mt-0">
              {/* <div class="pendingLink">
                <Link to="/patient/videocalllist">
                  Video / audio Appointments
                </Link>
              </div> */}
              {this.state.appoinments.length > 0 ?
              <div>
              {this.state.appoinments.map((obj) => {
                let appD = moment(obj.startTime);

                return (
                  <div class="practiceTimeline-panel practiceTimelineWrap row">
                    <div className="col-lg-2 col-md-4 text-center appnt-date-time appnt-brdr">
                      <div className="appmt-month">{appD.format("MMM")}</div>
                      <div className="appmt-date">{appD.format("DD")}</div>
                      <div className="appmt-time">
                        {moment(appD).format("h:mm a")}
                      </div>
                      {/* {obj.status =="OPEN" ? (
                      <i class="fa fa-edit ml-2 appmt-edit" title="Edit date and time" onClick={() =>this.appointmentEdit(obj)}></i>
                      ) : ''} */}
                    </div>
                    {/* <AppointmentEditPopup appointmentObj={obj}></AppointmentEditPopup> */}

                    <div className="col-lg-3 col-md-8 appnt-brdr">
                      <span class="appnt-drName">{obj.doctorName} </span>
                      <br></br>
                      <span class="appnt-hospitalName">
                        ({obj.hospitalName})
                      </span>
                    </div>
                    <div className="col-lg-3 appnt-center appnt-brdr">
                      <div className="mb-2">
                        Mobile Number{" "}
                        <span className="appnt-span-det">{obj.mobNumber}</span>{" "}
                      </div>
                      <div className="mb-2">
                        MR No{" "}
                        <span className="appnt-span-det" title={obj.memberMrNo==='undefined'?'':obj.memberMrNo}>
                          {obj.memberMrNo==='undefined'?'':obj.memberMrNo}
                        </span>{" "}
                      </div>
                      <div className="mb-2">
                      Transaction ID{" "}
                        <span className="appnt-span-det">
                          {obj.transactionId}
                        </span>{" "}
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6 appnt-type appnt-brdr">
                      Type :{" "}
                      <span className="appnt-type-text">
                        {" "}
                        {obj.appointmentType}
                      </span>
                    </div>
                    <div className="col-lg-2 col-md-6 appnt-status appnt-brdr">
                      Status :{" "}
                      <span class="appnt-status-text"> {obj.status === "CALL COMPLETED-DOCTOR" ? 'Call Completed': obj.status}</span>
                      {obj.status == "CANCELLED"||obj.status ==   "CALL COMPLETED-DOCTOR" ||obj.status == "COMPLETED" ? (
                        ""
                      ) : 
                        <input
                          type="button"
                          value="Cancel"
                          className="formButton cancelButton appnt-btn"
                          onClick={() =>
                            this.cancelAppointment(obj.appointmentId)
                          }
                        ></input>
                      }
                    </div>
                  </div>

                  // <div class="practiceTimeline-panel practiceTimelineWrap">
                  //   <div class="practiceTimeSpecific">
                  //     <p class="timeSpecificBlock">
                  //       <span>{appD.format("DD")}</span>
                  //       <span class="timeSpecificSubBlock">
                  //         {appD.format("MMM")} {appD.format("YYYY")}
                  //       </span>
                  //     </p>
                  //   </div>

                  //   <div class="practiceTimelineDivision">
                  //     <div class="practiceTimeline-heading">
                  //       <h4 class="practiceTimeline-title practiceTitleTop">
                  //         <span class="elipsis">{obj.doctorName} </span>
                  //         <span class="span2 elipsis">{obj.hospitalName}</span>
                  //         <span class="span2 elipsis">
                  //           Transaction Id :{" "}
                  //           <font color="red">{obj.transactionId}</font>
                  //         </span>
                  //       </h4>

                  //       <div class="my-2">
                  //         <small class="color-maintext mrgRgtPrctmngt">
                  //           <i class="fa fa-clock-o"></i>
                  //           <span class="appoinmentTymBlock">
                  //             {" "}
                  //             {moment(appD).format("h:mm a")}
                  //           </span>
                  //           <i
                  //             class="fa fa-edit ml-2"
                  //             onClick={this.appointmentEdit}
                  //           ></i>
                  //           <AppointmentEditPopup></AppointmentEditPopup>
                  //         </small>
                  //         <small class="text-muted mrgRgtPrctmngt">
                  //           <i class="fa fa-mobile"></i> {obj.mobNumber}
                  //         </small>
                  //         <small class="text-muted mrgRgtPrctmngt">
                  //           {" "}
                  //           MR No : {obj.memberMrNo}{" "}
                  //         </small>
                  //       </div>
                  //     </div>
                  //     <div class="col-12 float-left p-0">
                  //       <span class="statusPrctmngt confirm">
                  //         Type : {obj.appointmentType}
                  //       </span>
                  //       <span class="statusPrctmngt confirm mrgRgtPrctmngt pt-0">
                  //         Status : {obj.status}
                  //       </span>
                  //     </div>
                  //     <div className="col-12 float-left p-0 text-right">
                  //       <input
                  //         type="button"
                  //         value="cancel"
                  //         className="formButton cancelButton"
                  //         onClick={() =>
                  //           this.cancelAppointment(obj.appointmentId)
                  //         }
                  //       ></input>
                  //     </div>
                  //   </div>
                  // </div>
                );
              })}
               </div>
                   :
               <p className="error-msg text-center mb-0   hiddenTestDiv"> No Data Found </p>
                } 
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
        {/* <PrescriptionPopup></PrescriptionPopup> */}
      </div>
    );
  }
}
