import React, { Component } from "react";
import Slider from "react-slick";
const bannerimage = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/banner.jpg";

export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerData: [],
    };
  }
  componentDidMount() {
    const bannerData = []; // JSON.parse(window.sessionStorage.getItem("Banner"));
    if (bannerData) {
      this.setState({ bannerData: bannerData });
    } else {
      // window.location.reload(false);
    }
  }

  render() {
    const banner = "parse";
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <div className="mentalBanner ">
        <Slider {...settings}>
          {this.state.bannerData.map((ban) => {
            return (
              <div>
                <div className="mentalBannerImage">
                  <img src={bannerimage} alt=""/>
                  <div className="mentalBannerContent">
                    <h1>{ban.parentName}</h1>
                    <h3>{ban.parentSubTitle}</h3>
                    {/*<span className="bannerButton">{ban.parentSubTitle}</span>*/}
                  </div>
                </div>
              </div>
            );
          })}
          <div>
            <div className="mentalBannerImage">
              <img src={bannerimage}  alt=""/>
              <div className="mentalBannerContent">
                <h1>“TAKE HOME A HEALTHY BABY”</h1>

                {/* <span className="bannerButton">Online Booking</span> */}
              </div>
            </div>
          </div>
          <div>
            <div className="mentalBannerImage">
              <img src={bannerimage}  alt=""/>
              <div className="mentalBannerContent">
                <h1>“TAKE HOME A HEALTHY BABY”</h1>
                {/* <span className="bannerButton">Online Booking</span> */}
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
