import React, { Component, Suspense } from "react";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import QrcodeImg from "../../image/icons/qrcode.png";
import Deactivate from "../../image/icons/deactvate.png";
import { apiCalling } from "../../apiService";
import { Link } from "react-router-dom";
import $ from "jquery";
import Pagination from "react-js-pagination";
// import Header from "../../layout/header";
// import Footer from "../../layout/footer";
import Logoimg from "../../image/icons/image-preview.png";
import "../../css/admin.css"
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../../" + folderName + "/header"));
const Footer = React.lazy(() => import("../../" + folderName + "/footer"));
export default class IdaStoresList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeResult: [],
      totalCount: 0,
      activePage: 1,
      showMessage: "Waiting for data... ",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify(
        {
          functionName: "getStoreListingByCategory",
          idaStoreCategoryId: "",
          keyWord: "",
          idaStoreDetailsId: "",
          Offset:offset.toString(),
          max:max,
        }
      ),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          approvalResult: data.RepApprovalSearchData,
          totalCount: data.totalCount,
        });
        $(".loader").hide();
      }
    });
  }

  componentDidMount() {
    this.getIdaStoreDetails();
  }

  getIdaStoreDetails() {
    $(".loader").show();
    const idaStoreList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getStoreListingByCategory",
        idaStoreCategoryId: "",
        keyWord: "",
        idaStoreDetailsId: "",
        Offset: "0",
        max: "10",
      }),
    };
    apiCalling(idaStoreList).then((data) => {
      // console.log(data);
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          storeResult: data.result,
          totalCount: data.totalCount,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          storeResult: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }

  changeText = () => {
    if ($("#searchType").val() !== "0") {
      document.getElementById("resultTxt").disabled = false;
      $("input[name=resultTxt]").focus();
    } else {
      document.getElementById("resultTxt").disabled = true;
    }
    document.getElementById("resultTxt").value = "";
  };

  searchHandler() {
    if ($("#searchType").val() !== "0" && $("#resultTxt").val() === "") {
      var t = document.getElementById("searchType");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#resultTxt").focus();
      return false;
    } else {
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "getStoreListingByCategory",
          keyWord: $("#resultTxt").val(),
          status: $("#searchStatusType").val() === '0' ? '' : $("#searchStatusType").val(),
          idaStoreDetailsId: "",
          Offset: "0",
          max: "10",
        }),
      };
      $(".loader").show();
      apiCalling(searchData).then((data) => {
        // console.log(data);
        if (data.success === "1") {
          $("#paginationType").show();
          $(".loader").hide();
          this.setState({
            storeResult: data.result,
            totalCount: data.totalCount,
            activePage: 1,
          });
        }
        if (data.success === "0") {
          $("#paginationType").hide();
          $(".loader").hide();
          this.setState({
            storeResult: [],
            showMessage: data.errorMessage,
          });
        }
      });
    }
  }

  statusChangeHandler(id, Status) {
    var x = false;
    if (Status === true) {
      x = window.confirm("Do you want to Disable this?");
    } else {
      x = window.confirm("Do you want to Enable this?");
    }
    if (x === true) {
      const SatusData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "storeListingStatusChange",
          idaStoreDetailsId: id,
          status: Status === true ? "false" : "true",
        }),
      };
      $(".loader").show();
      apiCalling(SatusData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Status Changed Successfully");
          this.getIdaStoreDetails();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  }

  srcTypeImage(ev) {
    ev.target.src = Logoimg;
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />
       
        <div className="loader"></div>
        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboard">Dashboard</a>
              </li>
              {/* <li>
                <a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a>
              </li> */}
              <li>
                <a href="" className="current">
                  IDA Stores List
                </a>
              </li>
            </ul>
          </div>
          <div className="head-hospMangmntList">IDA Stores List</div>

          <div className="HosptlMangemntTable">
            <div className="AddNewHspbtnDiv">
              <a
                href="/adminManagement/pharmadashboardList/addEditIdaStore"
                className="addnewhspbtn"
              >
                {" "}
                Add New Store
              </a>
            </div>
            {this.state.totalCount > 5 ? (
              <div className="pagination-qualList" id="paginationType">
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalCount}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange}
                />
              </div>
            ) : (
              ""
            )}
            <div className="tableHeader-HspMangemnt">
              <div className="selectField-HspMangmnt">
                <select
                  className="slecthsp slecthspMob"
                  id="searchType"
                  name="searchType"
                  onChange={() => this.changeText()}
                >
                  <option value="0">Search In</option>
                  <option value="1">Store Code</option>
                  <option value="2">Store Name</option>
                  <option value="3">Contact Number</option>
                </select>
              </div>
              <div className="selectField-HspMangmnt">
                <input
                  type="text"
                  className="hspmngInput hspmngInputmob"
                  name="resultTxt"
                  id="resultTxt"
                  disabled
                />
              </div>
              <div className="selectField-HspMangmnt">
                <select className="slecthsp slecthspMob" id="searchStatusType" name="searchStatusType">
                  <option value="0">Status</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>
              <div className="selectField-HspMangmnt">
                <button
                  className="searchbtn-hspMng"
                  onClick={() => this.searchHandler()}
                >
                  Search
                </button>
              </div>
            </div>
            {this.state.storeResult.length > 0 ? (
              <div class="table-responsive">
                <table className="table table-bordered-hsp">
                  <thead>
                    <tr>
                      <th className="tablehead-hspmng">ID</th>
                      <th className="tablehead-hspmng">Store Code</th>
                      <th className="tablehead-hspmng">Store Name </th>
                      <th className="tablehead-hspmng">Address</th>
                      <th className="tablehead-hspmng">Contact Number</th>
                      <th className="tablehead-hspmng">Service Number</th>
                      <th className="tablehead-hspmng">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.storeResult.map((data) => (
                      <tr>
                        <td>{data.idaStoreDetailsId}</td>
                        <td>{data.storeCode}</td>
                        <td>{data.storeName}</td>
                        <td>{data.address}</td>
                        <td>{data.contactNo}</td>
                        <td>{data.seviceContactNumber}</td>
                        <td className="nowrap">
                          <div>
                            <span>
                              <Link
                                to={
                                  "/adminManagement/pharmadashboardList/addEditIdaStore/" +
                                  data.idaStoreDetailsId
                                }
                                className="mr-3"
                              >
                                <img
                                  src={Edit}
                                  alt=""
                                  width="18"
                                  className="img-fluid"
                                />
                              </Link>
                            </span>
                            <span>
                              <Link
                                to={
                                  "/adminManagement/dataMigration/generateQRcodeIda/" +
                                  data.idaStoreDetailsId
                                }
                                className="mr-3"
                              >
                                <img
                                  src={QrcodeImg}
                                  alt=""
                                  title="Click to Generate QRCode"
                                  width="18"
                                  className="img-fluid"
                                />
                              </Link>
                            </span>
                            {data.status === true ? (
                              <img
                                src={Deactivate}
                                width="20"
                                title="Click to Deactivate "
                                className="conf-cursorpointer"
                                alt=""
                                onClick={() => {
                                  this.statusChangeHandler(data.idaStoreDetailsId, true);
                                }}
                              ></img>
                            ) : (
                              <img
                                src={tick}
                                alt=""
                                title="Click to Activate"
                                className="conf-cursorpointer"
                                width="18"
                                onClick={() => {
                                  this.statusChangeHandler(data.idaStoreDetailsId, false);
                                }}
                              ></img>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {this.state.totalCount > 5 ? (
                  <div className="pagination-qualList pagination-qualListwidth" id="paginationType">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={10}
                      onChange={this.handlePageChange}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <p className="error-msg text-center mb-0">
                {" "}
                {this.state.showMessage}{" "}
              </p>
            )}
          </div>
        </div>
        {folderName !== null && <Footer />}
          </section>
        </Suspense>
        {/* <Suspense fallback={<div></div>}>
          <section>
          
          </section>
        </Suspense> */}
      </div>
    );
  }
}
