import React, { Component, Suspense } from "react";
import ViewImg from "../image/icons/view.png";
import InnerHTML from "dangerously-set-html-content";
import Close from "../image/icons/close-sm.png";
import Analisationpopup from "./analisationpopup";
import Pagination from "react-js-pagination";
import $ from "jquery";
export default class AnalyticsListAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedDetailsType: "",
      SelectedDetailsId: "",
      EmojiDeatilsUser: [],
      pageOffset: 0,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  handlePageChange(pageNumber) {
    // console.log("page no====" + pageNumber);
    this.props.setPagination(pageNumber);
  }
  componentDidMount() {
    $("#analisePopup").hide();
    $("#analiseEmojiPopup").hide();
  }
  addEditSpec = (messageId, analyticsType) => {
    $("#analisePopup").show();
    this.setState({
      SelectedDetailsId: messageId,
      SelectedDetailsType: analyticsType,
    });
  };
  EmojiDetails = (EmojiDataDetails) => {
    $("#analiseEmojiPopup").show();
    this.setState({
      EmojiDeatilsUser: EmojiDataDetails,
    });
  };
  cancelPopup = () => {
    $("#analiseEmojiPopup").hide();
  };
  render() {
    return (
      <div>
        <div className="loader"></div>
        <div class="table-responsive">
          {this.props.eventAlertCount !== 0 ? (
            <table class="table">
              <thead>
                <tr>
                  <th>Alert Title</th>
                  <th>Alert Message</th>
                  <th>Count</th>
                  <th>Emoji Count</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "12px" }}>
                {this.props.eventViewListDataAlert.map((data) => (
                  <tr>
                    <td>{data.messageTitle}</td>
                    <td className="journl-des-txt">
                      <InnerHTML html={data.messageDetails} />
                    </td>
                    <td>
                      <a
                        className="mr-2 edit_href"
                        onClick={() =>
                          this.addEditSpec(data.messageId, data.analyticsType)
                        }
                      >
                        {data.viewCount}{" "}
                        <img
                          src={ViewImg}
                          alt="Edit"
                          title="More"
                          width="18"
                          className="img-fluid"
                        />
                      </a>
                    </td>
                    <td className="no-wrap">
                      {data.emojiData.map((EmojiData) => (
                        <span>
                          <a className="mr-2 emoji-text">
                            {EmojiData.count}
                            <img
                              src={EmojiData.imageName}
                              onClick={() =>
                                this.EmojiDetails(EmojiData.userData)
                              }
                              alt="Edit"
                              title={EmojiData.emojiName}
                              width="18"
                              className="img-fluid edit_view"
                            />
                          </a>
                        </span>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="nodata_found">No Data Found</div>
          )}
          {this.props.eventAlertCount !== 0 && (
            <div className="paginationSection orderm-paginationSection">
              <Pagination
                activePage={this.props.activepage}
                itemsCountPerPage={10}
                totalItemsCount={this.props.eventAlertCount}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          )}
        </div>
        <p className="error-msg text-center mb-0"> </p>

        {this.state.SelectedDetailsId !== "" && (
          <Analisationpopup
            key={this.state.SelectedDetailsId}
            PopupnewsFeedDownloadDataId={this.state.SelectedDetailsId}
            PopupnewsFeedDownloadType={this.state.SelectedDetailsType}
          ></Analisationpopup>
        )}
        {/*  */}
        {this.state.EmojiDeatilsUser !== "" && (
          <div class="popup-overlay modal_overlay" id="analiseEmojiPopup">
            <div className="popup-contentDiv popup-content-padd">
              <div className="modal-popup">
                <span
                  className="closepopupqual close-popup-top"
                  id="txtCancel"
                  onClick={() => this.cancelPopup()}
                >
                  <img
                    src={Close}
                    alt="Close"
                    className="img-fluid"
                    width="25"
                  />
                </span>
                <div className="content">
                  <div class="">
                    <div class="HosptlMangemntTable">
                      <div class="table-responsive">
                        <table className="table table-bordered-hsp table_custom">
                          <thead>
                            <tr>
                              <th>User Name</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.EmojiDeatilsUser.map((EmojiData) => (
                              <tr>
                                <td>{EmojiData.userName}</td>
                                <td>{EmojiData.createdDate}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
