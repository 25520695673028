import { Component } from "react";
import Chart from "react-apexcharts";

export default class SelectHosp extends Component {
    constructor(props) {
        super(props);

        this.state = {
        
            series: [
          //     {
          //       name: "In Person",
          //       data: [44, 55, 41, 64, 22]
          // }, 
          {
              name: "Video Chat",
                data: this.props.revenueVideoChatData
          },
          {
                  name: "Text Chat",
                data: this.props.revenueTextchatData
          },
          {
              name: "Audio Chat",
             data: this.props.revenueAudioChatData
          }
          ],
          options: {
            chart: {
              type: 'bar',
              height: 430,
              toolbar: {
                show: false
              }
            },
            plotOptions: {
                bar: {
                  horizontal: false,
                  dataLabels: {
                    position: 'bottom',
                  },
                }
              },
              colors:['#5079ce', '#68cbe0','#d80f5a','#507de4'],
              legend: {
          position: 'top'
        },
              dataLabels: {
                enabled: false,
                offsetX: -6,
                style: {
                  fontSize: '12px',
                  colors: ['#fff']
                }
              },
              stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
              },
              tooltip: {
                shared: true,
                intersect: false
              },
              xaxis: {
                type:"text",
                categories: this.props.dateData,
              },
              yaxis: {
                title: {
                  text: "Revenue",
                },
                min: 0,
                max: this.props.revenueMax + 5,
              },
            },
        }
      }
      componentDidMount(){
      }
  render() {

    return (
      <div id="chart">
        <Chart
          options={this.state.options} series={this.state.series} type="bar" height={200}
        />
      </div>
    );
  }
}
