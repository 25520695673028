import React, { Component, Suspense } from "react";
import { apiCalling } from "../apiService";
import InnerHTML from "dangerously-set-html-content";
const folderName = window.sessionStorage.getItem("homeName");
// const header1 = require("../pamba/h")
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqData: [],
    };
  }
  componentDidMount() {
    if (folderName === null) {
      window.sessionStorage.setItem("pathname", window.location.pathname);
      window.location.href = "/";
    }
    if (this.props.match.params.id) {
      this.faqDataCall(this.props.match.params.id)
    }
    // $('.accordionTab').on('click', function () {
    //   $(this).find(".collapse").slideToggle();
    //   $(".accordionTab").not(this).find(".collapse").slideUp();
    //   $(this).toggleClass("accordionTabActive");
    //   $(".accordionTab").not(this).removeClass("accordionTabActive");

    // });
  }
  faqDataCall = (id) => {
    const doctorDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorFaqDetails",
        doctorId: id,
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    try {
      apiCalling(doctorDetails)
        .then(async (data) => {
          if (data.success === "1") {
            const groupedData = {};
            data.result?.inpersonAppointmentData.forEach((obj) => {
              if (!groupedData[obj.hospitalId]) {
                groupedData[obj.hospitalId] = {
                  hospitalId: obj.hospitalId,
                  hospitalName: obj.hospitalName,
                  appointments: [],
                };
              }
              groupedData[obj.hospitalId].appointments.push(obj);
            });
            const groupedArray = Object.values(groupedData);
            const allAppointments = groupedArray.map((group) => group.appointments).flat();
            this.setState({
              faqData: data.result,
              inpersonAppointmentData: groupedArray,
            });
          } else {
            this.setState({ faqData: [], inpersonAppointmentData: [] });
          }
        })
        .catch((error) => {
          console.error("API request error:", error);
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  formatOptions = {
    timeZone: 'Asia/Kolkata',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  timestampTo12HourTime(timestamp) {
    if (timestamp) {
      const date = new Date(timestamp);
      date.setUTCHours(date.getUTCHours() + 5);
      date.setUTCMinutes(date.getUTCMinutes() + 30);
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const period = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      const timeString = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
      return timeString;
    } else { return "Not Available"; }
  }
  convertTo12HourFormat(time) {
    if (time) {
      if (time == 24) { return "12:00 AM" }
      else if (time == 25) { return "01:30 AM" }
      else if (time == 26) { return "02:30 AM" }
      else if (time == 27) { return "03:30 AM" }
      else if (time == 28) { return "04:30 AM" }
      else if (time == 29) { return "05:30 AM" }
      else if (time == 30) { return "06:30 AM" }
      else if (time == 31) { return "07:30 AM" }
      else if (time == 32) { return "08:30 AM" }
      else if (time == 33) { return "09:30 AM" }
      else if (time == 34) { return "10:30 AM" }
      else if (time == 35) { return "11:30 AM" }
      else if (time == 36) { return "12:30 AM" }
      else if (time == 37) { return "01:30 PM" }
      else if (time == 38) { return "02:30 PM" }
      else if (time == 39) { return "03:30 PM" }
      else if (time == 40) { return "04:30 PM" }
      else if (time == 41) { return "05:30 PM" }
      else if (time == 42) { return "06:30 PM" }
      else if (time == 43) { return "07:30 PM" }
      else if (time == 44) { return "08:30 PM" }
      else if (time == 45) { return "09:30 PM" }
      else if (time == 46) { return "10:30 PM" }
      else if (time == 47) { return "11:30 PM" }
      else if (time == 48) { return "12:30 PM" }
      else if (time == 49) { return "12:00 AM" }

      else {
        let hours = time;
        let period = 'AM';
        if (parseInt(hours, 10) >= 12) {
          period = 'PM';
          if (parseInt(hours, 10) > 12) {
            hours = (parseInt(hours - 12)).toString();
          }
        }
        return `${hours < 10 ? "0" + hours : hours}:00 ${period}`;
      }
    }
    else { return "Not Available"; }

  }
  render() {
    return (
      <div>
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            {folderName !== null && <Header />}
            <div className="loader"></div>
            <div class="container">
              <div class="main-wrapper1">
                {/* <div id="accordion">
                    <div class="card accordionTab accordionTabActive mb-3">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button class="btn btn-link" >
                            Collapsible Group Item #1
                          </button>
                        </h5>
                      </div>
                      <div class="collapse show">
                        <div class="card-body">
                          
                        </div>
                      </div>
                    </div>
                  </div> */}
                <InnerHTML html={this.state.faqData.message} />
                {this.state.inpersonAppointmentData?.length > 0 && (
                  <div className="card">
                    <div className="table-responsive card-body pb-0">
                      <div className="form-head">Inperson Appointment Details</div>
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th className="font-weight-bold" style={{ minWidth: "150px" }}>Hospital</th>
                            <th className="font-weight-bold" colSpan={8}>Appointment Date & Time</th>
                          </tr>
                        </thead>
                        {this.state.inpersonAppointmentData?.map((obj, objIndex) => (

                          <tbody key={objIndex}>
                            <tr>
                              <td style={{ verticalAlign: "top" }}>{obj.hospitalName}</td>
                              {obj?.appointments.map((data, objIndex) => (
                                <>
                                  <td colSpan={objIndex === 2 ? 1 : 6} >
                                    {objIndex != "1" ? <p className="font-weight-bold">Schedule-1</p>
                                      : <p className="font-weight-bold">Schedule-2</p>}
                                    <table className={"table "}>
                                      <thead>
                                        <tr>
                                          <th className="border-0 font-weight-bold">Day</th>
                                          <th className="border-0 font-weight-bold">From</th>
                                          <th className="border-0 font-weight-bold">To</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Sunday</td>
                                          <td>{this.convertTo12HourFormat(data.sun_from)}</td>
                                          <td>{this.convertTo12HourFormat(data.sun_to)}</td>
                                        </tr>
                                        <tr>
                                          <td>Monday</td>
                                          <td>{this.convertTo12HourFormat(data.mon_from)}</td>
                                          <td>{this.convertTo12HourFormat(data.mon_to)}</td>
                                        </tr>
                                        <tr>
                                          <td>Tuesday</td>
                                          <td>{this.convertTo12HourFormat(data.tue_from)}</td>
                                          <td>{this.convertTo12HourFormat(data.tue_to)}</td>
                                        </tr>
                                        <tr>
                                          <td>Wednesday</td>
                                          <td>{this.convertTo12HourFormat(data.wed_from)}</td>
                                          <td>{this.convertTo12HourFormat(data.wed_to)}</td>
                                        </tr>
                                        <tr>
                                          <td>Thursday</td>
                                          <td>{this.convertTo12HourFormat(data.thu_from)}</td>
                                          <td>{this.convertTo12HourFormat(data.thu_to)}</td>
                                        </tr>
                                        <tr>
                                          <td>Friday</td>
                                          <td>{this.convertTo12HourFormat(data.fri_from)}</td>
                                          <td>{this.convertTo12HourFormat(data.fri_to)}</td>
                                        </tr>
                                        <tr>
                                          <td>Saturday</td>
                                          <td>{this.convertTo12HourFormat(data.sat_from)}</td>
                                          <td>{this.convertTo12HourFormat(data.sat_to)}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td></>))}
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                )}
                {this.state.faqData?.videoAppointmentData?.length > 0 &&
                  <div className="card mt-4">
                    <div class="table-responsive card-body pb-0">
                      <div className="form-head mb-3">Video Appointment Details</div>
                      <table class="table">
                        <thead>
                          <tr>
                            <th className="font-weight-bold">Day</th>
                            <th className="font-weight-bold">From</th>
                            <th className="font-weight-bold">To</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.faqData?.videoAppointmentData?.map((obj) => (
                            <tr>
                              <td>{obj.day}</td>
                              <td>{this.timestampTo12HourTime(obj.fromTime)}</td>
                              <td>{this.timestampTo12HourTime(obj.toTime)}</td>
                            </tr>))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
                <div className="col-md-12 noData"  >
                  {(!this.state.faqData?.videoAppointmentData?.length > 0) && (!this.state.inpersonAppointmentData?.length > 0) && folderName !== null &&
                    "Not Available"
                  }</div>
              </div>
            </div>
            {folderName !== null && <Footer />}
          </section>
        </Suspense>
      </div>
    )
  }
}
