import React, { Component } from "react";
import $ from "jquery";
import { apiCalling, changeDateFormat } from "../apiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import TimePicker from "rc-time-picker";

 
const format = "h:mm a";
const now = moment().hour(0).minute(0);
 

export default class newFeedCalender extends Component {
  constructor(props) {
    super(props);
    this.state = {
        startDate:new Date(),
        editTime:new Date()
    
    };
  }

  componentDidMount() {
    $(".popup-hide").click(function () {
      $(".popup-overlay").hide();
      $("body").removeClass("overflow-hidden");
    });
  }
  
  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
    $("#selectDate").val(moment(date).format("DD/MM/YYYY"));
  };

  confirmRequestPopUp(id){

    let Date = $("#editDate" + id).val();
    let Time = $("#editTime" + id).val();
    let appointmentTime = moment(Time, "hh:mm a").format("LT")

        const dataList = {
          method: 'POST',
          body: JSON.stringify({
            "functionName": "pharmaAdRequest",
            "userId": window.sessionStorage.getItem("userId"),
            "adId": this.props.requestId,
            "requestType":this.props.requestedType,
            "appointmentDateTime":Date + " " + appointmentTime
          })
        }
        $(".loader").show();
        apiCalling(dataList).then((data) => {
          $(".loader").hide();
          if (data.success === "1") {
            alert("Request Sent Successfully")
            $("#appointment-edit-main" + this.props.requestId).hide();
          } else if (data.success === "0") {
            alert(data.errorMessage)
          }
        });
  }

  render() {
    return (
      <div class="popup-overlay time-date-popup" style={{display:"none"}} 
      id={"appointment-edit-main"+this.props.requestId}>
        {/* <div className="popup-content"> */}
          <div className="newsfeed-popup "> 
          <div className="modal-popup">
            <a className="close-popup popup-hide">&times;</a>
            <div className="header border-0">
              {" "}
              Request Office Visit{" "}
            </div>
            <div className="content">
              <div class="row sh-pop-content">
                <div class="col-6">
                <label class="label-text">Appointment Date</label>
                  <DatePicker
                   placeholderText="Edit Date"
                   id={"editDate"+this.props.requestId }
                   name={"editDate"+this.props.requestId}
                   minDate={new Date()}
                   selected={this.state.startDate}
                   onChange={this.handleChange}
                   dateFormat="dd/MM/yyyy"
                   calendarClassName="react-date-picker"
                  />
                </div>
                <div class="col-6">
                  <label class="label-text">Appointment Time</label>
                  <TimePicker
                     defaultValue={moment(this.state.editTime)}
                    showSecond={false}
                    className="app-popup-time"
                    format={format}
                    use12Hours
                    inputReadOnly
                    id={"editTime"+this.props.requestId }
                    name={"editTime"+this.props.requestId}
                  />

                
                </div>
              </div>
              <div className="text-center">
                <a href="#">
                  <input
                    type="submit"
                    value="Change"
                    className="formButtonBg mx-1"
                    onClick={() =>
                      this.confirmRequestPopUp(this.props.requestId)
                    }
                  ></input>
                </a>
                <input
                  type="button"
                  value="cancel"
                  className="formButtonBg cancelButtonBg mx-1 popup-hide"
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDay(props, currentDate, selectedDate) {
    // Adds 0 to the days in the days view
    return <td {...props}>{"0" + currentDate.date()}</td>;
  }
  renderMonth(props, month, year, selectedDate) {
    // Display the month index in the months view
    return <td {...props}>{month}</td>;
  }
  renderYear(props, year, selectedDate) {
    // Just display the last 2 digits of the year in the years view
    return <td {...props}>{year % 100}</td>;
  }

}
